import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Decrypt_Id_Name } from '../../../../Common/Utility';
import { fetchPostData, ScreenPermision } from '../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import CommentsAddUp from './CommentsAddUp';

const Comments = () => {

    const { get_Arrest_Count, localStoreArray, get_LocalStorage } = useContext(AgencyContext);

    const [commentData, setCommentData] = useState([])
    const [upDateCount, setUpDateCount] = useState(0)
    //screen permission 
    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState();

    const localStore = {
        Value: "",
        UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
        Key: JSON.stringify({ AgencyID: "", PINID: "", ArrestID: '', }),
    }
    useEffect(() => {
        if (!localStoreArray?.AgencyID || !localStoreArray?.PINID) {
            get_LocalStorage(localStore);
        }
    }, []);

    // Onload Function
    useEffect(() => {
        if (localStoreArray) {
            if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
                if (localStoreArray.ArrestID) { get_CommentsData(localStoreArray?.ArrestID) }
                getScreenPermision(localStoreArray?.AgencyID, localStoreArray?.PINID);
            }
        }
    }, [localStoreArray])


    const get_CommentsData = (ArrestID) => {
        const val = {
            'ArrestID': ArrestID
        }
        fetchPostData('ArrestComments/GetData_ArrestComments', val)
            .then(res => {
                if (res) {
                    setCommentData(res);
                } else {
                    setCommentData([]);
                }
            })
    }

    const getScreenPermision = (LoginAgencyID, LoginPinID) => {
        ScreenPermision("I033", LoginAgencyID, LoginPinID).then(res => {
            if (res) {
                setEffectiveScreenPermission(res)
            } else {
                setEffectiveScreenPermission()
            }
        });
    }

    const columns = [
        {
            width: '120px',
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', }}>Action</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, left: 20 }}>
                    {
                        effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok ?
                            <Link to={''} onClick={(e) => editComments(e, row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#FieldCommentsModal" >
                                <i className="fa fa-edit"></i>
                            </Link>
                            : <></>
                            : <Link to={''} onClick={(e) => editComments(e, row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#FieldCommentsModal" >
                                <i className="fa fa-edit"></i>
                            </Link>
                    }
                </div>

        },
        {
            name: 'Comments',
            selector: (row) => <>{row?.Comments ? row?.Comments.substring(0, 60) : ''}{row?.Comments?.length > 40 ? '  . . .' : null} </>,
            sortable: true
        },

        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 0 }}>Delete</p>,
            cell: row =>
                <div style={{ position: 'absolute', top: 4, right: 5 }}>

                    {
                        effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
                            <Link to={`#`} onClick={''}  className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                                <i className="fa fa-trash"></i>
                            </Link>
                            : <></>
                            : <Link to={`#`} onClick={''} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                                <i className="fa fa-trash"></i>
                            </Link>
                    }
                </div>

        }
    ]

    const editComments = (e, val) => {
        e.preventDefault();
        get_Arrest_Count(val.ArrestID)
        setUpDateCount(upDateCount + 1);
    }

    return (
        <>
            <div className="col-md-12 mt-2">
                <div className="row">
                    <div className="col-md-12">
                        <div className="bg-line text-white py-1 px-2 d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0">Comments</p>
                            <p className="p-0 m-0">
                                <Link to="" className="btn btn-sm bg-green text-white px-2 py-0"
                                    data-toggle="modal" data-target="#FieldCommentsModal">
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </p>
                        </div>
                        <div className="col-12 ">
                            <DataTable
                                dense
                                columns={columns}
                                data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? commentData : '' : commentData}
                                pagination
                                selectableRowsHighlight
                                highlightOnHover
                                noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <CommentsAddUp />
        </>
    )
}
export default Comments;