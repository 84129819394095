import React, { useContext, useEffect, useRef, useState, } from 'react'
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import SelectBox from '../../../../Common/SelectBox';
import { Comman_changeArrayFormat, Comman_changeArrayFormatBasicInfo, threeColArray, threeColArrayWithCode } from '../../../../Common/ChangeArrayFormat';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import Select from "react-select";
import { BiasError, ErrorStyleBias, ErrorStyleOffenderUse, ErrorStyleWeapon, ErrorTooltip, OffenderUseError_N, OffenderUseError_Other, WeaponError } from '../ErrorNibrs';
import { Link, Navigate } from 'react-router-dom';
import { AgencyContext } from '../../../../../Context/Agency/Index';

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <p className='ml-2 d-inline'>{props.label}</p>
      </components.Option>
    </div>
  );
};

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);



const BasicInformation = ({ loginPinID, loginAgencyID, offenceID, }) => {
  const SelectedValue = useRef();

  const { setChangesStatus, changesStatus, } = useContext(AgencyContext);
  // DropDown Value
  const [pretentedDrp, setPretentedDrp] = useState([]);
  const [pointExitDrp, setPointExitDrp] = useState([]);
  const [pointEntryDrp, setPointEntryDrp] = useState([]);
  const [crimeOffenderUseDrp, setCrimeOffenderUseDrp] = useState([]);
  const [crimeActivityDrp, setCrimeActivityDrp] = useState([]);
  const [crimeBiasCategoryDrp, setCrimeBiasCategoryDrp] = useState([]);
  const [toolsUseIDDrp, setToolsUseIDDrp] = useState([]);
  const [crimeTargetDrp, setCrimeTargetDrp] = useState([]);
  const [crimeSuspectDrp, setCrimeSuspectDrp] = useState([]);
  const [crimeSecurityviolatedDrp, setCrimeSecurityviolatedDrp] = useState([]);
  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState()
  const [methodOfOperationData, setMethodOfOperationData] = useState();
  const [methodOfOperationDrp, setMethodOfOperationDrp] = useState();
  const [methodOfOperationID, setMethodOfOperationID] = useState();
  const [methodOfEntryData, setMethodOfEntryData] = useState([]);
  const [methodEntryDrp, setMethodEntryDrp] = useState();
  const [methodOfEntryId, setMethodOfEntryId] = useState();
  const [weaponData, setWeaponData] = useState();
  const [weaponDrp, setWeaponDrp] = useState();
  const [weaponID, setWeaponID] = useState();
  const [weaponCode, setWeaponCode] = useState('')
  const [methodOperation, setMethodOperation] = useState('')

  // Edit Value Data
  const [editval, setEditval] = useState([]);
  const [pointExitEditVal, setPointExitEditVal] = useState([]);
  const [pointEntryEditVal, setPointEntryEditVal] = useState([]);
  const [crimeOffenderUseEditVal, setCrimeOffenderUseEditVal] = useState([]);
  const [criminalActivityEditVal, setCriminalActivityEditVal] = useState([]);
  const [crimeBiasCategoryEditVal, setCrimeBiasCategoryEditVal] = useState([]);
  const [crimeToolsUseEditVal, setCrimeToolsUseEditVal] = useState([]);
  const [crimeTargeteEditVal, setCrimeTargeteEditVal] = useState([]);
  const [crimeSuspectEditVal, setCrimeSuspectEditVal] = useState([]);
  const [securityViolatedEditVal, setSecurityViolatedEditVal] = useState([]);
  const [methodOfOperationEditVal, setmethodOfOperationEditVal] = useState([]);
  const [methodOfEntryEditVal, setmethodOfEntryEditVal] = useState([]);
  const [weaponEditVal, setweaponEditVal] = useState([]);
  const [value, setValue] = useState()


  const [disabled, setDisabled] = useState(false);

  const [multiSelected, setMultiSelected] = useState({
    PretendToBeID: null, CrimePointOfExitID: null, CrimePointOfEntry: null, CrimeOffenderUse: null, CrimeActivity: null, CrimeBiasCategory: null, CrimeToolsUse: null, CrimeTarget: null, CrimeSuspect: null, SecurityViolated: null, methodOfOperation: null, methodOfEntry: null,weaponID :null,
  })

  useEffect(() => {
    if (offenceID) { GetBasicInfoData(); get_Point_Exit_Data(); get_Point_Entry_Data(); get_Crime_OffenderUse_Data(); get_Criminal_Activity_Data(); get_Crime_Bias_Category_Data(); get_Crime_Tools_Use_Data(); get_Crime_Target_Data(); get_Crime_Suspect_Data(); get_Security_Violated_Data(); get_MethodOfOperation_Data(); get_MethodOfEntry_Data();get_Weapon_Data();}
  }, [])

  const GetBasicInfoData = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('CrimePretendToBe/GetData_CrimePretendToBe', val)
      .then((res) => {
        if (res) {
          setEditval(Comman_changeArrayFormatBasicInfo(res, 'PretendToBeID', 'Description', 'PretendToBeID', 'CrimePretendID', 'PretendToBeCode'));
        }
        else {
          setEditval([]);
        }
      })
  }

  const get_Point_Exit_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffensePointOfExit/GetDataOffensePointOfExit', val)
      .then((res) => {
        if (res) {
          setPointExitEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimePointOfExitID', 'Description', 'PretendToBeID', 'PointOfExitID', 'PointOfExitCode'));
        }
        else {
          setPointExitEditVal([]);
        }
      })
  }

  const get_Point_Entry_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffensePointOfEntry/GetData_OffensePointOfEntry', val)
      .then((res) => {
        if (res) {
          setPointEntryEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimePointOfEntryID', 'Description', 'PretendToBeID', 'PointOfEntryID', 'EntryPointCode'));
        }
        else {
          setPointEntryEditVal([]);
        }
      })
  }

  const get_Crime_OffenderUse_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffenseOffenderUse/GetData_OffenseOffenderUse', val)
      .then((res) => {
        if (res) {
          setCrimeOffenderUseEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeOffenderUseID', 'Description', 'PretendToBeID', 'OffenderUseID', 'OffenderUseCode'));
        }
        else {
          setCrimeOffenderUseEditVal([]);
        }
      })
  }

  const get_Criminal_Activity_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('CriminalActivity/GetData_OffenseCriminalActivity', val)
      .then((res) => {
        if (res) {
          setCriminalActivityEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeActivityID', 'Description', 'PretendToBeID', 'ActivityID', 'CriminalActivityCode'));
        }
        else {
          setCriminalActivityEditVal([]);
        }
      })
  }

  const get_Crime_Bias_Category_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffenseBiasCategory/GetData_OffenseBiasCategory', val)
      .then((res) => {
        if (res) {
          setCrimeBiasCategoryEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeBiasCategoryID', 'Description', 'PretendToBeID', 'BiasCategoryID', 'BiasCode'));
        }
        else {
          setCrimeBiasCategoryEditVal([]);
        }
      })
  }

  const get_Crime_Tools_Use_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffenseToolsUse/GetData_OffenseToolsUse', val)
      .then((res) => {
        if (res) {
          setCrimeToolsUseEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeToolsUseID', 'Description', 'PretendToBeID', 'ToolsUseID', 'ToolsUseCode'));
        }
        else {
          setCrimeToolsUseEditVal([]);
        }
      })
  }

  const get_Crime_Target_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffenseTarget/GetData_OffenseTarget', val)
      .then((res) => {
        if (res) {
          setCrimeTargeteEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeTargetID', 'Description', 'PretendToBeID', 'TargetID', 'TargetCode'));
        }
        else {
          setCrimeTargeteEditVal([]);
        }
      })
  }

  const get_Crime_Suspect_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffenseSuspect/GetData_OffenseSuspect', val)
      .then((res) => {
        if (res) {
          setCrimeSuspectEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeSuspectID', 'Description', 'PretendToBeID', 'SuspectID', 'SuspectCode'));
        }
        else {
          setCrimeSuspectEditVal([]);
        }
      })
  }

  const get_Security_Violated_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffenseSecurityViolated/GetData_OffenseSecurityViolated', val)
      .then((res) => {
        if (res) {
          setSecurityViolatedEditVal(Comman_changeArrayFormatBasicInfo(res, 'CrimeSecurityviolatedID', 'Description', 'PretendToBeID', 'SecurityviolatedID', 'SecurityviolatedCode'));
        }
        else {
          setSecurityViolatedEditVal([]);
        }
      })
  }

  useEffect(() => {
    if (editval) { setMultiSelected(prevValues => { return { ...prevValues, ['PretendToBeID']: editval } }) }
    if (pointExitEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['CrimePointOfExitID']: pointExitEditVal } }) }
    if (pointEntryEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['CrimePointOfEntry']: pointEntryEditVal } }) }
    if (crimeOffenderUseEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['CrimeOffenderUse']: crimeOffenderUseEditVal } }) }
    if (criminalActivityEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['CrimeActivity']: criminalActivityEditVal } }) }
    if (crimeBiasCategoryEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['CrimeBiasCategory']: crimeBiasCategoryEditVal } }) }
    if (crimeToolsUseEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['CrimeToolsUse']: crimeToolsUseEditVal } }) }
    if (crimeTargeteEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['CrimeTarget']: crimeTargeteEditVal } }) }
    if (crimeSuspectEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['CrimeSuspect']: crimeSuspectEditVal } }) }
    if (securityViolatedEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['SecurityViolated']: securityViolatedEditVal } }) }
    if (methodOfOperationEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['methodOfOperation']: methodOfOperationEditVal } }) }
    if (methodOfEntryEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['methodOfEntry']: methodOfEntryEditVal } }) }
    if (weaponEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['weaponID']: weaponEditVal } }) }
  }, [editval, pointExitEditVal, pointEntryEditVal, crimeOffenderUseEditVal, criminalActivityEditVal, crimeBiasCategoryEditVal, crimeToolsUseEditVal, crimeTargeteEditVal, crimeSuspectEditVal, securityViolatedEditVal, methodOfOperationEditVal, methodOfEntryEditVal,weaponEditVal])

  // Onchange Fuction
  const Agencychange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      PretendToBeID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < editval?.length) {
      let missing = null;
      let i = editval.length;
      while (i) {
        missing = (~multiSelected.indexOf(editval[--i])) ? missing : editval[i];
      }
      DelSertBasicInfo(missing.id, 'CrimePretendID', 'CrimePretendToBe/DeleteCrimePretendToBe')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'PretendToBeID', 'CrimePretendToBe/InsertCrimePretendToBe')
    }
  }

  const poinOfExitchange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      CrimePointOfExitID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < pointExitEditVal?.length) {
      let missing = null;
      let i = pointExitEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(pointExitEditVal[--i])) ? missing : pointExitEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'PointOfExitID', 'OffensePointOfExit/DeleteOffensePointOfExit')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimePointOfExitID', 'OffensePointOfExit/InsertOffensePointOfExit')
    }
  }

  const poinOfEntrychange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      CrimePointOfEntry: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < pointEntryEditVal?.length) {
      let missing = null;
      let i = pointEntryEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(pointEntryEditVal[--i])) ? missing : pointEntryEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'PointOfEntryID', 'OffensePointOfEntry/DeleteOffensePointOfEntry')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimePointOfEntryID', 'OffensePointOfEntry/InsertOffensePointOfEntry')
    }
  }

  const OffenderUsechange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      CrimeOffenderUse: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeOffenderUseEditVal?.length) {
      let missing = null;
      let i = crimeOffenderUseEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeOffenderUseEditVal[--i])) ? missing : crimeOffenderUseEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'OffenderUseID', 'OffenseOffenderUse/DeleteOffenseOffenderUse')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimeOffenderUseID', 'OffenseOffenderUse/InsertOffenseOffenderUse')
    }
  }

  const CrimeActivitychange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      CrimeActivity: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < criminalActivityEditVal?.length) {
      let missing = null;
      let i = criminalActivityEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(criminalActivityEditVal[--i])) ? missing : criminalActivityEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'ActivityID', 'CriminalActivity/DeleteOffenseCriminalActivity')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimeActivityID', 'CriminalActivity/InsertOffenseCriminalActivity')
    }
  }

  const CrimeBiasCategorychange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      CrimeBiasCategory: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeBiasCategoryEditVal?.length) {
      let missing = null;
      let i = crimeBiasCategoryEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeBiasCategoryEditVal[--i])) ? missing : crimeBiasCategoryEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'BiasCategoryID', 'OffenseBiasCategory/DeleteOffenseBiasCategory')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimeBiasCategoryID', 'OffenseBiasCategory/InsertOffenseBiasCategory')
    }
  }

  const CrimeToolsUsechange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      CrimeToolsUse: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeToolsUseEditVal?.length) {
      let missing = null;
      let i = crimeToolsUseEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeToolsUseEditVal[--i])) ? missing : crimeToolsUseEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'ToolsUseID', 'OffenseToolsUse/DeleteOffenseToolsUse')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimeToolsUseID', 'OffenseToolsUse/InsertOffenseToolsUse')
    }
  }

  const CrimeTargetchange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      CrimeTarget: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeTargeteEditVal?.length) {
      let missing = null;
      let i = crimeTargeteEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeTargeteEditVal[--i])) ? missing : crimeTargeteEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'TargetID', 'OffenseTarget/DeleteOffenseTarget')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimeTargetID', 'OffenseTarget/InsertOffenseTarget')
    }
  }

  const CrimeSuspectchange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      CrimeSuspect: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < crimeSuspectEditVal?.length) {
      let missing = null;
      let i = crimeSuspectEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(crimeSuspectEditVal[--i])) ? missing : crimeSuspectEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'SuspectID', 'OffenseSuspect/DeleteOffenseSuspect')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimeSuspectID', 'OffenseSuspect/InsertOffenseSuspect')
    }
  }

  const SecurityViolatedchange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      SecurityViolated: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < securityViolatedEditVal?.length) {
      let missing = null;
      let i = securityViolatedEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(securityViolatedEditVal[--i])) ? missing : securityViolatedEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'SecurityviolatedID', 'OffenseSecurityViolated/DeleteOffenseSecurityViolated')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimeSecurityviolatedID', 'OffenseSecurityViolated/InsertOffenseSecurityViolated')
    }
  }
  const CrimeMethodOfOpeationchange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      methodOfOperation: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < methodOfOperationEditVal?.length) {
      let missing = null;
      let i = methodOfOperationEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(methodOfOperationEditVal[--i])) ? missing : methodOfOperationEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'methodOfOperationID', 'OffenseMethodOfOperation/Delete_OffenseMethodOfOperation')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimeMethodOfOpeationID', 'OffenseMethodOfOperation/Insert_OffenseMethodOfOperation')
    }
  }
  const CrimeMethodOfEntrychange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      methodOfEntry: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < methodOfEntryEditVal?.length) {
      let missing = null;
      let i = methodOfEntryEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(methodOfEntryEditVal[--i])) ? missing : methodOfEntryEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'MethodOfEntryID', 'OffenseMethodOfEntry/Delete_OffenseMethodOfEntry')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'CrimeMethodOfEntryID', 'OffenseMethodOfEntry/Insert_OffenseMethodOfEntry')
    }
  }
  const Weaponchange = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      weaponID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < weaponEditVal?.length) {
      let missing = null;
      let i = weaponEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(weaponEditVal[--i])) ? missing : weaponEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'weaponID', 'OffenseWeapon/Delete_OffenseWeapon')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'WeaponID', 'OffenseWeapon/Insert_OffenseWeapon')
    }
  }


  useEffect(() => {
    getPretendTobeDrpVal(loginAgencyID); getPointExitTobeDrpVal(loginAgencyID); getPointEntryTobeDrpVal(loginAgencyID); getCrimeOffenderUseDrpVal(loginAgencyID); getCrimeActivityDrpVal(loginAgencyID); getCrimeBiasCategoryDrpVal(loginAgencyID); getCrimeToolsUseDrpVal(loginAgencyID); getCrimeTargetDrpVal(loginAgencyID); getCrimeSuspectDrpVal(loginAgencyID); getCrimeSecurityviolatedDrpVal(loginAgencyID); get_MethodOfOperation_DropDown(loginAgencyID);get_MethodOfEntry_DropDown(loginAgencyID);get_Weapon_DropDown(loginAgencyID);
  }, [loginAgencyID])

  const getPretendTobeDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('PretendToBe/GetDataDropDown_PretendToBe', val).then((data) => {
      if (data) {
        setPretentedDrp(threeColArrayWithCode(data, 'PretendToBeID', 'Description', 'PretendToBeCode'))
      } else {
        setPretentedDrp([]);
      }
    })
  }

  const getPointExitTobeDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CrimePointOfexit/GetDataDropDown_CrimePointOfexit', val).then((data) => {
      if (data) {
        setPointExitDrp(threeColArrayWithCode(data, 'PointOfExit', 'Description', 'PointOfExitCode'))
      } else {
        setPointExitDrp([]);
      }
    })
  }

  const getPointEntryTobeDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CrimePointOfEntry/GetDataDropDown_CrimePointOfEntry', val).then((data) => {
      if (data) {
        setPointEntryDrp(threeColArrayWithCode(data, 'EntryPointId', 'Description', 'EntryPointCode'))
      } else {
        setPointEntryDrp([]);
      }
    })
  }

  const getCrimeOffenderUseDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CrimeOffenderUse/GetDataDropDown_CrimeOffenderUse', val).then((data) => {
      if (data) {
        setCrimeOffenderUseDrp(threeColArrayWithCode(data, 'OffenderUseID', 'Description', 'OffenderUseCode'))
      } else {
        setCrimeOffenderUseDrp([]);
      }
    })
  }

  const getCrimeActivityDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CriminalActivity/GetDataDropDown_CriminalActivity', val).then((data) => {
      if (data) {
        setCrimeActivityDrp(threeColArrayWithCode(data, 'CriminalActivityID', 'Description', 'CriminalActivityCode'))
      } else {
        setCrimeActivityDrp([]);
      }
    })
  }

  const getCrimeBiasCategoryDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CrimeBias/GetDataDropDown_CrimeBias', val).then((data) => {
      if (data) {
        setCrimeBiasCategoryDrp(threeColArrayWithCode(data, 'BiasID', 'Description', 'BiasCode'))
      } else {
        setCrimeBiasCategoryDrp([]);
      }
    })
  }

  const getCrimeToolsUseDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CrimeToolsUse/GetDataDropDown_CrimeToolsUse', val).then((data) => {
      if (data) {
        setToolsUseIDDrp(threeColArrayWithCode(data, 'ToolsUseID', 'Description', 'ToolsUseCode'))
      } else {
        setToolsUseIDDrp([]);
      }
    })
  }

  const getCrimeTargetDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CrimeTarget/GetDataDropDown_CrimeTarget', val).then((data) => {
      if (data) {
        setCrimeTargetDrp(threeColArrayWithCode(data, 'TargetID', 'Description', 'TargetCode'))
      } else {
        setCrimeTargetDrp([]);
      }
    })
  }

  const getCrimeSuspectDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CrimeSuspect/GetDataDropDown_CrimeSuspect', val).then((data) => {
      if (data) {
        setCrimeSuspectDrp(threeColArrayWithCode(data, 'SuspectID', 'Description', 'SuspectCode'))
        console.log(data)
      } else {
        setCrimeSuspectDrp([]);
      }
    })
  }

  const getCrimeSecurityviolatedDrpVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CrimeSecurityviolated/GetDataDropDown_CrimeSecurityviolated', val).then((data) => {
      if (data) {
        setCrimeSecurityviolatedDrp(threeColArrayWithCode(data, 'SecurityviolatedID', 'Description', 'SecurityviolatedCode'))
      } else {
        setCrimeSecurityviolatedDrp([]);
      }
    })
  }


  const InSertBasicInfo = (id, col1, url) => {
    setDisabled(true)
    const val = {
      'CrimeID': offenceID,
      [col1]: id,
      'CreatedByUserFK': loginPinID,
    }
    console.log(offenceID)
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        setDisabled(false); toastifySuccess(res.Message);
        col1 === 'PretendToBeID' && GetBasicInfoData();
        col1 === 'CrimePointOfExitID' && get_Point_Exit_Data();
        col1 === 'CrimePointOfEntryID' && get_Point_Entry_Data();
        col1 === 'CrimeOffenderUseID' && get_Crime_OffenderUse_Data();
        col1 === 'CrimeActivityID' && get_Criminal_Activity_Data();
        col1 === 'CrimeBiasCategoryID' && get_Crime_Bias_Category_Data();
        col1 === 'CrimeToolsUseID' && get_Crime_Tools_Use_Data();
        col1 === 'CrimeTargetID' && get_Crime_Target_Data();
        col1 === 'CrimeSuspectID' && get_Crime_Suspect_Data();
        col1 === 'CrimeSecurityviolatedID' && get_Security_Violated_Data();
        col1 === 'CrimeMethodOfOpeationID' && get_MethodOfOperation_Data();
        col1 === 'CrimeMethodOfEntryID' && get_MethodOfEntry_Data();
        col1 === 'WeaponTypeID' && get_Weapon_Data();
      } else {
        console.log("Somthing Wrong");
      }
    })
  }

  const DelSertBasicInfo = (CrimePretendID, col1, url) => {
    setDisabled(true)
    const val = {
      [col1]: CrimePretendID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        setDisabled(false); toastifySuccess(res.Message);
        col1 === 'CrimePretendID' && GetBasicInfoData();
        col1 === 'PointOfExitID' && get_Point_Exit_Data();
        col1 === 'PointOfEntryID' && get_Point_Entry_Data();
        col1 === 'OffenderUseID' && get_Crime_OffenderUse_Data();
        col1 === 'ActivityID' && get_Criminal_Activity_Data();
        col1 === 'BiasCategoryID' && get_Crime_Bias_Category_Data();
        col1 === 'ToolsUseID' && get_Crime_Tools_Use_Data();
        col1 === 'TargetID' && get_Crime_Target_Data();
        col1 === 'SuspectID' && get_Crime_Suspect_Data();
        col1 === 'SecurityviolatedID' && get_Security_Violated_Data();
        col1 === 'methodOfOperationID' && get_MethodOfOperation_Data();
        col1 === 'MethodOfEntryID' && get_MethodOfEntry_Data();
        col1 === 'weaponID' && get_Weapon_Data();
      } else {
        console.log("Somthing Wrong");
      }
    })
  }
  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else setValue({
      ...value,
      [name]: null
    })
  }
  // Custom Style
  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 40,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),

  };

  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 40,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };
  const OnClose = () => {
    if (!changesStatus) {
      setChangesStatus(false)
      Navigate('/Off-Home');
    }
  }
  //  method of operation
  const get_MethodOfOperation_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffenseMethodOfOperation/GetData_OffenseMethodOfOperation', val).then((res) => {
      if (res) {
        setmethodOfOperationEditVal(Comman_changeArrayFormatBasicInfo(res, 'MethodOfOperationID','CrimeID','PretendToBeID','CrimeMethodOfOpeationID','MethodOfOperation_Description'))
        console.log(res);
      } else {
        setmethodOfOperationEditVal([]);
      }
    })
  }

  const get_MethodOfOperation_DropDown = (loginAgencyID) => {
    const val = {
      // 'CrimeID': offenceID,
      'AgencyID': loginAgencyID,
    }
    fetchPostData('OffenseMethodOfOperation/GetData_InsertGetData_OffenseMethodOfOperation', val).then((data) => {
      if (data) {
        setMethodOfOperationDrp(threeColArrayWithCode(data, 'MethodOfOperationID', 'Description', 'MethodOfOperationCode',));
        console.log(data,"operation")
      }
      else {
        setMethodOfOperationDrp([])
      }
    })
  }

  // method of entry

  const get_MethodOfEntry_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffenseMethodOfEntry/GetData_OffenseMethodOfEntry', val).then((res) => {
      if (res) {
        setmethodOfEntryEditVal(Comman_changeArrayFormatBasicInfo(res, 'MethodOfEntryID','CrimeID','PretendToBeID','CrimeMethodOfEntryID','MethodOfEntry_Description'))
        console.log(res,'dsfsdf');
      } else {
        setmethodOfEntryEditVal([]);
      }
    })
  }

  const get_MethodOfEntry_DropDown = (loginAgencyID) => {
    const val = {
      'AgencyID': loginAgencyID,
    }
    fetchPostData('OffenseMethodOfEntry/GetData_InsertGetData_OffenseMethodOfEntry', val).then((data) => {
      if (data) {
        setMethodEntryDrp(threeColArrayWithCode(data, 'EntryMethodID', 'Description','MethodOfEntryCode'));
        console.log(data,'entry')
      }
      else {
        setMethodEntryDrp([])
      }
    })
  }


  // weapon


  const get_Weapon_Data = () => {
    const val = {
      'CrimeID': offenceID,
    }
    fetchPostData('OffenseWeapon/GetData_OffenseWeapon', val).then((res) => {
      if (res) {
        setweaponEditVal(Comman_changeArrayFormatBasicInfo(res, 'WeaponID','CrimeID','PretendToBeID','WeaponTypeID','Weapon_Description'))
        // setWeaponData(res);
        console.log(res,'weapon');
      } else {
        setweaponEditVal([]);
      }
    })
  }

  const get_Weapon_DropDown = (loginAgencyID) => {
    const val = {
      'AgencyID': loginAgencyID,
    }
    fetchPostData('OffenseWeapon/GetData_InsertOffenseWeapon', val).then((data) => {
      if (data) {
        setWeaponDrp(threeColArrayWithCode(data, 'WeaponID','WeaponCode', 'Description', ));
      }
      else {
        setWeaponDrp([])
      }
    })
  }


  return (

    <>
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                <Link to={'/ListManagement?page=Pretend%20To%20Be&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Pretented To Be
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-2">
                <SelectBox
                  className="basic-multi-select"
                  options={pretentedDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => Agencychange(e)}
                  value={multiSelected.PretendToBeID}
                  placeholder='Select Pretented To Be From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                <Link to={'/ListManagement?page=Point%20Of%20Entry&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Point Of Entry
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-2 ">
                <SelectBox
                  className="basic-multi-select"
                  name='pointofentry'
                  options={pointEntryDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => poinOfEntrychange(e)}
                  value={multiSelected.CrimePointOfEntry}
                  placeholder='Select Point Of Entry From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                <Link to={'/ListManagement?page=Criminal%20Activity&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Criminal Activity
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-9 mt-2">
                <SelectBox
                  className="basic-multi-select"
                  name='CrimeActivity'
                  options={crimeActivityDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeActivitychange(e)}
                  value={multiSelected.CrimeActivity}
                  placeholder='Select Criminal Activity From List'

                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                <Link to={'/ListManagement?page=Tools%20Use&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Tools
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-2">
                <SelectBox
                  className="basic-multi-select"
                  name='btoolsias'
                  options={toolsUseIDDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeToolsUsechange(e)}
                  menuPlacement="top"
                  value={multiSelected.CrimeToolsUse}
                  placeholder='Select Tools From List'

                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                <Link to={'/ListManagement?page=Crime%20Suspect&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Suspect Action
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-2">
                <SelectBox
                  className="basic-multi-select"
                  name='suspectaction'
                  options={crimeSuspectDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeSuspectchange(e)}
                  menuPlacement="top"
                  value={multiSelected.CrimeSuspect}
                  placeholder='Select Suspect Action From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                <Link to={'/ListManagement?page=Method%20Of%20Entry&call=Off-Home?page=CrimeInformation'} className='new-link'>
                  Method Of Entry
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-2">
                <Select
                  className="basic-multi-select"
                  isMulti
                  name='CrimeMethodOfEntryID'
                  menuPlacement='top'
                  isClearable={false}
                  // closeMenuOnSelect={false}
                  // hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  options={methodEntryDrp}
                  value={multiSelected.methodOfEntry}
                  onChange={(e) => CrimeMethodOfEntrychange(e)}
                  placeholder='Select Method Of Entry From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-3 mt-4">
                <Link to={'/ListManagement?page=Weapon%20Type&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Weapon Type {weaponCode === '99' && <ErrorTooltip Error={WeaponError} />}
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-9  mt-2">
                <Select
                  name='WeaponTypeID'
                  styles={ErrorStyleWeapon(weaponCode)}
                  isClearable
                  options={weaponDrp}
                  components={{ MultiValue, }}
                  onChange={(e) => Weaponchange(e)}
                  value={multiSelected.weaponID}
                  // onChange={(e) => { ChangeDropDown(e, 'WeaponTypeID'); }}
                  placeholder='Select Weapon Type From List'
                  // ref={SelectedValue}
                  className="basic-multi-select"
                  isMulti
                  menuPlacement='top'
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                <Link to={'/ListManagement?page=Point%20Of%20Exit&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Point Of Exit
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-2">
                <SelectBox
                  className="basic-multi-select"
                  name='pointofexit'
                  options={pointExitDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => poinOfExitchange(e)}
                  value={multiSelected.CrimePointOfExitID}
                  placeholder='Select Point Of Exit From List'

                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                <Link to={'/ListManagement?page=Offender%20Suspected%20of%20Using&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Offender Using {ErrorStyleOffenderUse(crimeOffenderUseEditVal) === 'N' ? <ErrorTooltip Error={OffenderUseError_N} /> : ErrorStyleOffenderUse(crimeOffenderUseEditVal) === 'A' || ErrorStyleOffenderUse(crimeOffenderUseEditVal) === 'C' || ErrorStyleOffenderUse(crimeOffenderUseEditVal) === 'D' || ErrorStyleOffenderUse(crimeOffenderUseEditVal) === undefined ? <></> : <ErrorTooltip Error={OffenderUseError_Other} />}
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-2">
                <SelectBox
                  className="basic-multi-select"
                  name='offenderusing'
                  options={crimeOffenderUseDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  style={ErrorStyleOffenderUse(crimeOffenderUseEditVal)}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => OffenderUsechange(e)}
                  value={multiSelected.CrimeOffenderUse}
                  placeholder='Select Offender Using From List'

                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                <Link to={'/ListManagement?page=Bias%20Motivation&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Bias {ErrorStyleBias(crimeBiasCategoryEditVal) === 'N' ? <ErrorTooltip Error={BiasError} /> : <></>}
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-2">
                <SelectBox
                  className="basic-multi-select"
                  name='bias'
                  options={crimeBiasCategoryDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeBiasCategorychange(e)}
                  value={multiSelected.CrimeBiasCategory}
                  placeholder='Select Bias From List'

                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                <Link to={'/ListManagement?page=Crime%20Target&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Target
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-2" >
                <SelectBox
                  className="basic-multi-select"
                  name='target'
                  options={crimeTargetDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeTargetchange(e)}
                  value={multiSelected.CrimeTarget}
                  menuPlacement="top"
                  placeholder='Select Target From List'
                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                <Link to={'/ListManagement?page=Crime%20Security%20Violated&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Security Violated
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-2">
                <SelectBox
                  className="basic-multi-select"
                  name='SecurityViolated'
                  options={crimeSecurityviolatedDrp}
                  isClearable={false}
                  // isDisabled={disabled}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  components={{ MultiValue, }}
                  onChange={(e) => SecurityViolatedchange(e)}
                  menuPlacement="top"
                  value={multiSelected.SecurityViolated}
                  placeholder='Select Security Violated From List'

                />
              </div>
              <div className="col-3 col-md-3 col-lg-4 mt-4">
                <Link to={'/ListManagement?page=Method%20Of%20Operation&call=/Off-Home?page=CrimeInformation'} className='new-link'>
                  Method Of Operation
                </Link>
              </div>
              <div className="col-9 col-md-9 col-lg-8  mt-2">
                <Select
                  className="basic-multi-select"
                  isMulti
                  name='CrimeMethodOfOpeationID'
                  // styles={customStylesWithOutColor}
                  isClearable={false}
                  menuPlacement='top'
                  options={methodOfOperationDrp}
                  components={{ MultiValue, }}
                  onChange={(e) => CrimeMethodOfOpeationchange(e)}
                  value={multiSelected.methodOfOperation}
                  placeholder='Select Method Of Operation From List'
                // ref={SelectedValue}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>


  )
}

export default BasicInformation