import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Decrypt_Id_Name, getShowingDateText, getShowingMonthDateYear, tableCustomStyles } from '../../../../Common/Utility';
import { fetchPostData, AddDeleteUpadate, ScreenPermision } from '../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import Loader from '../../../../Common/Loader';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import Select from "react-select";
import DatePicker from 'react-datepicker';
import { convertToHTML } from 'draft-convert';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';

const Narrative = (props) => {

  const { incidentReportedDate } = props
  const [clickedRow, setClickedRow] = useState(null);

  const { get_IncidentTab_Count, localStoreArray, get_LocalStorage, } = useContext(AgencyContext);
  const [narrativeData, setNarrativeData] = useState([])
  const [upDateCount, setUpDateCount] = useState(0)
  const [status, setStatus] = useState(false)
  const [modal, setModal] = useState(false);
  const [narrativeID, setNarrativeID] = useState('')
  const [loder, setLoder] = useState(false)
  //screen permission 
  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState()
  const [loginAgencyID, setLoginAgencyID] = useState('')
  const [incidentID, setIncidentID] = useState('')
  const [loginPinID, setLoginPinID] = useState('');
  const [userName, setUserName] = useState('')

  const [value, setValue] = useState({
    'CommentsDoc': '',
    'IncidentId': '',
    'NarrativeID': '',
    'Comments': '',
    'ReportedByPINActivityID': null, 'NarrativeTypeID': null,
    'AsOfDate': null,
    'CreatedByUserFK': '',
    'ModifiedByUserFK': '',
  })
  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: '', UserName: '' }),
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID || !localStoreArray.UserName) {
      get_LocalStorage(localStore);
    }
  }, []);

  // Onload Function
  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
        setLoginAgencyID(localStoreArray?.AgencyID);
        setLoginPinID(parseInt(localStoreArray?.PINID));
        setIncidentID(localStoreArray?.IncidentID);
        setUserName(localStoreArray?.UserName)
        get_NarrativesData(localStoreArray?.IncidentID); getScreenPermision(localStoreArray?.AgencyID, localStoreArray?.PINID);
      }
    }
  }, [localStoreArray])

  const [errors, setErrors] = useState({
    'ReportedByPinError': '', 'AsOfDateError': '', 'NarrativeIDError': '', 'CommentsError': '',
  })

  const [editval, setEditval] = useState();
  const [headOfAgency, setHeadOfAgency] = useState([])
  const [narrativeTypeList, setNarrativeTypeList] = useState([])

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  useEffect(() => {
    if (incidentID) {
      setValue({ ...value, 'IncidentId': incidentID, 'CreatedByUserFK': loginPinID, 'ReportedByPINActivityID': loginPinID, })
    }
  }, [incidentID, upDateCount]);

  // Get Head of Agency
  useEffect(() => {
    if (loginAgencyID) {
      Get_Officer_Name(loginAgencyID);
      get_Narrative_Type(loginAgencyID);
    }
  }, [loginAgencyID])

  useEffect(() => {
    if (narrativeID && status) {
      GetSingleData(narrativeID)
    }
  }, [upDateCount, narrativeID])

  const GetSingleData = (NarrativeID) => {
    const val = { 'NarrativeID': NarrativeID }
    fetchPostData('Narrative/GetSingleData_Narrative', val)
      .then((res) => {
        if (res) setEditval(res)
        else setEditval()
      })
  }

  useEffect(() => {
    if (status) {
      setValue({
        ...value,
        'OfficerId': editval[0].OfficerId,
        'AsOfDate': editval[0].AsOfDate ? getShowingDateText(editval[0].AsOfDate) : null,
        'NarrativeID': editval[0].NarrativeID,
        'NarrativeTypeID': editval[0].NarrativeTypeID,
        'ReportedByPINActivityID': editval[0].ReportedByPINActivityID,
        'Comments': editval[0].Comments,
        'ModifiedByUserFK': loginPinID,
        'CommentsDoc': editval[0].CommentsDoc,
      })
      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(editval[0].CommentsDoc))));
    } else {
      setValue({
        ...value,
        'Comments': '', 'CommentsDoc': '', 'ModifiedByUserFK': '', 'NarrativeID': '',
        'NarrativeTypeID': null, 'AsOfDate': null,
      });
      setEditorState(() => EditorState.createEmpty(),);
    }
  }, [editval])

  const Get_Officer_Name = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('DropDown/GetData_HeadOfAgency', val)
      .then(res => {
        if (res) {
          setHeadOfAgency(Comman_changeArrayFormat(res, 'PINID', 'HeadOfAgency'))
        } else setHeadOfAgency([])
      })
  };

  const get_Narrative_Type = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('NarrativeType/GetDataDropDown_NarrativeType', val)
      .then((res) => {
        if (res) setNarrativeTypeList(Comman_changeArrayFormat(res, 'NarrativeTypeID', 'Description'))
        else setNarrativeTypeList([])
      })
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      reset()
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value, [name]: e.value
      })
    } else {
      setValue({
        ...value, [name]: null
      })
    }
  }

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setValue({ ...value, 'CommentsDoc': currentContentAsHTML })
  }

  const getValueNarrative = (e) => {
    setValue({
      ...value,
      ['Comments']: e.blocks[0].text
    });
  }

  const submit = () => {
    const result = narrativeData?.find(item => {
      if (item.Comments) {
        if (item.Comments.toLowerCase() === value.Comments.toLowerCase()) {
          return item.Comments.toLowerCase() === value.Comments.toLowerCase()
        } else return item.Comments.toLowerCase() === value.Comments.toLowerCase()
      }
    });
    if (result) {
      toastifyError(' Already Exists');
      setErrors({ ...errors, ['AsOfDateError']: '' })
    } else {
      AddDeleteUpadate('Narrative/Insert_Narrative', value)
        .then((res) => {
          get_NarrativesData(incidentID);
          get_IncidentTab_Count(incidentID);
          toastifySuccess(res.Message);
          setStatusFalse();
          setErrors({ ...errors, ['AsOfDateError']: '', });
        })
    }
  }

  const updateNarrative = (e) => {
    const result = narrativeData?.find(item => {
      if (item.Comments) {
        if (item.NarrativeID != value.NarrativeID) {
          if (item.Comments.toLowerCase() === value.Comments.toLowerCase()) {
            return item.Comments.toLowerCase() === value.Comments.toLowerCase()
          } else return item.Comments.toLowerCase() === value.Comments.toLowerCase()
        }
      }
    }
    );
    if (result) {
      toastifyError('Comments Already Exists')
      setErrors({ ...errors, ['AsOfDateError']: '' })
    } else {
      AddDeleteUpadate('Narrative/Update_Narrative', value)
        .then((res) => {
          toastifySuccess(res.Message)
          setStatus(true);
          setStatusFalse();
          setErrors({ ...errors, ['AsOfDateError']: '', });
          get_NarrativesData(incidentID);
        })
    }
  }

  const reset = () => {
    setValue({
      ...value,
      'NarrativeTypeID': '', 'Comments': '', 'CommentsDoc': '', 'ModifiedByUserFK': '', 'NarrativeID': '', 'AsOfDate': null,
    });
    setErrors({
      ...errors,
      'ReportedByPinError': '', 'AsOfDateError': '', 'NarrativeIDError': '', 'CommentsError': '',
    });
    setEditorState(() => EditorState.createEmpty(),);
  }

  const check_Validation_Error = () => {
    if (RequiredFieldIncident(value.ReportedByPINActivityID)) {
      setErrors(prevValues => { return { ...prevValues, ['ReportedByPinError']: RequiredFieldIncident(value.ReportedByPINActivityID) } })
    }
    if (RequiredFieldIncident(value.AsOfDate)) {
      setErrors(prevValues => { return { ...prevValues, ['AsOfDateError']: RequiredFieldIncident(value.AsOfDate) } })
    }
    if (RequiredFieldIncident(value.NarrativeTypeID)) {
      setErrors(prevValues => { return { ...prevValues, ['NarrativeIDError']: RequiredFieldIncident(value.NarrativeTypeID) } })
    }
    if (RequiredFieldIncident(value.Comments)) {
      setErrors(prevValues => { return { ...prevValues, ['CommentsError']: RequiredFieldIncident(value.Comments) } })
    }
  }

  // Check All Field Format is True Then Submit 
  const { ReportedByPinError, AsOfDateError, NarrativeIDError, CommentsError, } = errors

  useEffect(() => {
    if (ReportedByPinError === 'true' && AsOfDateError === 'true' && NarrativeIDError === 'true' && CommentsError === 'true') {
      if (status) updateNarrative()
      else submit()
    }
  }, [ReportedByPinError, AsOfDateError, NarrativeIDError, CommentsError,])

  const startRef = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  // const closeModal = () => {
  //   setStatus(false);
  //   setModal(true);
  //   reset();
  // }

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }


  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];

  const get_NarrativesData = (incidentID) => {
    const val = {
      IncidentId: incidentID,
    }
    fetchPostData('Narrative/GetData_Narrative', val)
      .then(res => {
        if (res) {
          setNarrativeData(res); setLoder(true)
        } else {
          setNarrativeData([]); setLoder(true)
        }
      })
  }

  const getScreenPermision = (LoginAgencyID, LoginPinID) => {
    ScreenPermision("I032", LoginAgencyID, LoginPinID).then(res => {
      if (res) {
        setEffectiveScreenPermission(res)
      } else {
        setEffectiveScreenPermission([])
      }
    });
  }

  const columns = [
    // {
    //   width: '100px',
    //   name: <p className='text-end' style={{ position: 'absolute', top: '7px', }}>Action</p>,
    //   cell: row =>
    //     <div style={{ position: 'absolute', top: 4, left: 20 }}>
    //       {
    //         effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok ?
    //           <Link to={''} onClick={(e) => editNarratives(e, row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#NarrativeModal" >
    //             <i className="fa fa-edit"></i>
    //           </Link>
    //           : <></>
    //           : <Link to={''} onClick={(e) => editNarratives(e, row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#NarrativeModal" >
    //             <i className="fa fa-edit"></i>
    //           </Link>
    //       }
    //     </div>

    // },
    {
      width: '150px',
      name: 'Date',
      selector: (row) => getShowingDateText(row.AsOfDate),
      sortable: true
    },
    {
      width: '250px',
      name: 'Narrative',
      selector: (row) => <>{row?.Comments ? row?.Comments.substring(0, 60) : ''}{row?.Comments?.length > 60 ? '  . . .' : null} </>,
      sortable: true
    },
    {
      width: '230px',
      name: 'Reported By',
      selector: (row) => row.ReportedBy_Description,
      sortable: true
    },
    {
      name: 'Type',
      selector: (row) => row.NarrativeDescription,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 15 }}>Delete</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, right: 15 }}>
          {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
              <Link to={`#`} onClick={(e) => setNarrativeID(row.NarrativeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </Link>
              : <></>
              : <Link to={`#`} onClick={(e) => setNarrativeID(row.NarrativeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </Link>
          }
        </div>

    }
  ]

  const editNarratives = (val) => {
    // e.preventDefault();
    setNarrativeID(val.NarrativeID); 
    setUpDateCount(upDateCount + 1);
     setStatus(true)
    setModal(true);
  }
  const setStatusFalse = (e) => {
    setStatus(false);
    setModal(true);
    reset();
  }
  const DeleteNarratives = () => {
    const val = {
      'narrativeID': narrativeID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate('Narrative/Delete_Narrative', val).then((res) => {
      if (res.success) {
        toastifySuccess(res.Message);
        get_IncidentTab_Count(incidentID);
      } else { console.log("Somthing Wrong"); }
      get_NarrativesData(incidentID);
    })
  }

  return (
    <>

      <div className="row mt-1">
        <div className="col-12 col-md-12 col-lg-12 px-0 pl-0">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onChange={getValueNarrative}
            editorStyle={{ height: '15vh' }}
            toolbar={{
              options: ['inline', 'blockType', 'fontFamily', 'list', 'history'],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline', 'monospace',],
              },
            }}
          />
          {errors.CommentsError !== 'true' ? (
            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CommentsError}</span>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Officer Name</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <Select
                  name='ReportedByPINActivityID'
                  isClearable
                  styles={colourStyles}
                  value={headOfAgency?.filter((obj) => obj.value === value?.ReportedByPINActivityID)}
                  options={headOfAgency}
                  onChange={(e) => ChangeDropDown(e, 'ReportedByPINActivityID')}
                  placeholder="Select.."
                  menuPlacement="top"
                />
                {errors.ReportedByPinError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReportedByPinError}</span>
                ) : null}
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Narrative Type/Report Type</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <Select
                  name='NarrativeTypeID'
                  isClearable
                  styles={colourStyles}
                  value={narrativeTypeList?.filter((obj) => obj.value === value?.NarrativeTypeID)}
                  options={narrativeTypeList}
                  onChange={(e) => ChangeDropDown(e, 'NarrativeTypeID')}
                  placeholder="Select.."
                  menuPlacement="top"
                />
                {errors.NarrativeIDError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NarrativeIDError}</span>
                ) : null}
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Approving Supervisior</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 text-field ">
                <input type="text" className='readonlyColor' name='status' disabled readOnly />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Date/Time</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <DatePicker
                  ref={startRef}
                  onKeyDown={onKeyDown}
                  dateFormat="MM/dd/yyyy HH:mm"
                  timeInputLabel
                  isClearable={value?.AsOfDate ? true : false}
                  className='requiredColor'
                  name='AsOfDate'
                  onChange={(date) => { setValue({ ...value, ['AsOfDate']: date ? getShowingMonthDateYear(date) : null }) }}
                  selected={value?.AsOfDate && new Date(value?.AsOfDate)}
                  placeholderText={'Select...'}
                  showTimeSelect
                  timeIntervals={1}
                  timeCaption="Time"
                  popperPlacement="top-end"
                  maxDate={new Date()}
                  autoComplete="Off"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors.AsOfDateError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AsOfDateError}</span>
                ) : null}
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Status</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 text-field ">
                <input type="text" className='readonlyColor' name='status' disabled readOnly />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-12 col-lg-12 text-right  bb">
        <button type="button" className="btn btn-sm btn-success mr-1 mb-2" onClick={() => { setStatusFalse(); }}>New</button>
        {
          status ?
            <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success pl-2 mb-2">Update</button>
            :
            <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success pl-2 mb-2">Save</button>
        }
        {/* <button type="button" onClick={() => { closeModal(); }} className="btn btn-sm btn-success ml-2 mb-2" data-dismiss="modal">New</button> */}
      </div>

      <div className="col-12 px-0 mt-1" style={{ height: '160px', overflowY: 'scroll' }}>
        {
          loder ?
            <DataTable
              dense
              columns={columns}
              data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? narrativeData : '' : narrativeData}
              pagination
              // paginationPerPage={'2'}
              // paginationRowsPerPageOptions={[2]}
              // selectableRowsHighlight
              highlightOnHover
              customStyles={tableCustomStyles}
              onRowClicked={(row) => {
                setClickedRow(row);
                editNarratives(row);
              }}
              conditionalRowStyles={conditionalRowStyles}
              noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
            />
            :
            <Loader />
        }
      </div>
      <DeletePopUpModal func={DeleteNarratives} />
      {/* <NarrativeAddUp {...{ incidentReportedDate, userName, loginPinID, incidentID, loginAgencyID, upDateCount, narrativeID, status, modal, setModal, narrativeData, get_NarrativesData }} /> */}
    </>
  )
}

export default Narrative;