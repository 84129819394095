import React, { useCallback, useContext, useEffect, useState } from 'react'
import Select from "react-select";
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { RequiredField, RequiredFieldSpaceNotAllow } from '../../../Agency/AgencyValidation/validators';
import { useLocation } from 'react-router-dom';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';

const DocumentsAddUp = (props) => {

    const { nameID, masterNameID, loginPinID, loginAgencyID, updateStatus, get_Documentdata, modal, setModal, documentID, } = props
    const { get_Name_Count } = useContext(AgencyContext)
    const useQuery = () => new URLSearchParams(useLocation().search);

    let openPage = useQuery().get('page');

    const [documentDrpVal, setDocumentDrpVal] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState([]);

    const [value, setValue] = useState({
        'DocumentNotes': '',
        'DocumentTypeId': null,
        'DocFileName': '',
        'CreatedByUserFK': '',
        'MasterNameID': '',
        'NameID': '',
        'AgencyID': '',

    })

    const [errors, setErrors] = useState({
        'DocFileNameError': '', 'DocumentNotesError': '', 'DocumentTypeIdError': '', 'File_Not_Selected': '',
    })

    useEffect(() => {
        setValue({ ...value, 'AgencyID': loginAgencyID, 'CreatedByUserFK': loginPinID, 'MasterNameID': masterNameID, 'NameID': nameID })
    }, [nameID, masterNameID, updateStatus]);


    const reset = () => {
        setValue({
            ...value,
            'DocFileName': '',
            'DocumentFile': '',
            'DocumentTypeId': '',
            'DocumentNotes': '',
        }); setSelectedFileName('');
        setErrors({
            ...errors,
            'DocFileNameError': '', 'File_Not_Selected': '', 'DocumentTypeIdError': '', 'DocumentNotesError': '',
        }); setModal(false);
    }

    const check_Validation_Error = (e) => {
        if (RequiredFieldSpaceNotAllow(value.DocFileName)) {
            setErrors(prevValues => { return { ...prevValues, ['DocFileNameError']: RequiredFieldSpaceNotAllow(value.DocFileName) } })
        }
        if (RequiredField(value.DocumentTypeId)) {
            setErrors(prevValues => { return { ...prevValues, ['DocumentTypeIdError']: RequiredField(value.DocumentTypeId) } })
        }
        if (validate_fileupload(selectedFileName)) {
            setErrors(prevValues => { return { ...prevValues, ['File_Not_Selected']: validate_fileupload(selectedFileName) } })
        }
        if (RequiredFieldIncident(value.DocumentNotes)) {
            setErrors(prevValues => { return { ...prevValues, ['DocumentNotesError']: RequiredFieldIncident(value.DocumentNotes) } })
        }
    }

    // Check All Field Format is True Then Submit 
    const { DocFileNameError, DocumentNotesError, DocumentTypeIdError, File_Not_Selected, } = errors

    useEffect(() => {
        if (DocFileNameError === 'true' && DocumentNotesError === 'true' && DocumentTypeIdError === 'true' && File_Not_Selected === 'true') {
            Add_Documents();
        }
    }, [DocFileNameError, DocumentNotesError, DocumentTypeIdError, File_Not_Selected,])

    const handleChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value
        })
    }

    const changeHandler = (e) => {
        const files = e.target.files
        setSelectedFile(files)
        const nameArray = []
        for (let name of files) {
            nameArray?.push(name?.name)
        }
        setSelectedFileName(nameArray);
    };

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
        } else setValue({
            ...value,
            [name]: null
        })
    }

    useEffect(() => {
        get_DocumentDropDwn(loginAgencyID);
    }, [loginAgencyID])

    const get_DocumentDropDwn = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('DocumentType/GetDataDropDown_DocumentType', val).then((data) => {
            if (data) {
                setDocumentDrpVal(Comman_changeArrayFormat(data, 'DocumentTypeID', 'Description'));
            }
            else {
                setDocumentDrpVal([])
            }
        })
    };

    const Add_Documents = (id) => {
        const formdata = new FormData();
        // multiple file upload <----
        for (let i = 0; i < selectedFile.length; i++) {
            formdata.append("File", selectedFile[i])
            // console.log(selectedFile[i])
        }
        console.log(value)
        const values = JSON.stringify(value);
        formdata.append("Data", values);
        AddDeleteUpadate(openPage === 'mastername' ? 'MainMasterNameDocument/Insert_MainMasterNameDocument' : 'NameDocument/Insert_NameDocument', formdata)
            .then((res) => {
                if (res.success) {
                    const parsedData = JSON.parse(res.data);
                    const message = parsedData.Table[0].Message;
                    toastifySuccess(message); get_Documentdata(nameID, masterNameID);
                    get_Name_Count(nameID); setModal(false); reset(); setSelectedFileName([]); setSelectedFile([])
                    setErrors({ ...errors, 'DocFileNameError': '', 'DocumentNotesError': '', 'DocumentTypeIdError': '', 'File_Not_Selected': '', })
                } else {
                    console.log("something Wrong");
                }
            })
            .catch(err => console.log(err))
    }

    // Custom Style
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 33,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),

    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            reset();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const closeModal = () => {
        reset();
        setModal(false);
        setSelectedFileName([]); setSelectedFile([]);
    }

    return (
        <>
            {
                modal ?
                    <div className="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="DocumentModal" tabIndex="-1" aria-hidden="true" data-backdrop="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="m-1 mt-3">
                                        <fieldset style={{ border: '1px solid gray' }}>
                                            <legend style={{ fontWeight: 'bold' }}>Documents Management</legend>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-6 col-md-6  col-lg-6 mt-1">
                                                            <div className="text-field">
                                                                <input type="text" className='requiredColor' name='DocFileName' value={value.DocFileName} onChange={handleChange} required />
                                                                <label className=''>Documents Name</label>
                                                                {errors.DocFileNameError !== 'true' ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DocFileNameError}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6  col-lg-6  dropdown__box">
                                                            <Select
                                                                name='DocumentTypeId'
                                                                styles={colourStyles}
                                                                value={documentDrpVal?.filter((obj) => obj.value === value?.DocumentTypeId)}
                                                                isClearable
                                                                options={documentDrpVal}
                                                                onChange={(e) => ChangeDropDown(e, 'DocumentTypeId')}
                                                                placeholder="Select.."
                                                            />
                                                            <label htmlFor='' >Document Type</label>
                                                            {errors.DocumentTypeIdError !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DocumentTypeIdError}</span>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-12 col-md-12  col-lg-12 mt-3">
                                                            <div className="text-field  ">
                                                                <input type="file" className='requiredColor' name='DocumentFile' onChange={changeHandler} multiple required />
                                                                <label className='pl-1'>File Attachement</label>
                                                            </div>
                                                            <div className=" col-12 mt-3">
                                                                {
                                                                    selectedFileName?.length > 0 &&
                                                                    selectedFileName?.map((data) => {
                                                                        return <span className='bg-info mx-1 text-white px-2' key={data.id}>{data}</span>
                                                                    })
                                                                }
                                                            </div>
                                                            {errors.File_Not_Selected !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.File_Not_Selected}</span>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-12  col-md-12 col-lg-12 mt-5 pt-1 dropdown__box" style={{ marginTop: '20px' }}>
                                                            <textarea name='DocumentNotes' onChange={handleChange} id="Comments" value={value.DocumentNotes} cols="30" rows='3' className="form-control pt-2 pb-2 requiredColor" ></textarea>
                                                            <label htmlFor="" className='pt-1'>Notes</label>
                                                            {errors.DocumentNotesError !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DocumentNotesError}</span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="btn-box text-right mr-1 mb-2">
                                    <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1">Save</button>
                                    <button type="button" data-dismiss="modal" onClick={closeModal} className="btn btn-sm btn-success mr-1" >Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    :
                    <></>
            }
        </>
    )
}

export default DocumentsAddUp



function validate_fileupload(fileName) {
    if (fileName.length > 0 && fileName.length < 2) {
        return 'true';
    } else if (fileName.length > 1) {
        toastifyError("Please Select Single File");
    } else {
        return 'Please Select File..';
    }

}