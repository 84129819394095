import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import Select from "react-select";
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { RequiredFieldIncident, Space_Allow_with_Trim } from '../../../Utility/Personnel/Validation';
import { AgencyContext } from '../../../../../Context/Agency/Index';

const CommentsAddUp = (props) => {

    const { upDateCount, arrestCommentsID, status, modal, setModal, get_CommentsData, commentData, loginPinID, arrestID, loginAgencyID, } = props
    const { get_Arrest_Count } = useContext(AgencyContext)

    const [editval, setEditval] = useState();
    const [headOfAgency, setHeadOfAgency] = useState([])
    const [value, setValue] = useState({
        'CommentsDoc': '',
        'ArrestID': '',
        'Comments': '',
        'CreatedByUserFK': '',
        'ModifiedByUserFK': '',
        'OfficerID': '',
        'AdminOfficer': '',
        'arrestCommentsID': "",
    })

    const [errors, setErrors] = useState({
        'CommentsError': '', 'OfficerIDError': '',
    })

    useEffect(() => {
        if (arrestID) {
            setValue(pre => { return { ...pre, 'ArrestID': arrestID, 'CreatedByUserFK': loginPinID, 'OfficerID': loginPinID, } })
        }
    }, [arrestID, upDateCount]);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    useEffect(() => {
        if (loginAgencyID) {
            Get_Officer_Name(loginAgencyID);
        }
    }, [loginAgencyID])

    useEffect(() => {
        if (arrestCommentsID && status) {
            GetSingleData(arrestCommentsID)
        }
    }, [upDateCount, arrestCommentsID])

    const GetSingleData = (arrestCommentsID) => {
        const val = { 'ArrestCommentsID': arrestCommentsID }
        fetchPostData('ArrestComments/GetSingleData_ArrestComments', val)
            .then((res) => {
                if (res) { setEditval(res) }
                else { setEditval([]) }
            })
    }

    useEffect(() => {
        if (status) {
            setValue({
                ...value,
                'ArrestCommentsID': arrestCommentsID,
                'OfficerID': editval[0].OfficerID,
                'Comments': editval[0].Comments,
                'ModifiedByUserFK': loginPinID,
                'CommentsDoc': editval[0].CommentsDoc,
            })
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(editval[0].CommentsDoc))));
        } else {
            setValue({
                ...value,
                'Comments': '', 'CommentsDoc': '', 'ModifiedByUserFK': '', 'ArrestCommentsID': '',
            });
            setEditorState(() => EditorState.createEmpty(),);
        }
    }, [editval])

    const reset = (e) => {
        setValue({
            ...value,
            'Comments': '', 'CommentsDoc': '', 'ModifiedByUserFK': '', 'ArrestCommentsID': '',
        });
        setErrors({
            ...errors,
            'CommentsError': '', 'OfficerIDError': '',
        });
        setEditorState(() => EditorState.createEmpty(),);
    }

    const check_Validation_Error = (e) => {
        if (RequiredFieldIncident(value.Comments)) {
            setErrors(prevValues => { return { ...prevValues, ['CommentsError']: RequiredFieldIncident(value.Comments) } })
        }
        if (RequiredFieldIncident(value.OfficerID)) {
            setErrors(prevValues => { return { ...prevValues, ['OfficerIDError']: RequiredFieldIncident(value.OfficerID) } })
        }
    }


    const { CommentsError, OfficerIDError } = errors

    useEffect(() => {
        if (CommentsError === 'true' && OfficerIDError === 'true') {
            if (status) { updateComments() }
            else { submit() }
        }
    }, [CommentsError, OfficerIDError])

    // Get Head of Agency
    const Get_Officer_Name = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('DropDown/GetData_HeadOfAgency', val)
            .then(res => {
                if (res) {
                    setHeadOfAgency(Comman_changeArrayFormat(res, 'PINID', 'HeadOfAgency'))
                } else { setHeadOfAgency([]) }
            })
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            reset()
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value, [name]: e.value
            })
        } else {
            setValue({
                ...value, [name]: null
            })
        }
    }

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setValue({ ...value, 'CommentsDoc': currentContentAsHTML })
    }

    const getValueNarrative = (e) => {
        setValue({
            ...value,
            ['Comments']: e.blocks[0].text
        })
    }

    const submit = (e) => {
        const result = commentData?.find(item => {
            if (item.Comments) {
                if (item.Comments.toLowerCase() === value.Comments.toLowerCase()) {
                    return item.Comments.toLowerCase() === value.Comments.toLowerCase()
                } else return item.Comments.toLowerCase() === value.Comments.toLowerCase()
            }
        });
        if (result) {
            toastifyError('Comments Already Exists')
            setErrors({ ...errors, ['CommentsError']: '', })
        } else {
            AddDeleteUpadate('ArrestComments/Insert_ArrestComments', value)
                .then((res) => {
                    toastifySuccess(res.Message);
                    get_Arrest_Count(arrestID);
                    setModal(false)
                    get_CommentsData(arrestID);
                    reset();
                })
        }
    }

    const updateComments = (e) => {
        const result = commentData?.find(item => {
            if (item.Comments) {
                if (item.ArrestCommentsID != value.ArrestCommentsID) {
                    if (item.Comments.toLowerCase() === value.Comments.toLowerCase()) {
                        return item.Comments.toLowerCase() === value.Comments.toLowerCase()
                    } else return item.Comments.toLowerCase() === value.Comments.toLowerCase()
                }
            }
        });
        if (result) {
            toastifyError('Code Already Exists')
            setErrors({ ...errors, ['NarrativeCommentsError']: '' })
        } else {
            AddDeleteUpadate('ArrestComments/Update_ArrestComments', value)
                .then((res) => {
                    toastifySuccess(res.Message)
                    get_CommentsData(arrestID);
                    setModal(false)
                    reset();
                })
        }
    }

    const closeModal = () => {
        reset();
        setModal(false);
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    return (
        <>
            {
                modal ?

                    <dialog className="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="CommentsModal" tabIndex="-1" aria-hidden="true" data-backdrop="false">
                        <div className="modal-dialog modal-dialog-centered modal-xl" >
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="m-1 mt-3">
                                        <fieldset style={{ border: '1px solid gray' }}>
                                            <div className="row ">
                                                <div className="col-12 col-md-12 col-lg-12 ">
                                                    <div className="row ">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="text-field">
                                                                <Editor
                                                                    editorState={editorState}
                                                                    onEditorStateChange={handleEditorChange}
                                                                    wrapperClassName="wrapper-class"
                                                                    editorClassName="editor-class"
                                                                    toolbarClassName="toolbar-class"
                                                                    onChange={getValueNarrative}
                                                                    editorStyle={{ height: '60vh' }}
                                                                    toolbar={{
                                                                        options: ['inline', 'blockType', 'fontFamily', 'list', 'history'],
                                                                        inline: {
                                                                            inDropdown: false,
                                                                            className: undefined,
                                                                            component: undefined,
                                                                            dropdownClassName: undefined,
                                                                            options: ['bold', 'italic', 'underline', 'monospace',],
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                            {errors.CommentsError !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CommentsError}</span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            name='OfficerID'
                                                            isClearable
                                                            styles={colourStyles}
                                                            value={headOfAgency?.filter((obj) => obj.value === value?.OfficerID)}
                                                            options={headOfAgency}
                                                            onChange={(e) => ChangeDropDown(e, 'OfficerID')}
                                                            placeholder="Select.."
                                                            menuPlacement="top"
                                                        />
                                                        <label htmlFor="">Reported By</label>
                                                        {errors.OfficerIDError !== 'true' ? (
                                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.OfficerIDError}</span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-8 text-right mt-4 pt-2">
                                                    {
                                                        status ?
                                                            <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success pl-2">Update</button>
                                                            :
                                                            <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success pl-2">Save</button>
                                                    }
                                                    <button type="button" onClick={() => { closeModal(); }} className="btn btn-sm btn-success ml-2" data-dismiss="modal">Close</button>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog>

                    :
                    <>
                    </>
            }
        </>
    )
}

export default CommentsAddUp

