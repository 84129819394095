// Import Component
import moment from 'moment'
import { useState } from 'react';
const CryptoJS = require("crypto-js");

let randomize = require('randomatic');

export const getShowingDateText = (dateStr) => {
  return moment(dateStr).format("MM/DD/yyyy HH:mm")
}

export const getShowingYearMonthDate = (dateStr) => {
  return moment(dateStr).format("yyyy-MM-DD HH:mm:ss")
}

export const currentDate = () => {
  return moment(new Date()).format('YYYY-MM-DD');
}

export const getShowingMonthDateYear = (dateStr) => {
  return moment(dateStr).format("MM/DD/YYYY HH:mm:ss")
}

export const getShowingWithOutTime = (dateStr) => {
  return moment(dateStr).format("MM/DD/YYYY")
}

export const getMonthWithOutDateTime = (dateStr) => {
  return moment(dateStr).month()
}

export const getYearWithOutDateTime = (dateStr) => {
  return moment(dateStr).year()
}

// Encrypted And Decrypted -> Key 
const Key = '9z$C&F)J@NcRfTjW'
const Code = 'QUJDREVGR0g='

export function encryptAndEncodeToBase64(stringToEncrypt) {
  const key = CryptoJS.enc.Utf8.parse(Key);
  const iv = CryptoJS.enc.Base64.parse(Code);
  const encoded = CryptoJS.enc.Utf8.parse(stringToEncrypt);
  const bytes = CryptoJS.TripleDES.encrypt(encoded, key, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });
  const encryptedString = bytes.toString();
  const base64String = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedString));
  return base64String;
}

export const DecryptedList = (response) => {
  const key = CryptoJS.enc.Utf8.parse(Key);
  const iv = CryptoJS.enc.Base64.parse(Code);
  const bytes = CryptoJS.TripleDES.decrypt(response, key, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });
  return bytes.toString(CryptoJS.enc.Utf8);
}

// Encrypt Data 
export const EncryptedList = (text) => {
  const key = CryptoJS.enc.Utf8.parse(Key);
  const iv = CryptoJS.enc.Base64.parse(Code);
  const encoded = CryptoJS.enc.Utf8.parse(text);
  const bytes = CryptoJS.TripleDES.encrypt(encoded, key, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });
  return bytes.toString();
}

// Otp Component
export const get_OTP = () => {
  const OTP = randomize('0', 6);
  return OTP
}

// Decrypt ID And name 
export const Decrypt_Id_Name = (data, key) => {
  const result = JSON.parse(CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8))
  return result
}

export const Decrypt_Id = (data, key) => {
  const bytes = CryptoJS.AES.decrypt(data, key);
  const result = bytes.toString(CryptoJS.enc.Utf8);
  return result
}

export const Encrypted_Id_Name = (data, key) => {
  const result = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()
  return result
}

export const Encrypted_Password = (data) => {
  console.log(data)
  const result = CryptoJS.AES.encrypt(JSON.stringify(data)).toString()
  return result
}

//  Change Array
export const changeArrayFormat = (data, type) => {
  const result = data?.map((sponsor) =>
    ({ value: sponsor.GroupID, label: sponsor.GroupName, })
  )
  return result
}

export const changeArrayFormat_WithFilter = (data, type, id) => {
  if (type === 'group') {
    const result = data?.filter(function (option) { return option.GroupID === id }).map((sponsor) =>
      ({ value: sponsor.GroupID, label: sponsor.GroupName })
    )
    return result[0]
  }
}

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    height: 20,
    minHeight: 30,
    fontSize: 14,
    margintop: 2,
    boxShadow: 0,
  }),
}

export const customStylesWithOutColor = {
  control: base => ({
    ...base,
    height: 20,
    minHeight: 30,
    fontSize: 14,
    margintop: 2,
    boxShadow: 0,
  }),
};

// export const tableCustomStyles = {
//   headCells: {
//     style: {
//       fontSize: '18px',
//       fontWeight: 'bold',
//       paddingLeft: '0 8px',
//     },
//   },
//   cells: {
//     style: {
//       fontSize: '18px',
//       paddingLeft: '0 8px',
//     },
//   },
//   rows: {
//     highlightOnHoverStyle: {
//       backgroundColor: 'rgb(230, 244, 244)',
//       borderBottomColor: '#FFFFFF',
//       outline: '1px solid #FFFFFF',
//     },
//     style: {
//       padding: '10px 0',
//     },
//   },
// }
export const tableCustomStyles = {
  headRow: {
    style: {
      color: '#fff',
      backgroundColor: '#001f3f ',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
    },
  },
}
//WebSocket data encrypt and decrypt
export function encrypt(plain) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const encryptedData = CryptoJS.AES.encrypt(plain, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedData.ciphertext.toString()));
}

export function decrypt(secret) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const decryptedData = CryptoJS.AES.decrypt(secret, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decryptedData.toString(CryptoJS.enc.Utf8);
}
