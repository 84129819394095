import React, {useState } from 'react'
import Home from './FieldInterviewTab/Home/Home'
import { FieldTabs } from '../../Utility/Tab/TabsArray'
import SubTab from '../../Utility/Tab/SubTab';
import Name from './FieldInterviewTab/Name/Name';
import Comments from './FieldInterviewTab/Comments/Comments';

const FieldInterviewTab = () => {
    const [showPage, setShowPage] = useState('home');

    return (
    
            <div className="section-body view_page_design pt-3">
                <div className="row clearfix" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <div className="row ">
                                    <div className={`col-12 col-md-12`}>
                                        <div className="row">
                                         
                                            <div className="col-12 pl-3 ">
                                                <SubTab tabs={FieldTabs}  showPage={showPage} setShowPage={setShowPage} />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        showPage === 'home' ?
                                            <Home />
                                            :
                                            showPage === 'FieldName' ?
                                                <Name />
                                                :
                                            showPage === 'FieldComments' ?
                                                <Comments />
                                                :

                                                <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
    )
}

export default FieldInterviewTab