import React from 'react'
import { Link } from 'react-router-dom';
import Tab from '../../Utility/Tab/Tab';
import DataTable from 'react-data-table-component';

const FieldInterview = () => {

    
  
    return (
     

            <div className="section-body view_page_design pt-3">
                <div className="row clearfix" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <div className="row  ">
                                    <div className={`col-12 col-md-12`}>
                                        <div className="row">
                                            <div className="col-12 pl-3  inc__tabs">
                                                <Tab />
                                            </div>
                                            <div className="col-12  mt-2">
                                                <div className="bg-line text-white py-1 px-2 d-flex justify-content-between align-items-center">
                                                    <p className="p-0 m-0">Field Interview</p>
                                                    <p className="p-0 m-0">
                                                        <Link to={'/field-interviewtab'} className="btn btn-sm bg-green text-white px-2 py-0" >
                                                            <i className="fa fa-plus"></i>
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 ">
                           
                                            <DataTable
                                                dense
                                                pagination
                                                selectableRowsHighlight
                                                highlightOnHover
                                                noDataComponent={ "There are no data to display"}
                                                subHeader
                                                responsive
                                                showPaginationBottom={10}
                                            />
                           
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
    )
}

export default FieldInterview