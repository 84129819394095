import React, { useEffect, useState, useContext, useCallback } from "react";
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { Decrypt_Id_Name, getShowingDateText, getShowingMonthDateYear, tableCustomStyles } from '../../../../Common/Utility';
import { AddDeleteUpadate, fetchPostData, ScreenPermision } from '../../../../hooks/Api';
import Loader from '../../../../Common/Loader';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import DatePicker from "react-datepicker";
import Select from "react-select";
import { RequiredFieldIncident } from "../../../Utility/Personnel/Validation";
import { Comman_changeArrayFormat } from "../../../../Common/ChangeArrayFormat";

const DispatchActivity = () => {

  const { get_IncidentTab_Count, localStoreArray, get_LocalStorage, } = useContext(AgencyContext);
  const [clickedRow, setClickedRow] = useState(null);

  const [dispatchData, setDispatchData] = useState();
  const [dispatchEditValue, setDispatchEditValue] = useState();
  const [dispatchID, setDispatchID] = useState();
  const [status, setStatus] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(0)
  const [modal, setModal] = useState(false);
  const [loder, setLoder] = useState(false)

  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState();
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [incidentID, setIncidentID] = useState('');
  const [loginPinID, setLoginPinID] = useState('');
  const [userName, setUserName] = useState('')

  const [headOfAgency, setHeadOfAgency] = useState([]);


  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: '', UserName: '' }),
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID || !localStoreArray?.IncidentID) {
      get_LocalStorage(localStore);
    }
  }, []);

  // Onload Function
  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
        setLoginAgencyID(localStoreArray?.AgencyID);
        setLoginPinID(parseInt(localStoreArray?.PINID));
        setIncidentID(parseInt(localStoreArray?.IncidentID));
        setUserName(localStoreArray?.UserName)
        get_Dispatch_Data(localStoreArray?.IncidentID); getScreenPermision(localStoreArray?.AgencyID, localStoreArray?.PINID);
      }
    }
  }, [localStoreArray])
  const [value, setValue] = useState({
    'OfficerId': null,
    'DispatchDate': '',
    'Comments': '',
    'DispatchId': '',
    'IncidentId': '',
    'CreatedByUserFK': '',
    'AdminOfficer': '',
  })

  const [errors, setErrors] = useState({
    'DispatchDateError': '', 'CommentsError': '', 'officerIdError': '',
  })

  useEffect(() => {
    if (incidentID) {

      setValue(pre => {
        return {
          ...pre, 'OfficerId': loginPinID, 'IncidentId': incidentID, 'CreatedByUserFK': loginPinID,
          'DispatchDate': '',
          'Comments': '',
          'DispatchId': '',
          'ModifiedByUserFK': '',
        }
      })
    }
  }, [incidentID, status, updateStatus]);

  useEffect(() => {
    if (dispatchEditValue) {
      setValue({
        ...value,
        'OfficerId': dispatchEditValue?.OfficerId,
        'DispatchDate': dispatchEditValue?.DispatchDate ? getShowingDateText(dispatchEditValue?.DispatchDate) : null,
        'Comments': dispatchEditValue?.Comments,
        'DispatchId': dispatchEditValue?.DispatchId,
        'ModifiedByUserFK': loginPinID,
      })
    }
  }, [dispatchEditValue, updateStatus])

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.DispatchDate)) {
      setErrors(prevValues => { return { ...prevValues, ['DispatchDateError']: RequiredFieldIncident(value.DispatchDate) } })
    }
    if (RequiredFieldIncident(value.OfficerId)) {
      setErrors(prevValues => { return { ...prevValues, ['officerIdError']: RequiredFieldIncident(value.OfficerId) } })
    }
    if (RequiredFieldIncident(value.Comments)) {
      setErrors(prevValues => { return { ...prevValues, ['CommentsError']: RequiredFieldIncident(value.Comments) } })
    }
  }

  // Check All Field Format is True Then Submit 
  const { DispatchDateError, CommentsError, officerIdError } = errors

  useEffect(() => {
    if (DispatchDateError === 'true' && CommentsError === 'true' && officerIdError === 'true') {
      if (status) { UpdateDispatched() }
      else { AddDispatch() }
    }
  }, [DispatchDateError, CommentsError, officerIdError])

  useEffect(() => {
    if (loginAgencyID) {
      get_Head_Of_Agency(loginAgencyID);
    }
  }, [loginAgencyID, loginPinID])

  const get_Head_Of_Agency = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('DropDown/GetData_HeadOfAgency', val).then(res => {
      if (res) {
        setHeadOfAgency(Comman_changeArrayFormat(res, 'PINID', 'HeadOfAgency'))
      } else setHeadOfAgency([])
    })
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      Reset()
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value
    })
  }

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else {
      setValue({
        ...value,
        [name]: null
      })
    }
  }

  const AddDispatch = () => {
    AddDeleteUpadate('IncidentDispatchComments/Insert_IncidentDispatcherComments', value)
      .then((res) => {
        if (res.success) {
          toastifySuccess(res.Message);
          get_IncidentTab_Count(incidentID);
          get_Dispatch_Data(incidentID);
          // setStatus(true);
          closeModal();
        }
      })
  }

  const UpdateDispatched = () => {
    AddDeleteUpadate('IncidentDispatchComments/Update_IncidentDispatcherComments', value)
      .then((res) => {
        if (res.success) {
          toastifySuccess(res.Message);
          get_Dispatch_Data(incidentID);
          setStatus(true);
          setStatusFalse();
        }
      })
  }

  const startRef = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const closeModal = () => {
    Reset();
    setModal(false);
  }

  const Reset = () => {
    setValue({
      ...value,
      'DispatchDate': '',
      'Comments': '',
      'DispatchId': '',
      'ModifiedByUserFK': '',
    });
    setErrors({
      ...errors,
      'DispatchDateError': '', 'CommentsError': '',
    });
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }
  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];


  const get_Dispatch_Data = (incidentID) => {
    const val = {
      'IncidentId': incidentID,
    }
    fetchPostData('IncidentDispatchComments/GetData_IncidentDispatcherComments', val).then((res) => {
      if (res) {
        setDispatchData(res); setLoder(true)
      } else {
        setDispatchData([]); setLoder(true)
      }
    })
  }

  const getScreenPermision = (LoginAgencyID, LoginPinID) => {
    ScreenPermision("I031", LoginAgencyID, LoginPinID).then(res => {
      if (res) {
        setEffectiveScreenPermission(res)
      } else {
        setEffectiveScreenPermission([])
      }
    });
  }

  const columns = [

    // {
    //   width: '120px',
    //   name: <p className='text-end' style={{ position: 'absolute', top: '7px', }}>Action</p>,
    //   cell: row =>
    //     <div style={{ position: 'absolute', top: 4, }}>
    //       {
    //         effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok ?
    //           <Link to={`#`} onClick={(e) => { set_Edit_Value(e, row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DispatchModal">
    //             <i className="fa fa-edit"></i>
    //           </Link>
    //           : <></>
    //           : <Link to={`#`} onClick={(e) => { set_Edit_Value(e, row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DispatchModal">
    //             <i className="fa fa-edit"></i>
    //           </Link>
    //       }
    //     </div>

    // },
    {
      name: 'Dispatch Date/Time',
      selector: (row) => getShowingDateText(row.DispatchDate),
      sortable: true
    },
    {
      name: 'Dispatch Activity Comments',
      selector: (row) => <>{row?.Comments ? row?.Comments.substring(0, 60) : ''}{row?.Comments?.length > 100 ? '  . . .' : null} </>,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 20 }}>Delete</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, right: 20 }}>
          {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
              <Link to={`#`} onClick={() => { setDispatchID(row.DispatchId) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </Link>
              : <></>
              : <Link to={`#`} onClick={() => { setDispatchID(row.DispatchId) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </Link>
          }
        </div>

    }
  ]

  const set_Edit_Value = (row) => {
    setStatus(true);
    setDispatchEditValue(row);
    setUpdateStatus(updateStatus + 1);
    setModal(true)
  }

  const setStatusFalse = (e) => {
    setStatus(false)
    setDispatchEditValue();
    setModal(true);
  }

  const DeleteDispatch = () => {
    const val = {
      'DispatchId': dispatchID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate('IncidentDispatchComments/Delete_IncidentDispatcherComments', val)
      .then((res) => {
        if (res.success) {
          toastifySuccess(res.Message);
          get_IncidentTab_Count(incidentID);
        } else { console.log("Somthing Wrong"); }
        get_Dispatch_Data(incidentID);
      })
  }

  return (
    <>
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-6 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Dispatch Date/Time</label>
              </div>
              <div className="col-7 col-md-7 col-lg-6 mt-2 ">
                <DatePicker
                  ref={startRef}
                  onKeyDown={onKeyDown}
                  name='DispatchDate'
                  id='DispatchDate'
                  className='requiredColor'
                  dateFormat="MM/dd/yyyy HH:mm"
                  onChange={(date) => { setValue({ ...value, ['DispatchDate']: date ? getShowingMonthDateYear(date) : null }) }}
                  timeInputLabel
                  isClearable={value?.DispatchDate ? true : false}
                  placeholderText={'Select..'}
                  selected={value?.DispatchDate && new Date(value?.DispatchDate)}
                  showTimeSelect
                  timeIntervals={1}
                  timeCaption="Time"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors.DispatchDateError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DispatchDateError}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Officer Name</label>
              </div>
              <div className="col-7 col-md-7 col-lg-8 mt-2 ">
                <Select
                  name='OfficerId'
                  styles={colourStyles}
                  value={headOfAgency?.filter((obj) => obj.value === value?.OfficerId)}
                  options={headOfAgency}
                  onChange={(e) => ChangeDropDown(e, 'OfficerId')}
                  placeholder="Select.."
                  menuPlacement="top"
                  isClearable
                />
                {errors.officerIdError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.officerIdError}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 col-md-4 col-lg-3 mt-2 pt-2">
            <label htmlFor="" className='new-label'>Dispatch Activity Comments</label>
          </div>
          <div className="col-8 col-md-8 col-lg-9 mt-2 ">
            <textarea name='Comments' id="Comments" cols="30" onChange={handleChange} value={value.Comments} rows='3' className="form-control pt-2 pb-2 requiredColor" ></textarea>
            {errors.CommentsError !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CommentsError}</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="btn-box text-right mt-4 mr-1  bb">
        <button type="button" className="btn btn-sm btn-success mr-1 mb-2" data-dismiss="modal" onClick={() => { setStatusFalse(); setUpdateStatus(updateStatus + 1); }}>New</button>
        {
          status ?
            <button type="button" onClick={() => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1 mb-2">Update</button>
            :
            <button type="button" onClick={() => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1 mb-2">Save</button>
        }
      </div>
      <div className="col-12 px-0 mt-3"  style={{ height: '160px', overflowY: 'scroll' }}>
        {
          loder ?
            <DataTable
              dense
              columns={columns}
              data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? dispatchData : '' : dispatchData}
              // pagination
              // paginationPerPage={'5'}
              // paginationRowsPerPageOptions={[5]}
              selectableRowsHighlight
              highlightOnHover
              customStyles={tableCustomStyles}
              onRowClicked={(row) => {
                setClickedRow(row);
                set_Edit_Value(row);
              }}
              conditionalRowStyles={conditionalRowStyles}
              showHeader={true}
              noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
            />
            :
            <Loader />
        }
      </div>
      {/* <AddDispatch {...{ loginPinID, incidentID, loginAgencyID, userName, status, setStatus, dispatchEditValue, setDispatchEditValue, get_Dispatch_Data, updateStatus, modal, setModal }} /> */}
      <DeletePopUpModal func={DeleteDispatch} />
    </>
  )
}

export default DispatchActivity; 