import React, { memo, useRef, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useLocation } from "react-router-dom";
import { SplitAddress, } from "../Common/SplitAddress";

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const REACT_APP_GOOGLE_MAP_API_KEY = "AIzaSyDL9J82iDhcUWdQiuIvBYa0t5asrtz3Swk"
const libraries = ["places"];

function Location({ setValue, value, col, locationStatus, setLocationStatus, check, ref, locationID, verify }) {

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
        libraries
    });

    const [markers, setMarkers] = React.useState([]);

    const mapRef = React.useRef();

    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
        setMarkers(current => [
            ...current,
            {
                lat,
                lng,
                time: new Date()
            }
        ]);
    }, []);

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";

    return (
        <div>
            <Search panTo={panTo} set={setValue} val={value} col={col} LoStatus={locationStatus} setLoSta={setLocationStatus} check={check} ref={ref} locationID={locationID} verify={verify} />
        </div>
    );
}

export default memo(Location)

function Search({ panTo, set, val, col, LoStatus, setLoSta, check = { check }, ref, locationID, verify }) {

    let storageRef = useRef(verify);

    useEffect(() => {
        if (!storageRef.current || LoStatus) {
            handleInput(null);
        }
    }, [verify, LoStatus])

    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');


    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {
            location: { lat: () => 43.6532, lng: () => -79.3832 },
            radius: 100 * 1000,
            componentRestrictions: { country: "us" },
            strictBounds: false,
        }
    });

    useEffect(() => {
        if (val[col]) {
            setValue(val[col], false);
        }
    }, [val])


    const handleInput = e => {
        if (e) {
            setValue(e.target.value);
        }
        else {
            setValue('');
        }
    };

    useEffect(() => {
        if (openPage === 'clear') setValue('')
    }, [openPage])

    const handleSelect = async (address) => {
        set(pre => { return { ...pre, [col]: address } })
        setValue(address, false);
        clearSuggestions();
        saveVerifyLocation({ address, set, val, col, locationID });
    };

    return (
        <div className="search" style={{ pointerEvents: !verify && 'none' }}>
            <Combobox onSelect={handleSelect}>
                <ComboboxInput style={{ background: check ? '#fce9bf' : '', zIndex: 200 }}
                    value={value}
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder="Search your location"
                />
                <ComboboxPopover >
                    {
                        verify ?
                            <ComboboxList  >
                                {status === "OK" &&
                                    data.map(({ id, description }) => (
                                        <ComboboxOption key={id} value={description} />
                                    ))}
                            </ComboboxList>
                            :
                            <>
                            </>
                    }
                </ComboboxPopover>
            </Combobox>
        </div >
    );
}

const saveVerifyLocation = async ({ address, set, val, col, locationID }) => {

    let geoApiData = await SplitAddress(address);
    let Add = geoApiData?.address?.addressComponents ? geoApiData?.address?.addressComponents : [];
    let country = Add?.filter((obj) => { if (obj?.componentType === "country") { return obj.componentName } });
    let City = Add?.filter((obj) => { if (obj?.componentType === "locality") { return obj.componentName } });
    let Street = Add?.filter((obj) => { if (obj?.componentType === "route") { return obj.componentName } });
    let street_number = Add?.filter((obj) => { if (obj?.componentType === 'street_number') { return obj.componentName } });
    let sublocality_level_1 = Add?.filter((obj) => { if (obj?.componentType === 'sublocality_level_1') { return obj.componentName } });
    let administrative_area_level_1 = Add?.filter((obj) => { if (obj?.componentType === "administrative_area_level_1") { return obj.componentName } });
    let postal_code = Add?.filter((obj) => { if (obj?.componentType === "postal_code") { return obj.componentName } });
    let point_of_interest = Add?.filter((obj) => { if (obj?.componentType === "point_of_interest") { return obj.componentName } });
    let neighborhood = Add?.filter((obj) => { if (obj?.componentType === "neighborhood") { return obj.componentName } });
    let subpremise = Add?.filter((obj) => { if (obj?.componentType === "subpremise") { return obj.componentName } });
    let premise = Add?.filter((obj) => { if (obj?.componentType === "premise") { return obj.componentName } });
    set(pre => {
        return {
            ...pre,
            'IsUsLocation': 'Y',
            'Street': Street[0]?.componentName?.text ? Street[0].componentName.text : '',
            'City': City[0]?.componentName?.text ? City[0]?.componentName?.text : '',
            'Country': country[0]?.componentName?.text ? country[0]?.componentName?.text : '',
            'PremiseNo': street_number[0]?.componentName?.text ? street_number[0]?.componentName?.text : '',
            'ZipCode': postal_code[0]?.componentName?.text ? postal_code[0]?.componentName?.text : '',
            'TypeSufix': typeof (sublocality_level_1[0]?.componentName?.text) === 'number' ? sublocality_level_1[0]?.componentName?.text : 0,
            'DirectionSufix': administrative_area_level_1[0]?.componentName?.text ? administrative_area_level_1[0]?.componentName?.text : '',
            'point_of_interest': point_of_interest[0]?.componentName?.text ? point_of_interest[0]?.componentName?.text : '',
            'neighborhood': neighborhood[0]?.componentName?.text ? neighborhood[0]?.componentName?.text : '',
            'subpremise': subpremise[0]?.componentName?.text ? subpremise[0]?.componentName?.text : '',
            'premise': premise[0]?.componentName?.text ? premise[0]?.componentName?.text : '',
            'DirectionPrefix': '',
            'State': '',
            'ApartmentNo': '',
            'CommonPlace': '',
            'ApartmentType': '',
            'Street_Parse': '',
            'PremiseNo_Parse': '',
            'DirectionPrefix_Parse': '',
            'TypeSuffix_Parse': '',
            'DirectionSuffix_Parse': '',
            'ZipCodeID': '',
            'CityID': '',
            'CountryID': '',
        }
    })

}
