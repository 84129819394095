import React from 'react'

const ArrestSearch = () => {
    return (
        <div className="row px-1">
            <div className="col-12">
                <input type="text" className='form-control' placeholder='Search By Name ...' />
            </div>
        </div>
    )
}

export default ArrestSearch