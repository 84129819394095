import React, { memo, useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { getShowingDateText, getShowingWithOutTime } from '../../Common/Utility';
import { AgencyContext } from '../../../Context/Agency/Index';
import { fetchPostData } from '../../hooks/Api';
import { toastifyError } from '../../Common/AlertMsg';

const NameSearchModal = ({ mainIncidentID, nameSearchValue, setValue, value, setDobDate, get_Name_MultiImage }) => {

    const { nameSearchStatus, setNameSearchStatus } = useContext(AgencyContext);
    const [editval, setEditval] = useState([]);

    useEffect(() => {
        if (editval) {
            setDobDate(getShowingWithOutTime(editval?.DateOfBirth))
            setValue({
                ...value,
                'NameID': editval?.NameID,
                'MasterNameID': editval?.MasterNameID, 'EthnicityID': editval?.EthnicityID,
                'NameIDNumber': editval?.NameIDNumber ? editval?.NameIDNumber : 'Auto Generated',
                'checkVictem': editval?.NewVictimID ? editval?.NewVictimID?.NewVictimID : "", 'checkOffender': editval?.NewOffenderID ? editval?.NewOffenderID?.NewOffenderID : "",
                // DropDown
                'NameTypeID': editval?.NameTypeID, 'BusinessTypeID': editval?.BusinessTypeID, 'SuffixID': editval?.SuffixID, 'VerifyID': editval?.VerifyID,
                'SexID': editval?.SexID, 'RaceID': editval?.RaceID, 'PhoneTypeID': editval?.PhoneTypeID,
                'NameReasonCodeID': '', 'CertifiedByID': editval?.CertifiedByID,
                // checkbox
                'IsJuvenile': editval?.IsJuvenile, 'IsVerify': editval?.IsVerify, 'IsUnListedPhNo': editval?.IsUnListedPhNo,
                //textbox
                'LastName': editval?.LastName, 'FirstName': editval?.FirstName, 'MiddleName': editval?.MiddleName,
                'SSN': editval?.SSN, 'WeightFrom': editval?.WeightFrom, 'WeightTo': editval?.WeightTo,
                'HeightFrom': editval?.HeightFrom, 'HeightTo': editval?.HeightTo, 'Address': editval?.Address,
                'Contact': editval?.Contact, 'AgeFrom': editval?.AgeFrom ? editval?.AgeFrom : '', 'AgeTo': editval?.AgeTo ? editval?.AgeTo : '',
                //Datepicker
                'DateOfBirth': editval?.DateOfBirth ? getShowingWithOutTime(editval?.DateOfBirth) : '',
                'CertifiedDtTm': editval?.CertifiedDtTm ? getShowingDateText(editval?.CertifiedDtTm) : '',
                'Years': editval?.Years,
            })
            setDobDate(editval?.DateOfBirth ? new Date(getShowingWithOutTime(editval?.DateOfBirth)) : '');
            get_Name_MultiImage(0, editval?.MasterNameID)
        }
    }, [editval])

    const columns = [
        {
            name: 'NameID',
            selector: (row) => <>{row?.NameIDNumber} </>,
            sortable: true
        },
        {
            name: 'LastName',
            selector: (row) => <>{row?.LastName ? row?.LastName.substring(0, 10) : ''}{row?.LastName?.length > 20 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: 'FirstName',
            selector: (row) => <>{row?.FirstName ? row?.FirstName.substring(0, 10) : ''}{row?.FirstName?.length > 20 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: 'MiddleName',
            selector: (row) => <>{row?.MiddleName ? row?.MiddleName.substring(0, 10) : ''}{row?.MiddleName?.length > 20 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: 'SSN',
            selector: (row) => row.SSN,
            sortable: true
        },
        {
            name: 'DOB',
            selector: (row) => row.DateOfBirth ? getShowingDateText(row.DateOfBirth) : '',
            sortable: true
        },
        {
            name: 'Gender',
            selector: (row) => row.Gender_Description,
            sortable: true
        },
        {
            name: 'Race',
            selector: (row) => row.Race_Description,
            sortable: true
        },
        {
            name: 'IsAlias',
            selector: (row) => row.IsAlias,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
            cell: row => <>
                {
                    <Link to={'#'} onClick={() => setEditValue(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                        <i className="fa fa-edit"></i>
                    </Link>
                }
            </>
        }
    ]

    const setEditValue = (row) => {
        fetchPostData("MasterName/GetData_EventNameExists", {
            "MasterNameID": row.MasterNameID,
            "IncidentID": mainIncidentID ? mainIncidentID : '',
        }).then((data) => {
            if (data) {
                if (data[0]?.Total === 0) {
                    console.log(row)
                    setEditval(row); setNameSearchStatus(false);
                } else {
                    toastifyError('Name Already Exists'); setNameSearchStatus(true);
                }
            }
        })
    }

    return (
        <>
            {
                nameSearchStatus &&
                <dialog className="modal fade " style={{ background: "rgba(0,0,0, 0.5)", display: 'block', opacity: '1' }} id="SearchModal" tabIndex="-1" data-backdrop="false"  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header px-3 p-2">
                                <h5 className="modal-title">Name List</h5>
                                <button type="button" onClick={() => { setNameSearchStatus(false); setValue(pre => { return { ...pre, ['SSN']: '' } }) }} className="close btn-modal" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ color: 'red', fontSize: '20px', }}>&times;</span>
                                </button>
                              
                            </div>
                            <div className="box text-center px-2">
                                <div className="col-12 ">
                                    <DataTable
                                        dense
                                        columns={columns}
                                        data={nameSearchValue}
                                        pagination
                                        selectableRowsHighlight
                                        highlightOnHover
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </dialog>
            }
        </>
    )
}

export default memo(NameSearchModal)