import React, { useRef, useContext, useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { Comman_changeArrayFormat, Comman_changeArrayFormatBasicInfo, threeColArrayWithCode, typeofsecurityArray } from '../../../../Common/ChangeArrayFormat';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { Decrypt_Id_Name, tableCustomStyles } from '../../../../Common/Utility';
import { fetchPostData, AddDeleteUpadate, ScreenPermision } from '../../../../hooks/Api';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import FindListDropDown from '../../../../Common/FindListDropDown';
import { IncTypeListDropDownArray } from '../../../../Utility/ListDropDownArray/ListDropArray';
import Loader from '../../../../Common/Loader';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { components } from "react-select";

const TypeOfSecurity = () => {

  const SelectedValue = useRef();
  const { get_IncidentTab_Count, localStoreArray, get_LocalStorage, status } = useContext(AgencyContext);
  const [typeOfSecurityData, setTypeOfSecurityData] = useState();
  const [typeOfSecurityEditVal, setTypeOfSecurityEditVal] = useState();
  const [typeOfSecurityList, setTypeOfSecurityList] = useState([]);
  const [securityID, setSecurityID] = useState();
  const [loder, setLoder] = useState(false);
  const [loginAgencyID, setLoginAgencyID] = useState('');

  const [incidentID, setIncidentID] = useState('');
  const [loginPinID, setLoginPinID] = useState('');
  //screen permission 
  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState();
  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const [value, setValue] = useState({
    'SecurityId': '', 'SecurityIdName': '',
    'IncidentSecurityID': '',
    'ModifiedByUserFK': '',
    'IncidentId': '',
    'CreatedByUserFK': '',
  })
  const [multiSelected, setMultiSelected] = useState({
    securityID: null,
  })
  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: '' }),
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage(localStore);
    }
  }, []);

  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
        setLoginPinID(localStoreArray?.PINID);
        setIncidentID(localStoreArray?.IncidentID);
        setLoginAgencyID(parseInt(localStoreArray?.AgencyID));
        // get_Security_DropDown(localStoreArray?.IncidentID);
        // get_Security_Data(localStoreArray?.IncidentID);
        // getScreenPermision(localStoreArray?.AgencyID, localStoreArray?.PINID);
      }
    }
  }, [localStoreArray])
console.log(localStoreArray)
  useEffect(() => {
    if (incidentID) setValue(pre => { return { ...pre, 'IncidentId': incidentID, 'CreatedByUserFK': loginPinID } })
  }, [incidentID]);

  const [errors, setErrors] = useState({
    'TypeOfSecError': '',
  })

  console.log(incidentID);

  useEffect(() => {
    if (incidentID) { get_Security_Data(); }
  }, [incidentID])

  useEffect(() => {
    if (typeOfSecurityEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['securityID']: typeOfSecurityEditVal } }) }
  }, [typeOfSecurityEditVal])


  const typeofsecurity = (multiSelected) => {
    setMultiSelected({
      ...multiSelected,
      securityID: multiSelected
    })
    const len = multiSelected.length - 1
    if (multiSelected?.length < typeOfSecurityEditVal?.length) {
      let missing = null;
      let i = typeOfSecurityEditVal.length;
      while (i) {
        missing = (~multiSelected.indexOf(typeOfSecurityEditVal[--i])) ? missing : typeOfSecurityEditVal[i];
      }
      DelSertBasicInfo(missing.id, 'IncidentSecurityID', 'TypeOfSecurity/DeleteTypeOfSecurity')
    } else {
      InSertBasicInfo(multiSelected[len].value, 'SecurityId', 'TypeOfSecurity/InsertTypeOfSecurity')
    }
  }
  useEffect(() => {
    get_Security_DropDown(loginAgencyID);;
  }, [loginAgencyID])

  console.log(loginAgencyID)

  const get_Security_Data = () => {
    const val = {
      'IncidentId': incidentID,
    }
    fetchPostData('TypeOfSecurity/GetDataTypeOfSecurity', val).then((res) => {
      if (res) {
        setTypeOfSecurityEditVal(typeofsecurityArray(res, 'SecurityId', 'IncidentID', 'PretendToBeID', 'IncidentSecurityID', 'Security_Description'));
        // setTypeOfSecurityData(res);
        console.log(res);
      } else {
        setTypeOfSecurityEditVal([]);
      }
    })
  }


  //--------Security_fetchData----------//
  const get_Security_DropDown = (loginAgencyID) => {
    const val = {
      'AgencyID': loginAgencyID,
      // 'IncidentId': incidentID,
    }
    fetchPostData('TypeOfSecurity/GetData_InsertTypeOfSceurity', val).then((data) => {
      if (data) {
        setTypeOfSecurityList(threeColArrayWithCode(data, 'SecurityId', 'Description', 'SecurityCode'));
        // setTypeOfSecurityList(Comman_changeArrayFormat(data, 'SecurityId', 'Description'));
        console.log(data);
      }
      else {
        setTypeOfSecurityList([])
      }
    })
  }

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else setValue({
      ...value,
      [name]: null
    })
  }

  // const columns = [
  //   {
  //     name: 'Description',
  //     selector: (row) => row.Security_Description,
  //     sortable: true
  //   },
  //   {
  //     name: <p className='text-end' style={{ position: 'absolute', top: 8, left: '15px' }}>Delete</p>,
  //     cell: row =>
  //       <div style={{ position: 'absolute', top: 4, }}>
  //         {
  //           effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
  //             <Link to={`#`} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={() => { setSecurityID(row.IncidentSecurityID) }} data-toggle="modal" data-target="#DeleteModal">
  //               <i className="fa fa-trash"></i>
  //             </Link>
  //             : <></>
  //             : <Link to={`#`} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={() => { setSecurityID(row.IncidentSecurityID) }} data-toggle="modal" data-target="#DeleteModal">
  //               <i className="fa fa-trash"></i>
  //             </Link>
  //         }
  //       </div>

  //   }
  // ]



  const onClear = () => {
    SelectedValue?.current?.clearValue();
  };

  // const AddTypeOfSecurity = () => {
  //   AddDeleteUpadate('TypeOfSecurity/InsertTypeOfSecurity', value).then((res) => {
  //     if (res.success) {
  //       toastifySuccess(res.Message);
  //       get_Security_DropDown(incidentID);
  //       get_IncidentTab_Count(incidentID);
  //       get_Security_Data(incidentID);
  //       console.log(res, 'insert');
  //       // onClear();
  //       // setErrors({
  //       //   ['TypeOfSecError']: '',
  //       // })
  //     } else console.log("Somthing Wrong");
  //   })
  // }

  // const UpdateTypeOfSecurity = () => {
  //   AddDeleteUpadate('TypeOfSecurity/UpdateTypeOfSecurity', value).then((res) => {
  //     if (res.success) {
  //       toastifySuccess(res.Message);
  //       get_Security_DropDown(incidentID);
  //       get_Security_Data(incidentID);
  //       setErrors({
  //         ['TypeOfSecError']: '',
  //       })
  //     } else { console.log("Somthing Wrong"); }
  //   })
  // }

  // const DeleteTypeOfSecurity = () => {
  //   const val = {
  //     'IncidentSecurityID': securityID,
  //     'DeletedByUserFK': loginPinID,
  //   }
  //   AddDeleteUpadate('TypeOfSecurity/DeleteTypeOfSecurity', val).then((res) => {
  //     if (res.success) {
  //       toastifySuccess(res.Message);
  //       get_IncidentTab_Count(incidentID);
  //       get_Security_DropDown(incidentID);
  //       get_Security_Data(incidentID);
  //     } else { console.log("Somthing Wrong"); }
  //   })
  // }
  const InSertBasicInfo = (id, col1, url) => {
    const val = {
      'IncidentId': incidentID,
      [col1]: id,
      'CreatedByUserFK': loginPinID,
    }
    console.log(incidentID)
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        col1 === 'SecurityId' && get_Security_Data();
      } else {
        console.log("Somthing Wrong");
      }
    })
  }

  const DelSertBasicInfo = (IncidentSecurityID, col1, url) => {
    const val = {
      [col1]: IncidentSecurityID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate(url, val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        col1 === 'IncidentSecurityID' && get_Security_Data()
      } else {
        console.log("res");
      }
    })
  }

  // Custom Style
  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>

      <div className="col-12 ">
        <div className="row mt-1">
          <div className="col-2 col-md-2 col-lg-2 mt-4">
            <label htmlFor="" className='new-label'>Type Of Security</label>
          </div>
          <div className="col-7 col-md-7 col-lg-5 mt-2 mb-2">
            {
              value?.SecurityIdName ?
                <Select
                  className="basic-multi-select"
                  isMulti
                  name='SecurityId'
                  isClearable
                  // defaultValue={value?.SecurityIdName}
                  options={typeOfSecurityList}
                  // onChange={(e) => ChangeDropDown(e, 'SecurityId')}
                  onChange={(e) => typeofsecurity(e)}
                  value={multiSelected.securityID}
                  components={{ MultiValue, }}

                  placeholder="Select Type Of Security From List.."

                />
                :
                <Select
                  className="basic-multi-select"
                  isMulti
                  name='SecurityId'
                  isClearable
                  options={typeOfSecurityList}
                  // onChange={(e) => ChangeDropDown(e, 'SecurityId')}
                  onChange={(e) => typeofsecurity(e)}
                  value={multiSelected.securityID}
                  placeholder="Select Type Of Security From List.."
                  components={{ MultiValue, }}

                // ref={SelectedValue}
                />
            }
            {/* {errors.TypeOfSecError !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.TypeOfSecError}</span>
            ) : null} */}
          </div>
        </div>
      </div>
      {/* <div className="col-12 col-md-12 col-lg-12 p-0 bb text-right">
        {
          effectiveScreenPermission ? effectiveScreenPermission[0]?.AddOK ?
            <div className="col-12 col-md-12 col-lg-12 mt-3 pt-1 p-0 mb-1">
              <button type="button" className="btn btn-sm btn-success mx-1 py-1 text-center" onClick={() => { check_Validation_Error(); }}>Save</button>
            </div>
            : <></>
            : <div className="col-12 col-md-12 col-lg-12 mt-3 pt-1 p-0 mb-1">
              <button type="button" className="btn btn-sm btn-success mx-1 py-1 text-center" onClick={() => { check_Validation_Error(); }}>Save</button>
            </div>
        }

      </div> */}

      {/* <div className="col-md-12 mt-2">
        <div className="bg-line text-white py-1 px-2  d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">
            Type Of Security
          </p>
          <FindListDropDown
            array={IncTypeListDropDownArray}
          />
        </div>
        <div className="row px-1">
          <div className="col-12 col-md-6 col-lg-4 mt-5 pt-1  dropdown__box">
            {
              value?.SecurityIdName ?
                <Select
                  name='SecurityId'
                  styles={colourStyles}
                  isClearable
                  defaultValue={value?.SecurityIdName}
                  options={typeOfSecurityList}
                  onChange={(e) => ChangeDropDown(e, 'SecurityId')}
                  placeholder="Select.."
                />
                :

                <Select
                  name='SecurityId'
                  styles={colourStyles}
                  isClearable
                  options={typeOfSecurityList}
                  onChange={(e) => ChangeDropDown(e, 'SecurityId')}
                  placeholder="Select.."
                  ref={SelectedValue}
                />

            }
            <label htmlFor="" className='pt-1'>Type Of Security</label>
            {errors.TypeOfSecError !== 'true' ? (
              <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.TypeOfSecError}</span>
            ) : null}
          </div>

          <div className="col-6 col-md-6 col-lg-8 p-0">
            {
              effectiveScreenPermission ? effectiveScreenPermission[0]?.AddOK ?
                <div className="col-6 col-md-6 col-lg-8 mt-3 pt-1 p-0">
                  <button type="button" className="btn btn-sm btn-success mx-1 py-1 text-center" onClick={() => { check_Validation_Error(); }}>Save</button>
                </div>
                : <></>
                : <div className="col-6 col-md-6 col-lg-8 mt-3 pt-1 p-0">
                  <button type="button" className="btn btn-sm btn-success mx-1 py-1 text-center" onClick={() => { check_Validation_Error(); }}>Save</button>
                </div>
            }

          </div>
        </div>
      </div> */}
      {/* <div className="col-12 px-0 mt-3">
        {
          loder ?
            <DataTable
              columns={columns}
              data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? typeOfSecurityData : '' : typeOfSecurityData}
              pagination
              paginationPerPage={'5'}
              paginationRowsPerPageOptions={[5]}
              dense
              selectableRowsHighlight
              highlightOnHover
              customStyles={tableCustomStyles}
            // noDataComponent={columns}
            />
            :
            <Loader />
        }
      </div> */}
      {/* <DeletePopUpModal func={DeleteTypeOfSecurity} /> */}
    </>
  )
}

export default TypeOfSecurity

