
// const googleAddKey = process.env.REACT_APP_GOOGLE_ADD_API_KEY;
const REACT_APP_GOOGLE_ADD_API_KEY = "AIzaSyBEhZNFF92eTAqWeQ2ZCaITiWeaFrWlf1w"


export async function SplitAddress(Add) {
    try {
        const myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "address": {
                "regionCode": "US",
                "addressLines": [Add]
            },
            "enableUspsCass": true
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const res = await fetch(`https://addressvalidation.googleapis.com/v1:validateAddress?key=${REACT_APP_GOOGLE_ADD_API_KEY}`, requestOptions);
        if (!res.ok) {
            throw Error(res.statusText);
        }
        const data = await res.json();
        console.log(data?.result);
        const newData = data?.result
        return newData
    }
    catch (error) {
        console.log(error)
    }
}