import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { fetchPostData, AddDeleteUpadate } from './../../../../../../hooks/Api.js'
import { getShowingDateText, } from './../../../../../../Common/Utility';
import { toastifySuccess } from './../../../../../../Common/AlertMsg';
import DeletePopUpModal from './../../../../../../Common/DeleteModal';
import { ArrChargeCourtListDropDownArray } from '../../../../../../Utility/ListDropDownArray/ListDropArray.js';
import FindListDropDown from '../../../../../../Common/FindListDropDown.jsx';
import Loader from '../../../../../../Common/Loader.jsx';
import { AgencyContext } from '../../../../../../../Context/Agency/Index.js';
import CourtDispositionAddUp from './CourtDispositionAddUp.jsx';

const CourtDisposition = () => {

  const { get_ArrestCharge_Count, localStoreArray, get_LocalStorage, } = useContext(AgencyContext);

  const [courtDispoData, setCourtDispoData] = useState();
  const [status, setStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [chargeCourtDispositionID, setChargeCourtDispositionID] = useState();
  const [loder, setLoder] = useState(false);
  const [arrestID, setArrestID] = useState('');

  //screen permission 
  const [chargeID, setChargeID] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [loginPinID, setLoginPinID,] = useState('');

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage();
    }
  }, []);

  // Onload Function
  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
        setLoginAgencyID(localStoreArray?.AgencyID);
        setLoginPinID(parseInt(localStoreArray?.PINID));
        setArrestID(localStoreArray?.ArrestID);
        if (localStoreArray.ChargeID) {
          setChargeID(localStoreArray?.ChargeID); get_CourtDisposition_Data(localStoreArray?.ChargeID);
        } else { setChargeID('') }
      }
    }
  }, [localStoreArray])

  const get_CourtDisposition_Data = (chargeID) => {
    const val = {
      'ChargeID': chargeID,
    }
    fetchPostData('ChargeCourtDisposition/GetData_ChargeCourtDisposition', val).then((res) => {
      if (res) {
        setCourtDispoData(res); setLoder(true)
      } else {
        setCourtDispoData([]); setLoder(true)
      }
    })
  }



  const columns = [
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, }}>Action</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', top: 4, left: 20 }}>

           <Link to={''} onClick={(e) => { set_Edit_Value(e, row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#CourtDispositionModal" >
            <i className="fa fa-edit"></i></Link>

        </div>

    },
    {
      name: 'Date/Time',
      selector: (row) => getShowingDateText(row.DispositionDtTm),
      sortable: true
    },
    {
      name: 'Comment',
      selector: (row) => row.Comments,
      sortable: true
    },

    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 60 }}>Delete</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', top: 4, right: 65 }}>

          <Link to={`#`} onClick={() => { setChargeCourtDispositionID(row.ChargeCourtDispositionID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
            <i className="fa fa-trash"></i>
          </Link>

        </div>

    }
  ]

  const set_Edit_Value = (e, row) => {
    get_ArrestCharge_Count(row.ChargeID)
    setStatus(true);
    setModal(true)
    setUpdateStatus(updateStatus + 1);
    setChargeCourtDispositionID(row.ChargeCourtDispositionID);

  }

  const DeleteCourtDisposition = () => {
    const val = {
      'ChargeCourtDispositionID': chargeCourtDispositionID,
      'DeletedByUserFK': loginPinID
    }
    AddDeleteUpadate('ChargeCourtDisposition/Delete_ChargeCourtDisposition', val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_ArrestCharge_Count(chargeID)
        get_CourtDisposition_Data(chargeID);
      } else console.log("Somthing Wrong");
    })
  }

  const setStatusFalse = (e) => {
    setStatus(false);
    setModal(true)
    setUpdateStatus(updateStatus + 1);

  }

  return (
    <>
      <div className="col-md-12 mt-2">
        <div className="bg-line text-white py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">
            Court Disposition
          </p>
          <div>

            <Link to="" className="btn btn-sm bg-green text-white px-2 py-0" onClick={setStatusFalse}
              data-toggle="modal" data-target="#CourtDispositionModal" style={{ marginTop: '-7px' }}>
              <i className="fa fa-plus"></i>
            </Link>

            <FindListDropDown
              array={ArrChargeCourtListDropDownArray}
            />
          </div>
        </div>
        {
          loder ?
            <DataTable
              dense
              columns={columns}
              data={courtDispoData}
              pagination
              highlightOnHover
              noDataComponent={'There are no data to display'}
            />
            :
            <Loader />
        }
      </div>
      <DeletePopUpModal func={DeleteCourtDisposition} />
      <CourtDispositionAddUp {...{ chargeID, loginPinID, loginAgencyID, status, setStatus, chargeCourtDispositionID, modal, setModal, get_CourtDisposition_Data, updateStatus, arrestID }} />
    </>
  )
}

export default CourtDisposition;



