import React, { useContext, useEffect, useState, useCallback } from 'react';
import Select from "react-select";
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { Comman_changeArrayFormat, Comman_changeArrayFormat_With_Name } from '../../../../Common/ChangeArrayFormat';
import { getShowingWithOutTime } from '../../../../Common/Utility';
import { fetchData, fetchPostData, AddDeleteUpadate } from '../../../../hooks/Api';
import DatePicker from "react-datepicker";
import { RequiredFieldIncident, StateID } from '../../../Utility/Personnel/Validation';
import { RequiredFieldSpaceNotAllow } from '../../../Agency/AgencyValidation/validators';
import { useLocation } from 'react-router-dom';
import { AgencyContext } from '../../../../../Context/Agency/Index';

const IdentificationAddUp = (props) => {

    const { nameID, masterNameID, loginPinID, upDateCount, loginAgencyID, status, Get_IdentificationData, setModal, modal, identificationNumberID, updateStatus } = props
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');
    const { get_Name_Count } = useContext(AgencyContext)

    const [identification, setIdentification] = useState([]);
    const [editval, setEditval] = useState();
    const [identificationDate, setIdentificationDate] = useState();
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [isCountrySelected, setIsCountrySelected] = useState(false);
    const [selected, setSelected] = useState(false)

    const [value, setValue] = useState({
        'IdentificationTypeID': null, 'StateID': null, 'CountryID': null, 'IdentificationNumber': '', 'IsCurrent': "", 'ExpiryDate': "", 'IdentificationNumberID': '',
        'NameID': '', 'MasterNameID': '', 'CreatedByUserFK': '',
    })

    const [errors, setErrors] = useState({
        'IdentificationTypeIDErrors': '', 'IdentificationNumberErrors': '', 'StateIDErrors': '', 'CountryIDErrors': '',
    })

    useEffect(() => {
        setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'MasterNameID': masterNameID, 'NameID': nameID } });
    }, [nameID, masterNameID, updateStatus]);

    useEffect(() => {
        if (identificationNumberID) {
            GetSingleData(identificationNumberID)
        }
    }, [updateStatus, identificationNumberID])

    const GetSingleData = (identificationNumberID) => {
        const val = { 'IdentificationNumberID': identificationNumberID }
        fetchPostData('NameIdentificationNumber/GetSingleData_NameIdentificationNumber', val)
            .then((res) => {
                if (res) { setEditval(res) }
                else { setEditval() }
            })
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") { reset() }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    useEffect(() => {
        if (status) {
            setValue({
                ...value,
                'IdentificationNumberID': identificationNumberID, 'IdentificationTypeID': editval[0]?.IdentificationTypeID, 'IsCurrent': editval[0]?.IsCurrent,
                'ExpiryDate': editval[0]?.ExpiryDate ? getShowingWithOutTime(editval[0]?.ExpiryDate) : '', "IdentificationNumber": editval[0]?.IdentificationNumber,
                'StateID': editval[0]?.StateID, 'CountryID': editval[0]?.CountryID, 'ModifiedByUserFK': loginPinID,
            })
        }
        else {
            setValue({
                ...value,
                'IdentificationTypeID': null, 'IdentificationNumber': '', 'IsCurrent': "", 'StateID': null, 'CountryID': null, 'ExpiryDate': "", 'ModifiedByUserFK': '',
            })
        }
    }, [editval])

    const reset = () => {
        setValue({
            ...value,
            'IdentificationTypeID': '', 'IdentificationNumber': '', 'IsCurrent': "", 'StateID': "", 'CountryID': "", 'ExpiryDate': "",
        });
        setIdentificationDate('')
        setErrors({
            'IdentificationTypeIDErrors': '', 'IdentificationNumberErrors': '',
        })
    }
    const check_Validation_Error = () => {
        if (RequiredFieldSpaceNotAllow(value.IdentificationNumber)) {
            setErrors(prevValues => { return { ...prevValues, ['IdentificationNumberErrors']: RequiredFieldSpaceNotAllow(value.IdentificationNumber) } })
        }
        if (RequiredFieldIncident(value.IdentificationTypeID)) {
            setErrors(prevValues => { return { ...prevValues, ['IdentificationTypeIDErrors']: RequiredFieldIncident(value.IdentificationTypeID) } })
        }
        if (selected) {
            if (RequiredFieldIncident(value.IdentificationTypeID)) {
                setErrors(prevValues => { return { ...prevValues, ['StateIDErrors']: RequiredFieldIncident(value.StateID) } });
            }
            if (RequiredFieldIncident(value.CountryID)) {
                setErrors(prevValues => ({ ...prevValues, ['CountryIDErrors']: RequiredFieldIncident(value.CountryID) }));
            }
        }

    }

    // Check All Field Format is True Then Submit 
    const { IdentificationNumberErrors, IdentificationTypeIDErrors, StateIDErrors } = errors

    useEffect(() => {
        if (selected) {
            if (IdentificationNumberErrors === 'true' && IdentificationTypeIDErrors === 'true' && StateIDErrors === 'true') {
                if (status) { update_Identification(); }
                else { Add_Type(); }
            }
        }
        else {
            if (IdentificationNumberErrors === 'true' && IdentificationTypeIDErrors === 'true') {
                if (status) { update_Identification(); }
                else { Add_Type(); }
            }
        }

    }, [IdentificationNumberErrors, IdentificationTypeIDErrors, StateIDErrors, status]);

    useEffect(() => {
        get_Identification(loginAgencyID);
        getCountryID();
        getStateList();
    }, [loginAgencyID])

    const get_Identification = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('IDTypes/GetDataDropDown_IDTypes', val).then((data) => {
            if (data) {
                setIdentification(Comman_changeArrayFormat(data, 'IDTypeID', 'Description'))
            } else { setIdentification([]); }
        })
    }

    const getStateList = async () => {
        fetchData("State_City_ZipCode/GetData_State").then((data) => {
            if (data) {
                setStateList(Comman_changeArrayFormat_With_Name(data, "StateID", "StateName", "StateID"));
            } else { setStateList([]); }
        });
    };

    const getCountryID = async () => {
        fetchData("State_City_ZipCode/GetData_Country").then((data) => {
            if (data) {
                setCountryList(Comman_changeArrayFormat_With_Name(data, "CountryID", "CountryName", "CountryID"));
            } else { setCountryList([]); }
        });
    };

    const selectHandleChange = (newValue, name) => {
        if (name === 'StateID') { setErrors(prevErrors => ({ ...prevErrors, StateIDErrors: '' })); }
        if (newValue) {
            setValue({ ...value, [name]: newValue.value });
        } else {
            setValue({ ...value, [name]: null });
        }
    }


    const handleChange = (e) => {
        if (e.target.name === "IsCurrent") {
            setValue({ ...value, [e.target.name]: e.target.checked, });
        } else {
            setValue({ ...value, [e.target.name]: e.target.value, });
        }
    };

    const startRef = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
        }
    };

    const Add_Type = () => {
        AddDeleteUpadate(openPage === 'mastername' ? 'MainMasterNameIdentificationNumber/Insert_MainMasterNameIdentificationNumber' : 'NameIdentificationNumber/Insert_NameIdentificationNumber', value)
            .then((res) => {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                Get_IdentificationData(nameID, masterNameID);
                get_Name_Count(nameID);
                setModal(false)
                reset(); setErrors({ ...errors, 'IdentificationTypeIDErrors': '', })
            })
    }

    const update_Identification = () => {
        AddDeleteUpadate('NameIdentificationNumber/Update_NameIdentificationNumber', value).then((res) => {
            const parsedData = JSON.parse(res.data);
            const message = parsedData.Table[0].Message;
            toastifySuccess(message);
            setModal(false);
            Get_IdentificationData(nameID, masterNameID);
            setErrors({ ...errors, 'IdentificationTypeIDErrors': '', })
        })
    }

    const ChangeDropDown = (newValue, name) => {
        if (name == 'IdentificationTypeID') {
            if (newValue.value == '2') {
                setSelected(true);
            }
            else { setSelected(false); }
        }
        if (newValue) {
            setValue({ ...value, [name]: newValue.value });
        } else {
            setValue({ ...value, [name]: null });
        }
    }

    const closeModal = () => {
        reset();
        setModal(false);
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    return (
        <>
            {
                modal ?

                    <dialog className="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="PinModal" tabIndex="-1" aria-hidden="true" data-backdrop="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="m-1 mt-3">
                                        <fieldset style={{ border: '1px solid gray' }}>
                                            <legend style={{ fontWeight: 'bold' }}>Identification</legend>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-6 mt-1 ">
                                                            <div className="dropdown__box">
                                                                <Select
                                                                    name='IdentificationTypeID'
                                                                    styles={colourStyles}
                                                                    value={identification?.filter((obj) => obj.value === value?.IdentificationTypeID)}
                                                                    isClearable
                                                                    options={identification}
                                                                    onChange={(e) => {
                                                                        ChangeDropDown(e, 'IdentificationTypeID');
                                                                    }}
                                                                    placeholder="Select..."
                                                                />
                                                                <label htmlFor=''>Identification Type</label>
                                                                {errors.IdentificationTypeIDErrors !== 'true' ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.IdentificationTypeIDErrors}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-6 mt-1">
                                                            <div className="text-field">
                                                                <input type="text" value={value.IdentificationNumber} maxLength={25} onChange={handleChange} className='requiredColor' name='IdentificationNumber' required />
                                                                <label>Identification Number</label>
                                                                {errors.IdentificationNumberErrors !== 'true' ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.IdentificationNumberErrors}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-12 col-lg-4 mt-1 ">
                                                            <div className="date__box">
                                                                <DatePicker
                                                                    ref={startRef}
                                                                    onKeyDown={onKeyDown}
                                                                    id='ExpiryDate'
                                                                    name='ExpiryDate'
                                                                    dateFormat="MM/dd/yyyy"
                                                                    onChange={(date) => { setIdentificationDate(date); setValue({ ...value, ['ExpiryDate']: date ? getShowingWithOutTime(date) : null }) }}
                                                                    showMonthDropdown
                                                                    isClearable={value?.ExpiryDate ? true : false}
                                                                    autoComplete="nope"
                                                                    showDisabledMonthNavigation
                                                                    dropdownMode="select"
                                                                    showYearDropdown
                                                                    placeholderText={value?.ExpiryDate ? value?.ExpiryDate : 'Select...'}
                                                                    selected={identificationDate}

                                                                />
                                                                <label htmlFor="">ID Expiry</label>
                                                            </div>

                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-4 mt-2">
                                                            <div className="dropdown__box">
                                                                <Select
                                                                    name="CountryID"
                                                                    value={countryList?.filter((obj) => obj.value === value?.CountryID)}
                                                                    isClearable
                                                                    options={countryList}
                                                                    onChange={(newValue) => {
                                                                        selectHandleChange(newValue, 'CountryID');
                                                                        ChangeDropDown(newValue, 'CountryID');
                                                                        setIsCountrySelected(!!newValue);
                                                                    }}
                                                                    placeholder="Select..."

                                                                    styles={selected ? colourStyles : customStylesWithOutColor}
                                                                />
                                                                <label htmlFor="">Country</label>
                                                                {errors.CountryIDErrors !== 'true' && errors.CountryIDErrors ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CountryIDErrors}</span>
                                                                ) : null}
                                                            </div>

                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-4 mt-2">
                                                            <div className="dropdown__box">
                                                                <Select
                                                                    name="StateID"
                                                                    value={stateList.find(obj => obj.value === value.StateID)}
                                                                    isClearable
                                                                    options={stateList}
                                                                    onChange={(newValue) => {
                                                                        selectHandleChange(newValue, 'StateID');
                                                                        ChangeDropDown(newValue, 'StateID');
                                                                    }}
                                                                    placeholder="Select..."
                                                                    styles={selected ? colourStyles : customStylesWithOutColor}
                                                                    isDisabled={!isCountrySelected}
                                                                />


                                                                <label htmlFor="">State</label>

                                                                {errors.StateIDErrors !== 'true' ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.StateIDErrors}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="btn-box text-right mt-3 mr-1 mb-2">
                                    {
                                        status ?
                                            <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1">Update</button>
                                            :
                                            <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1">Save</button>
                                    }
                                    <button type="button" data-dismiss="modal" onClick={closeModal} className="btn btn-sm btn-success mr-1" >Close</button>
                                </div>
                            </div>
                        </div>
                    </dialog>

                    :
                    <>
                    </>
            }
        </>
    )
}

export default IdentificationAddUp

