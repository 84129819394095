import React from 'react'
import Select from "react-select";
import DatePicker from "react-datepicker";


const IncidentModal = () => {

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    return (
    



            <dialog className="modal top fade  " style={{ background: "rgba(0,0,0, 0.5)" }} id="AddMasterModal" tabIndex="-1" data-backdrop="false" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog   modal-xl">
                    <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0px 0px 3px floralwhite' }}>
                        <div className="modal-header px-3 p-2" style={{ backgroundColor: 'aliceblue', boxShadow: '0px 0px 2px dimgray' }}>
                            <h5 className="modal-title">Incident Advance Search</h5>
                            <button type="button" className="close btn-modal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" style={{ color: 'red', fontSize: '20px', }}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" >
                            <div className="m-1 ">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                        <fieldset className='fieldset' style={{ marginTop: '-10px' }}>
                                            <legend>Incident Report</legend>
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-3 mb-1">
                                                    <div className="text-field">
                                                        <input type="text" />
                                                        <label className=''>Case From</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 mb-1">
                                                    <div className="text-field">
                                                        <input type="text" />
                                                        <label className=''>Case To</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 mb-1">
                                                    <div className="text-field">
                                                        <input type="text" />
                                                        <label className=''>Master Incident From</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 mb-1">
                                                    <div className="text-field">
                                                        <input type="text" />
                                                        <label className=''>Master Incident To</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            styles={colourStyles}
                                                            menuPlacement='top'
                                                            isClearable
                                                            placeholder="Select..."
                                                        />
                                                        <label htmlFor='' className='mt-0'>RMS CFS Range From</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            styles={colourStyles}
                                                            menuPlacement='top'
                                                            isClearable
                                                            placeholder="Select..."
                                                        />
                                                        <label htmlFor='' className='mt-0'>RMS CFS Range To</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 ">
                                                    <div className="dropdown__box">
                                                        <DatePicker
                                                            id='ActivityDateTime'
                                                            name='ActivityDateTime'
                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                            isClearable
                                                            placeholderText={'Select...'}
                                                            timeIntervals={1}
                                                            timeCaption="Time"
                                                            dropdownMode="select"
                                                        />
                                                        <label htmlFor="" className='pt-1'>Occurred From Date</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 ">
                                                    <div className="dropdown__box">
                                                        <DatePicker
                                                            id='ActivityDateTime'
                                                            name='ActivityDateTime'
                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                            isClearable
                                                            placeholderText={'Select...'}
                                                            timeIntervals={1}
                                                            timeCaption="Time"
                                                            dropdownMode="select"
                                                        />
                                                        <label htmlFor="" className='pt-1'>Occurred To Date</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 ">
                                                    <div className="dropdown__box">
                                                        <DatePicker
                                                            id='ActivityDateTime'
                                                            name='ActivityDateTime'
                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                            isClearable
                                                            placeholderText={'Select...'}
                                                            timeIntervals={1}
                                                            timeCaption="Time"
                                                            dropdownMode="select"
                                                        />
                                                        <label htmlFor="" className='pt-1'>Reported From Date</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 ">
                                                    <div className="dropdown__box">
                                                        <DatePicker
                                                            id='ActivityDateTime'
                                                            name='ActivityDateTime'
                                                            dateFormat="MM/dd/yyyy HH:mm"
                                                            isClearable
                                                            placeholderText={'Select...'}
                                                            timeIntervals={1}
                                                            timeCaption="Time"
                                                            dropdownMode="select"
                                                        />
                                                        <label htmlFor="" className='pt-1'>Reported To Date</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-2 mb-1 ">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            styles={colourStyles}
                                                            menuPlacement='top'
                                                            isClearable
                                                            placeholder="Select..."
                                                        />
                                                        <label htmlFor='' className='mt-0'>Pin Activity</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-2  ">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            styles={colourStyles}
                                                            menuPlacement='top'
                                                            isClearable
                                                            placeholder="Select..."
                                                        />
                                                        <label htmlFor='' className='mt-0'>Officer</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-1 ">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            styles={colourStyles}
                                                            menuPlacement='top'
                                                            isClearable
                                                            placeholder="Select..."
                                                        />
                                                        <label htmlFor='' className='mt-0'>Type Of Security</label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-3 pt-1">
                                                    <div className="dropdown__box">
                                                        <Select
                                                            styles={colourStyles}
                                                            menuPlacement='top'
                                                            isClearable
                                                            placeholder="Select..."
                                                        />
                                                        <label htmlFor='' className='mt-0'>Recieve Source</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <fieldset className='fieldset mt-2'>
                                    <legend>RMS Disposition/Clearance Information</legend>
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-2">
                                            <div className="dropdown__box">
                                                <Select
                                                    styles={colourStyles}
                                                    menuPlacement='top'
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                                <label htmlFor='' className='mt-0'>RMS Disposition</label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-1">
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='ActivityDateTime'
                                                    name='ActivityDateTime'
                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                    isClearable
                                                    placeholderText={'Select...'}
                                                    timeIntervals={1}
                                                    timeCaption="Time"
                                                    dropdownMode="select"
                                                />
                                                <label htmlFor="" className='pt-1'>Disposition From Date </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-1">
                                            <div className="dropdown__box">
                                                <DatePicker
                                                    id='ActivityDateTime'
                                                    name='ActivityDateTime'
                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                    isClearable
                                                    placeholderText={'Select...'}
                                                    timeIntervals={1}
                                                    timeCaption="Time"
                                                    dropdownMode="select"
                                                />
                                                <label htmlFor="" className='pt-1'>Disposition To Date </label>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-3 pt-1 mb-1 mt-2">
                                            <div className="dropdown__box">
                                                <Select
                                                    styles={colourStyles}
                                                    menuPlacement='top'
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                                <label htmlFor='' className='mt-0'>Exceptional Clearance</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="btn-box text-right  mr-1 mb-2">
                            <button type="button" className="btn btn-sm btn-success mr-1">Save</button>
                            <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" >Close</button>
                        </div>
                    </div>
                </div>
            </dialog>

      
    )
}

export default IncidentModal