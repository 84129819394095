import React, { useContext, useState, useEffect } from 'react';
import Select from "react-select";
import DatePicker from "react-datepicker";
import { AgencyContext } from '../../../../Context/Agency/Index';
import { getShowingDateText, getShowingMonthDateYear } from '../../../Common/Utility';
import { AddDeleteUpadate, ScreenPermision, fetchPostData } from '../../../hooks/Api';
import { toastifyError, toastifySuccess } from '../../../Common/AlertMsg';
import { Link, useNavigate } from 'react-router-dom';
import { RequiredFieldIncident, Space_Allow_with_Trim, checkDateIsAfter } from '../../Utility/Personnel/Validation';
import { Comman_changeArrayFormat, threeColArrayWithCode, } from '../../../Common/ChangeArrayFormat';
import VerifyLocation from './VerifyLocation/VerifyLocation';
import IdentifyFieldColor from '../../../Common/IdentifyFieldColor';
import Location from '../../../Location/Location';
// import { IncListDropDownArray } from '../../../Utility/ListDropDownArray/ListDropArray';
import Loader from '../../../Common/Loader';
import ChangesModal from '../../../Common/ChangesModal';
const IncidentHome = (props) => {

    const { setIncidentReportedDate } = props
    const { incidentStatus, setIncidentStatus, get_IncidentTab_Count, get_Incident_Count, setIncidentNumber, setIncidentRmsCfs, exceptionalClearID, GetDataExceptionalClearanceID, rmsDisposition, getRmsDispositionID, setChangesStatus, changesStatus, localStoreArray, get_LocalStorage, deleteStoreData, storeData } = useContext(AgencyContext);

    const [reportedDate, setReportedDate] = useState(new Date());
    const [bibrsDate, setBibrsDate] = useState(new Date());
    const [occurDateIsAfter, setOccurDateIsAfter] = useState(false);

    const [loder, setLoder] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [addVerifySingleData, setAddVerifySingleData] = useState([]);

    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [agencyName, setAgencyName] = useState('');
    const [incidentID, setIncidentID] = useState();
    const [locationStatus, setLocationStatus] = useState(false);

    //DropDown Value
    const [rmsCfsID, setRmsCfsID] = useState([]);
    const [nibrsCodeDrp, setNibrsCodeDrp] = useState([]);
    const [reciveSourceID, setReciveSourceID] = useState([]);
    const [cadCfsCodeID, setCadCfsCodeID] = useState([]);
    const [cadDispositionID, setCadDispositionID] = useState([]);
    const [editval, setEditval] = useState();
    const [clsDrpCode, setClsDrpCode] = useState();
    const [exClsDateCode, setExClsDateCode] = useState('N');
    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);

    const [value, setValue] = useState({
        'IncidentID': '',
        'ReportedDate': getShowingMonthDateYear(new Date()),
        'OccurredFrom': '',
        'OccurredTo': '',
        'BIBRSDate': getShowingMonthDateYear(new Date()),
        'FinishedDate': '',

        'DispositionDate': '',
        'NIBRSclearancedate': '',
        //text
        'IncidentNumber': '',
        'CrimeLocation': '',
        'DispositionComments': '',
        'AgencyName': '',
        //drpdown
        'RMSCFSCodeID': '',
        'NIBRSClearanceID': '',
        'RMSDispositionId': '',
        'ReceiveSourceID': '',
        'CADCFSCodeID': '',
        'CADDispositionId': '',
        'AgencyID': '',
        'IsVerify': true,
        'crimelocationid': '',
        'FBIID': '',
        'IsIncidentCode': true,

        'DispatchedDate': '',
        'ArrivedDate': '',

        // Incident Location Field/
        'DirectionPrefix': '',
        'Street': '',
        'DirectionSufix': '',
        'TypeSufix': '',
        'City': '',
        'State': '',
        'ZipCode': '',
        'PremiseNo': '',
        'ApartmentNo': '',
        'CommonPlace': '',
        'ApartmentType': '',
        'Street_Parse': '',
        'PremiseNo_Parse': '',
        'DirectionPrefix_Parse': '',
        'TypeSuffix_Parse': '',
        'DirectionSuffix_Parse': '',
        'ZipCodeID': '',
        'CityID': '',
        'IsUsLocation': '',
        'CountryID': '',
        'Country': '',
        'point_of_interest': '',
        'neighborhood': '',
        'subpremise': '',
        'premise': '',
        'CreatedByUserFK': '',
        'ModifiedByUserFK': '',
    })

    const [errors, setErrors] = useState({
        'OccuredError': '',
        'CrimeLocationError': '', 'RmsCfsCodeError': '', 'ExceptionalClearaceError': '', 'IsVerify': '',
        'FBIIDError': '', 'NIBRSclearancedateError': '',
    })

    useEffect(() => {
        if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
            get_LocalStorage();
        }
    }, []);

    // Onload Function
    useEffect(() => {
        if (localStoreArray) {
            if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
                if (effectiveScreenPermission?.length === 0) { getScreenPermision(localStoreArray?.AgencyID, localStoreArray?.PINID); }
                setIncidentStatus(localStoreArray.IncidentStatus === true || localStoreArray.IncidentStatus === "True" ? true : false);
                setLoginAgencyID(localStoreArray?.AgencyID);
                setAgencyName(localStoreArray?.Agency_Name);
                setValue({ ...value, 'AgencyID': localStoreArray?.AgencyID, 'CreatedByUserFK': localStoreArray?.PINID });
                if (localStoreArray.IncidentID && localStoreArray.IncidentStatus === true || localStoreArray.IncidentStatus === "True") {
                    setIncidentID(parseInt(localStoreArray?.IncidentID));
                } else {
                    setIncidentID(''); setLoder(true)
                }
            }
        }
    }, [localStoreArray])

    useEffect(() => {
        if (incidentID) { GetEditData(incidentID); get_Incident_Count(incidentID); get_IncidentTab_Count(incidentID); }
    }, [incidentID]);

    const GetEditData = (incidentID) => {
        const val = {
            IncidentID: incidentID
        }
        fetchPostData('Incident/GetSingleData_Incident', val)
            .then((res) => {
                if (res) {
                    setEditval(res); setLoder(true);
                }
                else { setEditval(); setLoder(true) }
            })
    }

    const getScreenPermision = (LoginAgencyID, PinID) => {
        ScreenPermision("I034", LoginAgencyID, PinID).then(res => {
            if (res) {
                setEffectiveScreenPermission(res)
            } else {
                setEffectiveScreenPermission([])
            }
        });
    }

    useEffect(() => {
        if (incidentID) {
            setValue({
                ...value,
                //drpdown
                'IncidentID': editval[0]?.IncidentID,
                'AgencyID': editval[0]?.AgencyID,
                'CADDispositionId': editval[0]?.CADDispositionId,
                'CADCFSCodeID': editval[0]?.CADCFSCodeID,
                'RMSCFSCodeID': editval[0]?.RMSCFSCodeID,
                'RMSDispositionId': editval[0]?.RMSDispositionId,
                'NIBRSClearanceID': editval[0]?.NIBRSClearanceID,
                'ReceiveSourceID': editval[0]?.ReceiveSourceID,
                'crimelocationid': editval[0]?.crimelocationid,
                'FBIID': editval[0]?.FBIID,
                //date fields
                'IncidentNumber': editval[0]?.IncidentNumber,
                'ReportedDate': editval[0]?.ReportedDate ? getShowingDateText(editval[0]?.ReportedDate) : '',
                'OccurredFrom': editval[0]?.OccurredFrom ? getShowingDateText(editval[0]?.OccurredFrom) : '',
                'OccurredTo': editval[0]?.OccurredTo ? getShowingDateText(editval[0]?.OccurredTo) : '',
                'BIBRSDate': editval[0]?.BIBRSDate ? getShowingDateText(editval[0]?.BIBRSDate) : '',
                'DispositionDate': editval[0]?.DispositionDate ? editval[0]?.DispositionDate : '',
                'NIBRSclearancedate': editval[0]?.NIBRSclearancedate ? getShowingDateText(editval[0]?.NIBRSclearancedate) : '',
                'IsVerify': editval[0]?.IsVerify,
                //text
                'CrimeLocation': editval[0]?.CrimeLocation,
                'DispositionComments': editval[0]?.DispositionComments,
                // location column
                'DirectionPrefix': editval[0]?.DirectionPrefix,
                'Street': editval[0]?.Street,
                'DirectionSufix': editval[0]?.DirectionSufix,
                'TypeSufix': editval[0]?.TypeSufix,
                'City': editval[0]?.City,
                'State': editval[0]?.State,
                'ZipCode': editval[0]?.ZipCode,
                'PremiseNo': editval[0]?.PremiseNo,
                'ApartmentNo': editval[0]?.ApartmentNo,
                'CommonPlace': editval[0]?.CommonPlace,
                'ApartmentType': editval[0]?.ApartmentType,
                'Street_Parse': editval[0]?.Street_Parse,
                'PremiseNo_Parse': editval[0]?.PremiseNo_Parse,
                'DirectionPrefix_Parse': editval[0]?.DirectionPrefix_Parse,
                'TypeSuffix_Parse': editval[0]?.TypeSuffix_Parse,
                'DirectionSuffix_Parse': editval[0]?.DirectionSuffix_Parse,
                'ZipCodeID': editval[0]?.ZipCodeID,
                'CityID': editval[0]?.CityID,
                'IsUsLocation': editval[0]?.IsUsLocation,
                'CountryID': editval[0]?.CountryID,
                'Country': editval[0]?.Country,
                'point_of_interest': editval[0]?.point_of_interest,
                'neighborhood': editval[0]?.neighborhood,
                'subpremise': editval[0]?.subpremise,
                'premise': editval[0]?.premise,
            });
            setReportedDate(editval[0]?.ReportedDate ? new Date(editval[0]?.ReportedDate) : '');
            setBibrsDate(editval[0]?.BIBRSDate ? new Date(editval[0]?.BIBRSDate) : '');
            setIncidentReportedDate(editval[0]?.ReportedDate ? new Date(editval[0]?.ReportedDate) : '');
            setClsDrpCode(Get_Exceptional_Code(editval, rmsDisposition));
            setExClsDateCode(Get_ExClsDate_Code(editval, exceptionalClearID));
            setIncidentRmsCfs(editval[0]?.RMSCFSCodeID);
            getRmsCfsCodeID(editval[0]?.FBIID);
            //--------------get_Non_Verify_Add-------------------
            if (!editval[0]?.IsVerify && editval[0]?.crimelocationid) {
                get_Add_Single_Data(editval[0]?.crimelocationid);
            }
        } else {
            setValue({
                ...value,
                'IncidentNumber': '',
                'ReportedDate': getShowingMonthDateYear(new Date()),
                'OccurredFrom': getShowingMonthDateYear(new Date()),
                'OccurredTo': getShowingMonthDateYear(new Date()),
                'BIBRSDate': getShowingMonthDateYear(new Date()),
                'FBIID': '',
                'DispositionDate': '',
                'NIBRSclearancedate': '',
                //text
                'CrimeLocation': '',
                'DispositionComments': '',
                //drpdown
                'RMSCFSCodeID': '',
                'NIBRSClearanceID': '',
                'RMSDispositionId': '',
                'ReceiveSourceID': '',
                'CADCFSCodeID': '',
                'CADDispositionId': '',
            });
            // setIncidentReportedDate(getShowingMonthDateYear(new Date()));
        }
    }, [editval])

    const check_Validation_Error = (e) => {
        if (clsDrpCode === 'EC') {
            if (exClsDateCode != 'N') {
                // if (!value.OccurredFrom || !value.OccurredTo) {
                //     setOccurDateIsAfter(true);
                // } else {
                //     if (checkDateIsAfter(value.OccurredFrom, value.OccurredTo)) {
                //         setOccurDateIsAfter(checkDateIsAfter(value.OccurredFrom, value.OccurredTo))
                //         return;
                //     }
                //     setOccurDateIsAfter(false);
                // }
                RequiredFieldIncident(value.ReportedDate) && setErrors(prevValues => { return { ...prevValues, ['OccuredError']: RequiredFieldIncident(value.ReportedDate) } })
                Space_Allow_with_Trim(value.CrimeLocation) && setErrors(prevValues => { return { ...prevValues, ['CrimeLocationError']: Space_Allow_with_Trim(value.CrimeLocation) } });
                RequiredFieldIncident(value.RMSCFSCodeID) && setErrors(prevValues => { return { ...prevValues, ['RmsCfsCodeError']: RequiredFieldIncident(value.RMSCFSCodeID) } });
                RequiredFieldIncident(value.NIBRSClearanceID) && setErrors(prevValues => { return { ...prevValues, ['ExceptionalClearaceError']: RequiredFieldIncident(value.NIBRSClearanceID) } })
                RequiredFieldIncident(value.FBIID) && setErrors(prevValues => { return { ...prevValues, ['FBIIDError']: RequiredFieldIncident(value.FBIID) } });
                RequiredFieldIncident(value.NIBRSclearancedate) && setErrors(prevValues => { return { ...prevValues, ['NIBRSclearancedateError']: RequiredFieldIncident(value.NIBRSclearancedate) } });
            } else {
                if (!value.OccurredFrom || !value.OccurredTo) {
                    setOccurDateIsAfter(true);
                } else {
                    if (checkDateIsAfter(value.OccurredFrom, value.OccurredTo)) {
                        setOccurDateIsAfter(checkDateIsAfter(value.OccurredFrom, value.OccurredTo))
                        return;
                    }
                    setOccurDateIsAfter(false);

                }
                RequiredFieldIncident(value.ReportedDate) && setErrors(prevValues => { return { ...prevValues, ['OccuredError']: RequiredFieldIncident(value.ReportedDate) } });
                Space_Allow_with_Trim(value.CrimeLocation) && setErrors(prevValues => { return { ...prevValues, ['CrimeLocationError']: Space_Allow_with_Trim(value.CrimeLocation) } });
                RequiredFieldIncident(value.RMSCFSCodeID) && setErrors(prevValues => { return { ...prevValues, ['RmsCfsCodeError']: RequiredFieldIncident(value.RMSCFSCodeID) } });
                RequiredFieldIncident(value.NIBRSClearanceID) && setErrors(prevValues => { return { ...prevValues, ['ExceptionalClearaceError']: RequiredFieldIncident(value.NIBRSClearanceID) } });
                RequiredFieldIncident(value.FBIID) && setErrors(prevValues => { return { ...prevValues, ['FBIIDError']: RequiredFieldIncident(value.FBIID) } })
            }
        } else {
            RequiredFieldIncident(value.ReportedDate) && setErrors(prevValues => { return { ...prevValues, ['OccuredError']: RequiredFieldIncident(value.ReportedDate) } })
            Space_Allow_with_Trim(value.CrimeLocation) && setErrors(prevValues => { return { ...prevValues, ['CrimeLocationError']: Space_Allow_with_Trim(value.CrimeLocation) } })
            RequiredFieldIncident(value.RMSCFSCodeID) && setErrors(prevValues => { return { ...prevValues, ['RmsCfsCodeError']: RequiredFieldIncident(value.RMSCFSCodeID) } })
            RequiredFieldIncident(value.FBIID) && setErrors(prevValues => { return { ...prevValues, ['FBIIDError']: RequiredFieldIncident(value.FBIID) } })

            if (!value.OccurredFrom || !value.OccurredTo) {
                setOccurDateIsAfter(true);
            } else {
                if (checkDateIsAfter(value.OccurredFrom, value.OccurredTo)) {
                    setOccurDateIsAfter(checkDateIsAfter(value.OccurredFrom, value.OccurredTo))
                    return;
                }
                setOccurDateIsAfter(false);
            }
        }
    }

    // Check All Field Format is True Then Submit 
    const { OccuredError, CrimeLocationError, RmsCfsCodeError, ExceptionalClearaceError, FBIIDError, NIBRSclearancedateError } = errors

    useEffect(() => {
        if (clsDrpCode === 'EC') {
            if (exClsDateCode != 'N') {
                if (OccuredError === 'true' && CrimeLocationError === 'true' && RmsCfsCodeError === 'true' && ExceptionalClearaceError === 'true' && NIBRSclearancedateError === 'true') {
                    if (incidentStatus) { UpdateIncident(); setErrors({ ...errors, ['OccuredError']: '', }); }
                    else { AddIncident(); setErrors({ ...errors, ['OccuredError']: '', }); }
                }
            } else if (OccuredError === 'true' && CrimeLocationError === 'true' && RmsCfsCodeError === 'true' && ExceptionalClearaceError === 'true') {

                if (incidentStatus) { UpdateIncident(); setErrors({ ...errors, ['OccuredError']: '', }); }
                else { AddIncident(); setErrors({ ...errors, ['OccuredError']: '', }); }

            }
        } else if (OccuredError === 'true' && CrimeLocationError === 'true' && RmsCfsCodeError === 'true' && FBIIDError === 'true') {

            if (incidentStatus) { UpdateIncident(); setErrors({ ...errors, ['OccuredError']: '', }); }
            else { AddIncident(); setErrors({ ...errors, ['OccuredError']: '', }); }

        }
    }, [OccuredError, CrimeLocationError, RmsCfsCodeError, ExceptionalClearaceError, FBIIDError, NIBRSclearancedateError])

    const Reset = () => {
        setValue({
            ...value,
            'ReportedDate': '',
            'OccurredFrom': '',
            'OccurredTo': '',
            'FinishedDate': '',
            'BIBRSDate': '',
            'DispositionDate': '',
            'FBIID': '',
            'NIBRSclearancedate': '',
            //text
            // 'CrimeLocation': '',
            'DispositionComments': '', 'IncidentNumber': '',
            //drpdown
            'RMSCFSCodeID': '',
            'NIBRSClearanceID': '',
            'RMSDispositionId': '',
            'ReceiveSourceID': '',
            'CADCFSCodeID': '',
        });
        setErrors({
            ...errors,
            'OccuredError': '',
            'CrimeLocationError': '', 'RmsCfsCodeError': '', 'FBIIDError': '', 'ExceptionalClearaceError': '', 'NIBRSclearancedateError': '',
        })
    }

    const HandleChange = (e) => {
        if (e.target.name === 'IsVerify') {
            if (e.target.checked && addVerifySingleData.length > 0) {
                setModalStatus(false);
                setLocationStatus(true); setAddVerifySingleData([]);
                setValue(pre => { return { ...pre, ['CrimeLocation']: '', [e.target.name]: e.target.checked, } });
            } else {
                setValue(pre => { return { ...pre, [e.target.name]: e.target.checked, } });
                setModalStatus(true);
                setLocationStatus(false);
            }
        } else {
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        if (exceptionalClearID.length === 0) { GetDataExceptionalClearanceID(loginAgencyID); }
        if (rmsDisposition.length === 0) { getRmsDispositionID(loginAgencyID); }
        GetDataReciveSourceID(loginAgencyID); getCADCFSCodeID(loginAgencyID); GetCadDispositionId(loginAgencyID); FBIidDrpVal();
    }, [loginAgencyID])

    const FBIidDrpVal = (LoginAgencyID) => {
        const val = {
            AgencyID: LoginAgencyID,
        }
        fetchPostData('FBICodes/GetDataDropDown_FBICodes', val).then((data) => {
            if (data) {
                setNibrsCodeDrp(threeColArrayWithCode(data, 'FBIID', 'Description', 'FederalSpecificFBICode'))
            } else {
                setNibrsCodeDrp([]);
            }
        })
    }

    const getRmsCfsCodeID = (FBIID) => {
        const val = {
            'FBIID': FBIID,
            'AgencyID': null,
        }
        fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
            if (data) {
                setRmsCfsID(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
            } else {
                setRmsCfsID([]);
            }
        })
    }

    const GetCadDispositionId = (LoginAgencyID) => {
        const val = {
            AgencyID: LoginAgencyID,
        }
        fetchPostData('Incident/GetData_CADDisposition', val).then((data) => {
            if (data) {
                setCadDispositionID(Comman_changeArrayFormat(data, 'CADDispositionId', 'CADDispositionCode'))
            } else {
                setCadDispositionID([]);
            }
        })
    }

    const getCADCFSCodeID = (LoginAgencyID) => {
        const val = {
            AgencyID: LoginAgencyID,
        }
        fetchPostData('Incident/GetData_CADCFSCODE', val).then((data) => {
            if (data) {
                setCadCfsCodeID(Comman_changeArrayFormat(data, 'CADCFSCodeID', 'CADCFSCode'))
            } else {
                setCadCfsCodeID([]);
            }
        })
    }

    const GetDataReciveSourceID = (LoginAgencyID) => {
        const val = {
            AgencyID: LoginAgencyID,
        }
        fetchPostData('Incident/GetData_ReceiveSource', val).then((data) => {
            if (data) {
                setReciveSourceID(changeArrayFormat(data, 'ReciveSrcID'))
            } else {
                setReciveSourceID([]);
            }
        })
    }

    useEffect(() => {
        if (clsDrpCode !== "EC") {
            setValue({
                ...value,
                ['NIBRSClearanceID']: ''
            })
        }
    }, [clsDrpCode]);

    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === "RMSCFSCodeID") {
                setChangesStatus(true)
                setIncidentRmsCfs(e.value);
                setValue({
                    ...value,
                    [name]: e.value
                })
            } else if (name === "RMSDispositionId") {
                setChangesStatus(true)
                setClsDrpCode(e.id);
                setValue({
                    ...value,
                    [name]: e.value,
                    ['DispositionDate']: getShowingMonthDateYear(new Date()),
                    ['NIBRSclearancedate']: '',
                });
                setErrors({ ...errors, ['OccuredError']: '', ['ExceptionalClearaceError']: '', });
            } else if (name === 'FBIID') {
                setChangesStatus(true)
                getRmsCfsCodeID(e.value)
                setValue({
                    ...value,
                    [name]: e.value,
                    ['RMSCFSCodeID']: '',
                });
                setErrors({ ...errors, ['OccuredError']: '', });
            } else if (name === 'NIBRSClearanceID') {
                setChangesStatus(true)
                setExClsDateCode(e.id);
                if (e.id != 'N') {
                    setValue({
                        ...value,
                        [name]: e.value,
                        ['NIBRSclearancedate']: getShowingMonthDateYear(new Date()),
                    });
                    setErrors({ ...errors, ['OccuredError']: '', ['NIBRSclearancedateError']: '', });
                } else {
                    setChangesStatus(true)
                    setValue({
                        ...value,
                        [name]: e.value,
                        ['NIBRSclearancedate']: '',
                    });
                    setErrors({ ...errors, ['OccuredError']: '', ['NIBRSclearancedateError']: '', });
                }
            } else {
                setChangesStatus(true)
                setValue({
                    ...value,
                    [name]: e.value,
                })
            }
        } else if (e === null) {
            setChangesStatus(true)
            if (name === "RMSDispositionId") {
                setValue({
                    ...value,
                    [name]: null,
                    ['NIBRSClearanceID']: null,
                    ['DispositionDate']: '',
                    ['NIBRSclearancedate']: '',
                });
                setClsDrpCode('');
                setExClsDateCode('');
                setErrors({ ...errors, ['OccuredError']: '', ['ExceptionalClearaceError']: '', });
            } else if (name === 'FBIID') {
                setChangesStatus(true)
                setRmsCfsID([]);
                setValue({
                    ...value,
                    ['FBIID']: "",
                    ['RMSCFSCodeID']: "",
                })
            } else if (name === 'NIBRSClearanceID') {
                setChangesStatus(true)
                setValue({
                    ...value,
                    [name]: null,
                    ['NIBRSclearancedate']: "",
                });
            } else {
                setChangesStatus(true)
                setValue({
                    ...value,
                    [name]: null
                });
            }
        } else {
            setChangesStatus(true);
            setValue({
                ...value,
                [name]: null
            });
        }
    }

    // const AddIncident = () => {
    //     if (occurDateIsAfter) {
    //         AddDeleteUpadate('Incident/IncidentInsert', value).then((res) => {
    //             if (res.success) {
    //                 toastifySuccess(res.Message);
    //                 if (res.IncidentID) {
    //                     get_IncidentTab_Count(res.IncidentID);
    //                     setIncidentID(res.IncidentID); setChangesStatus(false);
    //                     storeData({ 'IncidentID': res?.IncidentID, 'IncidentNumber': res?.IncidentNumber, 'IncidentStatus': true, 'ReportedDate': res?.ReportedDate }); setIncidentNumber(res?.IncidentNumber);
    //                 }
    //                 setIncidentStatus(true)
    //                 setErrors({ ...errors, ['OccuredError']: '', });
    //             } else {
    //                 toastifyError("Error");
    //                 setErrors({ ...errors, ['OccuredError']: '', });
    //             }
    //         })
    //     } else {
    //         toastifyError("OccuredTo Date Must be Greater Then OccuredFrom Date")
    //     }
    // }
    const AddIncident = () => {

        AddDeleteUpadate('Incident/IncidentInsert', value).then((res) => {
            if (res.success) {
                toastifySuccess(res.Message);
                if (res.IncidentID) {
                    get_IncidentTab_Count(res.IncidentID);
                    setIncidentID(res.IncidentID); setChangesStatus(false);
                    storeData({ 'IncidentID': res?.IncidentID, 'IncidentNumber': res?.IncidentNumber, 'IncidentStatus': true, 'ReportedDate': res?.ReportedDate }); setIncidentNumber(res?.IncidentNumber);
                }
                setIncidentStatus(true)
                setErrors({ ...errors, ['OccuredError']: '', });
            } else {
                toastifyError("Error");
                setErrors({ ...errors, ['OccuredError']: '', });
            }
        })

    }
    // const UpdateIncident = () => {
    //     if (occurDateIsAfter) {
    //         AddDeleteUpadate('Incident/IncidentUpdate', value).then((res) => {
    //             toastifySuccess(res.Message);
    //             setChangesStatus(false);
    //             GetEditData(incidentID)
    //             setErrors({
    //                 ...errors, ['OccuredError']: '', ['ExceptionalClearaceError']: '',
    //             });
    //         })
    //     } else {
    //         toastifyError("OccuredTo Date Must be Greater Then OccuredFrom Date")
    //     }
    // }
    const UpdateIncident = () => {

        AddDeleteUpadate('Incident/IncidentUpdate', value).then((res) => {
            toastifySuccess(res.Message);
            setChangesStatus(false);
            GetEditData(incidentID)
            setErrors({
                ...errors, ['OccuredError']: '', ['ExceptionalClearaceError']: '',
            });
        })

    }
    let navigate = useNavigate();

    const OnClose = () => {
        if (!changesStatus) {
            setChangesStatus(false)
            Reset();
            navigate('/incident');
            setIncidentID('');
            deleteStoreData({ 'IncidentID': '', 'IncidentNumber': '', 'IncidentStatus': '', 'ReportedDate': '' })
        }
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();

    const onKeyDown = (e) => {
        if (e.target.id === 'ReportedDate') {
            e.preventDefault();
        } else if (e.keyCode === 9 || e.which === 9) {

            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);

        }
    };

    // Custom Style
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const colourStylesEC = {
        control: (styles) => ({
            ...styles, backgroundColor: exClsDateCode === 'N' || exClsDateCode === 'A' || exClsDateCode === 'B' || exClsDateCode === 'C' || exClsDateCode === 'D' || exClsDateCode === 'E' ? "#fce9bf" : "rgb(255 202 194)",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const get_Add_Single_Data = (crimelocationid) => {
        const val = {
            'LocationID': crimelocationid,
        }
        fetchPostData('MasterLocation/GetSingleData_MasterLocation', val).then((res) => {
            if (res.length > 0) {
                setAddVerifySingleData(res)
            } else {
                setAddVerifySingleData([])
            }
        })
    }

    return (
        loder ?
            <>
                <div className="col-12">
                    <div className="row bb">
                        <div className="col-6">
                            <div className="row mb-1">
                                <div className="col-4 col-md-4 col-lg-4 mt-2">
                                    <label htmlFor="" className='new-label'>Incident No:</label>
                                </div>
                                <div className="col-7 pl-1 col-md-7 col-lg-7  mt-1 ">
                                    <input type="text" className='form-control  py-1 new-input' readOnly value={value.IncidentNumber ? value.IncidentNumber : 'Auto Generated'} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-4 col-md-4 col-lg-5 mt-2">
                                    <label htmlFor="" className='new-label'>Master Incident:</label>
                                </div>
                                <div className="col-7 col-md-7 col-lg-7  mt-1 ">
                                    <input type="text" className='form-control  py-1 new-input' readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row bb">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-4 col-md-4 col-lg-4 mt-1">
                                        <label htmlFor="" className='new-label'>Reported Date/Time</label>
                                    </div>
                                    <div className="col-7 col-md-7 col-lg-7  ">
                                        <DatePicker
                                            ref={startRef}
                                            onKeyDown={onKeyDown}
                                            id="ReportedDate"
                                            name='ReportedDate'
                                            onChange={(date) => {
                                                setReportedDate(date); setBibrsDate(date);
                                                setValue({
                                                    ...value,
                                                    ['ReportedDate']: date ? getShowingMonthDateYear(date) : null,
                                                })
                                            }}
                                            className='requiredColor'
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            selected={reportedDate}
                                            autoComplete='off'
                                            timeInputLabel
                                            isClearable
                                            placeholderText='Select...'
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            maxDate={new Date()}
                                        />
                                        {errors.OccuredError !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.OccuredError}</span>
                                        ) : null}
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                                        <label htmlFor="" className='new-label'>Occurred  From Date/Time</label>
                                    </div>
                                    <div className="col-7  col-md-7 col-lg-7 mt-2">
                                        <DatePicker
                                            ref={startRef1}
                                            name='OccurredFrom'
                                            id='OccurredFrom'
                                            onChange={(date) => {
                                                setValue({ ...value, ['OccurredFrom']: date ? getShowingMonthDateYear(date) : null, })
                                            }}
                                            onKeyDown={onKeyDown}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            timeInputLabel
                                            isClearable
                                            placeholderText='Select...'
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            selected={value?.OccurredFrom && new Date(value?.OccurredFrom)}
                                            showTimeSelect
                                            showDisabledMonthNavigation
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            maxDate={new Date()}
                                            autoComplete='Off'
                                        />

                                    </div>

                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">

                                    <div className="col-5 col-md-5 col-lg-5 mt-2">
                                        <label htmlFor="" className='new-label'>NIBRS Date/Time</label>
                                    </div>
                                    <div className="col-7 col-md-7 col-lg-7">
                                        <DatePicker
                                            name='BIBRSDate'
                                            id='BIBRSDate'
                                            className={`${incidentID ? 'readonlyColor' : "requiredColor"}`}
                                            onChange={(date) => { setBibrsDate(date); setValue({ ...value, ['BIBRSDate']: date ? getShowingMonthDateYear(date) : null }) }}
                                            ref={startRef3}
                                            onKeyDown={onKeyDown}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            selected={bibrsDate}
                                            placeholderText={'Select..'}
                                            timeInputLabel
                                            isClearable={false}
                                            disabled={incidentID ? true : false}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            showTimeSelect
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            minDate={reportedDate}
                                            maxDate={new Date()}
                                        />
                                    </div>

                                    <div className="col-5 col-lg-5 col-md-5 mt-2 pt-2">
                                        <label htmlFor="" className='new-label'>Occurred  to Date/Time</label>
                                    </div>
                                    <div className="col-7 col-md-7 col-lg-7 mt-2">
                                        <DatePicker
                                            ref={startRef2}
                                            onKeyDown={onKeyDown}
                                            id='OccurredTo'
                                            name='OccurredTo'
                                            onChange={(date) => {
                                                setOccurDateIsAfter(date); setValue({ ...value, ['OccurredTo']: date ? getShowingMonthDateYear(date) : null })
                                            }}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            timeInputLabel
                                            isClearable
                                            selected={value?.OccurredTo && new Date(value?.OccurredTo)}
                                            placeholderText={value?.OccurredTo ? value.OccurredTo : 'Select...'}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            minDate={new Date(value?.OccurredFrom)}
                                            maxDate={new Date()}
                                            showDisabledMonthNavigation
                                            showTimeSelect
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            autoComplete='Off'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 mt-2">
                                <label htmlFor="" className='new-label'>Crime Location</label>
                            </div>
                            <div className="col-9  col-md-9 col-lg-8 text-field mt-2">
                                <Location {...{ value, setValue, locationStatus, setLocationStatus }} col='CrimeLocation' locationID='crimelocationid' check={true} verify={value.IsVerify} style={{ resize: 'both' }} />
                                {errors.CrimeLocationError !== 'true' ? (
                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.CrimeLocationError}</span>
                                ) : null}
                            </div>
                            <div className="col-2 col-md-2 col-lg-2 mt-2 mb-2 d-flex">
                                <div className="form-check custom-control custom-checkbox">
                                    <input className="custom-control-input" data-toggle="modal" data-target="#VerifyModal" type="checkbox" name='IsVerify'
                                        checked={(value?.IsVerify || value?.crimelocationid)}
                                        value={value?.IsVerify} onChange={HandleChange} id="flexCheckDefault" style={{ cursor: 'pointer' }} />
                                    <label className="custom-control-label " htmlFor="flexCheckDefault" style={{ fontSize: '14px' }}>
                                        Verify
                                    </label>
                                </div>
                                {
                                    !value?.IsVerify && addVerifySingleData.length > 0 ?

                                        <i className="fa fa-edit pl-3 mt-1 pt-1 " onKeyDown={''} onClick={() => { if (value.crimelocationid) { get_Add_Single_Data(value.crimelocationid); setModalStatus(true); } }} data-toggle="modal" data-target="#VerifyModal" style={{ cursor: 'pointer', backgroundColor: '' }} > Edit </i>

                                        :
                                        <>
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="col-12 mt-1 ">
                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-4 col-md-4 col-lg-4 mt-2">
                                        <Link to={'/ListManagement?page=FBI%20Code&call=/Inc-Home'} className='new-link'>
                                            FBI Code
                                        </Link>
                                    </div>
                                    <div className="col-7 col-md-7 col-lg-7 mt-1">
                                        <Select
                                            name='FBIID'
                                            styles={colourStyles}
                                            value={nibrsCodeDrp?.filter((obj) => obj.value === value?.FBIID)}
                                            isClearable
                                            options={nibrsCodeDrp}
                                            onChange={(e) => ChangeDropDown(e, 'FBIID')}
                                            placeholder="Select..."
                                        />
                                        {errors.FBIIDError !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.FBIIDError}</span>
                                        ) : null}
                                    </div>
                                    <div className="col-4 col-md-4 col--lg-4 mt-3 pt-1">
                                        <Link to={'/ListManagement?page=Incident%20Disposition&call=/Inc-Home'} className='new-link'>
                                            RMS Disposition
                                        </Link>
                                    </div>
                                    <div className="col-7  col-md-7 col-lg-7 mt-2 pt-1">
                                        <Select
                                            name='RMSDispositionId'
                                            value={rmsDisposition?.filter((obj) => obj.value === value?.RMSDispositionId)}
                                            isClearable
                                            options={rmsDisposition}
                                            onChange={(e) => ChangeDropDown(e, 'RMSDispositionId')}
                                            placeholder="Select..."
                                            styles={customStylesWithOutColor}
                                        />

                                    </div>
                                    <div className="col-4 col-md-4 col-lg-4 mt-3 pt-1">
                                        <Link to={'/ListManagement?page=Cleared%20Exceptionally&call=/Inc-Home'} className='new-link'>
                                            Exceptional Clearance {exClsDateCode === 'N' || exClsDateCode === 'A' || exClsDateCode === 'B' || exClsDateCode === 'C' || exClsDateCode === 'D' || exClsDateCode === 'E' ? <></> : exClsDateCode && <span className='hovertext' style={{ marginLeft: '15px' }} data-hover="Valid codes for clearance code are A,B,C,D,E,N" ><i className='fa fa-exclamation-circle'></i></span>}
                                        </Link>
                                    </div>
                                    <div className="col-7 col-md-7 col-lg-7 mt-2 pt-1">
                                        <Select
                                            name='NIBRSClearanceID'
                                            styles={clsDrpCode === 'EC' ? colourStylesEC : customStylesWithOutColor}
                                            value={exceptionalClearID?.filter((obj) => obj.value === value?.NIBRSClearanceID)}
                                            isClearable
                                            readOnly={clsDrpCode === 'EC' ? false : true}
                                            options={exceptionalClearID}
                                            onChange={(e) => ChangeDropDown(e, 'NIBRSClearanceID')}
                                            placeholder="Select..."
                                            isDisabled={clsDrpCode === "EC" ? false : true}
                                        />
                                        {errors.ExceptionalClearaceError !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ExceptionalClearaceError}</span>
                                        ) : null}
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-4 mt-3 pt-1">
                                        <Link to={'/ListManagement?page=Charge%20Code&call=/Inc-Home'} className='new-link'>
                                            CAD CFS Code
                                        </Link>
                                    </div>
                                    <div className="col-7 col-md-7 col-lg-7 mt-2 pt-1">
                                        <Select
                                            name='CADCFSCodeID'
                                            value={cadCfsCodeID?.filter((obj) => obj.value === value?.CADCFSCodeID)}
                                            isClearable
                                            menuPlacement='top'
                                            options={cadCfsCodeID}
                                            onChange={(e) => ChangeDropDown(e, 'CADCFSCodeID')}
                                            placeholder="Select..."
                                            styles={customStylesWithOutColor}
                                        />
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-4 mt-3 pt-1">
                                        <Link to={'/ListManagement?page=Incident%20Receive%20Source&call=/Inc-Home'} className='new-link'>
                                            How Reported
                                        </Link>
                                    </div>
                                    <div className="col-7  col-md-7 col-lg-7 mt-2 pt-1">
                                        <Select
                                            name='ReceiveSourceID'
                                            value={reciveSourceID?.filter((obj) => obj.value === value?.ReceiveSourceID)}
                                            isClearable
                                            options={reciveSourceID}
                                            menuPlacement='top'
                                            onChange={(e) => ChangeDropDown(e, 'ReceiveSourceID')}
                                            placeholder="Select..."
                                            styles={customStylesWithOutColor}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-5 col-md-5 col-lg-5 mt-2">
                                        <label htmlFor="" className='new-label'>RMS CFS Code/Description</label>
                                    </div>
                                    <div className="col-7  col-md-7 col-lg-7 mt-1">
                                        <Select
                                            name='RMSCFSCodeID'
                                            styles={colourStyles}
                                            value={rmsCfsID?.filter((obj) => obj.value === value?.RMSCFSCodeID)}
                                            isClearable
                                            options={rmsCfsID}
                                            onChange={(e) => ChangeDropDown(e, 'RMSCFSCodeID')}
                                            placeholder="Select..."
                                        />
                                        {errors.RmsCfsCodeError !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RmsCfsCodeError}</span>
                                        ) : null}
                                    </div>
                                    <div className="col-5 col-md-5 col-lg-5 mt-2 pt-2">
                                        <label htmlFor="" className='new-label'>Disposition Date/Time</label>
                                    </div>
                                    <div className="col-7  col-md-7 col-lg-7 mt-2">
                                        <DatePicker
                                            name='DispositionDate'
                                            id='DispositionDate'
                                            onChange={(date) => { setChangesStatus(true); setValue({ ...value, ['DispositionDate']: date ? date : null }) }}
                                            selected={value?.DispositionDate && new Date(value?.DispositionDate)}
                                            ref={startRef}
                                            onKeyDown={onKeyDown}
                                            placeholderText={value?.DispositionDate ? value?.DispositionDate : 'Select...'}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            timeInputLabel
                                            isClearable={value?.DispositionDate ? true : false}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            autoComplete='Off'
                                            dropdownMode="select"
                                            showTimeSelect
                                            timeIntervals={1}
                                            timeCaption="Time"
                                            disabled={value?.RMSDispositionId ? false : true}
                                            minDate={reportedDate}
                                        />
                                    </div>
                                    <div className="col-5 col-md-5 col-lg-5 mt-2 pt-2">
                                        <label htmlFor="" className='new-label '>Exceptional Clearance Date/Time</label>
                                    </div>
                                    <div className='col-7  col-md-7 col-lg-7 mt-2'>
                                        <DatePicker
                                            name='NIBRSclearancedate'
                                            id='NIBRSclearancedate'
                                            className={value?.NIBRSClearanceID && exClsDateCode !== 'N' ? 'requiredColor' : ''}
                                            onChange={(date) => { setValue({ ...value, ['NIBRSclearancedate']: date ? getShowingDateText(date) : null }) }}
                                            ref={startRef4}
                                            onKeyDown={onKeyDown}
                                            selected={value?.NIBRSclearancedate && new Date(value?.NIBRSclearancedate)}
                                            placeholderText={value?.NIBRSclearancedate ? value?.NIBRSclearancedate : 'Select...'}
                                            dateFormat="MM/dd/yyyy HH:mm"
                                            timeInputLabel
                                            isClearable={value?.NIBRSclearancedate ? true : false}
                                            autoComplete='Off'
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            showTimeSelect
                                            timeIntervals={1}
                                            disabled={value?.NIBRSClearanceID && exClsDateCode !== 'N' ? false : true}
                                            minDate={reportedDate}
                                            maxDate={new Date()}
                                        />
                                        {errors.NIBRSclearancedateError !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NIBRSclearancedateError}</span>
                                        ) : null}
                                    </div>
                                    <div className="col-5 col-md-5 col-lg-5 mt-3 pt-1">
                                        <Link to={'/ListManagement?page=Incident%20Disposition&call=/Inc-Home'} className='new-link'>
                                            CAD Disposition
                                        </Link>
                                    </div>
                                    <div className="col-7  col-md-7 col-lg-7 mt-3">
                                        <Select
                                            name='CADDispositionId'
                                            value={cadDispositionID?.filter((obj) => obj.value === value?.CADDispositionId)}
                                            isClearable
                                            menuPlacement='top'
                                            options={cadDispositionID}
                                            onChange={(e) => ChangeDropDown(e, 'CADDispositionId')}
                                            placeholder="Select..."
                                            styles={customStylesWithOutColor}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className="col-12 text-right field-button" style={{ position: 'absolute', bottom: '30px' }}>
                    {
                        incidentStatus ?
                            effectiveScreenPermission ?
                                effectiveScreenPermission[0]?.Changeok ?
                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Update</button>
                                    : <></>
                                : <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Update</button>
                            :
                            effectiveScreenPermission ?
                                effectiveScreenPermission[0]?.AddOK ?
                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                                    : <></>
                                : <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                    }
                    <button type="button" className="btn btn-sm btn-success mr-4" onClick={() => { OnClose() }} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>Close</button>
                </div>
                <IdentifyFieldColor />
                <VerifyLocation {...{ loginAgencyID, modalStatus, setModalStatus, value, setValue, addVerifySingleData, get_Add_Single_Data }} />
                <ChangesModal func={check_Validation_Error} />
            </>
            :
            <Loader />
    )
}

export default IncidentHome

const Get_Exceptional_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) =>
        (sponsor.RMSDispositionId)
    )
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    })
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}

const Get_ExClsDate_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) =>
        (sponsor.NIBRSClearanceID)
    )
    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    })
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}

export const changeArrayFormat = (data, type) => {
    if (type === 'zip') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.zipId, label: sponsor.Zipcode })
        )
        return result
    }
    if (type === 'state') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.StateID, label: sponsor.StateName })
        )
        return result
    }
    if (type === 'city') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.CityID, label: sponsor.CityName })
        );
        return result
    }

    if (type === 'RmsCfsID') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.RMSCFSCodeID, label: sponsor.RMSCFSCode })
        )
        return result
    }
    if (type === 'RmsDisposition') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.IncidentDispositionsID, label: sponsor.RMSDispositionCode })
        )
        return result
    }
    if (type === 'ExceptionClearID') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.ClearanceID, label: sponsor.ClearanceCode })
        )
        return result
    }
    if (type === 'ReciveSrcID') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.ReceiveSourceID, label: sponsor.ReceiveSourceCode })
        )
        return result
    }
}

export const changeArrayFormat_WithFilter = (data, type, DropDownValue) => {
    if (DropDownValue) {
        if (type === 'CADCFSCodeID') {
            const result = data?.map((sponsor) =>
                (sponsor.CADCFSCodeID)
            )
            const result2 = DropDownValue?.map((sponsor) => {
                if (sponsor.value === result[0]) {
                    return { value: result[0], label: sponsor.label }
                }
            }
            )
            const val = result2.filter(function (element) {
                return element !== undefined;
            });
            return val[0]
        }
    }
}
