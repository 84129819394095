import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api'
import DataTable from 'react-data-table-component'
import { toastifySuccess } from '../../../../Common/AlertMsg'
import DeletePopUpModal from '../../../../Common/DeleteModal'
import { ProRecoveredListDropDownArray } from '../../../../Utility/ListDropDownArray/ListDropArray'
import FindListDropDown from '../../../../Common/FindListDropDown'
import { AgencyContext } from '../../../../../Context/Agency/Index'
import RecoveredPropertyAddUpp from './RecoveredPropertyAddUpp'

const RecoveredProperty = () => {

    const { get_Property_Count, localStoreArray, get_LocalStorage, setIncidentStatus } = useContext(AgencyContext);

    //screen permission 
    const [modal, setModal] = useState(false)
    const [propertyData, setPropertyData] = useState([]);
    const [recoveredPropertyID, setRecoveredPropertyID] = useState();
    const [status, setStatus] = useState(false);

    const [propertyID, setPropertyID] = useState();
    const [mainIncidentID, setMainIncidentID] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');

    useEffect(() => {
        if (!localStoreArray?.AgencyID || !localStoreArray?.PINID || !localStoreArray.IncidentID || !localStoreArray.IncidentStatus) {
            get_LocalStorage();
        }
    }, []);

    // Onload Function
    useEffect(() => {
        if (localStoreArray) {
            if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
                setLoginAgencyID(localStoreArray?.AgencyID);
                setLoginPinID(parseInt(localStoreArray?.PINID));
                setMainIncidentID(parseInt(localStoreArray?.IncidentID));
                if (localStoreArray?.PropertyID) {
                    setPropertyID(localStoreArray?.PropertyID)
                    get_property_Data(localStoreArray?.PropertyID);
                }
            }
            else {
                setRecoveredPropertyID('');
            }
            setIncidentStatus(true);
        }
    }, [localStoreArray])

    const get_property_Data = (propertyID) => {
        const val = {
            'PropertyID': propertyID,
        }
        fetchPostData('RecoveredProperty/GetData_RecoveredProperty', val).then((res) => {
            if (res) {
                setPropertyData(res)
            } else {
                setPropertyData();
            }
        })
    }
    const columns = [
        {
            name: 'Recovered ID Number',
            selector: (row) => row.RecoveredIDNumber,
            sortable: true
        },
        {
            name: 'Comments',
            selector: (row) => row.Comments,
            sortable: true
        },
        {
            name: 'RecoveredValue',
            selector: (row) => row.RecoveredValue,
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 30 }}>Action</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 4, right: 30 }}>

                    <Link to={`#`} onClick={() => { setRecoveredPropertyID(row.RecoveredPropertyID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                        <i className="fa fa-trash"></i>
                    </Link>

                </div>

        }
    ]
    const setStatusFalse = (e, row) => {
        setStatus(false)
        setModal(true)
        setRecoveredPropertyID(row.RecoveredPropertyID);
    }

    const Delete_RecoveredProperty = () => {
        const val = {
            'RecoveredPropertyID': recoveredPropertyID,
            'DeletedByUserFK': loginPinID,
        }
        AddDeleteUpadate('RecoveredProperty/Delete_RecoveredProperty', val).then((res) => {
            if (res) {
                toastifySuccess(res.Message);
                get_property_Data(propertyID);
                get_Property_Count(propertyID); setRecoveredPropertyID('')
            } else console.log("Somthing Wrong");
        })
    }

    return (
        <>
            <div className="col-12 col-md-12 pt-1 p-0" >
                <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center ">
                    <p className="p-0 m-0">Recovered Property</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to="" onClick={setStatusFalse} className="btn btn-sm bg-green text-white px-2 py-0" data-toggle="modal" data-target="#RecoveredPropertyModal" style={{ marginTop: '-6px' }}>
                            <i className="fa fa-plus"></i>
                        </Link>
                        <FindListDropDown
                            array={ProRecoveredListDropDownArray}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <DataTable
                        dense
                        columns={columns}
                        data={propertyData}
                        pagination
                        highlightOnHover
                        noDataComponent={"There are no data to display"}
                    />
                </div>
            </div>
            <DeletePopUpModal func={Delete_RecoveredProperty} />
            <RecoveredPropertyAddUpp {...{ propertyID, loginPinID, loginAgencyID, mainIncidentID, recoveredPropertyID, setRecoveredPropertyID, modal, setModal, get_property_Data, propertyData, status, setStatus, }} />
        </>
    )
}

export default RecoveredProperty