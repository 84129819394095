import React from 'react'
import { Link } from "react-router-dom";

const ArrestMainTab = () => {

    const active = window.location.pathname;

    return (
        <div className="col-12 inc__tabs" style={{ marginTop: '-10px' }}>
            <ul className="nav nav-tabs">
                <li className="nav-item ">
                    {
                        active === "/arresttab" ?
                            <Link className="nav-link  active " data-toggle="pill" to="/arresttab">Arrest</Link>
                            :
                            <Link className="nav-link" data-toggle="pill" to="/arresttab">Arrest</Link>
                    }
                </li>
            </ul>
        </div>
    )
}

export default ArrestMainTab