import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { DecryptedList, EncryptedList, encryptAndEncodeToBase64, getShowingWithOutTime, getShowingYearMonthDate } from '../../../../Common/Utility';
import { Agency_Field_Permistion_Filter } from '../../../../Filter/AgencyFilter';
import { AddDeleteUpadate, fetchData, fetchPostData, fieldPermision } from '../../../../hooks/Api';
import { Email_Field, PasswordField, PinValidator, ReEnterPasswordVal, RequiredField } from '../../Validation/PersonnelValidation';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import defualtImage from '../../../../../img/uploadImage.png'
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { useNavigate } from 'react-router-dom';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import ChangesModal from '../../../../Common/ChangesModal';

const Home = (props) => {

    const { editValueList, pId, aId, pinId, setDobHireDate } = props

    // Hooks Initialization
    const navigate = useNavigate()
    const { personnelStatus, setPersonnelStatus, get_CountList, get_Personnel_Lists, PersonnelEffectiveScreenPermission, inActiveStatus, setInActiveStatus, getInActive_Personnel, setChangesStatus, } = useContext(AgencyContext);

    const [rankList, setRankList] = useState([]);
    const [associatedShift, setAssociatedShift] = useState([]);
    const [photoType, setPhotoType] = useState([]);
    const [division, setDivision] = useState([]);
    const [pasStatus, setPasStatus] = useState(false);
    const [rePasStatus, setRePasStatus] = useState(false);
    const [personnelEditList, setPersonnelEditList] = useState([]);
    const [passwordSettingVal, setPasswordSettingVal] = useState([]);
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [sexList, setSexList] = useState([]);
    const [employeeTypeList, setEmployeeTypeList] = useState([]);
    const [personnelImage, setPersonnelImage] = useState([]);
    const [imageId, setImageId] = useState('');
    const [isMultiPin, setIsMultiPin] = useState();
    const [validDobDate, setValidDobDate] = useState(null);

    // Fitler 
    const [value, setValue] = useState({
        'PIN': '', 'AgencyID': aId, 'AllowMultipleLogins': true, 'FirstName': '', 'MiddleName': '', 'LastName': '', 'UserName': '', 'Password': '', 'DivisionId': '', 'RankID': '', 'EmployeeTypeID': '', 'Email': '', 'ShiftID': '', 'ReEnterPassword': '', 'ModifiedByUserFK': '', 'PINID': '', 'IsSuperadmin': '', 'IsAllowLogin': '', 'ShiftName': '', "RankName": '', 'DivisionName': '', 'DateOfBirth': '', 'NCICLoginId': '', 'NCICORI': '', 'ScheduleId': '', 'MaxLockLevel': '', 'MaxRestrictLevel': '', 'IsJuvenileCleared': '', 'IsActive': true, 'SexID': '', 'IsSupervisor': '', 'photoId': '', 'GenderName': '', 'EmployeeName': '',
        'CreatedByUserFK': pinId,
    })

    const [fieldPermissionAgency, setFieldPermissionAgency] = useState({
        // Persionnel Fields
        'PIN': '', 'FirstName': '', 'MiddleName': '', 'LastName': '', 'UserName': '', 'Password': '', 'DivisionId': '', 'RankID': '', 'Email': '', 'ShiftID': '', 'ReEnterPassword': '', 'IsSuperadmin': '', 'IsAllowLogin': ''
    })

    // Onload Function
    useEffect(() => {
        get_Rank(pinId); get_AssociatedShift(aId); get_Division(aId);
        get_Password_Setting(aId);
        getSexList(aId);
        get_Photo_Type();
        get_EmployeeType();
        dateChange();
    }, [aId]);

    useEffect(() => {
        if (pId != '0000') {
            setPersonnelStatus(true);
            if (personnelStatus) {
                get_Single_PersonnelList(pId); get_Personnel_MultiImage(pId, aId)
                setValue({ ...value, ['RankName']: '', ['DivisionName']: '', ['ShiftName']: '', ['EmployeeName']: '', ['GenderName']: '' }); setErrors({
                    ...errors,
                    'PINError': '', 'FirstNameError': '', 'LastNameError': '', 'PasswordError': '', 'UserNameError': '', 'ReEnterPasswordError': ''
                })
            }
        } else if (!personnelStatus) {
            // make condition <--Dkashyap--<
            rest_Value()
        }
    }, [aId, pId, personnelStatus])

    useEffect(() => {
        if (aId && pinId) { get_Field_Permision_Division(aId, pinId) }
    }, [aId, pinId])

    // Get Single Persoonel Data
    const get_Single_PersonnelList = (pId) => {
        fetchPostData('Personnel/GetData_UpdatePersonnel', { PINID: pId })
            .then((res) => {
                // console.log(res)
                if (res) { setPersonnelEditList(res); }
                else { setPersonnelEditList(); }
            })
    }

    useEffect(() => {
        rest_Value();
    }, [inActiveStatus])

    useEffect(() => {
        if (personnelEditList[0]?.PINID) {
            setDateOfBirth(''); setIsMultiPin(false)
            setValue({
                ...value,
                'PIN': personnelEditList[0]?.PIN,
                'FirstName': personnelEditList[0]?.FirstName,
                'MiddleName': personnelEditList[0]?.MiddleName === null ? "" : personnelEditList[0]?.MiddleName,
                'LastName': personnelEditList[0]?.LastName,
                'UserName': personnelEditList[0]?.UserName,
                'Password': DecryptedList(personnelEditList[0]?.Password),
                'DivisionId': personnelEditList[0]?.DivisionId,
                'RankID': personnelEditList[0]?.RankID,
                'EmployeeTypeID': personnelEditList[0]?.EmployeeTypeID,
                'Email': personnelEditList[0]?.Email,
                'ShiftID': personnelEditList[0]?.ShiftID,
                'ReEnterPassword': DecryptedList(personnelEditList[0]?.Password),
                'PINID': personnelEditList[0]?.PINID,
                'IsSuperadmin': personnelEditList[0]?.IsSuperadmin, 'IsActive': personnelEditList[0]?.IsActive, 'IsSupervisor': personnelEditList[0]?.IsSupervisor,
                'IsAllowLogin': personnelEditList[0]?.IsAllowLogin, 'DateOfBirth': getShowingWithOutTime(personnelEditList[0]?.DateOfBirth) === 'Invalid date' ? '' : getShowingWithOutTime(personnelEditList[0]?.DateOfBirth), 'NCICLoginId': personnelEditList[0]?.NCICLoginId, 'NCICORI': personnelEditList[0]?.NCICORI, 'IsJuvenileCleared': personnelEditList[0]?.IsJuvenileCleared,
                'DivisionName': changeArrayFormat_WithFilter(personnelEditList, 'division'), 'ShiftName': changeArrayFormat_WithFilter(personnelEditList, 'shift'), 'RankName': changeArrayFormat_WithFilter(personnelEditList, 'rank'), 'EmployeeName': changeArrayFormat_WithFilter(personnelEditList, 'EmployeeName'), 'GenderName': changeArrayFormat_WithFilter(personnelEditList, 'gender'), 'MaxLockLevel': personnelEditList[0]?.MaxLockLevel, 'MaxRestrictLevel': personnelEditList[0]?.MaxRestrictLevel, 'SexID': personnelEditList[0]?.SexID,
                'ModifiedByUserFK': pinId,
            });
            setDateOfBirth(personnelEditList[0]?.DateOfBirth ? new Date(personnelEditList[0]?.DateOfBirth) : null);
            setPersonnelStatus(true)
            setPasStatus(true)
            setRePasStatus(true)
        } else rest_Value()
    }, [personnelEditList])

    // Get Multiple Image Personnel
    const get_Personnel_MultiImage = (pId, aId) => {
        fetchPostData('Personnel/GetData_Image', { PINID: pId, AgencyID: aId })
            .then((res) => {
                console.log(res);
                if (res) { setPersonnelImage(res); }
                else { setPersonnelImage(); }
            })
    }

    // Get Effective Field Permission
    const get_Field_Permision_Division = (aId, pinId) => {
        fieldPermision(aId, 'P018', pinId).then(res => {
            if (res) {
                const PINFilter = res?.filter(item => item.Description === "Personnel-Pin");
                // const PINFilter = Agency_Field_Permistion_Filter(res, "Personnel-Pin")

                const LastNameFilter = res?.filter(item => item.Description === "Personnel-LastName");
                const FirstNameFilter = res?.filter(item => item.Description === "Personnel-FirstName");
                const MiddleNameFilter = res?.filter(item => item.Description === "Personnel-MiddleName");
                const DivisionIdFilter = res?.filter(item => item.Description === "Personnel-Division");
                const RankIDFilter = res?.filter(item => item.Description === "Personnel-Rank");
                const ShiftIDFilter = res?.filter(item => item.Description === "Personnel-Shift");
                const EmailFilter = res?.filter(item => item.Description === "Personnel-Email_Id");
                const UserNameFilter = res?.filter(item => item.Description === "Personnel-LoginUserId");
                const PasswordFilter = res?.filter(item => item.Description === "Personnel-Password");
                const ReEnterPasswordFilter = res?.filter(item => item.Description === "Personnel-ReEnterPassword");
                const IsSuperadminFilter = res?.filter(item => item.Description === "Personnel-SuperAdmin");
                const IsAllowLoginFilter = res?.filter(item => item.Description === "Personnel-AllowLogin");

                setFieldPermissionAgency(prevValues => {
                    return {
                        ...prevValues,
                        ['PIN']: PINFilter || prevValues['PIN'],
                        ['LastName']: LastNameFilter || prevValues['LastName'],
                        ['FirstName']: FirstNameFilter || prevValues['FirstName'],
                        ['MiddleName']: MiddleNameFilter || prevValues['MiddleName'],
                        ['DivisionId']: DivisionIdFilter || prevValues['DivisionId'],
                        ['RankID']: RankIDFilter || prevValues['RankID'],
                        ['ShiftID']: ShiftIDFilter || prevValues['ShiftID'],
                        ['Email']: EmailFilter || prevValues['Email'],
                        ['UserName']: UserNameFilter || prevValues['UserName'],
                        ['Password']: PasswordFilter || prevValues['Password'],
                        ['ReEnterPassword']: ReEnterPasswordFilter || prevValues['ReEnterPassword'],
                        ['IsSuperadmin']: IsSuperadminFilter || prevValues['IsSuperadmin'],
                        ['IsAllowLogin']: IsAllowLoginFilter || prevValues['IsAllowLogin'],
                    }
                });
            }
        });
    }

    // Get Password setting 
    const get_Password_Setting = (aId) => {
        fetchPostData('PasswordSetting/PasswordSetting_getData', { AgencyID: aId }).then(res => {
            if (res) {
                setPasswordSettingVal(res[0])
            }
        })
    }

    // Rank, AssociatedShift and Division list 
    const get_Rank = (aId) => {
        fetchPostData('MasterPersonnel/GetData_Rank', { AgencyId: aId })
            .then(res => {
                if (res) setRankList(changeArrayFormat(res, 'rank'))
                else setRankList()
            })
    }

    const getSexList = async (aId) => {
        fetchPostData("DropDown/GetData_SexType", { AgencyId: aId })
            .then(response => {
                if (response) setSexList(changeArrayFormat(response, 'genderId'))
                else setSexList()
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    const get_AssociatedShift = (aId) => {
        fetchPostData('MasterPersonnel/GetData_Shift', { AgencyId: aId })
            .then(res => {
                if (res) setAssociatedShift(changeArrayFormat(res, 'shift'))
                else setAssociatedShift()
            })
    }

    const get_EmployeeType = (aId) => {
        fetchPostData('DropDown/GetDataDropDown_EmployeeType', { AgencyId: aId })
            .then(res => {
                if (res) setEmployeeTypeList(changeArrayFormat(res, 'EmployeeType'))
                else setEmployeeTypeList()
            })
    }

    const get_Photo_Type = () => {
        fetchData('DropDown/GetData_PhotoType')
            .then(res => {
                if (res) setPhotoType(changeArrayFormat(res, 'photoType'))
                else setPhotoType()
            })
    }

    const get_Division = (aId) => {
        fetchPostData('DropDown/GetData_Division', { AgencyId: aId })
            .then(res => {
                if (res) setDivision(changeArrayFormat(res, 'division'))
                else setDivision()
            })
    }

    // onChange Hooks Function
    const rankChange = (e) => {
        if (e) {
            setValue({ ...value, ['RankID']: e.value });
            setChangesStatus(true)
        } else {
            setValue({ ...value, ['RankID']: null });
            setChangesStatus(true)
        }
    }

    const divisionChange = (e) => {
        if (e) {
            setValue({ ...value, ['DivisionId']: e.value });
            setChangesStatus(true)
        } else {
            setValue({ ...value, ['DivisionId']: null });
            setChangesStatus(true)
        }
    }

    const shiftChange = (e) => {
        if (e) {
            setValue({ ...value, ['ShiftID']: e.value });
            setChangesStatus(true)
        } else {
            setValue({ ...value, ['ShiftID']: null });
            setChangesStatus(true)
        }
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
        } else setValue({
            ...value,
            [name]: null
        })
    }

    const handleChange = (e) => {
        if (e.target.name === 'Password') {
            setPasStatus(false);
            setValue(pre => { return { ...pre, [e.target.name]: e.target.value } }); setChangesStatus(true)
        }
        else if (e.target.name === 'ReEnterPassword') {
            setRePasStatus(false);
            setValue(pre => { return { ...pre, [e.target.name]: e.target.value } }); setChangesStatus(true)
        }
        else if (e.target.name === 'MaxLockLevel' || e.target.name === 'MaxRestrictLevel') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            if (checkNumber < 11) {
                setValue({
                    ...value,
                    [e.target.name]: checkNumber
                });
                setChangesStatus(true)
            }
        }
        else if (e.target.name === 'PIN') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            if (checkNumber?.length === 6) {
                checkPinExist(checkNumber, aId, pId);
                setChangesStatus(true)
            }
            setValue({
                ...value,
                [e.target.name]: checkNumber
            });
            setChangesStatus(true)
        } else {
            setValue(pre => { return { ...pre, [e.target.name]: e.target.value } });
            setChangesStatus(true)
        }
    }

    // onChange Hooks Function
    const dateChange = () => {
        const newDate = new Date()
        const year = new Date().getFullYear();
        setValidDobDate(new Date(newDate.setFullYear(year - 18)));
    }

    const rest_Value = () => {
        setValue({
            ...value,
            'PIN': '', 'AgencyID': aId, 'FirstName': '', 'MiddleName': '', 'LastName': '', 'UserName': '', 'Password': '', 'DivisionId': '', 'RankID': '', 'EmployeeTypeID': '', 'Email': '', 'ShiftID': '', 'ReEnterPassword': '', 'ModifiedByUserFK': '', 'PINID': '', 'IsSuperadmin': '', 'IsAllowLogin': '', 'ShiftName': '', "RankName": '', 'DivisionName': '', 'DateOfBirth': '', 'NCICLoginId': '', 'NCICORI': '', 'ScheduleId': '', 'MaxLockLevel': '', 'MaxRestrictLevel': '', 'IsJuvenileCleared': '', 'IsActive': true, 'SexID': '', 'IsSupervisor': '', 'photoId': '', 'GenderName': '', 'EmployeeName': '',
        }); setPersonnelImage([]); setRePasStatus(false); setPasStatus(false); setDateOfBirth(null); setIsMultiPin(false)
    }

    // Update Personnel List
    const update_Personnel = () => {
        if (isMultiPin) {
            toastifyError('PIN Already Exists');
            setErrors({ ...errors, ['PINError']: '' });
        } else {
            const {
                PIN, AgencyID, AllowMultipleLogins, FirstName, MiddleName, LastName, UserName, Password, DivisionId, RankID, EmployeeTypeID, Email, ShiftID, ReEnterPassword, ModifiedByUserFK, PINID, IsSuperadmin, IsAllowLogin, ShiftName, RankName, DivisionName, DateOfBirth, NCICLoginId, NCICORI, ScheduleId, MaxLockLevel, MaxRestrictLevel, IsJuvenileCleared, IsActive, SexID, IsSupervisor, photoId, GenderName, EmployeeName,
                CreatedByUserFK,
            } = value
            const val = {
                'PIN': PIN, 'AgencyID': AgencyID, 'AllowMultipleLogins': AllowMultipleLogins, 'FirstName': FirstName, 'MiddleName': MiddleName, 'LastName': LastName, 'UserName': UserName,
                'Password': encryptAndEncodeToBase64(Password), 'DivisionId': DivisionId, 'RankID': RankID, 'EmployeeTypeID': EmployeeTypeID, 'Email': Email, 'ShiftID': ShiftID,
                'ReEnterPassword': encryptAndEncodeToBase64(ReEnterPassword), 'ModifiedByUserFK': ModifiedByUserFK, 'PINID': PINID, 'IsSuperadmin': IsSuperadmin, 'IsAllowLogin': IsAllowLogin, 'ShiftName': ShiftName, "RankName": RankName, 'DivisionName': DivisionName, 'DateOfBirth': DateOfBirth, 'NCICLoginId': NCICLoginId, 'NCICORI': NCICORI, 'ScheduleId': ScheduleId, 'MaxLockLevel': MaxLockLevel, 'MaxRestrictLevel': MaxRestrictLevel, 'IsJuvenileCleared': IsJuvenileCleared, 'IsActive': IsActive, 'SexID': SexID, 'IsSupervisor': IsSupervisor, 'photoId': photoId, 'GenderName': GenderName, 'EmployeeName': EmployeeName,
                'CreatedByUserFK': CreatedByUserFK,
            }
            AddDeleteUpadate('Personnel/UpdatePersonnel', val).then((res) => {
                if (res.success === true) {
                    toastifySuccess(res.Message)
                    setErrors({ ...errors, ['PINError']: '' })
                    if (!inActiveStatus && !value.IsActive) {
                        getInActive_Personnel(aId); setPersonnelStatus(false); setInActiveStatus(true);
                    } else if (!inActiveStatus && value.IsActive) {
                        get_Personnel_Lists(aId); setPersonnelStatus(true)
                    } else if (inActiveStatus && value.IsActive) {
                        getInActive_Personnel(aId); get_Personnel_Lists(aId); setInActiveStatus(false); setPersonnelStatus(true)
                    } else {
                        console.log("CALL")
                    }
                    setChangesStatus(false)
                } else {
                    setErrors({ ...errors, ['PINError']: '' })
                    toastifyError(JSON.parse(res.request.response).Message)
                }
            })
        }
    }

    const [errors, setErrors] = useState({
        'PINError': '',
        'FirstNameError': '',
        'LastNameError': '',
        'PasswordError': '',
        'UserNameError': '',
        'ReEnterPasswordError': '',
        'emailError': ''
    });

    const { PINError, FirstNameError, LastNameError, PasswordError, UserNameError, ReEnterPasswordError, emailError } = errors

    useEffect(() => {
        if (PINError === 'true' && FirstNameError === 'true' && LastNameError === 'true' && PasswordError === 'true' && UserNameError === 'true' && ReEnterPasswordError === 'true' && emailError === 'true') {
            if (personnelStatus) { update_Personnel() }
            else { save_Personnel() }
        }
    }, [PINError, FirstNameError, LastNameError, PasswordError, UserNameError, ReEnterPasswordError, emailError])

    const check_Validation_Error = (e) => {
        e.preventDefault()
        if (PinValidator(value.PIN)) {
            setErrors(prevValues => { return { ...prevValues, ['PINError']: PinValidator(value.PIN) } })
        }
        if (RequiredField(value.FirstName)) {
            setErrors(prevValues => { return { ...prevValues, ['FirstNameError']: RequiredField(value.FirstName) } })
        }
        if (RequiredField(value.LastName)) {
            setErrors(prevValues => { return { ...prevValues, ['LastNameError']: RequiredField(value.LastName) } })
        }
        if (PasswordField(passwordSettingVal, value.Password, value.UserName)) {
            setErrors(prevValues => { return { ...prevValues, ['PasswordError']: PasswordField(passwordSettingVal, value.Password, value.UserName) } })
        }
        if (RequiredField(value.UserName)) {
            setErrors(prevValues => { return { ...prevValues, ['UserNameError']: RequiredField(value.UserName) } })
        }
        if (ReEnterPasswordVal(value.Password, value.ReEnterPassword)) {
            setErrors(prevValues => { return { ...prevValues, ['ReEnterPasswordError']: ReEnterPasswordVal(value.Password, value.ReEnterPassword) } })
        }
        if (Email_Field(value.Email)) {
            setErrors(prevValues => { return { ...prevValues, ['emailError']: Email_Field(value.Email) } })
        }
    }

    const save_Personnel = () => {
        if (isMultiPin) {
            toastifyError('PIN Already Exists')
            setErrors({ ...errors, ['PINError']: '' })
        } else {
            const {
                PIN, AgencyID, AllowMultipleLogins, FirstName, MiddleName, LastName, UserName, Password, DivisionId, RankID, EmployeeTypeID, Email, ShiftID, ReEnterPassword, ModifiedByUserFK, PINID, IsSuperadmin, IsAllowLogin, ShiftName, RankName, DivisionName, DateOfBirth, NCICLoginId, NCICORI, ScheduleId, MaxLockLevel, MaxRestrictLevel, IsJuvenileCleared, IsActive, SexID, IsSupervisor, photoId, GenderName, EmployeeName,
                CreatedByUserFK,
            } = value
            const val = {
                'PIN': PIN, 'AgencyID': AgencyID, 'AllowMultipleLogins': AllowMultipleLogins, 'FirstName': FirstName, 'MiddleName': MiddleName, 'LastName': LastName, 'UserName': UserName,
                'Password': encryptAndEncodeToBase64(Password), 'DivisionId': DivisionId, 'RankID': RankID, 'EmployeeTypeID': EmployeeTypeID, 'Email': Email, 'ShiftID': ShiftID,
                'ReEnterPassword': encryptAndEncodeToBase64(ReEnterPassword), 'ModifiedByUserFK': ModifiedByUserFK, 'PINID': PINID, 'IsSuperadmin': IsSuperadmin, 'IsAllowLogin': IsAllowLogin, 'ShiftName': ShiftName, "RankName": RankName, 'DivisionName': DivisionName, 'DateOfBirth': DateOfBirth, 'NCICLoginId': NCICLoginId, 'NCICORI': NCICORI, 'ScheduleId': ScheduleId, 'MaxLockLevel': MaxLockLevel, 'MaxRestrictLevel': MaxRestrictLevel, 'IsJuvenileCleared': IsJuvenileCleared, 'IsActive': IsActive, 'SexID': SexID, 'IsSupervisor': IsSupervisor, 'photoId': photoId, 'GenderName': GenderName, 'EmployeeName': EmployeeName,
                'CreatedByUserFK': CreatedByUserFK,
            }
            // console.log(val)
            AddDeleteUpadate('Personnel/InsertPersonnel', val)
                .then((res) => {
                    if (res?.success === true) {
                        setChangesStatus(false)
                        toastifySuccess(res?.Message); setPersonnelStatus(true)
                        navigate(`/personnelTab?id=U2FsdGVkX1${aId}/rbn7XDh9W4GiUkZ4MTV1Vx8pMNVkdjyw=&pd=89zw03LXTG${res?.Id.trim()}/2Wga0gJLXEgctxh79FeM/G`);
                        get_Personnel_Lists(aId)
                        setErrors({ ...errors, ['PINError']: '' })
                        get_CountList(aId);
                    }
                })
        }
    }

    // get Personnel Image
    const get_Image_File = (e) => {
        try {
            let currentFileType = e.target.files[0].type;
            let checkPng = currentFileType.indexOf("png");
            let checkJpeg = currentFileType.indexOf("jpeg");
            let checkJpg = currentFileType.indexOf("jpg");
            if (checkPng !== -1 || checkJpeg !== -1 || checkJpg !== -1) {
                upload_Image_File(e.target.files[0]);
                setChangesStatus(true)
            } else {
                toastifyError("Error: Invalid image file!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Upload Personnel Image 
    const upload_Image_File = (image) => {
        const val = {
            'PINID': pId,
            'CreatedByUserFK': pinId,
        }
        const values = JSON.stringify(val);
        console.log(values)
        const formdata = new FormData();
        formdata.append("Photo", image);
        formdata.append("Data", values);
        // console.log(formdata)
        AddDeleteUpadate('Personnel/Insert_PersonnelPhoto', formdata)
            .then((res) => {
                if (res.success) {
                    get_Personnel_MultiImage(pId, aId)
                }
            }).catch(err => console.log(err))
    }

    const delete_Image_File = (e) => {
        const val = { PhotoId: imageId, DeletedByUserFK: pinId, }
        AddDeleteUpadate('Personnel/Delete_PersonnelPhoto', val).then((data) => {
            if (data.success) {
                toastifySuccess(data.Message)
                get_Personnel_MultiImage(pId, aId);
            } else {
                toastifyError(data.Message)
            }
        });
    }

    const startRef2 = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef2.current.setOpen(false);
        }
    };

    // Custom color
    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const checkPinExist = (checkNumber, aId, pId) => {
        const val = { PINID: pId, PIN: checkNumber, AgencyID: aId, }
        fetchPostData('Personnel/GetData_CheckPIN', val).then(res => {
            if (res[0].Count > 0) {
                setIsMultiPin(true)
            } else {
                setIsMultiPin(false)
            }
        })
    }

    return (
        <>
            <div className="row">
                <div className="col-12 " id='display-not-form'>
                    <div className="row">
                        <div className="col-12 col-md-12 pt-2 p-0 " >
                            <div className="bg-line  cpy px-2 mt-1 d-flex justify-content-between align-items-center">
                                <p className="p-0 m-0 d-flex align-items-center">
                                    Employee  Information :- {personnelStatus ? personnelEditList[0]?.PINID : ''}
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-10 pt-2 p-0">
                            <div className="row">
                                <div className={`col-12 col-md-12 `} >
                                    <div className="row row-gd " >
                                        <div className="col-6 col-md-2 col-lg-3 mt-1">
                                            <div className="text-field">
                                                <input type="text" maxLength="6"
                                                    name='PIN' value={value.PIN}
                                                    className={fieldPermissionAgency?.PIN[0] ?
                                                        fieldPermissionAgency?.PIN[0]?.Changeok === 0 && fieldPermissionAgency?.PIN[0]?.AddOK === 0 && personnelStatus ? 'readonlyColor' : fieldPermissionAgency?.PIN[0]?.Changeok === 0 && fieldPermissionAgency?.PIN[0]?.AddOK === 1 && editValueList?.PIN === '' && personnelStatus ? 'requiredColor' : fieldPermissionAgency?.PIN[0]?.AddOK === 1 && !personnelStatus ? 'requiredColor' : fieldPermissionAgency?.PIN[0]?.Changeok === 1 && personnelStatus ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                                    }
                                                    onChange={fieldPermissionAgency?.PIN[0] ?
                                                        fieldPermissionAgency?.PIN[0]?.Changeok === 0 && fieldPermissionAgency?.PIN[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.PIN[0]?.Changeok === 0 && fieldPermissionAgency?.PIN[0]?.AddOK === 1 && editValueList?.PIN === '' && personnelStatus ? handleChange : fieldPermissionAgency?.PIN[0]?.AddOK === 1 && !personnelStatus ? handleChange : fieldPermissionAgency?.PIN[0]?.Changeok === 1 && personnelStatus ? handleChange : '' : handleChange
                                                    }
                                                    required />
                                                <label>Pin</label>
                                                <p> <span className='hovertext-1' data-hover="Enter a 6 digit code here with no repeating digits and sequential patterns (i.e., 112233, 123456)" ><i className='fa fa-exclamation-circle'></i></span></p>

                                                {errors.PINError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PINError}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 col-lg-3 mt-1">
                                            <div className="text-field">
                                                <input type="text" maxLength={50} name='LastName' value={value.LastName}
                                                    className={fieldPermissionAgency?.LastName[0] ?
                                                        fieldPermissionAgency?.LastName[0]?.Changeok === 0 && fieldPermissionAgency?.LastName[0]?.AddOK === 0 && personnelStatus ? 'readonlyColor' : fieldPermissionAgency?.LastName[0]?.Changeok === 0 && fieldPermissionAgency?.LastName[0]?.AddOK === 1 && editValueList?.LastName === '' && personnelStatus ? 'requiredColor' : fieldPermissionAgency?.LastName[0]?.AddOK === 1 && !personnelStatus ? 'requiredColor' : fieldPermissionAgency?.LastName[0]?.Changeok === 1 && personnelStatus ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                                    }
                                                    onChange={fieldPermissionAgency?.LastName[0] ?
                                                        fieldPermissionAgency?.LastName[0]?.Changeok === 0 && fieldPermissionAgency?.LastName[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.LastName[0]?.Changeok === 0 && fieldPermissionAgency?.LastName[0]?.AddOK === 1 && editValueList?.LastName === '' && personnelStatus ? handleChange : fieldPermissionAgency?.LastName[0]?.AddOK === 1 && !personnelStatus ? handleChange : fieldPermissionAgency?.LastName[0]?.Changeok === 1 && personnelStatus ? handleChange : '' : handleChange
                                                    }
                                                    required />
                                                <label>Last Name</label>
                                                {errors.LastNameError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.LastNameError}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 col-lg-3 mt-1">
                                            <div className="text-field">
                                                <input type="text" maxLength={50} name='FirstName' value={value.FirstName}
                                                    className={fieldPermissionAgency?.FirstName[0] ?
                                                        fieldPermissionAgency?.FirstName[0]?.Changeok === 0 && fieldPermissionAgency?.FirstName[0]?.AddOK === 0 && personnelStatus ? 'readonlyColor' : fieldPermissionAgency?.FirstName[0]?.Changeok === 0 && fieldPermissionAgency?.FirstName[0]?.AddOK === 1 && editValueList?.FirstName === '' && personnelStatus ? 'requiredColor' : fieldPermissionAgency?.FirstName[0]?.AddOK === 1 && !personnelStatus ? 'requiredColor' : fieldPermissionAgency?.FirstName[0]?.Changeok === 1 && personnelStatus ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                                    }
                                                    onChange={fieldPermissionAgency?.FirstName[0] ?
                                                        fieldPermissionAgency?.FirstName[0]?.Changeok === 0 && fieldPermissionAgency?.FirstName[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.FirstName[0]?.Changeok === 0 && fieldPermissionAgency?.FirstName[0]?.AddOK === 1 && editValueList?.FirstName === '' && personnelStatus ? handleChange : fieldPermissionAgency?.FirstName[0]?.AddOK === 1 && !personnelStatus ? handleChange : fieldPermissionAgency?.FirstName[0]?.Changeok === 1 && personnelStatus ? handleChange : '' : handleChange
                                                    }
                                                    required />
                                                <label>First Name</label>
                                                {errors.FirstNameError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.FirstNameError}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-3 mt-1">
                                            <div className="text-field">
                                                <input type="text" maxLength={200} name='MiddleName' value={value.MiddleName} onChange={handleChange} required />
                                                <label>Middle Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12" >
                                    <div className="row">
                                        <div className="col-6 col-md-5 col-lg-3 mt-4  index-select">
                                            <Select
                                                name='SexID'
                                                onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                value={sexList?.filter((obj) => obj.value === value?.SexID)}
                                                styles={customStylesWithOutColor}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                menuPlacement="top"
                                                options={sexList}
                                                isClearable
                                            />
                                            <label>Gender</label>
                                        </div>
                                        <div className="col-6 col-md-3 col-lg-3 mt-4 date__box">
                                            <DatePicker
                                                ref={startRef2}
                                                onKeyDown={onKeyDown}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                name='DateOfBirth'
                                                isClearable={true}
                                                maxDate={validDobDate}
                                                onChange={date => {
                                                    setDateOfBirth(date); setValue({ ...value, ['DateOfBirth']: getShowingYearMonthDate(date) });
                                                    const newDate = new Date(date)
                                                    const year = newDate.getFullYear();
                                                    setDobHireDate(new Date(newDate.setFullYear(year + 18)));
                                                }}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                selected={dateOfBirth}
                                                placeholderText={value.DateOfBirth ? value.DateOfBirth : 'Select ..'}
                                            />
                                            <div>
                                                <label htmlFor="">Date Of Birth</label>
                                            </div>
                                            <label>Date Of Birth</label>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mt-3">
                                            <div className="text-field">
                                                <input type="text" name='Email' value={value.Email}
                                                    className={fieldPermissionAgency?.Email[0] ?
                                                        fieldPermissionAgency?.Email[0]?.Changeok === 0 && fieldPermissionAgency?.Email[0]?.AddOK === 0 && personnelStatus ? 'readonlyColor' : fieldPermissionAgency?.Email[0]?.Changeok === 0 && fieldPermissionAgency?.Email[0]?.AddOK === 1 && editValueList?.Email === '' && personnelStatus ? '' : fieldPermissionAgency?.Email[0]?.AddOK === 1 && !personnelStatus ? '' : fieldPermissionAgency?.Email[0]?.Changeok === 1 && personnelStatus ? '' : 'readonlyColor' : ''
                                                    }
                                                    onChange={fieldPermissionAgency?.Email[0] ?
                                                        fieldPermissionAgency?.Email[0]?.Changeok === 0 && fieldPermissionAgency?.Email[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.Email[0]?.Changeok === 0 && fieldPermissionAgency?.Email[0]?.AddOK === 1 && editValueList?.Email === '' && personnelStatus ? handleChange : fieldPermissionAgency?.Email[0]?.AddOK === 1 && !personnelStatus ? handleChange : fieldPermissionAgency?.Email[0]?.Changeok === 1 && personnelStatus ? handleChange : '' : handleChange
                                                    }
                                                    required />
                                                <label>Email Id</label>
                                                {errors.emailError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.emailError}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 mt-4 index-select">
                                            <Select
                                                name='EmployeeTypeID'
                                                onChange={(e) => ChangeDropDown(e, 'EmployeeTypeID')}
                                                value={employeeTypeList?.filter((obj) => obj.value === value?.EmployeeTypeID)}
                                                styles={customStylesWithOutColor}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                menuPlacement="top"
                                                options={employeeTypeList}
                                                isClearable
                                            />
                                            <label htmlFor="">Employee Type</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 mt-4  index-select">
                                            <Select
                                                styles={customStylesWithOutColor}
                                                value={division?.filter((obj) => obj.value === value?.DivisionId)}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name='DivisionId'
                                                menuPlacement="top"
                                                options={division}
                                                isClearable
                                                onChange={fieldPermissionAgency?.DivisionId[0] ?
                                                    fieldPermissionAgency?.DivisionId[0]?.Changeok === 0 && fieldPermissionAgency?.DivisionId[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.DivisionId[0]?.Changeok === 0 && fieldPermissionAgency?.DivisionId[0]?.AddOK === 1 && editValueList?.DivisionId === '' && personnelStatus ? divisionChange : fieldPermissionAgency?.DivisionId[0]?.AddOK === 1 && !personnelStatus ? divisionChange : fieldPermissionAgency?.DivisionId[0]?.Changeok === 1 && personnelStatus ? divisionChange : '' : divisionChange
                                                }
                                                isDisabled={fieldPermissionAgency?.DivisionId[0] ?
                                                    fieldPermissionAgency?.DivisionId[0]?.Changeok === 0 && fieldPermissionAgency?.DivisionId[0]?.AddOK === 0 && personnelStatus ? true : fieldPermissionAgency?.DivisionId[0]?.Changeok === 0 && fieldPermissionAgency?.DivisionId[0]?.AddOK === 1 && editValueList?.DivisionId === '' && personnelStatus ? false : fieldPermissionAgency?.DivisionId[0]?.AddOK === 1 && !personnelStatus ? false : fieldPermissionAgency?.DivisionId[0]?.Changeok === 1 && personnelStatus ? false : true : false
                                                }
                                            />

                                            <label htmlFor="">Division</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 mt-4 index-select">
                                            <Select
                                                styles={customStylesWithOutColor}
                                                value={rankList?.filter((obj) => obj.value === value?.RankID)}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name='RankID' menuPlacement="top"
                                                options={rankList}
                                                isClearable
                                                onChange={fieldPermissionAgency?.RankID[0] ?
                                                    fieldPermissionAgency?.RankID[0]?.Changeok === 0 && fieldPermissionAgency?.RankID[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.RankID[0]?.Changeok === 0 && fieldPermissionAgency?.RankID[0]?.AddOK === 1 && editValueList?.RankID === '' && personnelStatus ? rankChange : fieldPermissionAgency?.RankID[0]?.AddOK === 1 && !personnelStatus ? rankChange : fieldPermissionAgency?.RankID[0]?.Changeok === 1 && personnelStatus ? rankChange : '' : rankChange
                                                }
                                                isDisabled={fieldPermissionAgency?.RankID[0] ?
                                                    fieldPermissionAgency?.RankID[0]?.Changeok === 0 && fieldPermissionAgency?.RankID[0]?.AddOK === 0 && personnelStatus ? true : fieldPermissionAgency?.RankID[0]?.Changeok === 0 && fieldPermissionAgency?.RankID[0]?.AddOK === 1 && editValueList?.RankID === '' && personnelStatus ? false : fieldPermissionAgency?.RankID[0]?.AddOK === 1 && !personnelStatus ? false : fieldPermissionAgency?.RankID[0]?.Changeok === 1 && personnelStatus ? false : true : false
                                                }
                                            />

                                            <label htmlFor="">Rank</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 mt-4 index-select">
                                            <Select
                                                styles={customStylesWithOutColor}
                                                value={associatedShift?.filter((obj) => obj.value === value?.ShiftID)}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                name='ShiftID' menuPlacement="top"
                                                options={associatedShift}
                                                isClearable
                                                onChange={fieldPermissionAgency?.ShiftID[0] ?
                                                    fieldPermissionAgency?.ShiftID[0]?.Changeok === 0 && fieldPermissionAgency?.ShiftID[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.ShiftID[0]?.Changeok === 0 && fieldPermissionAgency?.ShiftID[0]?.AddOK === 1 && editValueList?.ShiftID === '' && personnelStatus ? shiftChange : fieldPermissionAgency?.ShiftID[0]?.AddOK === 1 && !personnelStatus ? shiftChange : fieldPermissionAgency?.ShiftID[0]?.Changeok === 1 && personnelStatus ? shiftChange : '' : shiftChange
                                                }
                                                isDisabled={fieldPermissionAgency?.ShiftID[0] ?
                                                    fieldPermissionAgency?.ShiftID[0]?.Changeok === 0 && fieldPermissionAgency?.ShiftID[0]?.AddOK === 0 && personnelStatus ? true : fieldPermissionAgency?.ShiftID[0]?.Changeok === 0 && fieldPermissionAgency?.ShiftID[0]?.AddOK === 1 && editValueList?.ShiftID === '' && personnelStatus ? false : fieldPermissionAgency?.ShiftID[0]?.AddOK === 1 && !personnelStatus ? false : fieldPermissionAgency?.ShiftID[0]?.Changeok === 1 && personnelStatus ? false : true : false
                                                }
                                            />

                                            <label htmlFor="">Shift</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 px-2 " >
                                    <div className="row">
                                        <div className="col-12 col-md-12 pt-2 p-0" >
                                            <div className="bg-line cpy px-2 mt-1 d-flex justify-content-between align-items-center">
                                                <p className="p-0 m-0 d-flex align-items-center ">
                                                    Login Information
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-4 mt-3">
                                            <div className="text-field">
                                                <input type="text"
                                                    name='UserName' value={value.UserName}
                                                    className={fieldPermissionAgency?.UserName[0] ?
                                                        fieldPermissionAgency?.UserName[0]?.Changeok === 0 && fieldPermissionAgency?.UserName[0]?.AddOK === 0 && personnelStatus ? 'readonlyColor' : fieldPermissionAgency?.UserName[0]?.Changeok === 0 && fieldPermissionAgency?.UserName[0]?.AddOK === 1 && editValueList?.UserName === '' && personnelStatus ? 'requiredColor' : fieldPermissionAgency?.UserName[0]?.AddOK === 1 && !personnelStatus ? 'requiredColor' : fieldPermissionAgency?.UserName[0]?.Changeok === 1 && personnelStatus ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                                    }
                                                    onChange={fieldPermissionAgency?.UserName[0] ?
                                                        fieldPermissionAgency?.UserName[0]?.Changeok === 0 && fieldPermissionAgency?.UserName[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.UserName[0]?.Changeok === 0 && fieldPermissionAgency?.UserName[0]?.AddOK === 1 && editValueList?.UserName === '' && personnelStatus ? handleChange : fieldPermissionAgency?.UserName[0]?.AddOK === 1 && !personnelStatus ? handleChange : fieldPermissionAgency?.UserName[0]?.Changeok === 1 && personnelStatus ? handleChange : '' : handleChange
                                                    }
                                                    required />
                                                <label>Login UserId</label>
                                                {errors.UserNameError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.UserNameError}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-5 col-md-4 col-lg-4 mt-3">
                                            <div className="text-field">
                                                <input type="text" name='Password' value={pasStatus ? '*********' : value.Password}
                                                    className={fieldPermissionAgency?.Password[0] ?
                                                        fieldPermissionAgency?.Password[0]?.Changeok === 0 && fieldPermissionAgency?.Password[0]?.AddOK === 0 && personnelStatus ? 'readonlyColor' : fieldPermissionAgency?.Password[0]?.Changeok === 0 && fieldPermissionAgency?.Password[0]?.AddOK === 1 && editValueList?.Password === '' && personnelStatus ? '' : fieldPermissionAgency?.Password[0]?.AddOK === 1 && !personnelStatus ? '' : fieldPermissionAgency?.Password[0]?.Changeok === 1 && personnelStatus ? '' : 'readonlyColor' : ''
                                                    }
                                                    onChange={fieldPermissionAgency?.Password[0] ?
                                                        fieldPermissionAgency?.Password[0]?.Changeok === 0 && fieldPermissionAgency?.Password[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.Password[0]?.Changeok === 0 && fieldPermissionAgency?.Password[0]?.AddOK === 1 && editValueList?.Password === '' && personnelStatus ? handleChange : fieldPermissionAgency?.Password[0]?.AddOK === 1 && !personnelStatus ? handleChange : fieldPermissionAgency?.Password[0]?.Changeok === 1 && personnelStatus ? handleChange : '' : handleChange
                                                    }
                                                    required />
                                                <label>Password</label>
                                                {
                                                    fieldPermissionAgency?.Password[0]?.DisplayOK === 1 ?
                                                        <i className={!pasStatus ? "fa fa-eye" : "fa fa-eye-slash"} onKeyDown={''} onClick={(e) => setPasStatus(!pasStatus)} style={{ position: 'absolute', top: '25%', right: '3%' }}></i>
                                                        : <></>
                                                }
                                                <p><span className='hovertext-pass' data-hover={`Password: Enter password of min.${passwordSettingVal?.MinPasswordLength}  Length with min ${passwordSettingVal?.MinSpecialCharsInPassword} special char, Min ${passwordSettingVal?.MinUpperCaseInPassword} Uppercase, Min ${passwordSettingVal?.MinLowerCaseInPassword} Lowercase and Min ${passwordSettingVal?.MinNumericDigitsInPassword} numeric digit`} ><i className='fa fa-exclamation-circle'></i></span></p>
                                            </div>
                                            {errors.PasswordError !== 'true' ? (
                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.PasswordError === 'false' ? 'Incorrect format' : errors.PasswordError}</span>
                                            ) : null}
                                        </div>
                                        <div className='mt-4 col-1 col-md-1 col-lg-1'>
                                            <p className='mt-2'><i style={{ color: errors?.PasswordError === 'true' ? 'green' : 'red', fontSize: "20px" }} className="fa fa-check-circle" aria-hidden="true"></i> </p>
                                        </div>
                                        <div className="col-6 col-md-3 col-lg-3 mt-3">
                                            <div className="text-field">
                                                <input type="text" name='ReEnterPassword' value={rePasStatus ? '*********' : value.ReEnterPassword}
                                                    className={fieldPermissionAgency?.ReEnterPassword[0] ?
                                                        fieldPermissionAgency?.ReEnterPassword[0]?.Changeok === 0 && fieldPermissionAgency?.ReEnterPassword[0]?.AddOK === 0 && personnelStatus ? 'readonlyColor' : fieldPermissionAgency?.ReEnterPassword[0]?.Changeok === 0 && fieldPermissionAgency?.ReEnterPassword[0]?.AddOK === 1 && editValueList?.ReEnterPassword === '' && personnelStatus ? '' : fieldPermissionAgency?.ReEnterPassword[0]?.AddOK === 1 && !personnelStatus ? '' : fieldPermissionAgency?.ReEnterPassword[0]?.Changeok === 1 && personnelStatus ? '' : 'readonlyColor' : ''
                                                    }
                                                    onChange={fieldPermissionAgency?.ReEnterPassword[0] ?
                                                        fieldPermissionAgency?.ReEnterPassword[0]?.Changeok === 0 && fieldPermissionAgency?.ReEnterPassword[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.ReEnterPassword[0]?.Changeok === 0 && fieldPermissionAgency?.ReEnterPassword[0]?.AddOK === 1 && editValueList?.ReEnterPassword === '' && personnelStatus ? handleChange : fieldPermissionAgency?.ReEnterPassword[0]?.AddOK === 1 && !personnelStatus ? handleChange : fieldPermissionAgency?.ReEnterPassword[0]?.Changeok === 1 && personnelStatus ? handleChange : '' : handleChange
                                                    }
                                                    required />
                                                <label>Re-Enter Password</label>
                                                {errors.ReEnterPasswordError !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReEnterPasswordError}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className=" col-4 col-md-4 col-lg-2 pt-4" >
                            <div className="img-box" >
                                <Carousel className="carousel-style" showArrows={true} showThumbs={false} showStatus={false}
                                >
                                    {
                                        personnelImage ?
                                            personnelImage?.map((item) => (
                                                < div >
                                                    <img src={item.Photo_Path} style={{ height: '210px' }} alt='' />
                                                    <div className='box' style={{ background: 'red' }}>
                                                        <a type='button' data-toggle="modal" data-target="#DeleteModal" className="legend-img" onClick={setImageId(item.Id)} >
                                                            <i className='fa fa-close' ></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div >
                                                <img src={defualtImage} style={{ height: '190px' }} />
                                            </div>
                                    }
                                </Carousel>
                            </div>
                            <div className="row">
                                {
                                    personnelStatus ?
                                        <div className="col-md-12 text-center " style={{ position: 'absolute', bottom: '0px' }}>
                                            <label className='pers-img mt-1'> <i className='fa fa-upload'></i>
                                                <input type="file" size="60" onChange={get_Image_File} />
                                            </label>
                                        </div> : <></>
                                }
                            </div>
                        </div> */}
                         <div className=" col-4 col-md-4 col-lg-2 pt-4" >
                            <div className="img-box px-1" >
                                <Carousel className="carousel-style" showArrows={true} showThumbs={false} showStatus={false}
                                //  onClickItem={(e) => imageChange(e)}
                                >
                                    {
                                        personnelImage ?
                                            personnelImage?.map((item, index) => (
                                                <div key={index}>
                                                    <img src={item.Photo_Path} style={{ height: '210px' }} />
                                                    {/* <input type='button' className="legend" value={item.id} onClick={(e) => console.log(e.target.value)} /> */}
                                                    <div className='box' style={{ background: 'red' }}>
                                                        <a type='button' data-toggle="modal" data-target="#DeleteModal" className="legend-img " onClick={(e) => { setImageId(item.Id) }} >
                                                            <i className='fa fa-close' ></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            ))
                                            :
                                            <div key='test'>
                                                <img src={defualtImage} style={{ height: '190px' }} />
                                            </div>
                                    }
                                    {
                                        !personnelStatus ?
                                            <div key='test'>
                                                <img src={defualtImage} style={{ height: '190px' }} />
                                            </div>
                                            :
                                            <>
                                                <div key='test'>
                                                    <img src={defualtImage} style={{ height: '190px' }} />
                                                </div>
                                            </>
                                    }
                                </Carousel>
                            </div>
                            <div className="row">
                                {
                                    personnelStatus ?
                                        <div className="col-md-12 text-center " style={{ position: 'absolute', bottom: '0px' }}>
                                            <label className='pers-img mt-1'> <i className='fa fa-upload'></i>
                                                <input type="file" size="60" onChange={get_Image_File} />
                                            </label>
                                            {/* <input type="file" className="btn btn-sm btn-success" onChange={get_Image_File} /><i className='fa fa-upload'></i> */}
                                        </div> : <></>
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-12 " >
                            <div className="row">
                                <div className="col-12 col-md-12  p-0" >
                                    <div className="bg-line  cpy px-2 mt-1 d-flex justify-content-between align-items-center">
                                        <p className="p-0 m-0 d-flex align-items-center ">
                                            Other
                                        </p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-4 col-lg-3  mt-3">
                                    <div className="text-field">
                                        <input type="text" name='NCICLoginId' value={value?.NCICLoginId} onChange={handleChange}
                                            required />
                                        <label>NCIC Login Id</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-4 col-lg-2  mt-3">
                                    <div className="text-field">
                                        <input type="text" name='NCICORI' value={value?.NCICORI} onChange={handleChange}
                                            required />
                                        <label>NCIC ORI</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-4 col-lg-3 mt-4 index-select">
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        name='ShiftID' menuPlacement="top"
                                        options=''
                                        defaultValue=''
                                        isClearable
                                        isDisabled
                                        styles={customStylesWithOutColor}
                                    />
                                    <label>Schedule</label>
                                </div>
                                <div className="col-6 col-md-3 col-lg-2 mt-3">
                                    <div className="text-field">
                                        <input type="text" name='MaxLockLevel' value={value.MaxLockLevel} onChange={handleChange}
                                            required />
                                        <label>Max Lock Level</label>
                                    </div>
                                </div>
                                <div className="col-4 col-md-3 col-lg-2  mt-3">
                                    <div className="text-field">
                                        <input type="text" name='MaxRestrictLevel' value={value.MaxRestrictLevel} onChange={handleChange}
                                            required />
                                        <label>Max Restrict Level</label>
                                    </div>
                                </div>
                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                    <input type="checkbox" name="IsSuperadmin"
                                        checked={value?.IsSuperadmin}
                                        value={value?.IsSuperadmin}
                                        onChange={fieldPermissionAgency?.IsSuperadmin[0] ?
                                            fieldPermissionAgency?.IsSuperadmin[0]?.Changeok === 0 && fieldPermissionAgency?.IsSuperadmin[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.IsSuperadmin[0]?.Changeok === 0 && fieldPermissionAgency?.IsSuperadmin[0]?.AddOK === 1 && editValueList?.IsSuperadmin === '' && personnelStatus ? () => setValue({ ...value, ['IsSuperadmin']: !value?.IsSuperadmin }) : fieldPermissionAgency?.IsSuperadmin[0]?.AddOK === 1 && !personnelStatus ? () => setValue({ ...value, ['IsSuperadmin']: !value?.IsSuperadmin }) : fieldPermissionAgency?.IsSuperadmin[0]?.Changeok === 1 && personnelStatus ? () => setValue({ ...value, ['IsSuperadmin']: !value?.IsSuperadmin }) : ''
                                            : () => setValue({ ...value, ['IsSuperadmin']: !value?.IsSuperadmin })
                                        }
                                        disabled={fieldPermissionAgency?.IsSuperadmin[0] ?
                                            fieldPermissionAgency?.IsSuperadmin[0]?.Changeok === 0 && fieldPermissionAgency?.IsSuperadmin[0]?.AddOK === 0 && personnelStatus ? true : fieldPermissionAgency?.IsSuperadmin[0]?.Changeok === 0 && fieldPermissionAgency?.IsSuperadmin[0]?.AddOK === 1 && editValueList?.IsSuperadmin === '' && personnelStatus ? false : fieldPermissionAgency?.IsSuperadmin[0]?.AddOK === 1 && !personnelStatus ? false : fieldPermissionAgency?.IsSuperadmin[0]?.Changeok === 1 && personnelStatus ? false : true : false
                                        }
                                        id="IsSuperadmin" />
                                    <label className='ml-2' htmlFor="IsSuperadmin">Super Admin</label>
                                </div>
                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                    <input type="checkbox" name="IsAllowLogin"
                                        checked={value?.IsAllowLogin}
                                        value={value?.IsAllowLogin}
                                        onChange={fieldPermissionAgency?.IsAllowLogin[0] ?
                                            fieldPermissionAgency?.IsAllowLogin[0]?.Changeok === 0 && fieldPermissionAgency?.IsAllowLogin[0]?.AddOK === 0 && personnelStatus ? '' : fieldPermissionAgency?.IsAllowLogin[0]?.Changeok === 0 && fieldPermissionAgency?.IsAllowLogin[0]?.AddOK === 1 && editValueList?.IsAllowLogin === '' && personnelStatus ? () => setValue({ ...value, ['IsAllowLogin']: !value?.IsAllowLogin }) : fieldPermissionAgency?.IsAllowLogin[0]?.AddOK === 1 && !personnelStatus ? () => setValue({ ...value, ['IsAllowLogin']: !value?.IsAllowLogin }) : fieldPermissionAgency?.IsAllowLogin[0]?.Changeok === 1 && personnelStatus ? () => setValue({ ...value, ['IsAllowLogin']: !value?.IsAllowLogin }) : ''
                                            : () => setValue({ ...value, ['IsAllowLogin']: !value?.IsAllowLogin })
                                        }
                                        disabled={fieldPermissionAgency?.IsAllowLogin[0] ?
                                            fieldPermissionAgency?.IsAllowLogin[0]?.Changeok === 0 && fieldPermissionAgency?.IsAllowLogin[0]?.AddOK === 0 && personnelStatus ? true : fieldPermissionAgency?.IsAllowLogin[0]?.Changeok === 0 && fieldPermissionAgency?.IsAllowLogin[0]?.AddOK === 1 && editValueList?.IsAllowLogin === '' && personnelStatus ? false : fieldPermissionAgency?.IsAllowLogin[0]?.AddOK === 1 && !personnelStatus ? false : fieldPermissionAgency?.IsAllowLogin[0]?.Changeok === 1 && personnelStatus ? false : true : false
                                        }
                                        id="IsAllowLogin" />
                                    <label className='ml-2' htmlFor="IsAllowLogin">Allow Login</label>
                                </div>
                                <div className="col-2 col-md-3 col-lg-1 mt-2">
                                    <input type="checkbox" name="IsActive" checked={value?.IsActive} value={value?.IsActive} onChange={() => {
                                        if (personnelStatus) {
                                            setValue({ ...value, ['IsActive']: !value?.IsActive });
                                        }
                                    }}
                                    />
                                    <label className='ml-2' >Active</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-2 mt-2">
                                    <input type="checkbox" name="IsJuvenileCleared" checked={value.IsJuvenileCleared} value={value.IsJuvenileCleared} onChange={() => setValue({ ...value, ['IsJuvenileCleared']: !value?.IsJuvenileCleared })}
                                    />
                                    <label className='ml-2' >Juvenile Cleared</label>
                                </div>
                                <div className="col-2 mt-2">
                                    <input type="checkbox" name="IsSupervisor" checked={value?.IsSupervisor} value={value?.IsSupervisor} onChange={() => setValue({ ...value, ['IsSupervisor']: !value?.IsSupervisor })}
                                    />
                                    <label className='ml-2' >Report Approver</label>
                                </div>
                                <div className="col-2 mt-2">
                                    <input type="checkbox" name="AllowMultipleLogins" value={value?.AllowMultipleLogins} checked={value?.AllowMultipleLogins} onChange={handleChange} />
                                    <label className='ml-2' >Multiple Login</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-12   text-right">
                            {PersonnelEffectiveScreenPermission ?
                                PersonnelEffectiveScreenPermission[0]?.Changeok && personnelStatus ?
                                    <button type="button" className="btn btn-sm btn-success" onClick={check_Validation_Error}>Update</button>
                                    :
                                    PersonnelEffectiveScreenPermission[0]?.AddOK && !personnelStatus ?
                                        <button type="button" className="btn btn-sm btn-success" onClick={check_Validation_Error}>Save</button>
                                        : <></>
                                : personnelStatus ?
                                    <button type="button" className="btn btn-sm btn-success" onClick={check_Validation_Error}>Update</button>
                                    : <button type="button" className="btn btn-sm btn-success" onClick={check_Validation_Error}>Save</button>
                            }

                        </div>
                        <IdentifyFieldColor />
                    </div>
                </div >
            </div >
            <DeletePopUpModal func={delete_Image_File} />
            <ChangesModal func={check_Validation_Error} />
        </>
    )
}

export default Home


export const changeArrayFormat = (data, type) => {
    if (type === 'division') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.DivisionID, label: sponsor.Name })
        )
        return result
    }
    if (type === 'rank') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.RankID, label: sponsor.RankDescription })
        )
        return result
    }
    if (type === 'shift') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.ShiftId, label: sponsor.ShiftDescription })
        )
        return result
    }
    if (type === 'photoType') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.PhotoTypeId, label: sponsor.PhotoType })
        )
        return result
    }
    if (type === 'genderId') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.SexCodeID, label: sponsor.Description })
        )
        return result
    }
    if (type === 'EmployeeType') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.EmployeeTypeID, label: sponsor.Description })
        )
        return result
    }
}

export const changeArrayFormat_WithFilter = (data, type) => {
    if (type === 'division') {
        const result = data.map((sponsor) =>
            ({ value: sponsor.DivisionId, label: sponsor.DivisionName })
        )
        return result[0]
    }
    if (type === 'rank') {
        const result = data.map((sponsor) =>
            ({ value: sponsor.RankID, label: sponsor.RankName })
        )
        return result[0]
    }
    if (type === 'shift') {
        const result = data.map((sponsor) =>
            ({ value: sponsor.ShiftID, label: sponsor.ShiftDescription })
        )
        return result[0]
    }
    if (type === 'EmployeeName') {
        const result = data.map((sponsor) =>
            ({ value: sponsor.EmployeeTypeID, label: sponsor.EmployeeTypeDescription })
        )
        return result[0]
    }
    if (type === 'gender') {
        const result = data.map((sponsor) =>
            ({ value: sponsor.SexID, label: sponsor.Gender_Name })
        )
        return result[0]
    }
}

