import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { fetchPostData, AddDeleteUpadate, ScreenPermision, fetchData } from '../../../../hooks/Api';
import { Decrypt_Id_Name, getShowingDateText, getShowingMonthDateYear, tableCustomStyles } from '../../../../Common/Utility';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import Loader from '../../../../Common/Loader';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import DatePicker from "react-datepicker";
import Select from "react-select";
import { RequiredFieldIncident, SpaceCheck } from '../../../Utility/Personnel/Validation';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
const Pin = (props) => {

  const { incidentReportedDate } = props
  const { get_IncidentTab_Count, localStoreArray, get_LocalStorage, } = useContext(AgencyContext);
  const [activityDate, setActivityDate] = useState();
  const [pindata, setPindata] = useState();
  const [status, setStatus] = useState(false);
  const [modal, setModal] = useState(false)
  const [pinActivityID, setPinActivityID] = useState();
  const [updateStatus, setUpdateStatus] = useState(0)
  const [incidentPinActivityID, setIncidentPinActivityID] = useState('');
  const [loder, setLoder] = useState(false)
  const [shiftList, setShiftList] = useState();
  const [editval, setEditval] = useState();
  const [clickedRow, setClickedRow] = useState(null);

  //screen permission 
  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState();
  const [headOfAgency, setHeadOfAgency] = useState([]);
  const [pinActivityStatusDrpVal, setPinActivityStatusDrpVal] = useState([]);
  const [pinActivityRoleIdDrpVal, setPinActivityRoleIdDrpVal] = useState([]);
  const [loginAgencyID, setLoginAgencyID] = useState('')
  const [incidentID, setIncidentID] = useState('')
  const [loginPinID, setLoginPinID] = useState('');
  // Check All Field Format is True Then Submit 
  const [value, setValue] = useState({
    'ActivityDateTime': '', 'PINActivityStatusID': null, 'PINActivityRoleID': null, 'PINActivityRoleName': '', 'OfficerPINID': null,
    'ShiftID': null, 'ShiftName': '', 'ResourceNumber': '',
    'IncidentId': '',
    'CreatedByUserFK': '',
    'ModifiedByUserFK': '',
    'IncidentPINActivityID': null,
  })

  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: '' }),
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID || !localStoreArray.IncidentID) {
      get_LocalStorage(localStore);
    }
  }, []);

  // Onload Function
  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray.AgencyID && localStoreArray.PINID) {
        setLoginAgencyID(localStoreArray?.AgencyID);
        setLoginPinID(localStoreArray?.PINID);
        if (localStoreArray.IncidentID) {
          setIncidentID(parseInt(localStoreArray?.IncidentID));
          get_Pin_Data(localStoreArray?.IncidentID);
        } else {
          setIncidentID(''); get_Pin_Data('');
        }
        getScreenPermision(localStoreArray?.AgencyID, localStoreArray?.PINID);
      }
    }
  }, [localStoreArray])
  useEffect(() => {
    get_pin_Activity_Status(); get_pin_Activity_Role(); get_Head_Of_Agency(loginAgencyID); get_Shift(loginAgencyID);
  }, [loginAgencyID])

  ///-------GET SHIFT-----///
  const get_Shift = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('MasterPersonnel/GetData_Shift', val).then((data) => {
      if (data) {
        setShiftList(changeArrayFormat(data, 'shiftListVal'));
      }
      else {
        setShiftList([])
      }
    })
  };

  //------------OFFICERPINID-------------//
  const get_Head_Of_Agency = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
      if (data) {
        setHeadOfAgency(changeArrayFormat(data, 'HeadOfAgencyVal'));
      }
      else {
        setHeadOfAgency([])
      }
    })
  };
  const [errors, setErrors] = useState({
    'ActivityDateTimeError': '', 'ActivityRoleStatusError': '', 'OfficerPinError': '', 'ResourceNumberError': '',
  })

  useEffect(() => {
    if (incidentID) { setValue({ ...value, 'IncidentId': incidentID, 'CreatedByUserFK': loginPinID }) }
  }, [incidentID, updateStatus]);

  useEffect(() => {
    if (pinActivityID) {
      GetSingleData(pinActivityID)
    }
  }, [pinActivityID])

  const GetSingleData = (pinActivityID) => {
    const val = { 'IncidentPINActivityID': pinActivityID }
    fetchPostData('PINActivity/GetSingleData_PINActivity', val)
      .then((res) => {
        if (res) setEditval(res)
        else setEditval()
      })
  }
  useEffect(() => {
    if (status) {
      setValue({
        ...value,
        'IncidentPINActivityID': pinActivityID,
        'ActivityDateTime': editval[0]?.ActivityDateTime ? getShowingDateText(editval[0]?.ActivityDateTime) : ' ',
        "ResourceNumber": editval[0]?.ResourceNumber,
        'PINActivityStatusID': editval[0]?.PINActivityStatusID, 'PINActivityRoleID': editval[0]?.PINActivityRoleID,
        'OfficerPINID': editval[0]?.OfficerPINID, 'ShiftID': editval[0]?.ShiftID,
        'ModifiedByUserFK': loginPinID,
      })
      setActivityDate(editval[0]?.ActivityDateTime ? new Date(editval[0]?.ActivityDateTime) : '');
    }
    else {
      setValue({
        ...value,
        'PINActivityStatusID': null, 'IncidentId': incidentID, 'CreatedByUserFK': loginPinID, 'PINActivityRoleID': null, 'ShiftID': null, 'ModifiedByUserFK': '', 'ResourceNumber': '', 'ActivityDateTime': '',
      })
    }
  }, [editval])
  //------------PinActivityStatus----------------//
  const get_pin_Activity_Status = () => {
    fetchData('PINActivity/GetData_PINActivityType').then((data) => {
      setPinActivityStatusDrpVal(Comman_changeArrayFormat(data, 'ActivityTypeID', 'Description'));
    })
  }

  //-----------PinActivityRole------------------// 
  const get_pin_Activity_Role = () => {
    fetchData('PINActivity/GetData_ActivityRole').then((data) => {
      setPinActivityRoleIdDrpVal(changeArrayFormat(data, 'PINActivityRoleVal'));
    })
  }
  const get_Pin_Data = (incidentID) => {
    const val = {
      'IncidentId': incidentID,
    }
    fetchPostData('PINActivity/GetData_PinActivity', val).then((res) => {
      if (res) {
        console.log(res)
        setPindata(res); setLoder(true);
      } else {
        setPindata([]); setLoder(true);
      }
    })
  }
  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.PINActivityRoleID)) {
      setErrors(prevValues => { return { ...prevValues, ['ActivityRoleStatusError']: RequiredFieldIncident(value.PINActivityRoleID) } })
    }
    if (RequiredFieldIncident(value.ActivityDateTime)) {
      setErrors(prevValues => { return { ...prevValues, ['ActivityDateTimeError']: RequiredFieldIncident(value.ActivityDateTime) } })
    }
    if (RequiredFieldIncident(value.OfficerPINID)) {
      setErrors(prevValues => { return { ...prevValues, ['OfficerPinError']: RequiredFieldIncident(value.OfficerPINID) } })
    }
    if (SpaceCheck(value.ResourceNumber)) {
      setErrors(prevValues => { return { ...prevValues, ['ResourceNumberError']: SpaceCheck(value.ResourceNumber) } })
    }
  }
  // Check All Field Format is True Then Submit 
  const { ActivityDateTimeError, ActivityRoleStatusError, OfficerPinError, ResourceNumberError } = errors

  useEffect(() => {
    if (ActivityDateTimeError === 'true' && ActivityRoleStatusError === 'true' && OfficerPinError === 'true' && ResourceNumberError === 'true') {
      if (status) update_Activity()
      else Add_Type()
    }
  }, [ActivityDateTimeError, ActivityRoleStatusError, OfficerPinError, ResourceNumberError])
  const Add_Type = (e) => {
    AddDeleteUpadate('PINActivity/InsertPINActiivty', value)
      .then((res) => {
        get_Pin_Data(incidentID);
        get_IncidentTab_Count(incidentID);
        setStatusFalse()
        toastifySuccess(res.Message);
        setErrors({
          ['ActivityDateTimeError']: '',
        });
      })
  }

  const update_Activity = () => {
    AddDeleteUpadate('PINActivity/UpdatePinActivity', value).then((res) => {
      toastifySuccess(res.Message);
      // setModal(false);
      setStatus(true);
      setStatusFalse();
      get_Pin_Data(incidentID);
    })
  }
  const getScreenPermision = (LoginAgencyID, PinID) => {
    ScreenPermision("I028", LoginAgencyID, PinID).then(res => {
      if (res) {
        setEffectiveScreenPermission(res)
      } else {
        setEffectiveScreenPermission([])
      }
    });
  }
  const reset = () => {
    setValue({
      ...value,
      'PINActivityStatusID': null, 'PINActivityRoleID': null, 'OfficerPINID': null, 'ModifiedByUserFK': '', 'ResourceNumber': '', 'ShiftID': null, 'ActivityDateTime': '',
    }); setActivityDate("")
    setErrors({
      ...errors,
      'ActivityDateTimeError': '', 'ActivityRoleStatusError': '', 'OfficerPinError': '', 'ResourceNumberError': '',
    });
  }
  const startRef = React.useRef();
  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const columns = [
    // {
    //   width: '100px',
    //   name: <p className='text-end' style={{ position: 'absolute', top: 8, }}>Action</p>,
    //   cell: row =>
    //     <div className="div" style={{ position: 'absolute', top: 4, left: 20 }}>
    //       {
    //         effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok ?
    //           <Link to={''} onClick={(e) => { set_Edit_Value(row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#PinModal" >
    //             <i className="fa fa-edit"></i>
    //           </Link>
    //           : <></>
    //           : <Link to={''} onClick={(e) => { set_Edit_Value(row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#PinModal" >
    //             <i className="fa fa-edit"></i></Link>
    //       }
    //     </div>

    // },
    {
      width: '150px',
      name: 'Date/Time',
      selector: (row) => getShowingDateText(row.ActivityDateTime),
      sortable: true
    },
    {
      width: '200px',
      name: 'Activity Details',
      selector: (row) => row.ActivityStatus,
      sortable: true
    },
    {
      name: 'Role',
      selector: (row) => row.ActivityRole,
      sortable: true
    },
    {
      name: 'Officer',
      selector: (row) => row.OfficerName,
      sortable: true
    },
    {
      name: 'Module',
      selector: (row) => row.ResourceNumber,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 10 }}>Delete</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', top: 4, right: 10 }}>
          {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
              <Link to={`#`} onClick={() => { setIncidentPinActivityID(row.IncidentPINActivityID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </Link>
              : <></>
              : <Link to={`#`} onClick={() => { setIncidentPinActivityID(row.IncidentPINActivityID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </Link>
          }
        </div>

    }
  ]
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value
    })
  }
  const set_Edit_Value = (row) => {
    setStatus(true);
    // setModal(true)
    setUpdateStatus(updateStatus + 1);
    setPinActivityID(row.IncidentPINActivityID);
    // get_Pin_Data(incidentID);
  }

  const DeletePin = () => {
    const val = {
      'IncidentPINActivityID': incidentPinActivityID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate('PINActivity/DeletePinActivity', val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_Pin_Data(incidentID);
        get_IncidentTab_Count(incidentID);
        setStatus(false);
        reset();
      } else console.log("Somthing Wrong");
    })
  }

  const setStatusFalse = (e) => {
    setStatus(false);
    setModal(true);
    reset();
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };
  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else setValue({
      ...value,
      [name]: null
    })
  }

  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];
  return (
    <>
      <div className="col-12">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Date/Time</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <DatePicker
                  ref={startRef}
                  onKeyDown={onKeyDown}
                  id='ActivityDateTime'
                  name='ActivityDateTime'
                  className='requiredColor'
                  dateFormat="MM/dd/yyyy HH:mm"
                  onChange={(date) => { setActivityDate(date); setValue({ ...value, ['ActivityDateTime']: date ? getShowingMonthDateYear(date) : null }) }}
                  timeInputLabel
                  isClearable={value?.ActivityDateTime ? true : false}
                  placeholderText={value?.ActivityDateTime ? value?.ActivityDateTime : 'Select...'}
                  selected={activityDate}
                  showTimeSelect
                  timeIntervals={1}
                  timeCaption="Time"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={new Date()}
                  minDate={new Date(incidentReportedDate)}
                  showDisabledMonthNavigation
                  autoComplete='off'
                />
                {errors.ActivityDateTimeError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ActivityDateTimeError}</span>
                ) : null}
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <label htmlFor="" className='new-label'>User PIN</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <Select
                  name='OfficerPINID'
                  styles={colourStyles}
                  value={headOfAgency?.filter((obj) => obj.value === value?.OfficerPINID)}
                  isClearable
                  options={headOfAgency}
                  onChange={(e) => ChangeDropDown(e, 'OfficerPINID')}
                  placeholder="Select..."
                />
                {errors.OfficerPinError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.OfficerPinError}</span>
                ) : null}
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <label htmlFor="" className='new-label'>Activity Details</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 mb-1">
                <Select
                  name='PINActivityStatusID'
                  styles={customStylesWithOutColor}
                  isClearable
                  value={pinActivityStatusDrpVal?.filter((obj) => obj.value === value?.PINActivityStatusID)}
                  options={pinActivityStatusDrpVal}
                  onChange={(e) => ChangeDropDown(e, 'PINActivityStatusID')}
                  placeholder="Select.."
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Module</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 pt-1 text-field ">
                <input type="text" name='ResourceNumber' value={value.ResourceNumber} onChange={handleChange} required />
                {errors.ResourceNumberError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ResourceNumberError}</span>
                ) : null}
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <label htmlFor="" className='new-label'>Role</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <Select
                  name='PINActivityRoleID'
                  styles={colourStyles}
                  isClearable
                  value={pinActivityRoleIdDrpVal?.filter((obj) => obj.value === value?.PINActivityRoleID)}
                  options={pinActivityRoleIdDrpVal}
                  onChange={(e) => ChangeDropDown(e, 'PINActivityRoleID')}
                  placeholder="Select.."
                />
                {errors.ActivityRoleStatusError !== 'true' ? (
                  <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ActivityRoleStatusError}</span>
                ) : null}
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Select Shift</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 pt-1  mb-1">
                <Select
                  name='ShiftName'
                  styles={customStylesWithOutColor}
                  isClearable
                  value={shiftList?.filter((obj) => obj.value === value?.ShiftID)}
                  options={shiftList}
                  onChange={(e) => ChangeDropDown(e, 'ShiftID')}
                  placeholder="Select.."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn-box text-right mt-5 mr-1 bb">
        <button type="button" className="btn btn-sm btn-success mr-1 mb-2" onClick={() => { setStatusFalse(); setUpdateStatus(updateStatus + 1); }}>New</button>
        {
          status ?
            <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1 mb-2">Update</button>
            :
            <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1 mb-2">Save</button>
        }
        {/* <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1 mb-2" >Close</button> */}
      </div>
      <div className="col-md-12 mt-2 px-0 container-fluid" style={{ height: '160px', overflowY: 'scroll' }}>
        {
          loder ?
            <DataTable
              dense
              columns={columns}
              data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? pindata : '' : pindata}
              // pagination
              // paginationPerPage={'5'}
              // paginationRowsPerPageOptions={[5]}
              highlightOnHover
              customStyles={tableCustomStyles}
              onRowClicked={(row) => {
                setClickedRow(row);
                set_Edit_Value(row);
              }}
              conditionalRowStyles={conditionalRowStyles}
            />
            :
            <Loader />
        }
      </div>
      <DeletePopUpModal func={DeletePin} />
      {/* {/ <PinAddUp {...{ incidentReportedDate, get_LocalStorage, loginPinID, incidentID, loginAgencyID, status, setStatus, pinActivityID, setPinActivityID, setModal, modal, get_Pin_Data, updateStatus }} />  */}
    </>
  )
}
export default Pin

export const changeArrayFormat = (data, type) => {

  if (type === 'PINActivityRoleVal') {
    const result = data?.map((sponsor) =>
      ({ value: sponsor.ActivityTypeID, label: sponsor.Description })
    )
    return result
  }
  if (type === 'HeadOfAgencyVal') {
    const result = data?.map((sponsor) =>
      ({ value: sponsor.PINID, label: sponsor.HeadOfAgency })
    )
    return result
  }
  if (type === 'shiftListVal') {
    const result = data?.map((sponsor) =>
      ({ value: sponsor.ShiftId, label: sponsor.ShiftDescription })
    )
    return result
  }
}


export const changeArrayFormat_WithFilter = (data, type, DropDownValue) => {
  if (DropDownValue) {
    if (type === 'PINActivityRoleVal') {
      const result = data?.map((sponsor) =>
        (sponsor.PINActivityRoleID)
      )
      const result2 = DropDownValue?.map((sponsor) => {
        if (sponsor.value === result[0]) {
          return { value: result[0], label: sponsor.label }
        }
      })
      const val = result2.filter(function (element) {
        return element !== undefined;
      });
      return val[0]
    }
    if (type === 'HeadOfAgencyVal') {
      const result = data?.map((sponsor) =>
        (sponsor.OfficerPINID)
      )
      const result2 = DropDownValue?.map((sponsor) => {
        if (sponsor.value === result[0]) {
          return { value: result[0], label: sponsor.label }
        }
      })
      const val = result2.filter(function (element) {
        return element !== undefined;
      });
      return val[0]
    }
    if (type === 'shiftListVal') {
      const result = data?.map((sponsor) =>
        (sponsor.ShiftID)
      )
      const result2 = DropDownValue?.map((sponsor) => {
        if (sponsor.value === result[0]) {
          return { value: result[0], label: sponsor.label }
        }
      })
      const val = result2.filter(function (element) {
        return element !== undefined;
      });
      return val[0]
    }
  }
}