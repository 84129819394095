import React, { useContext, useEffect, useState } from 'react';
import { AgencyContext } from '../../../Context/Agency/Index';
import Tab from '../../Utility/Tab/Tab';
import { Link } from 'react-router-dom';
import SubTab from '../../Utility/Tab/SubTab';
import { IncidentTabs } from '../../Utility/Tab/TabsArray';
import Pin from './IncidentTab/PIN/Pin';
import TypeOfSecurity from './IncidentTab/TypeOfSecurity/TypeOfSecurity';
import DispatchActivity from './IncidentTab/DispatchActivity/DispatchActivity';
import Narrative from './IncidentTab/NarrativesCom/Narrative';
import LocationHistory from './IncidentTab/LocationHistory/LocationHistory';
import IncidentHome from './IncidentTab/IncidentHome';

const IncidentTab = () => {

    const { incidentStatus, localStoreArray, get_LocalStorage, inActiveStatus, changesStatus } = useContext(AgencyContext);
    const [status, setStatus] = useState();
    const [showIncPage, setShowIncPage] = useState('home');
    const [incidentReportedDate, setIncidentReportedDate] = useState(null);
    const iconHome = <i className="fa fa-home" style={{ fontSize: '20px' }}></i>

    useEffect(() => {
        if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
            get_LocalStorage();
        }
    }, []);
// console.log(localStoreArray)
    useEffect(() => {
        if (incidentStatus) {
            setStatus(incidentStatus)
        } else {
            setStatus(localStoreArray?.IncidentStatus === true || localStoreArray?.IncidentStatus === "True" ? true : false)
        }
        setShowIncPage('home')
    }, [incidentStatus]);

    return (
        <div className="section-body view_page_design pt-1 p-1 bt" >
            <div className="div">
                <div className="col-12  inc__tabs">
                    <Tab />
                </div>
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card ">
                            <div className="card-body" >
                                <div className="row " style={{ marginTop: '-18px', marginLeft: '-18px' }}>
                                    <div className="col-12   incident-tab">
                                        {/* <SubTab tabs={IncidentTabs}  showPage={showIncPage} setShowPage={setShowIncPage} /> */}
                                        <ul className='nav nav-tabs'>
                                            <Link className={`nav-item ${showIncPage === 'home' ? 'active' : ''} ${!status || inActiveStatus ? 'disabled' : ''}`} to={'/Inc-Home'} data-toggle={changesStatus ? "modal" : "pill"} data-target={changesStatus ? "#SaveModal" : ''} style={{ color: showIncPage === 'home' ? 'Red' : 'gray' }} aria-current="page" onClick={() => {
                                                if (!changesStatus) setShowIncPage('home')
                                            }}>
                                                {iconHome}
                                            </Link>
                                            <Link className={`nav-item ${showIncPage === 'Pin' ? 'active' : ''} ${!status || inActiveStatus ? 'disabled' : ''}`} to={'/Inc-Home?page=Pin'} data-toggle={changesStatus ? "modal" : "pill"} data-target={changesStatus ? "#SaveModal" : ''} style={{ color: showIncPage === 'Pin' ? 'Red' : 'gray' }} aria-current="page" onClick={() => {
                                                if (!changesStatus) setShowIncPage('Pin')
                                            }}
                                            >
                                                Pin
                                            </Link>

                                            <Link className={`nav-item ${showIncPage === 'TypeOfSecurity' ? 'active' : ''} ${!status || inActiveStatus ? 'disabled' : ''}`} to={'/Inc-Home?page=TypeOfSecurity'} data-toggle={changesStatus ? "modal" : "pill"} data-target={changesStatus ? "#SaveModal" : ''} style={{ color: showIncPage === 'type of security' ? 'Red' : 'gray' }} aria-current="page" onClick={() => {
                                                if (!changesStatus) setShowIncPage('type of security')
                                            }} >
                                                Type Of Security
                                            </Link>
                                            <Link className={`nav-item ${showIncPage === 'DispatchActivity' ? 'active' : ''} ${!status || inActiveStatus ? 'disabled' : ''}`} to={'/Inc-Home?page=DispatchActivity'} data-toggle={changesStatus ? "modal" : "pill"} data-target={changesStatus ? "#SaveModal" : ''} style={{ color: showIncPage === 'dispatch activity' ? 'Red' : 'gray' }} aria-current="page" onClick={() => {
                                                if (!changesStatus) setShowIncPage('dispatch activity')
                                            }} >
                                                Dispatch Activity
                                            </Link>
                                            <Link className={`nav-item ${showIncPage === 'Report' ? 'active' : ''} ${!status || inActiveStatus ? 'disabled' : ''}`} to={'/Inc-Home?page=Report'} data-toggle={changesStatus ? "modal" : "pill"} data-target={changesStatus ? "#SaveModal" : ''} style={{ color: showIncPage === 'Report' ? 'Red' : 'gray' }} aria-current="page" onClick={() => {
                                                if (!changesStatus) setShowIncPage('Report')
                                            }} >
                                                Report
                                            </Link>

                                            <Link className={`nav-item ${showIncPage === 'LocationHistory' ? 'active' : ''} ${!status || inActiveStatus ? 'disabled' : ''}`} to={'/Inc-Home?page=LocationHistory'} data-toggle={changesStatus ? "modal" : "pill"} data-target={changesStatus ? "#SaveModal" : ''} style={{ color: showIncPage === 'location history' ? 'Red' : 'gray' }} aria-current="page" onClick={() => {
                                                if (!changesStatus) setShowIncPage('location history')
                                            }}>
                                                Location History
                                            </Link>

                                        </ul>
                                    </div>
                                </div>
                                {
                                    showIncPage === 'home' ?
                                        <IncidentHome {...{ incidentReportedDate, setIncidentReportedDate }} />
                                        :
                                        showIncPage === 'Pin' ?
                                            <Pin {...{ incidentReportedDate }} />
                                            :
                                            showIncPage === 'type of security' ?
                                                <TypeOfSecurity />
                                                :
                                                showIncPage === 'dispatch activity' ?
                                                    <DispatchActivity />
                                                    :
                                                    showIncPage === 'Report' ?
                                                        <Narrative {...{ incidentReportedDate }} />
                                                        :
                                                        showIncPage === 'location history' ?
                                                            <LocationHistory />
                                                            :
                                                            <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default IncidentTab