import axios from "axios";

// ------get API Request
export const fetchData = async (url) => {
    try {
        const res = await axios.get(url);
        const decr = res.data.data
        const TextData = JSON.parse(decr)
        return TextData.Table
    } catch (error) {
        if (error.response) {
            console.log(`${error.response?.request?.responseURL} -- ${error.response?.data?.Message}`)
        }
    }
};
//------------By DK
export const fetchPostData = async (url, postData) => {
    try {
        const res = await axios.post(url, postData);
        const TextData = JSON.parse(res.data.data);
        return TextData.Table
    } catch (error) {
        // console.log(error)
        if (error.response) {
            console.log(`${error.response?.request?.responseURL} -- ${error.response?.data?.Message}`)
        }
        return []
    }
};
export const fetch_Post_Data = async (url, postData) => {
    let Data
    let Permision
    try {
        const res = await axios.post(url, postData);
        const decr = res.data.data
        const TextData = JSON.parse(decr)
        Permision = TextData.Table1
        Data = TextData.Table
        return { Data, Permision }
    } catch (error) {
        if (error.response) {
            console.log(`${error.response?.request?.responseURL} -- ${error.response?.data?.Message}`)
        }
    }
};
// --------ADD Update Delete Data  With API Post Request
export const AddDeleteUpadate = async (url, postData) => {
    const res = await axios.post(url, postData);
    // console.log(res)
    if (res.code === 'ERR_BAD_REQUEST') {
        return res
    } else {
        return res.data;
    }
}
// Agency Permision
export const ScreenPermision = async (code, agencyId, PinID) => {
    const val = {
        PINID: PinID,
        ApplicationID: '1',
        code: code,
        AgencyID: agencyId
    }
    const res = await axios.post("EffectivePermission/GetData_EffectiveScreenPermission", val);
    const decr = res.data.data
    const TextData = JSON.parse(decr)
    return TextData.Table
}
// Utility Personnel Screen Permision
export const UtilityPersonnelScreenPermision = async (code, tableId) => {
    const val = {
        PINID: '',
        TableCode: code,
        TableId: tableId
    }
    const res = await axios.post("TablePermission/GetData_SingleGroupTablePermission", val);
    const decr = res?.data?.data
    const TextData = JSON.parse(decr)
    return TextData.Table
}
export const fieldPermision = async (AgencyID, ScreenCode, pinId) => {
    const val = {
        AgencyID: AgencyID,
        ScreenCode: ScreenCode,
        PINID: pinId,
    }
    const res = await axios.post("EffectivePermission/GetData_EffectiveFieldPermission_Validate", val);
    const decr = res.data.data
    const TextData = JSON.parse(decr)
    return TextData.Table
}
export const fetchProgresData = async (url, postData) => {
    try {
        const res = await axios.post(url, postData);
        return res.data
    } catch (error) {
        if (error.response) {
            console.log(`${error.response?.request?.responseURL} -- ${error.response?.data?.Message}`)
        }
    }
};




