import React, { useEffect, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AgencyContext } from '../../Context/Agency/Index';
import { Decrypt_Id_Name } from "../Common/Utility";
import IncSidebar from "./SidebarCom/IncSidebar";
import DashboardSidebar from "./SidebarCom/DashboardSidebar";
import ListMangSidebar from "./SidebarCom/ListMangSidebar";
import AgencySidebar from "./SidebarCom/AgencySidebar";
import PersonnelSidebar from "./SidebarCom/PersonnelSidebar";
import { NameSearchSidebar } from "./SidebarCom/NameSearchSidebar";
import ArrestSearch from "./SidebarCom/ArrestSearch";
import ReportSidebar from "./SidebarCom/ReportSidebar";
import SearchSidebar from "./SidebarCom/SearchSidebar";
import arrest from '../../img/arrest.png'
import warrant from '../../img/warrant.png'
import Header from "./Header";
const CryptoJS = require("crypto-js");

const Sidebar = (props) => {

  const { getAgency, get_Personnel_Lists, get_CountList, inActiveStatus, changesStatus, localStoreArray, get_LocalStorage, } = useContext(AgencyContext)

  const { listManagementSideBar, agencySideBar, personnelSideBar, path, incidentSideBar, dashboardSidebar, nameSearchSideBar, arrestSearchSideBar, reportSidebar, searchSidebar } = props

  const [offcanvasActive, setOffcanvasActive] = useState(false);
  useEffect(() => {
    const storedOffcanvasState = sessionStorage.getItem('offcanvasActive');
    if (storedOffcanvasState) {
      setOffcanvasActive(JSON.parse(storedOffcanvasState));

    }
  }, []);

  const toggleOffcanvas = () => {
    const newState = !offcanvasActive;
    setOffcanvasActive(newState);
    sessionStorage.setItem('offcanvasActive', JSON.stringify(newState));
    document.body.classList.toggle('offcanvas-active', newState);
  };
  const useQuery = () => new URLSearchParams(useLocation().search);
  let aId = useQuery().get('id');
  let openPage = useQuery().get('page');

  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", PINID: "", })
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage(localStore);
    }
  }, []);


  useEffect(async () => {
    if (path === '/agencyTab') {
      if (!inActiveStatus) {
        await getAgency();
        if (aId.split(" ", 3)[0].split("/", 1)[0].substring(10,) != '000') {
          get_CountList(aId.split(" ", 3)[0].split("/", 1)[0].substring(10,))
        }
      }
    } else if (path === '/personnelTab') {
      if (aId && !inActiveStatus) get_Personnel_Lists(aId.split(" ", 3)[0].split("/", 1)[0].substring(10,));
    }
    else if (path === '/ListManagement');
    else if (path === '/Inc-Home');
  }, [aId, path])

  const incpath = 'Report' || 'LocationHistory' || 'DispatchActivity' || 'TypeOfSecurity' || 'Pin'

  return (
    <>
      {/* <div id="header_top" className="header_top dark">
        <div className="container">
          <div className="hleft">
            <Link to="/dashboard-page" className="header-brand">
              <i className="fa fa-soccer-ball-o brand-logo"></i>
            </Link>
            <div className="dropdown">
              <Link to={changesStatus ? '#' : "/incident"} className="nav-link icon xs-hide" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>
                <p className="p-0 m-0 ">
                  <i className="fa fa-stack-exchange " aria-hidden="true"></i>
                </p>
                <p className="side-p">
                  Incident
                </p>
              </Link>

              <Link to={changesStatus ? '#' : "/namesearch"} className="nav-link icon app_inbox xs-hide" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>

                <p className="p-0 m-0 ">
                  <i className="fa fa-user side-icon"></i>
                </p>
                <p className="side-p">
                  Name
                </p>
              </Link>

              <Link to={changesStatus ? '#' : "/property-search"} className="nav-link icon app_inbox xs-hide" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>

                <p className="p-0 m-0 ">
                  <i className="fa fa-building side-icon"></i>
                </p>
                <p className="side-p">
                  Property
                </p>
              </Link>
              <Link to={changesStatus ? '#' : "/vehicle-search"} className="nav-link icon app_inbox xs-hide" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>

                <p className="p-0 m-0 ">
                  <i className="fa fa-car side-icon"></i>
                </p>
                <p className="side-p">
                  Vehicle
                </p>
              </Link>


              <Link to={changesStatus ? '#' : "/warrant-search"} className="nav-link icon app_inbox xs-hide" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >

                <p className="p-0 m-0 ">
                  <img src={warrant} alt="" className="img-icon " style={{ width: '22px' }} />
                </p>
                <p className="side-p">
                  Warrant
                </p>
              </Link>
              <Link to={changesStatus ? '#' : "/arrest-search"} className="nav-link icon app_inbox xs-hide" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}  >

                <p className="p-0 m-0 ">
                  <img src={arrest} alt="" className="img-icon " style={{ width: '22px' }} />
                </p>
                <p className="side-p">
                  Arrest
                </p>
              </Link>

              <Link to={changesStatus ? '#' : "/field-interview-search"} className="nav-link icon app_inbox xs-hide" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>

                <p className="side-p">
                  Field Interview
                </p>
              </Link>

              <Link to={changesStatus ? '#' : "/ReportsMain"} className="nav-link icon app_inbox xs-hide" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>

                <p className="p-0 m-0 ">
                  <i className="fa fa-file side-icon"></i>
                </p>
                <p className="side-p">
                  Report
                </p>
              </Link>
              <Link to={changesStatus ? '#' : "/Search"} className="nav-link icon app_inbox xs-hide" data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>
                <p className="p-0 m-0 ">
                  <i className="fa fa-search side-icon"></i>
                </p>
                <p className="side-p">
                  Search
                </p>
              </Link>
            </div>
          </div>
       
          <span style={{ cursor: 'pointer' }} onClick={toggleOffcanvas}>
            <i className="fa fa-align-left icon "></i>
          </span>
        </div>
      </div> */}
      <div id="left-sidebar" className="sidebar ">
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <div className="">
            <h5 className="brand-name text-center" >
              GoldLine{" "}
              {/* <span className=" float-right" onClick={toggleOffcanvas} style={{ cursor: 'pointer' }}>
            <i className="fa fa-align-left icon "></i>
          </span> */}
            </h5>
          </div>
          <ul className="metismenu ">
            {
              dashboardSidebar && <DashboardSidebar />
            }
            {/* {
              incidentSideBar ? openPage === 'clear' || openPage === incpath || !openPage ? <IncSidebar /> : <></> : <></>
            } */}
            {
              incidentSideBar && <IncSidebar />
            }
            {
              agencySideBar && <AgencySidebar />
            }
            {
              personnelSideBar && <PersonnelSidebar />
            }
            {
              listManagementSideBar && <ListMangSidebar />
            }
            {
              nameSearchSideBar && openPage === 'mastername' && <NameSearchSidebar />
            }
            {
              arrestSearchSideBar && openPage === 'ArrestSearch' && <ArrestSearch />
            }
            {
              path === "/namesearch" && <NameSearchSidebar />
            }
            {
              reportSidebar && <ReportSidebar />
            }
            {
              searchSidebar && <SearchSidebar />
            }
          </ul>
        </nav>
      </div>
    </>
  );
};


export default Sidebar;
