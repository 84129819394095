import moment from "moment";

export const ReqTrim = (field) => {
	if (field.trim() == '') {
		return !field.trim() == ''
	} else {
		return true
	}
}

export const RequiredFieldIncident = (field) => {
	if (!field || field?.length === 0 || field === '' || field === null || field === undefined || field === 0 || field === "Invalid date") {
		return 'Required *';
	} else {
		return 'true'
	}
};

export const RequiredFieldOnConditon = (field) => {
	if (field?.length === 0 || field === '' || field === undefined || field === 0) {
		return 'Required *';
	} else {
		return 'true'
	}
};

export const RequiredFieldArrestee = (field) => {
	if (field === '' || field === null || field === undefined) {
		return 'Required *';
	}
	else {
		return 'true'
	}
};

export const RequiredField = (field) => {
	if (!field || field === null || field === "Invalid date") {
		return 'Required *';
	} else if (field.match(/^[a-zA-Z0-9\s]+$/)) {
		return 'true';
	} else {
		return 'Space Not Allow';
	}
};

export const SpaceCheck = (field) => {
	if (!field || field === null) {
		return 'true';
	} else if (field.match(/^[a-zA-Z0-9\s]+$/)) {
		return 'true';
	} else {
		return 'Space Not Allow';
	}
};

export const Space_Allow_with_Trim = (field) => {
	if (!field || field === null || field.trim() === '') {
		return 'Required *';
	}
	else if (field.match(/[^a-zA-Z0-9\s]/)) {
		return 'true';
	}
	else {
		return 'Space Not Allow';
	}
};

export const checkDateIsAfter = (fromDate, ToDate) => {
	const date1 = moment(fromDate);
	const date2 = moment(ToDate);
	if (date2.isAfter(date1) || date1.isSame(date2)) {
		return date2.isAfter(date1) || date1.isSame(date2)
	} else {
		return false
	}
}


// DL validation //

export const validateDLNumber = (field) => {
	if (!field || field?.length === 0 || field === '' || field === null || field === undefined || field === 0 || field === "Invalid date") {
		return 'Required *';
	} else {
		return 'true'
	}
};

