import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, getShowingWithOutTime } from "../../../../Common/Utility";
import { Comman_changeArrayFormat_With_Name } from "../../../../Common/ChangeArrayFormat";
import { AddDeleteUpadate, fetchData, fetchPostData } from "../../../../hooks/Api";
import { toastifySuccess } from "../../../../Common/AlertMsg";
import { NaGeneralListDropDownArray } from "../../../../Utility/ListDropDownArray/ListDropArray";
import FindListDropDown from "../../../../Common/FindListDropDown";
import { AgencyContext } from "../../../../../Context/Agency/Index";
import { validateDLNumber } from "../../../Utility/Personnel/Validation"

const General = () => {

  const { localStoreArray, get_LocalStorage, } = useContext(AgencyContext);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let openPage = useQuery().get('page');

  const [verifyIdDrp, setVerifyIdDrp] = useState([]);
  const [biVerifyIDDrp, setBiVerifyIDDrp] = useState([]);
  const [editval, setEditval] = useState();
  const [eyeColoIDDrp, setEyeColoIDDrp] = useState([]);
  const [hairColorIDDrp, setHairColorIDDrp] = useState([]);
  const [maritalStatusIDDrp, setMaritalStatusIDDrp] = useState([]);
  const [residentIDDrp, setResidentIDDrp] = useState([]);

  //------country-------//
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [dlCountryIDList, setDlCountryIDList] = useState([]);
  const [biCountryIDList, setBiCountryIDList] = useState([]);
  const [biStateList, setBiStateList] = useState([]);

  //------------------Ids---------------------
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [masterNameID, setMasterNameID,] = useState('');
  const [nameID, setNameID] = useState();

  const [value, setValue] = useState({
    //-------dropDown------//
    'DLVerifyID': null, 'BICountryID': null, 'BIStateID': null,
    'BIVerifyID': null, 'EyeColorID': null, 'HairColorID': null, 'ResidentID': null, 'MaritalStatusID': null,
    //------date--------//
    'DLExpiryDate': null,
    //------text------//
    'DLNumber': "", 'IsUSCitizen': "", 'BirthPlace': "", 'BINationality': "",
    'DLStateID': null, 'DLCountryID': null, "BICityID": null,
    'NameID': '', 'MasterNameID': '', 'CreatedByUserFK': '', 'ModifiedByUserFK': '',
  });

  const [errors, setErrors] = useState({
    DLNumber: '',
    DLStateID: ''
  });
  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", PINID: "", MasterNameID: '', NameID: '', Agency_Name: "", }),
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage(localStore);
    }
  }, []);

  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
        setLoginAgencyID(localStoreArray?.AgencyID);
        setNameID(localStoreArray?.NameID); setMasterNameID(localStoreArray?.masterNameID)
      }
    }
  }, [localStoreArray])

  useEffect(() => {
    setValue(pre => { return { ...pre, 'CreatedByUserFK': localStoreArray?.PINID, 'MasterNameID': localStoreArray?.masterNameID, 'NameID': localStoreArray?.NameID } });
  }, [nameID, masterNameID]);


  useEffect(() => {
    if (openPage === 'mastername') {
      console.log(openPage)
    } else if (value.NameID) {
      get_Single_Data(value.NameID);
    }
  }, [value.NameID, value.masterNameID])

  const get_Single_Data = (nameID, masterNameID) => {
    const val = {
      NameID: nameID,
      MasterNameID: masterNameID,
    }
    fetchPostData('MasterName/GetSingleData_MasterName', val)
      .then((res) => {
        if (res) {
          setEditval(res);
        }
        else setEditval([])
      })
  }

  const checkValidationErrors = () => {
    if (value.DLNumber) {
      const dlStateError = validateDLNumber(value.DLStateID);
      if (dlStateError === 'Required *') {
        setErrors({
          DLStateID: dlStateError
        });
      } else {
        Update_Name();
      }
    } else {
      Update_Name();
    }
  };

  useEffect(() => {
    if (editval) {
      setValue({
        ...value,
        'MasterNameID': masterNameID, 'NameID': nameID,
        'MaritalStatusID': editval[0]?.MaritalStatusID, 'HairColorID': editval[0]?.HairColorID, 'BIVerifyID': editval[0]?.BIVerifyID,
        'BICountryID': editval[0]?.BICountryID, 'BIStateID': editval[0]?.BIStateID, 'BICityID': editval[0]?.BICityID,
        'DLVerifyID': editval[0]?.DLVerifyID, "ResidentID": editval[0]?.ResidentID,
        'EyeColorID': editval[0]?.EyeColorID, 'DLStateID': editval[0]?.DLStateID, 'DLCountryID': editval[0]?.DLCountryID,
        // checkbox
        'IsJuvenile': editval[0]?.IsJuvenile, 'IsVerify': editval[0]?.IsVerify, 'IsUnListedPhNo': editval[0]?.IsUnListedPhNo,
        //textbox
        'BINationality': editval[0]?.BINationality, 'BirthPlace': editval[0]?.BirthPlace, 'IsUSCitizen': editval[0]?.IsUSCitizen,
        'DLNumber': editval[0]?.DLNumber,
        //Datepicker
        'DLExpiryDate': editval[0]?.DLExpiryDate ? getShowingWithOutTime(editval[0]?.DLExpiryDate) : null,
      })
      getStateList(editval[0]?.BICountryID);
      getBIStateList(editval[0]?.BICountryID);
      getCity(editval[0]?.BIStateID);
    } else {
      resetState()
    }
  }, [editval])

  useEffect(() => {
    GetVerifyIDDrp(loginAgencyID); GetColoIDDrp(loginAgencyID); GetMaritalStatusIDDrp(loginAgencyID); GetResidentIDDrp(loginAgencyID); getCountryID(); getStateList();
  }, [loginAgencyID]);

  const GetVerifyIDDrp = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID };
    fetchPostData("Verify/GetDataDropDown_Verify", val).then((data) => {
      if (data) {
        setVerifyIdDrp(
          Comman_changeArrayFormat_With_Name(data, "VerifyID", "Description", "DLVerifyID")
        );
        setBiVerifyIDDrp(
          Comman_changeArrayFormat_With_Name(data, "VerifyID", "Description", "BIVerifyID")
        );
      } else {
        setVerifyIdDrp([]);
      }
    });
  };

  const GetColoIDDrp = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID
    };
    fetchPostData("DropDown/GetData_DropDown_Color", val).then((data) => {
      if (data) {
        setEyeColoIDDrp(
          Comman_changeArrayFormat_With_Name(data, "ColorID", "ColorDescription", "EyeColorID")
        );
        setHairColorIDDrp(
          Comman_changeArrayFormat_With_Name(data, "ColorID", "ColorDescription", "HairColorID")
        );
      } else {
        setEyeColoIDDrp([]);
      }
    });
  };

  const GetMaritalStatusIDDrp = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID
    };
    fetchPostData("MaritalStatus/GetDataDropDown_MaritalStatus", val).then(
      (data) => {
        if (data) {
          setMaritalStatusIDDrp(
            Comman_changeArrayFormat_With_Name(data, "MaritalStatusID", "Description", "MaritalStatusID")
          );
        } else {
          setMaritalStatusIDDrp([]);
        }
      }
    );
  };

  const GetResidentIDDrp = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID
    };
    fetchPostData("Resident/GetDataDropDown_Resident", val).then((data) => {
      if (data) {
        setResidentIDDrp(
          Comman_changeArrayFormat_With_Name(data, "ResidentID", "Description", "ResidentID")
        );
      } else {
        setResidentIDDrp([]);
      }
    });
  };

  const getCountryID = async () => {
    fetchData("State_City_ZipCode/GetData_Country").then((data) => {
      if (data) {
        setDlCountryIDList(Comman_changeArrayFormat_With_Name(data, "CountryID", "CountryName", "DLCountryID"));
        setBiCountryIDList(Comman_changeArrayFormat_With_Name(data, "CountryID", "CountryName", "BICountryID"));
      } else {
        setDlCountryIDList([]);
      }
    });
  };

  const getStateList = async (CountryID) => {
    const val = {
      CountryID: CountryID,
    };
    fetchPostData("NameCountry_State_City/GetData_NameState", val).then((data) => {
      if (data) {
        setStateList(Comman_changeArrayFormat_With_Name(data, "StateID", "StateName", "DLStateID"));
      } else {
        setStateList([]);
      }
    });
  };

  const getBIStateList = async (CountryID) => {
    const val = {
      CountryID: CountryID,
    };
    fetchPostData("NameCountry_State_City/GetData_NameState", val).then((data) => {
      if (data) {
        setBiStateList(Comman_changeArrayFormat_With_Name(data, "StateID", "StateName", "BIStateID"));
      } else {
        setStateList([]);
      }
    });
  };

  const getCity = async (StateID) => {
    const val = {
      StateID: StateID,
    };
    fetchPostData("State_City_ZipCode/GetData_City", val).then((data) => {
      if (data) {
        setCityList(Comman_changeArrayFormat_With_Name(data, "CityID", "CityName", "BICityID"))
      } else {
        setCityList([]);
      }
    });
  };

  // handle change state
  const selectHandleChange = (e, name) => {
    if (e) {
      if (name === 'DLCountryID') {
        getStateList(e.value);
        setValue({
          ...value,
          [name]: e.value
        })
      } else if (name === 'BICountryID') {
        getBIStateList(e.value);
        setValue({
          ...value,
          [name]: e.value
        })
      } else if (name === 'BIStateID') {
        getCity(e.value);
        setValue({
          ...value,
          [name]: e.value
        })
      } else {
        setValue({
          ...value,
          [name]: e.value
        })
      }
    } else if (e === null) {
      if (name === 'DLCountryID') {
        setValue({
          ...value,
          ['DLStateID']: null,
        })
        setStateList([])
        setValue({
          ...value,
          [name]: null,
        })
      } else if (name === 'BICountryID') {
        setValue({
          ...value,
          ['BIStateID']: '',
          ['BICityID']: '',
        })
        setCityList([]); setBiStateList([]);
        setValue({
          ...value,
          [name]: '',
        })
      } else if (name === 'BIStateID') {
        setValue({
          ...value,
          ['BICityID']: '',
        })
        setCityList([])
        setValue({
          ...value,
          [name]: '',
        })
      } else {
        setValue({
          ...value,
          [name]: null,
        })
      }
    }
    else {
      setValue({
        ...value,
        [name]: '',
      })
    }
  }

  const HandleChange = (e) => {
    if (e.target.name === "IsUSCitizen") {
      setValue({
        ...value,
        [e.target.name]: e.target.checked,
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    }
  };

  const startRef = React.useRef();
  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const Update_Name = () => {
    AddDeleteUpadate('MasterName/UpdateBasicInfoName', value).then((res) => {
      const parsedData = JSON.parse(res.data);
      const message = parsedData.Table[0].Message;
      toastifySuccess(message);
    })
  }

  const resetState = () => {
    setValue({
      ...value,
      //-------dropDown------//
      'BIVerifyID': "", 'EyeColorID': "", 'HairColorID': "", 'ResidentID': "", 'MaritalStatusID': "",
      //------date--------//
      'DLExpiryDate': null,
      //------text------//
      'DLNumber': "", 'IsUSCitizen': "", 'BirthPlace': "", 'BINationality': "",
      'DLStateID': "", 'DLCountryID': "", 'DLVerifyID': "",
    })
  }


  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (

    <div className="col-12 " id="display-not-form">
      <div className="col-12 col-md-12 col-lg-12 pt-2 p-0">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">DL Information</p>
          <FindListDropDown
            array={NaGeneralListDropDownArray}
          />
        </div>
        <div className="row">
          <div className="col-6 col-md-4 col-lg-4 mt-1">
            <div className=" dropdown__box">
              <Select
                name="DLCountryID"
                styles={customStylesWithOutColor}
                value={dlCountryIDList?.filter((obj) => obj.value === value?.DLCountryID)}
                isClearable
                options={dlCountryIDList}
                onChange={(e) => selectHandleChange(e, 'DLCountryID')}
                placeholder="Select..."
              />
              <label htmlFor="">Country</label>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 mt-1">
            <div className="dropdown__box">
              <Select
                name="DLStateID"
                styles={customStylesWithOutColor}
                value={stateList?.find(obj => obj.value === value.DLStateID)}
                isClearable
                options={stateList}
                onChange={(e) => selectHandleChange(e, 'DLStateID')}
                placeholder="Select..."
              />
              <label>License State</label>
              {errors.DLStateID && <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }} className="error-message">{errors.DLStateID}</p>}
            </div>

          </div>

        </div>
        <div className="row">
          <div className="col-6 col-md-4 col-lg-2 " style={{ marginTop: '9px' }}>
            <div className="text-field">
              <input
                type="text"
                style={{ textTransform: "uppercase" }}
                value={value.DLNumber}
                maxLength={21}
                onChange={HandleChange}
                name="DLNumber"
                required
              />
              <label>DL Number</label>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-2 mt-1">
            <div className="dropdown__box">
              <DatePicker
                ref={startRef}
                onKeyDown={onKeyDown}
                id="DLExpiryDate"
                name="DLExpiryDate"
                dateFormat="MM/dd/yyyy"
                onChange={(date) => { setValue({ ...value, ["DLExpiryDate"]: date ? getShowingWithOutTime(date) : null, }); }}
                isClearable
                selected={value?.DLExpiryDate && new Date(value?.DLExpiryDate)}
                placeholderText={"Select..."}
                autoComplete="Off"
                dropdownMode="select"
                showMonthDropdown
                showDisabledMonthNavigation
                showYearDropdown
              />
              <label htmlFor="" className="pt-1">
                DL Expiry Date
              </label>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 mt-2">
            <div className=" dropdown__box">
              <Select
                name="DLVerifyID"
                styles={customStylesWithOutColor}
                value={verifyIdDrp?.filter((obj) => obj.value === value?.DLVerifyID)}
                options={verifyIdDrp}
                onChange={(e) => selectHandleChange(e, 'DLVerifyID')}
                isClearable
                placeholder="Select..."
              />
              <label htmlFor="">How Verify</label>
            </div>
          </div>
        </div>
      </div>
      {/* Birth */}
      <div className="col-12 col-md-12 col-lg-12 p-0 pt-1 ">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">
            Birth Information
          </p>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 mt-1 pl-2">
            <div className="form-check ">
              <input className="form-check-input" value={value?.IsUSCitizen} checked={value?.IsUSCitizen} onChange={HandleChange} type="checkbox" name="IsUSCitizen" id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                US Citizen
              </label>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 mt-1">
            <div className=" dropdown__box">
              <Select
                name="BICountryID"
                styles={customStylesWithOutColor}
                value={biCountryIDList?.filter((obj) => obj.value === value?.BICountryID)}
                isClearable
                options={biCountryIDList}
                onChange={(e) => selectHandleChange(e, 'BICountryID')}
                placeholder="Select..."
              />
              <label htmlFor="">Country</label>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 mt-1">
            <div className=" dropdown__box">
              <Select
                name="BIStateID"
                styles={customStylesWithOutColor}
                value={biStateList?.filter((obj) => obj.value === value?.BIStateID)}
                isClearable
                options={biStateList}
                onChange={(e) => selectHandleChange(e, 'BIStateID')}
                placeholder="Select..."
              />
              <label htmlFor="">State</label>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 mt-1">
            <div className=" dropdown__box">
              <Select
                name="BICityID"
                styles={customStylesWithOutColor}
                value={cityList?.filter((obj) => obj.value === value?.BICityID)}
                isClearable
                options={cityList}
                onChange={(e) => selectHandleChange(e, 'BICityID')}
                placeholder="Select..."
              />
              <label htmlFor="">City</label>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 mt-2">
            <div className="text-field">
              <input type="text" className="" value={value?.BirthPlace} onChange={HandleChange} name="BirthPlace" required />
              <label>Place Of Birth</label>
            </div>
          </div>

          <div className="col-6 col-md-4 col-lg-4 mt-2">
            <div className="text-field">
              <input type="text" className="" value={value?.BINationality} onChange={HandleChange} name="BINationality" required />
              <label>Nationality</label>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-4 mt-2">
            <div className=" dropdown__box">
              <Select
                name="BIVerifyID"
                styles={customStylesWithOutColor}
                value={biVerifyIDDrp?.filter((obj) => obj.value === value?.BIVerifyID)}
                options={biVerifyIDDrp}
                onChange={(e) => selectHandleChange(e, 'BIVerifyID')}
                isClearable
                placeholder="Select..."
              />
              <label htmlFor="">How Verify</label>
            </div>
          </div>
        </div>
      </div>
      {/* Basic */}
      <div className="col-12 col-md-12 col-lg-12  p-0 pt-1">
        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center">
            Basic Information
          </p>
        </div>
        <div className="row">
          <div className="col-6 col-md-6 col-lg-3 mt-1">
            <div className=" dropdown__box">
              <Select
                name="EyeColorID"
                styles={customStylesWithOutColor}
                value={eyeColoIDDrp?.filter((obj) => obj.value === value?.EyeColorID)}
                options={eyeColoIDDrp}
                onChange={(e) => selectHandleChange(e, 'EyeColorID')}
                isClearable
                placeholder="Select..."
                menuPlacement="top"
              />
              <label htmlFor="">Eye Color</label>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-3 mt-1">
            <div className=" dropdown__box">
              <Select
                name="HairColorID"
                styles={customStylesWithOutColor}
                value={hairColorIDDrp?.filter((obj) => obj.value === value?.HairColorID)}
                options={hairColorIDDrp}
                onChange={(e) => selectHandleChange(e, 'HairColorID')}
                isClearable
                placeholder="Select..."
                menuPlacement="top"
              />
              <label htmlFor="">Hair Color</label>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-3 mt-1">
            <div className=" dropdown__box">
              <Select
                name="ResidentID"
                styles={customStylesWithOutColor}
                value={residentIDDrp?.filter((obj) => obj.value === value?.ResidentID)}
                options={residentIDDrp}
                onChange={(e) => selectHandleChange(e, 'ResidentID')}
                isClearable
                placeholder="Select..."
                menuPlacement="top"
              />
              <label htmlFor="">Resident</label>
            </div>
          </div>

          <div className="col-6 col-md-6 col-lg-3 mt-1">
            <div className=" dropdown__box">
              <Select
                name="MaritalStatusID"
                styles={customStylesWithOutColor}
                value={maritalStatusIDDrp?.filter((obj) => obj.value === value?.MaritalStatusID)}
                options={maritalStatusIDDrp}
                onChange={(e) => selectHandleChange(e, 'MaritalStatusID')}
                isClearable
                placeholder="Select..."
                menuPlacement="top"
              />
              <label htmlFor="">Marital Status</label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12  text-right mt-3 p-0">
        <button type="button" onClick={checkValidationErrors} className="btn btn-sm btn-success pl-2">Update</button>
        <Link to={"/name"}>
          <button type="button" className="btn btn-sm btn-success mx-1" data-dismiss="modal" > Close </button>
        </Link>
      </div>
    </div>

  );
};

export default General;




