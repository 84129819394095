import React from 'react'
import Agency from '../../Components/Pages/Agency/Agency'


const AgencyPage = () => {
  return (
    <>
      <Agency />
    </>
  )
}

export default AgencyPage