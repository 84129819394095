// Import Component
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { AddDeleteUpadate, fetchPostData, ScreenPermision } from '../../../../hooks/Api';

const PersonnelGroup = (props) => {
	const { pId, aId, pinId } = props
	console.log(pId)
	// Hooks Initialization
	const [personalGroupList, setPersonalGroupList] = useState([]);
	const [effectiveScreenPermission, setEffectiveScreenPermission] = useState()

	// Onload Function
	useEffect(() => {
		if (pId && aId) {
			get_Group_Personnel();
		}
		getScreenPermision(aId, pinId)
	}, [pId, aId]);

	const get_Group_Personnel = () => {
		const val = {
			AgencyID: aId,
			PINID: pId,
		};
		console.log("get_Group_Personnel", val)
		fetchPostData('EffectivePermission/GetData_Personal_Group', val)
			.then((res) => {
				console.log('GetData_Personal_Group', res);
				if (res) setPersonalGroupList(res);
				else setPersonalGroupList();
			}).catch((error) => {
				console.error('There was an error!', error);
			});
	};

	// Table Columns Array
	const columns = [
		{
			name: 'Group IN',
			selector: (row) => <input type="checkbox" checked={row.Status} onClick={(e) => update_Group_Member(e, row)} />,
			sortable: true
		},
		{
			name: 'Group Name',
			selector: (row) => row.GroupName,
			sortable: true
		}
	];

	// Get Effective Screeen Permission
	const getScreenPermision = (aId, pinId) => {
		ScreenPermision("P015", aId, pinId).then(res => {
			if (res) setEffectiveScreenPermission(res)
			else setEffectiveScreenPermission()
		});
	}

	// Update Group 
	const update_Group_Member = (e, row) => {
		console.log(row)
		const value = {
			"GroupID": row.GroupID,
			"PINID": pId,
			"ModifiedByUserFK": pinId,
		}
		console.log("update_Group_Member", value);
		AddDeleteUpadate("SecurityGroupUserMembers/UpdateGroupMembers", value).then((data) => {
			if (data) { toastifySuccess(data.Message); get_Group_Personnel(aId, pinId) }
		})
	}

	return (
		<div className="row px-3">
			<div className="col-12  ">
				<div className="bg-line py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
					<p className="p-0 m-0 d-flex align-items-center">Group</p>
				</div>
				<DataTable
					columns={columns}
					data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? personalGroupList : '' : ''}
					dense
					paginationPerPage={'5'}
					paginationRowsPerPageOptions={[5, 10, 15]}
					highlightOnHover
					noContextMenu
					pagination
					responsive
					subHeaderAlign="right"
					fixedHeader
					fixedHeaderScrollHeight="195px"
					subHeaderWrap
					noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
				/>
			</div>
		</div>
	);
};

export default PersonnelGroup;
