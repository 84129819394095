import React, { useContext, useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Link} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Decrypt_Id_Name, colourStyles, getShowingWithOutTime } from '../../../Common/Utility';
import { AddDeleteUpadate, fetchPostData } from '../../../hooks/Api';
import { AgencyContext } from '../../../../Context/Agency/Index';
import { Comman_changeArrayFormat } from '../../../Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../../Common/AlertMsg';
import DeletePopUpModal from '../../../Common/DeleteModal';

const ArrestSearch = () => {

  const { deleteStoreData, storeData, get_Arrest_Count, updateCount, setUpdateCount, get_Police_Force, localStoreArray, get_LocalStorage, setIncStatus, arrestSearch } = useContext(AgencyContext);

  const [arrestFromDate, setArrestFromDate] = useState();
  const [arrestToDate, setArrestToDate] = useState();
  const [arrestData, setArrestData] = useState();
  const [arrestSearchID, setArrestSearchID] = useState();
  const [advancedSearch, setAdvancedSearch] = useState(false);
  //Drp
  const [arrestTypeDrpData, setArrestTypeDrpData] = useState([]);
  const [agencyNameDrpData, setAgencyNameDrpData] = useState([]);
  const [headOfAgency, setHeadOfAgency] = useState([]);
  const [juvenileDisDrp, setJuvenileDisDrp] = useState([]);
  const [chargeCodeDrp, setChargeCodeDrp] = useState([]);

  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [loginPinID, setLoginPinID,] = useState('');

  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: "", }),
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage(localStore);
    }
  }, []);

  // Onload Function
  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
        setLoginAgencyID(localStoreArray?.AgencyID);
        setLoginPinID(localStoreArray?.PINID);
      }
    }
  }, [localStoreArray])

  const [value, setValue] = useState({
    IncidentID: '', ArrestNumber: "", ArrestNumberTo: "", IncidentNumber: "",
    ArrestDtTm: "", ArrestDtTmTo: "", ArrestTypeID: "", ArrestingAgencyID: "",
    JuvenileDispositionID: "", LastName: "", FirstName: "", MiddleName: "",
    SSN: "", PrimaryOfficerID: "", ChargeCodeID: "", AgencyID: "",
  });

  useEffect(() => {
    setValue({ ...value, 'AgencyID': loginAgencyID, })
  }, [loginAgencyID]);

  useEffect(() => {
    get_Arresting_DropDown(loginAgencyID, loginPinID); Get_ArrestType_Drp(loginAgencyID); get_Head_Of_Agency(loginAgencyID);
    get_Arrest_juvenile_Drp(loginAgencyID); get_ChargeCode_Drp_Data(loginAgencyID);
    get_Police_Force();
  }, [loginAgencyID])

  const get_ChargeCode_Drp_Data = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
      'FBIID': null
    }
    fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
      if (data) {
        setChargeCodeDrp(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
      } else {
        setChargeCodeDrp([]);
      }
    })
  }

  const get_Head_Of_Agency = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID
    }
    fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
      if (data) {
        setHeadOfAgency(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency'));
      }
      else {
        setHeadOfAgency([]);
      }
    });
  };

  const get_Arrest_juvenile_Drp = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID
    }
    fetchPostData('ArrestJuvenileDisposition/GetDataDropDown_ArrestJuvenileDisposition', val).then((data) => {
      if (data) {
        setJuvenileDisDrp(Comman_changeArrayFormat(data, 'ArrestJuvenileDispositionID', 'Description'));
      }
      else {
        setJuvenileDisDrp([])
      }
    })
  };

  const get_Arresting_DropDown = (loginAgencyID, loginPinID) => {
    const val = {
      AgencyID: loginAgencyID,
      PINID: loginPinID
    }
    fetchPostData('Agency/GetData_Agency', val).then((data) => {
      if (data) {
        setAgencyNameDrpData(Comman_changeArrayFormat(data, 'AgencyID', 'Agency_Name'))
      } else {
        setAgencyNameDrpData([]);
      }
    })
  }

  const Get_ArrestType_Drp = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID
    }
    fetchPostData('ArrestType/GetDataDropDown_ArrestType', val).then((data) => {
      if (data) {
        setArrestTypeDrpData(Comman_changeArrayFormat(data, 'ArrestTypeID', 'Description'))
      } else {
        setArrestTypeDrpData([]);
      }
    })
  }

  const get_Data_Arrest = () => {
    fetchPostData('Arrest/Search_Arrest', value).then((res) => {
      if (res.length > 0) {
        setArrestData(res);
      } else {
        setArrestData([]); toastifyError("Data Not Available");
      }
    })
  }

  const reset = () => {
    setValue({
      ...value,
      ArrestNumber: '', ArrestNumberTo: '', IncidentNumber: '', ArrestDtTm: '',
      ArrestDtTmTo: '', ArrestTypeID: '', ArrestingAgencyID: '', JuvenileDispositionID: '',
      LastName: '', FirstName: '', MiddleName: '', SSN: '', PrimaryOfficerID: '', ChargeCodeID: '',
    });
    setArrestToDate(null); setArrestFromDate(null);
    setOnAdvanceSearch();
  }

  const setOnAdvanceSearch = () => {
    setValue({
      ...value,
      'ArrestNumber': null, 'ArrestNumberTo': null, 'IncidentNumber': null, 'ArrestDtTm': null,
      'ArrestDtTmTo': null, 'ArrestTypeID': null, 'ArrestingAgencyID': null, 'JuvenileDispositionID': null,
      'LastName': null, 'FirstName': null, 'MiddleName': null, 'SSN': null, 'PrimaryOfficerID': null, 'ChargeCodeID': null,
    });
    setArrestToDate(null);
    setArrestFromDate(null);
  }

  const columns = [
    {
      width: '150px',
      name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
      cell: row => 
        <Link to={'/arresttab?page=ArrestSearch'} onClick={(e) => set_Edit_Value(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
          <i className="fa fa-edit"></i>
        </Link>
     
    },
    {
      name: 'Arrest Number',
      selector: (row) => row.ArrestNumber,
      sortable: true
    },
    {
      name: 'Incident Number',
      selector: (row) => row.IncidentNumber,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 5 }}>Delete</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', right: 5 }}>
          <Link to={`#`} onClick={(e) => setArrestSearchID(row.ArrestID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
            <i className="fa fa-trash"></i>
          </Link>
        </div>


    },
  ]

  const set_Edit_Value = (row) => {
    if (row.ArrestID) {
      get_Arrest_Count(row.ArrestID);
      storeData({ 'ArrestID': row.ArrestID, 'ArrestStatus': true })
    }
    setIncStatus(true);
    setUpdateCount(updateCount + 1);
  }

  const HandleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value
    });
  }

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value,
      })
    } else {
      setValue({
        ...value,
        [name]: null,
      })
    }
  }

  const DeleteArrest = () => {
    const val = {
      'ArrestID': arrestSearchID,
      'DeletedByUserFK': loginPinID
    }
    AddDeleteUpadate('Arrest/Delete_Arrest', val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_Data_Arrest();
      } else { console.log("Somthing Wrong"); }
    })
  }

  const startRef = React.useRef();
  const startRef1 = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
      startRef1.current.setOpen(false);
    }
  };

  return (
    <>
      <div className="section-body view_page_design pt-3">
        <div className="row clearfix" >
          <div className="col-12 col-sm-12">
            <div className="card Agency">
              <div className="card-body">
                <div className="row ">
                  <div className="col-12 mb-2">
                    <div className="bg-line text-white py-1 px-2 d-flex justify-content-between align-items-center">
                      <p className="p-0 m-0">Arrest Search</p>
                    </div>
                  </div>
                  <div className="col-4 col-md-4 col-lg-3 mt-2">
                    <div className="text-field">
                      <input type="text" id='ArrestNumber' name='ArrestNumber' value={value?.ArrestNumber} onChange={HandleChange} />
                      <label>Arrest Number From</label>
                    </div>
                  </div>
                  <div className="col-4 col-md-4 col-lg-3 mt-2">
                    <div className="text-field">
                      <input type="text" id='ArrestNumberTo' name='ArrestNumberTo' value={value?.ArrestNumberTo} onChange={HandleChange} />
                      <label>Arrest Number To</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 " style={{ marginTop: '2px' }}>
                    <div className="dropdown__box">
                      <DatePicker
                        id='ArrestDtTm'
                        name='ArrestDtTm'
                        ref={startRef}
                        onKeyDown={onKeyDown}
                        onChange={(date) => { setArrestFromDate(date); setValue({ ...value, ['ArrestDtTm']: date ? getShowingWithOutTime(date) : null }) }}
                        className=''
                        dateFormat="MM/dd/yyyy"
                        autoComplete='Off'
                        timeInputLabel
                        maxDate={new Date()}
                        isClearable
                        showYearDropdown
                        dropdownMode="select"
                        selected={arrestFromDate}
                        placeholderText={value?.ArrestDtTm ? value.ArrestDtTm : 'Select...'}
                      />
                      <label htmlFor="" className='pt-1'>Arrest From Date</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 " style={{ marginTop: '2px' }}>
                    <div className="dropdown__box">
                      <DatePicker
                        id='ArrestDtTmTo'
                        name='ArrestDtTmTo'
                        ref={startRef1}
                        onKeyDown={onKeyDown}
                        onChange={(date) => { setArrestToDate(date); setValue({ ...value, ['ArrestDtTmTo']: date ? getShowingWithOutTime(date) : null }) }}
                        className=''
                        dateFormat="MM/dd/yyyy"
                        autoComplete='Off'
                        timeInputLabel
                        minDate={arrestFromDate}
                        maxDate={new Date()}
                        isClearable
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selected={arrestToDate}
                        placeholderText={value?.ArrestDtTmTo ? value.ArrestDtTmTo : 'Select...'}
                      />
                      <label htmlFor="" className='pt-1'>Arrest To Date</label>
                    </div>
                  </div>
                  <div className="col-4 col-md-4 col-lg-3 " style={{ marginTop: '10px' }}>
                    <div className="text-field">
                      <input type="text" id='IncidentNumber' name='IncidentNumber' value={value?.IncidentNumber} onChange={HandleChange} />
                      <label>Incident Number</label>
                    </div>
                  </div>
                  <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                    <div className="dropdown__box">
                      <Select
                        name='PrimaryOfficerID'
                        styles={colourStyles}
                        value={headOfAgency?.filter((obj) => obj.value === value?.PrimaryOfficerID)}
                        isClearable
                        options={headOfAgency}
                        onChange={(e) => ChangeDropDown(e, 'PrimaryOfficerID')}
                        placeholder="Select..."
                      />
                      <label htmlFor='' className='mt-0'>Arresting Officer</label>
                    </div>
                  </div>
                </div>
                <div className="row  text-right">
                  <div className="col-12 col-md-12 col-lg-12" style={{ marginTop: '2px' }}>
                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={get_Data_Arrest} >Search</button>
                    <Link to={'/dashboard-page'}>
                      <button type="button" className="btn btn-sm btn-success mr-1" data-dismiss="modal"  >Close</button>
                    </Link>
                    <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={() => { setAdvancedSearch(true); reset(); setOnAdvanceSearch(); }} data-toggle="modal" data-target="#ArrestSearchModal">Advance Search</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-2">
                    <div className="bg-line text-white py-1 px-2 d-flex justify-content-between align-items-center">
                      <p className="p-0 m-0">Arrest</p>
                      <p className="p-0 m-0">
                        <Link to={'/arresttab?page=ArrestSearch'} onClick={() => {
                          setIncStatus(false);
                          get_Arrest_Count('0');
                          deleteStoreData({ 'ArrestID': '', 'ArrestStatus': '', });
                        }} className="btn btn-sm bg-green text-white px-2 py-0" >
                          <i className="fa fa-plus"></i>
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="col-12 ">
                    <DataTable
                      dense
                      columns={columns}
                      data={arrestSearch?.length > 0 ? arrestSearch : arrestData}
                      pagination
                      selectableRowsHighlight
                      highlightOnHover
                      paginationPerPage={'5'}
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        advancedSearch &&

        <dialog className="modal top fade " style={{ background: "rgba(0,0,0, 0.5)" }} id="ArrestSearchModal" tabIndex="-1" data-backdrop="false" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog  modal-xl">
            <div className="modal-content" style={{ borderRadius: '10px', boxShadow: '0px 0px 3px floralwhite' }}>
              <div className="modal-header px-3 p-2" style={{ backgroundColor: 'aliceblue', boxShadow: '0px 0px 2px dimgray' }}>
                <h5 className="modal-title">Arrest Advance Search</h5>
                <button type="button" className="close btn-modal" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" style={{ color: 'red', fontSize: '20px', }}>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="m-1 ">
                  <div className="row">
                    <div className="col-12">
                      <fieldset className='fieldset'>
                        <legend>Arrest Information</legend>
                        <div className="row">
                          <div className="col-6 col-md-3 mt-1">
                            <div className="text-field">
                              <input type="text" id='ArrestNumber' name='ArrestNumber' value={value?.ArrestNumber} onChange={HandleChange} />
                              <label className=''>Arrest Number From</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mt-1">
                            <div className="text-field">
                              <input type="text" id='ArrestNumberTo' name='ArrestNumberTo' value={value?.ArrestNumberTo} onChange={HandleChange} />
                              <label className=''>Arrest Number To</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mb-1">
                            <div className="dropdown__box">
                              <DatePicker
                                id='ArrestDtTm'
                                name='ArrestDtTm'
                                ref={startRef}
                                onKeyDown={onKeyDown}
                                onChange={(date) => { setArrestFromDate(date); setValue({ ...value, ['ArrestDtTm']: date ? getShowingWithOutTime(date) : null }) }}
                                className=''
                                dateFormat="MM/dd/yyyy"
                                autoComplete='Off'
                                timeInputLabel
                                maxDate={new Date()}
                                isClearable
                                showYearDropdown
                                dropdownMode="select"
                                selected={arrestFromDate}
                                placeholderText={value?.ArrestDtTm ? value.ArrestDtTm : 'Select...'}
                              />
                              <label htmlFor="" className='pt-1'>Arrest From Date</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mb-1">
                            <div className="dropdown__box">
                              <DatePicker
                                id='ArrestDtTmTo'
                                name='ArrestDtTmTo'
                                ref={startRef1}
                                onKeyDown={onKeyDown}
                                onChange={(date) => { setArrestToDate(date); setValue({ ...value, ['ArrestDtTmTo']: date ? getShowingWithOutTime(date) : null }) }}
                                className=''
                                dateFormat="MM/dd/yyyy"
                                autoComplete='Off'
                                timeInputLabel
                                minDate={arrestFromDate}
                                maxDate={new Date()}
                                isClearable
                                showYearDropdown
                                dropdownMode="select"
                                selected={arrestToDate}
                                placeholderText={value?.ArrestDtTmTo ? value.ArrestDtTmTo : 'Select...'}
                              />
                              <label htmlFor="" className='pt-1'>Arrest To Date</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='IncidentNumber' name='IncidentNumber' value={value?.IncidentNumber} onChange={HandleChange} />
                              <label className=''>Incident</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name='PrimaryOfficerID'
                                styles={colourStyles}
                                menuPlacement='bottom'
                                value={headOfAgency?.filter((obj) => obj.value === value?.PrimaryOfficerID)}
                                isClearable
                                options={headOfAgency}
                                onChange={(e) => ChangeDropDown(e, 'PrimaryOfficerID')}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Arresting Officer</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name='ChargeCodeID'
                                styles={colourStyles}
                                value={chargeCodeDrp?.filter((obj) => obj.value === value?.ChargeCodeID)}
                                isClearable
                                options={chargeCodeDrp}
                                onChange={(e) => ChangeDropDown(e, 'ChargeCodeID')}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Charge Code/Description</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name='ArrestTypeID'
                                styles={colourStyles}
                                value={arrestTypeDrpData?.filter((obj) => obj.value === value?.ArrestTypeID)}
                                isClearable
                                options={arrestTypeDrpData}
                                onChange={(e) => { ChangeDropDown(e, 'ArrestTypeID') }}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Arrest Type</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name="ArrestingAgencyID"
                                styles={colourStyles}
                                value={agencyNameDrpData?.filter((obj) => obj.value === value?.ArrestingAgencyID)}
                                isClearable
                                options={agencyNameDrpData}
                                onChange={(e) => { ChangeDropDown(e, 'ArrestingAgencyID') }}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Arresting Agency</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 col-lg-3 mb-1 mt-2">
                            <div className="dropdown__box">
                              <Select
                                name='JuvenileDispositionID'
                                menuPlacement='bottom'
                                styles={colourStyles}
                                value={juvenileDisDrp?.filter((obj) => obj.value === value?.JuvenileDispositionID)}
                                isClearable
                                options={juvenileDisDrp}
                                onChange={(e) => ChangeDropDown(e, 'JuvenileDispositionID')}
                                placeholder="Select..."
                              />
                              <label htmlFor='' className='mt-0'>Juvenile Disposition</label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className='fieldset mt-2'>
                        <legend>Arrestee Information</legend>
                        <div className="row">
                          <div className="col-6 col-md-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='LastName' name='LastName' value={value?.LastName} onChange={HandleChange} />
                              <label className=''>Last Name</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='FirstName' name='FirstName' value={value?.FirstName} onChange={HandleChange} />
                              <label className=''>First Name</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='MiddleName' name='MiddleName' value={value?.MiddleName} onChange={HandleChange} />
                              <label className=''>Middle Name</label>
                            </div>
                          </div>
                          <div className="col-6 col-md-3 mt-2">
                            <div className="text-field">
                              <input type="text" id='SSN' name='SSN' value={value?.SSN} onChange={HandleChange} />
                              <label className=''>SSN</label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <div className="row mt-1 px-2 text-right">
                        <div className="col-12">
                          <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { get_Data_Arrest(); }}>Search</button>
                          <button type="button" data-dismiss="modal" className="btn btn-sm btn-success" >Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dialog>


      }
      <DeletePopUpModal func={DeleteArrest} />
    </>
  )
}

export default ArrestSearch