import React, { useContext, useState, useEffect, useCallback } from 'react'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { getShowingDateText, getShowingMonthDateYear } from '../../../../Common/Utility';
import { fetchPostData, AddDeleteUpadate } from '../../../../hooks/Api';
import { RequiredFieldSpaceNotAllow } from '../../../Agency/AgencyValidation/validators';
import { useLocation } from 'react-router-dom';
import { SSN_Field } from '../../../PersonnelCom/Validation/PersonnelValidation';
import { AgencyContext } from '../../../../../Context/Agency/Index';

const AliasesAddUp = (props) => {

    const { nameID, masterNameID, loginPinID, loginAgencyID, status, get_Aliases_Data, setModal, modal, nameAliasesID, updateStatus } = props
    const { get_Name_Count } = useContext(AgencyContext);

    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');

    const [editval, setEditval] = useState();
    const [suffixIdDrp, setSuffixIdDrp] = useState([])
    const [dob, setDob] = useState();

    const [value, setValue] = useState({
        'LastName': '', 'FirstName': '', 'MiddleName': '', 'SuffixID': null, 'AliasSSN': '', 'DOB': '', 'ModifiedDtTm': "",
        'NameID': '',
        'MasterNameID': '',
        'CreatedByUserFK': '',
        "ModifiedByUserFK": ""
    })

    useEffect(() => {
        setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'MasterNameID': masterNameID, 'NameID': nameID } });
    }, [nameID, masterNameID, updateStatus]);

    const [errors, setErrors] = useState({
        'LastNameErrors': '',
    })

    useEffect(() => {
        if (nameAliasesID) {
            GetSingleData(nameAliasesID)
        }
    }, [nameAliasesID, updateStatus])

    const GetSingleData = (nameAliasesID) => {
        fetchPostData('NameAliases/GetSingleData_NameAliases', { 'NameAliasesID': nameAliasesID })
            .then((res) => {
                console.log(res)
                if (res) setEditval(res)
                else setEditval()
            }
            )
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            reset()
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    useEffect(() => {
        if (status) {
            setValue({
                ...value,
                'NameAliasesID': nameAliasesID,
                'DOB': editval[0]?.DOB ? getShowingDateText(editval[0]?.DOB) : ' ', "LastName": editval[0]?.LastName,
                'FirstName': editval[0]?.FirstName, 'MiddleName': editval[0]?.MiddleName, 'SuffixID': editval[0]?.SuffixID,
                'AliasSSN': editval[0]?.AliasSSN,
                'ModifiedByUserFK': loginPinID,
            });
            setDob(editval[0]?.DOB ? new Date(editval[0]?.DOB) : '');
        }
        else {
            setValue({
                ...value,
                'LastName': '', 'FirstName': '', 'MiddleName': '', 'DOB': '', 'SuffixID': null, 'AliasSSN': '', 'ModifiedByUserFK': '',
            })
        }
    }, [editval])

    const reset = () => {
        setValue({
            ...value,
            'LastName': '', 'FirstName': '', 'MiddleName': '', 'DOB': '', 'SuffixID': '', 'AliasSSN': '',
        });
        setDob("")
        setErrors({
            ...errors,
            'LastNameErrors': '',
        })
    }

    const check_Validation_Error = (e) => {
        if (RequiredFieldSpaceNotAllow(value.LastName)) {
            setErrors(prevValues => { return { ...prevValues, ['LastNameErrors']: RequiredFieldSpaceNotAllow(value.LastName) } })
        }
        if (SSN_Field(value.AliasSSN)) {
            setErrors(prevValues => { return { ...prevValues, ['AliasSSN']: SSN_Field(value.AliasSSN) } })
        }
    }
    const { LastNameErrors, AliasSSN } = errors

    useEffect(() => {
        if (LastNameErrors === 'true' && AliasSSN === 'true') {
            if (status) { update_Activity() }
            else { Add_Type() }
        }
    }, [LastNameErrors, AliasSSN])

    useEffect(() => {
        GetSuffixIDDrp(loginAgencyID);
    }, [loginAgencyID])

    const GetSuffixIDDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('Suffix/GetDataDropDown_Suffix', val).then((data) => {
            if (data) {
                setSuffixIdDrp(Comman_changeArrayFormat(data, 'SuffixID', 'Description'))
            } else {
                setSuffixIdDrp([]);
            }
        })
    }

    const startRef = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
        }
    };

    const Add_Type = (e) => {
        AddDeleteUpadate(openPage === 'mastername' ? 'MainMasterNameAliases/Insert_MainMasterNameAliases' : 'NameAliases/Insert_NameAliases', value).then((res) => {
            get_Aliases_Data(nameID, masterNameID);
            get_Name_Count(nameID);
            setModal(false)
            toastifySuccess(res.Message);
            reset();
        })
    }

    const update_Activity = () => {
        AddDeleteUpadate('NameAliases/Update_NameAliases', value).then((res) => {
            toastifySuccess(res.Message);
            setModal(false);
            get_Aliases_Data(nameID, masterNameID)
            reset();
        })
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
        } else setValue({
            ...value,
            [name]: null
        })
    }

    useEffect(() => {
        if (!status) {
            console.log(!status)
        } else {
            setDob('');
            setValue({
                ...value,
                ['DOB']: '',
            });
        }
    }, [])

    const handleChange = (e) => {
        if (e.target.name === "IsVerify") {
            setValue({
                ...value,
                [e.target.name]: e.target.checked,
            });
        }
        else if (e.target.name === 'AliasSSN') {
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value,
            });
        }
    };

    const closeModal = () => {
        reset();
        setModal(false);
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 32,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    return (
        <>
            {
                modal ?

                    <dialog className="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="PinModal" tabIndex="-1" aria-hidden="true" data-backdrop="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="m-1 mt-3">
                                        <fieldset style={{ border: '1px solid gray' }}>
                                            <legend style={{ fontWeight: 'bold' }}>Aliases</legend>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">

                                                        <div className="col-6 col-md-6 col-lg-4 mt-2 pt-1">
                                                            <div className="text-field">
                                                                <input type="text" className='requiredColor' name='LastName' value={value?.LastName} onChange={handleChange} required />
                                                                <label>Last Name</label>
                                                                {errors.LastNameErrors !== 'true' ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.LastNameErrors}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-4  mt-2 pt-1">
                                                            <div className="text-field">
                                                                <input type="text" name='FirstName' value={value?.FirstName} onChange={handleChange} required />
                                                                <label>First Name</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-4  mt-2 pt-1">
                                                            <div className="text-field">
                                                                <input type="text" name='MiddleName' value={value?.MiddleName} onChange={handleChange} required />
                                                                <label>Middle Name</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6 mt-1 pt-1 col-lg-4 mb-2">
                                                            <div className="text-field">
                                                                <input type="text" name='AliasSSN' value={value.AliasSSN} maxLength={9} onChange={handleChange} required />
                                                                <label className=''>Alias SSN</label>
                                                                {errors.AliasSSN !== 'true' ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AliasSSN}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6 pt-1 mb-1 col-lg-4  dropdown__box">

                                                            <Select
                                                                name='SuffixID'
                                                                className='requiredColor'
                                                                styles={customStylesWithOutColor}
                                                                value={suffixIdDrp?.filter((obj) => obj.value === value?.SuffixID)}
                                                                isClearable
                                                                options={suffixIdDrp}
                                                                onChange={(e) => ChangeDropDown(e, 'SuffixID')}
                                                                placeholder="Select..."
                                                            />
                                                            <label htmlFor='' className='mt-1'>Suffix</label>

                                                        </div>
                                                        <div className="col-6 col-md-6  col-lg-4">
                                                            <div className=' dropdown__box'>
                                                                <DatePicker
                                                                    id='DOB'
                                                                    name='DOB'
                                                                    className=''
                                                                    ref={startRef}
                                                                    onKeyDown={onKeyDown}
                                                                    onChange={(date) => { setDob(date); setValue({ ...value, ['DOB']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                    dateFormat="MM/dd/yyyy"
                                                                    isClearable={value?.DOB ? true : false}
                                                                    selected={dob}
                                                                    showDisabledMonthNavigation
                                                                    autoComplete="nope"
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    dropdownMode="select"
                                                                    maxDate={new Date()}
                                                                    placeholderText={value?.DOB ? value.DOB : 'Select...'}

                                                                />
                                                                <label htmlFor="" className='pt-1'>DOB</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-12 col-lg-2  pl-2">
                                                            <div className="form-check ">

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="btn-box text-right  mr-1 mb-2">
                                    {
                                        status ?
                                            <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1">Update</button>
                                            :
                                            <button type="button" onClick={(e) => { check_Validation_Error(); }} className="btn btn-sm btn-success mr-1">Save</button>
                                    }
                                    <button type="button" data-dismiss="modal" onClick={closeModal} className="btn btn-sm btn-success mr-1" >Close</button>
                                </div>
                            </div>
                        </div>
                    </dialog>

                    :
                    <>
                    </>
            }
        </>
    )
}

export default AliasesAddUp

