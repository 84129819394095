import React, { useContext, useEffect, useState } from 'react';
import SubTab from '../../Utility/Tab/SubTab';
import { NameTabs } from '../../Utility/Tab/TabsArray';
import Home from './NameTab/Home/Home';
import General from './NameTab/General/General';
import ContactDetails from './NameTab/ContactDetails/ContactDetails';
import Aliases from './NameTab/Aliases/Aliases';
import Documents from './NameTab/Documents/Documents';
import Smt from './NameTab/SMT/Smt';
import Victim from './NameTab/Victim/Victim';
import IdentificationNumber from './NameTab/IdentificationNumber/IdentificationNumber';
import Offender from './NameTab/Offender/Offender';
import Gang from './NameTab/Gang/Gang';
import { AgencyContext } from '../../../Context/Agency/Index';
import Connection from './NameTab/Connection/Connection';
import Address from './NameTab/Address/Address';
import TransactionLog from './NameTab/TransactionLog/TransactionLog';
import { Decrypt_Id_Name } from '../../Common/Utility';
import Tab from '../../Utility/Tab/Tab';
import { Link } from 'react-router-dom';

const NameTab = () => {

    const { nameStatus, nameShowPage, setNameShowPage, localStoreArray, get_LocalStorage, } = useContext(AgencyContext);
    const [status, setStatus] = useState();
    const iconHome = <i className="fa fa-home" style={{ fontSize: '20px' }}></i>

    const [showOffender, setShowOffender] = useState(false);
    const [showVictim, setShowVictim] = useState(false);
    const [nameModifiTab, setNameModifiTab] = useState();
    const [isBusinessName, setIsBusinessName] = useState(false);
    // Not in use--------------

    useEffect(() => {
        if (!isBusinessName) {
            if (showOffender && showVictim) {
                setNameModifiTab(NameTabs)
            } else if (showOffender) {
                const NameTabsArray = NameTabs?.filter((item) => item.tab !== "Victim")
                setNameModifiTab(NameTabsArray)
            } else if (showVictim) {
                const NameTabsArray = NameTabs?.filter((item) => item.tab !== "Offender")
                setNameModifiTab(NameTabsArray)
            } else {
                setNameModifiTab(NameTabs)
            }
        } else {
            const NameTabsArray = NameTabs?.filter((item) => item.tab !== "Offender" && item.tab !== "Victim" && item.tab !== "General" && item.tab !== "Aliases" && item.tab !== "Documents" && item.tab !== "SMT" && item.tab !== "Identification Number" && item.tab !== "Transaction Log");
            setNameModifiTab(NameTabsArray)
        }
    }, [showOffender, showVictim, isBusinessName]);

    const localStore = {
        Value: "",
        UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
        Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: '', MasterNameID: '', NameID: '', Agency_Name: "", NameStatus: '', IncidentNumber: '', IncidentStatus: '' }),
    }

    useEffect(() => {
        if (!localStoreArray.AgencyID || !localStoreArray.PINID || !localStoreArray.IncidentStatus) {
            get_LocalStorage(localStore);
        }
    }, []);

    useEffect(() => {
        if (localStoreArray) {
            if (nameStatus) {
                setStatus(nameStatus);
            } else {
                setStatus(localStoreArray.NameStatus === true || localStoreArray.NameStatus === "True" ? true : false);
            }
        }
        setNameShowPage('home')
    }, [localStoreArray])

    return (
        <div className=" view_page_design pt-1 p-1 bt" >
            <div className="div">
                <div className="col-12  inc__tabs">
                    <Tab />
                </div>
                <div className="row clearfix" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card ">
                            <div className="card-body" >
                                <div className="row " style={{ marginTop: '-18px', marginLeft: '-18px' }}>
                                    {/* <div className="col-12 pl-3 ">
                                        <SubTab tabs={nameModifiTab} showPage={nameShowPage} setShowPage={setNameShowPage} status={status} showOffender={showOffender} showVictim={showVictim} />
                                    </div> */}
                                    <div className="col-12 incident-tab">
                                        <ul className='nav nav-tabs'>
                                            <Link className={`nav-item ${nameShowPage === 'home' ? 'active' : ''}`} to={'/Name-Home'} data-toggle={"pill"} style={{ color: nameShowPage === 'home' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('home') }} >
                                                {iconHome}
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=general'} data-toggle={"pill"} style={{ color: nameShowPage === 'general' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('general') }} >
                                                General
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=Contact_Details'} data-toggle={"pill"} style={{ color: nameShowPage === 'Contact_Details' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('Contact_Details') }} >
                                                Contact Details
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=aliases'} data-toggle={"pill"} style={{ color: nameShowPage === 'aliases' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('aliases') }} >
                                                Aliases
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=documents'} data-toggle={"pill"} style={{ color: nameShowPage === 'documents' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('documents') }} >
                                                Documents
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=SMT'} data-toggle={"pill"} style={{ color: nameShowPage === 'SMT' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('SMT') }} >
                                            SMT
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=Identification_Number'} data-toggle={"pill"} style={{ color: nameShowPage === 'Identification_Number' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('Identification_Number') }} >
                                                Identification Number
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=Address'} data-toggle={"pill"} style={{ color: nameShowPage === 'Address' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('Address') }} >
                                                Address
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=TransactionLog'} data-toggle={"pill"} style={{ color: nameShowPage === 'TransactionLog' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('TransactionLog') }} >
                                                Transaction Log
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=Victim'} data-toggle={"pill"} style={{ color: nameShowPage === 'Victim' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('Victim') }} >
                                                Victim
                                            </Link>
                                            <Link className='nav-item ' to={'/Name-Home?page=Offender'} data-toggle={"pill"} style={{ color: nameShowPage === 'Offender' ? 'Red' : 'gray' }} aria-current="page" onClick={() => { setNameShowPage('Offender') }} >
                                                Offender
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                                {
                                    nameShowPage === 'home' ?
                                        <Home {...{ setStatus, setShowVictim, setShowOffender, setIsBusinessName }} />
                                        :
                                        nameShowPage === 'general' ?
                                            <General />
                                            :
                                            nameShowPage === 'Contact_Details' ?
                                                <ContactDetails />
                                                :
                                                nameShowPage === 'aliases' ?
                                                    <Aliases />
                                                    :
                                                    nameShowPage === 'documents' ?
                                                        <Documents />
                                                        :
                                                        nameShowPage === 'SMT' ?
                                                            <Smt />
                                                            :
                                                            nameShowPage === 'Offender' ?
                                                                <Offender />
                                                                :
                                                                nameShowPage === 'Identification_Number' ?
                                                                    <IdentificationNumber />
                                                                    :
                                                                    nameShowPage === 'Victim' ?
                                                                        <Victim showTabs={setNameShowPage} />
                                                                        :
                                                                        nameShowPage === 'Gang' ?
                                                                            <Gang />
                                                                            :
                                                                            nameShowPage === 'connections' ?
                                                                                <Connection />
                                                                                :
                                                                                nameShowPage === 'Address' ?
                                                                                    <Address />
                                                                                    :
                                                                                    nameShowPage === 'TransactionLog' ?
                                                                                        <TransactionLog />
                                                                                        :

                                                                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default NameTab