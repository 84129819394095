import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { fetchPostData } from '../../../../hooks/Api'
import { Decrypt_Id_Name, tableCustomStyles } from '../../../../Common/Utility'
import { AgencyContext } from '../../../../../Context/Agency/Index'

const LocationHistory = () => {

  const { localStoreArray, get_LocalStorage, } = useContext(AgencyContext);
  const [locationData, setLocationData] = useState([]);
  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: '' }),
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage(localStore);
    }
  }, []);

  // Onload Function
  useEffect(() => {
    console.log("localStoreArray Location", localStoreArray)
    if (localStoreArray) {
      if (localStoreArray?.AgencyID && localStoreArray?.PINID && localStoreArray?.IncidentID) {
        get_LocationData(localStoreArray?.IncidentID);
      }
    }
  }, [localStoreArray])

  const get_LocationData = (MainIncidentID) => {
    const val = {
      'IncidentID': MainIncidentID
    }
    fetchPostData('Incident/GetData_IncidentLocation', val).then((res) => {
      if (res?.length > 0) {
        console.log(res)
        setLocationData(res)
      } else {
        setLocationData([]);
      }
    })
  }

  const columns = [
    {
      width: '700px',
      name: 'Address',
      selector: (row) => row.IncidentAddress,
      sortable: true
    },

  ]

  return (
    <div className="col-12 px-0 mt-3">
      <DataTable
        dense
        columns={columns}
        data={locationData ? locationData : []}
        pagination
        selectableRowsHighlight
        highlightOnHover
        customStyles={tableCustomStyles}

      />
    </div>

  )
}

export default LocationHistory