import React, { useContext } from "react";
import { AgencyContext } from '../../../Context/Agency/Index';
import { Link } from 'react-router-dom';

export const NameSearchSidebar = () => {

    const { nameSearchData, setUpdateCount, setIncStatus, updateCount } = useContext(AgencyContext);


   
    return (
        <div className="row px-1">
            <div className="col-12">
                <input type="text" className='form-control' placeholder='Search By Name ...' />
            </div>
            <li>
                <ul style={{ marginLeft: '-23px' }}>
                
                        {
                            nameSearchData?.map((val) => (
                                <li className="ml-3 p-0" key={val.ResidentID}>
                                    <Link to={`/nametab?page=mastername`}
                                        onClick={() => {
                                            setIncStatus(true)
                                            setUpdateCount(updateCount + 1)
                                        }}
                                    >
                                        <i className=" fa fa-arrow-right"></i>
                                        <span className="m-0 p-0">{val.LastName}</span>
                                    </Link>
                                </li>
                            ))
                        }
               
                </ul>
            </li>
        </div>
    )
}
