import React, { useCallback, useContext, useState, useEffect } from 'react'
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, getShowingMonthDateYear } from '../../../../Common/Utility';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { Comman_changeArrayFormat, threeColArray } from '../../../../Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { RequiredFieldIncident, RequiredFieldOnConditon } from '../../../Utility/Personnel/Validation';
import { RequiredFieldSpaceNotAllow } from '../../../Agency/AgencyValidation/validators';
import { AgencyContext } from '../../../../../Context/Agency/Index';

const RecoveredPropertyAddUpp = (props) => {

    const { get_Property_Count } = useContext(AgencyContext)

    const { masterPropertyID, propertyID, loginPinID, loginAgencyID, modal, setModal, recoveredPropertyID, setRecoveredPropertyID, updateStatus, get_property_Data, propertyData, setStatus, } = props

    const [recoverDate, setRecoverDate] = useState();
    const [recoveryTypeDrpData, setRecoveryTypeDrpData] = useState([]);
    const [dispositionsDrpData, setDispositionsDrpData] = useState([]);
    const [recoverTypeCode, setRecoverTypeCode] = useState('');
    const [remainBalance, setRemainBalance] = useState(0);

    const [editval, setEditval] = useState();

    const [value, setValue] = useState({
        'RecoveredPropertyID': '', 'MasterPropertyID': '', 'PropertyID': '', 'RecoveredIDNumber': '', 'RecoveredDateTime': '', 'OfficerPFID': null, 'RecoveryTypeID': null,
        'RecoveredValue': '', 'Comments': '', 'Balance': '', 'DispositionID': null, 'UCRRecoveredID': null, 'CreatedByUserFK': '', 'ModifiedByUserFK': '',
    });

    const [errors, setErrors] = useState({
        'DispositionIDError': '', 'OfficerPFIDError': '', 'RecoveredDateTimeError': '', 'RecoveryTypeIDError': '', 'Comments': '', 'RecoverTypeCode': '',
    })

    useEffect(() => {
        setValue({ ...value, 'PropertyID': propertyID, 'CreatedByUserFK': loginPinID, 'MasterPropertyID': masterPropertyID, })
    }, [propertyID, updateStatus]);

    useEffect(() => {
        if (sessionStorage.getItem('propertyStolenValue')) {
            if (propertyData) {
                let remainBal = Decrypt_Id_Name(sessionStorage.getItem('propertyStolenValue'), 'SForStolenValue');
                const newArr = propertyData.map((val) => { return val.RecoveredValue });
                for (let i in newArr) { remainBal = parseFloat(remainBal) - parseFloat(newArr[i]); }
                remainBal = parseFloat(remainBal)?.toFixed(2)
                setRemainBalance(remainBal);
                setValue({ ...value, ['Balance']: remainBal })
            } else {
                setValue({ ...value, ['Balance']: parseInt(Decrypt_Id_Name(sessionStorage.getItem('propertyStolenValue'), 'SForStolenValue')) });
                setRemainBalance(parseFloat(Decrypt_Id_Name(sessionStorage.getItem('propertyStolenValue'), 'SForStolenValue')));
            }
        } else { setRemainBalance(0) }
    }, [updateStatus, modal])

    useEffect(() => {
        if (recoveredPropertyID) {
            GetSingleData(recoveredPropertyID)
        } else { reset() }
    }, [recoveredPropertyID, updateStatus])

    const GetSingleData = (recoveredPropertyID) => {
        const val = {
            'RecoveredPropertyID': recoveredPropertyID
        }
        fetchPostData('RecoveredProperty/GetSingleData_RecoveredProperty', val)
            .then((res) => {
                if (res) { setEditval(res) }
                else { setEditval() }
            })
    }

    useEffect(() => {
        if (recoveredPropertyID) {
            setValue({
                ...value,
                'MasterPropertyID': editval[0]?.MasterPropertyID,
                'PropertyID': editval[0]?.PropertyID, 'DispositionID': editval[0]?.DispositionID, 'UCRRecoveredID': editval[0]?.UCRRecoveredID,
                'RecoveredPropertyID': editval[0]?.RecoveredPropertyID, 'RecoveredIDNumber': editval[0]?.RecoveredIDNumber, 'RecoveredDateTime': editval[0]?.RecoveredDateTime,
                'OfficerPFID': editval[0]?.OfficerPFID, 'RecoveredValue': editval[0]?.RecoveredValue, 'RecoveryTypeID': editval[0]?.RecoveryTypeID, 'Comments': editval[0]?.Comments,
                'Balance': editval[0]?.Balance, 'ModifiedByUserFK': loginPinID,
            })
            setRecoverDate(editval[0]?.RecoveredDateTime ? new Date(editval[0]?.RecoveredDateTime) : '');
            setRecoverTypeCode(Get_Property_Code(editval, recoveryTypeDrpData));
        } else {
            setValue({
                ...value, 'RecoveredIDNumber': '', 'RecoveredDateTime': '', 'OfficerPFID': null, 'RecoveryTypeID': null, 'RecoveredValue': '', 'Comments': '', 'DispositionID': null, 'UCRRecoveredID': null,
            })
        }
    }, [editval])

    const check_Validation_Error = (e) => {
        if (RequiredFieldIncident(value.DispositionID)) {
            setErrors(prevValues => { return { ...prevValues, ['DispositionIDError']: RequiredFieldIncident(value.DispositionID) } })
        }
        if (RequiredFieldIncident(value.OfficerPFID)) {
            setErrors(prevValues => { return { ...prevValues, ['OfficerPFIDError']: RequiredFieldIncident(value.OfficerPFID) } })
        }
        if (RequiredFieldIncident(value.RecoveredDateTime)) {
            setErrors(prevValues => { return { ...prevValues, ['RecoveredDateTimeError']: RequiredFieldIncident(value.RecoveredDateTime) } })
        }
        if (RequiredFieldIncident(value.RecoveryTypeID)) {
            setErrors(prevValues => { return { ...prevValues, ['RecoveryTypeIDError']: RequiredFieldIncident(value.RecoveryTypeID) } })
        }
        if (RequiredFieldSpaceNotAllow(value.Comments)) {
            setErrors(prevValues => { return { ...prevValues, ['Comments']: RequiredFieldSpaceNotAllow(value.Comments) } })
        }
        if (recoverTypeCode === 'P') {
            if (RequiredFieldOnConditon(value.RecoveredValue)) {
                setErrors(prevValues => ({ ...prevValues, ['ContactError']: RequiredFieldOnConditon(value.RecoveredValue) }));
            }
        } else { setErrors(prevValues => ({ ...prevValues, ['ContactError']: RequiredFieldOnConditon(null) })); }
    }

    // Check All Field Format is True Then Submit 
    const { DispositionIDError, OfficerPFIDError, RecoveredDateTimeError, RecoveryTypeIDError, Comments, ContactError } = errors

    useEffect(() => {
        if (DispositionIDError === 'true' && OfficerPFIDError === 'true' && RecoveredDateTimeError === 'true' && RecoveryTypeIDError === 'true' && Comments === 'true' && ContactError === 'true') {
            if (recoveredPropertyID) { Update_RecoveredProperty() }
            else { Add_RecoveredProperty() }
        }
    }, [DispositionIDError, OfficerPFIDError, RecoveredDateTimeError, RecoveryTypeIDError, Comments, ContactError])

    useEffect(() => {
        if (loginAgencyID) { get_RecoveryType(loginAgencyID); get_Dispositions(); }
    }, [loginAgencyID])

    const get_RecoveryType = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('RecoveryType/GetDataDropDown_RecoveryType', val).then((data) => {
            if (data) {
                setRecoveryTypeDrpData(threeColArray(data, 'RecoveryTypeID', 'Description', 'RecoveryTypeCode'));
            }
            else { setRecoveryTypeDrpData([]) }
        })
    };

    const get_Dispositions = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('PropertyDispositions/GetDataDropDown_PropertyDispositions', val).then((data) => {
            if (data) {
                setDispositionsDrpData(Comman_changeArrayFormat(data, 'PropertyDispositionsID', 'Description'));
            }
            else { setDispositionsDrpData([]) }
        })
    };

    const HandleChanges = (e) => {
        if (e.target.name === 'RecoveredValue') {
            const ele = e.target.value.replace(/[^0-9]/g, "")
            if (ele.includes('.')) {
                if (ele.length === 16) {
                    let total = 0
                    total = parseFloat(remainBalance) - parseFloat(e.target.value)
                    total = total?.toFixed(2)
                    setValue(pre => { return { ...pre, ['Balance']: total, [e.target.name]: ele } });
                } else {
                    if (ele.substr(ele.indexOf('.') + 1).slice(0, 2)) {
                        const checkDot = ele.substr(ele.indexOf('.') + 1).slice(0, 2).match(/\./g)
                        if (!checkDot) {
                            let total = 0
                            total = parseFloat(remainBalance) - parseFloat(e.target.value)
                            total = total?.toFixed(2)
                            setValue(pre => { return { ...pre, ['Balance']: total, [e.target.name]: ele.substring(0, ele.indexOf(".")) + '.' + ele.substr(ele.indexOf('.') + 1).slice(0, 2) } });
                        } return;
                    }
                    let total = 0
                    total = parseFloat(remainBalance) - parseFloat(e.target.value)
                    total = total.toFixed(2)
                    setValue(pre => { return { ...pre, ['Balance']: total, [e.target.name]: ele } });
                }
            } else {
                if (ele.length === 16) {
                    let total = 0;
                    total = parseFloat(remainBalance) - parseFloat(ele)
                    total = total?.toFixed(2)
                    setValue({ ...value, ['Balance']: total ? total : remainBalance, [e.target.name]: ele });
                    return;
                }
                let total = 0;
                total = parseFloat(remainBalance) - parseFloat(ele ? ele : 0)
                total = total?.toFixed(2)
                setValue({ ...value, ['Balance']: total ? total : remainBalance, [e.target.name]: ele });
            }
        } else { setValue({ ...value, [e.target.name]: e.target.value }) }
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'RecoveryTypeID') {
                setValue({ ...value, ['RecoveryTypeID']: e.value })
                setRecoverTypeCode(e.id)
                if (e.id === 'FU' || e.id === 'F') {
                    if (propertyData) {
                        setValue(pre => { return { ...pre, ['RecoveredValue']: remainBalance } })
                    } else {
                        setValue(pre => { return { ...pre, ['RecoveredValue']: sessionStorage.getItem('propertyStolenValue') ? Decrypt_Id_Name(sessionStorage.getItem('propertyStolenValue'), 'SForStolenValue') : '', } })
                    }
                } else if (e.id === 'P' || e.id !== 'FU' || e.id !== 'F') {
                    setValue(pre => { return { ...pre, ['RecoveredValue']: '' } })
                }
            } else {
                setValue({ ...value, [name]: e.setValue })
            }
        } else {
            setValue({ ...value, [name]: null });
        }
    }

    const Add_RecoveredProperty = (e) => {
        if (value.RecoveredValue != 0) {
            if (parseFloat(value.RecoveredValue) <= parseFloat(remainBalance)) {
                if (remainBalance !== 0) {
                    AddDeleteUpadate('RecoveredProperty/Insert_RecoveredProperty', value)
                        .then((res) => {
                            toastifySuccess(res.Message); get_property_Data(propertyID); get_Property_Count(propertyID);
                            setModal(false); setStatus(false); reset();
                        })
                } else {
                    toastifyError("Remaining Balance is Zero")
                }
            } else {
                toastifyError("Recovered value should not be greater than Remaining Value")
            }
        } else {
            toastifyError("The recovered value must be greater than zero")
        }
    }

    const Update_RecoveredProperty = () => {
        AddDeleteUpadate('RecoveredProperty/Update_RecoveredProperty', value).then((res) => {
            toastifySuccess(res.Message); setModal(false); setStatus(false); reset(); get_property_Data(propertyID);
        })
    }
    const OnClose = () => {
        setModal(false); reset(); setRecoveredPropertyID(); setRecoverTypeCode()
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
        }
    };

    const reset = () => {
        setValue(pre => {
            return {
                ...pre, 'RecoveredIDNumber': '', 'RecoveredDateTime': '', 'OfficerPFID': '', 'RecoveryTypeID': '', 'RecoveredValue': '', 'Comments': '', 'Balance': '', 'DispositionID': '', 'UCRRecoveredID': '',
            }
        });
        setErrors({
            ...errors,
            'DispositionIDError': '', 'OfficerPFIDError': '', 'RecoveredDateTimeError': '', 'RecoveryTypeIDError': '', 'Comments': '', 'ContactError': '',
        });
        setRecoverDate("");
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            reset(); setModal(false); setRecoveredPropertyID(); setRecoverTypeCode()
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => { document.removeEventListener("keydown", escFunction, false); };
    }, [escFunction]);


    return (
        <>
            {
                modal ?

                    <dialog className="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="RecoveredPropertyModal" tabIndex="-1" aria-hidden="true" data-backdrop="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="m-1">
                                        <fieldset style={{ border: '1px solid gray' }}>
                                            <legend style={{ fontWeight: 'bold' }}>Recovered Property</legend>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row bb">
                                                        <div className="col-6 col-md-6 col-lg-4 mt-1">
                                                            <div className="text-field">
                                                                <input type="text" className="readonlyColor" value={value?.RecoveredIDNumber} name="recoverid" required readOnly />
                                                                <label>Recovered Id</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6  col-lg-4 pt-1 ">
                                                            <div className="dropdown__box">
                                                                <Select
                                                                    name='DispositionID'
                                                                    value={dispositionsDrpData?.filter((obj) => obj.value === value?.DispositionID)}
                                                                    options={dispositionsDrpData}
                                                                    onChange={(e) => ChangeDropDown(e, 'DispositionID')}
                                                                    styles={colourStyles}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                />
                                                                <label htmlFor='' >Officer Pf</label>
                                                                {errors.OfficerPFIDError !== 'true' ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.OfficerPFIDError}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-4  ">
                                                            <div className="dropdown__box ">
                                                                <DatePicker
                                                                    id='RecoveredDateTime'
                                                                    name='RecoveredDateTime'
                                                                    ref={startRef}
                                                                    onKeyDown={onKeyDown}
                                                                    onChange={(date) => { setRecoverDate(date); setValue({ ...value, ['RecoveredDateTime']: date ? getShowingMonthDateYear(date) : null }) }}
                                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                                    timeInputLabel
                                                                    isClearable={value?.RecoveredDateTime ? true : false}
                                                                    selected={recoverDate}
                                                                    placeholderText={value?.RecoveredDateTime ? value.RecoveredDateTime : 'Select...'}
                                                                    showTimeSelect
                                                                    timeIntervals={1}
                                                                    timeCaption="Time"
                                                                    className='requiredColor'
                                                                    autoComplete='Off'
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    peekNextMonth
                                                                    dropdownMode="select"
                                                                    maxDate={new Date(sessionStorage.getItem('OccurredFromDate') ? Decrypt_Id_Name(sessionStorage.getItem('OccurredFromDate'), 'OForOccurredFromDate') : null)}
                                                                />
                                                                <label htmlFor="" className='pt-1'>Recovered Date/Time</label>
                                                                {errors.RecoveredDateTimeError !== 'true' ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RecoveredDateTimeError}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6  col-lg-4  mt-2">
                                                            <div className="dropdown__box">
                                                                <Select
                                                                    name='RecoveryTypeID'
                                                                    value={recoveryTypeDrpData?.filter((obj) => obj.value === value?.RecoveryTypeID)}
                                                                    options={recoveryTypeDrpData}
                                                                    onChange={(e) => ChangeDropDown(e, 'RecoveryTypeID')}
                                                                    styles={colourStyles}
                                                                    isClearable
                                                                    placeholder="Select..."
                                                                />
                                                                <label htmlFor='' >Recovery Type</label>
                                                                {errors.RecoveryTypeIDError !== 'true' ? (
                                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.RecoveryTypeIDError}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-4" style={{ marginTop: '10px' }}>
                                                            <div className="text-field">
                                                                <input type="text" name="RecoveredValue" value={value?.RecoveredValue} onChange={HandleChanges} className={`${recoverTypeCode === 'P' ? " " : "readonlyColor"} requiredColor`} required readOnly={recoverTypeCode === 'P' ? false : true}
                                                                />
                                                                <label >Recovered Value</label>
                                                            </div>
                                                            {errors.ContactError !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ContactError}</span>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-12  col-md-12 col-lg-12 mt-1 mb-2" >
                                                            <div className="dropdown__box">
                                                                <textarea name='Comments' value={value?.Comments} onChange={HandleChanges} id="Comments" cols="30" rows='2' className="form-control pt-2 pb-2  requiredColor" ></textarea>
                                                                <label htmlFor="" >Comments</label>
                                                            </div>
                                                            {errors.Comments !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Comments}</span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-6 col-md-6 col-lg-6" style={{ marginTop: '2px' }}>
                                                            <div className="text-field">
                                                                <input type="text" name="Balance" value={value?.Balance < 0 ? 0 : value?.Balance} onChange={HandleChanges} className="readonlyColor" required readOnly />
                                                                <label>Balance</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-6  col-lg-6  dropdown__box">
                                                            <Select
                                                                name='DispositionID'
                                                                value={dispositionsDrpData?.filter((obj) => obj.value === value?.DispositionID)}
                                                                options={dispositionsDrpData}
                                                                onChange={(e) => ChangeDropDown(e, 'DispositionID')}
                                                                styles={colourStyles}
                                                                isClearable
                                                                placeholder="Select..."
                                                            />
                                                            <label htmlFor='' >Disposition</label>
                                                            {errors.DispositionIDError !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DispositionIDError}</span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="btn-box text-right  mr-1 mb-2">
                                    {
                                        recoveredPropertyID ?
                                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Update</button>
                                            :
                                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>
                                    }
                                    <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={OnClose} >Close</button>
                                </div>
                            </div>
                        </div>
                    </dialog>

                    :
                    <>
                    </>
            }
        </>
    )
}

export default RecoveredPropertyAddUpp

const Get_Property_Code = (data, dropDownData) => {
    const result = data?.map((sponsor) => (sponsor.RecoveryTypeID))

    const result2 = dropDownData?.map((sponsor) => {
        if (sponsor.value === result[0]) {
            return { value: result[0], label: sponsor.label, id: sponsor.id }
        }
    }
    )
    const val = result2.filter(function (element) {
        return element !== undefined;
    });
    return val[0]?.id
}
