import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AgencyContext } from "../../Context/Agency/Index";
import { toastifySuccess } from "../Common/AlertMsg";
import ThemeSetting from "./ThemeSetting";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

const Header = (props) => {

  const { listManagementSideBar, agencySideBar, personnelSideBar } = props
  const { setAgencyName, agnecyName, setShowPage, setShowPagePersonnel, setInActiveStatus, changesStatus, localStoreArray, get_LocalStorage, setIsLogout } = useContext(AgencyContext)

  const useQuery = () => new URLSearchParams(useLocation().search);
  let aId = useQuery().get('id');
  let goToBack = useQuery().get('call');
  const navigate = useNavigate();

  // Logout User
  const signOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    toastifySuccess('Logout Succesfully !!')
    setIsLogout(true)
    navigate('/')

  }
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Get the mode preference from localStorage (or default to light mode if not available)
    return sessionStorage.getItem('darkMode') === 'true';
  });

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      // Store the new mode preference in localStorage
      sessionStorage.setItem('darkMode', newMode);
      return newMode;
    });
    document.body.classList.toggle('dark-mode');
  };

  // Effect to update body class on initial load
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);


  const [userName, setUserName] = useState('')

  useEffect(() => {
    if (!localStoreArray.UserName || !localStoreArray.LocalAgencyName) {
      get_LocalStorage();
    }
  }, []);

  // Onload Function
  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray.UserName) {
        setUserName(localStoreArray?.UserName);
      }
      if (!agnecyName) {
        setAgencyName(localStoreArray?.LocalAgencyName)
      }
    }
  }, [localStoreArray])

  return (
    <>
      <div id="page_top" className={`${listManagementSideBar ? 'section-body' : personnelSideBar ? 'section-body' : agencySideBar ? 'section-body' : 'section-body top_dark'}`} style={{ padding: `${listManagementSideBar ? '0' : personnelSideBar ? '0' : agencySideBar ? '0' : '20px;'}` }}>
        <div className="container-fluid p-0" style={{ backgroundColor: `${listManagementSideBar ? '#274c77' : personnelSideBar ? '#274c77' : agencySideBar ? '#274c77' : ''}` }} >
          <div className="page-header" >
            <div className="left text-white">
              <i className="mr-2 ml-2"></i>
              <h6 className=" mt-1 pt-1 " >{listManagementSideBar ? 'List Management' : personnelSideBar ? 'Personnel' : agencySideBar ? 'Agency' : ''}</h6>
              <div className="div d-flex header-menu">
                <Link to='/dashboard-page' className=" text-white  ml-2" >
                  <span className=" ">
                    Dashboard
                  </span>
                </Link>
                <div className="dropdown d-flex">
                  <Link to='#' className="nav-link icon text-white btn-icon ml-2" data-toggle="dropdown" >
                    <span className=" ">
                      Application
                      <i className="fa fa-caret-down pl-1" aria-hidden="true"></i>
                    </span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-left dropdown-menu-arrow mt-2 pt-1">
                    <Link className="dropdown-item" to={'/incident'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Incident
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Name
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Arrest
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Property
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Warrant
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Field Interview
                    </Link>
                  </div>

                </div>
                <div className="dropdown d-flex">
                  <Link to='#' className="nav-link icon text-white btn-icon ml-2" data-toggle="dropdown" >
                    <span className=" ">
                      Report
                      <i className="fa fa-caret-down pl-1" aria-hidden="true"></i>
                    </span>
                  </Link>
                </div>
                <div className="dropdown d-flex">
                  <Link to='#' className="nav-link icon text-white btn-icon ml-2" data-toggle="dropdown" >
                    <span className=" ">
                      Utility
                      <i className="fa fa-caret-down pl-1" aria-hidden="true"></i>
                    </span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-left dropdown-menu-arrow mt-2 pt-1">
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      List Manager
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Security Manager
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Editable  Incident
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      List-Module Manager
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Counter Table
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Pervious Year Counter
                    </Link>
                  </div>
                </div>
                <div className="dropdown d-flex">
                  <Link to='#' className="nav-link icon text-white btn-icon ml-2" data-toggle="dropdown" >
                    <span className=" ">
                      Search
                      <i className="fa fa-caret-down pl-1" aria-hidden="true"></i>
                    </span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-left dropdown-menu-arrow mt-2 pt-1">
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Incident
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Name
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Property
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Vehicle
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Warrant
                    </Link>
                    <Link className="dropdown-item" to={'#'} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                      Arrest
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {
              agencySideBar && <span className="text-white">{agnecyName || ''}</span>
            }
            <div className="right " >
              <div className="notification d-flex justify-content-between align-items-center px-3" >
                {
                  listManagementSideBar || agencySideBar || personnelSideBar ?
                    <Link to={listManagementSideBar ? goToBack ? goToBack : '/dashboard-page' : agencySideBar ? `/agency` : personnelSideBar ? `/agencyTab?id=U2FsdGVkX1${aId.split(" ", 3)[0].split("/", 1)[0].substring(10,)}/rbn7XDh9W4GiUkZ4MTV1Vx8pMNVkdjyw=` : '/dashboard-page'}>
                      <button style={{ background: 'inherit', border: 'none', outline: 'none', color: '#e63946' }}
                        onClick={() => {
                          agencySideBar ? setShowPage('home') :
                            personnelSideBar ? setShowPagePersonnel('home') : setInActiveStatus(false);
                        }}
                      >
                        <i className='fa fa-close text-white'></i>

                      </button>

                    </Link>
                    :
                    <></>
                }
                <div>
                  <button onClick={toggleDarkMode} className='dark-toogle'>
                    <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
                  </button>
                </div>
                {
                  listManagementSideBar ? '' : agencySideBar ? '' : personnelSideBar ? '' :
                    <div className="dropdown d-flex">
                      <Link to='#' className="nav-link icon  btn btn-default btn-icon ml-2" data-toggle="dropdown" >
                        <span className="text-uppercase text-white">
                          {userName}&nbsp;
                          <i className="fa fa-caret-down" aria-hidden="true"></i>
                        </span>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                        <Link className="dropdown-item" to={changesStatus ? '#' : "/agency"} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} >
                          <span className="float-right">
                          </span>
                          <i className="fa fa-building-o" aria-hidden="true"></i>&nbsp; Agency
                        </Link>
                        <Link className="dropdown-item" to={changesStatus ? '#' : "/LockedUser"} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''}>
                          <i className="fa fa-lock"></i> &nbsp; Locked User
                        </Link>
                        <Link className="dropdown-item" to={'/'} onClick={() => signOut()}>
                          <i className="fa fa-sign-out"></i>&nbsp; Sign out
                        </Link>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <ThemeSetting />
    </>
  )
}

export default Header;
