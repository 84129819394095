import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Select from "react-select";
import DatePicker from "react-datepicker";
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';
import { getShowingMonthDateYear, getShowingWithOutTime, tableCustomStyles } from '../../../../Common/Utility';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { Comman_changeArrayFormat, threeColArray, threeColArrayWithCode } from '../../../../Common/ChangeArrayFormat';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import FindListDropDown from '../../../../Common/FindListDropDown';
import { OffenceListDropDownArray } from '../../../../Utility/ListDropDownArray/ListDropArray';
import ChangesModal from '../../../../Common/ChangesModal';
import { ErrorStyleLocation, ErrorTooltip, LocationError } from '../ErrorNibrs';
import DataTable from 'react-data-table-component';
import DeletePopUpModal from '../../../../Common/DeleteModal';

const Home = ({ status, setStatus, setOffenceID, }) => {

  const { get_Offence_Count, setShowPage, updateCount, setUpdateCount, setChangesStatus, offenceData, get_Offence_Data, changesStatus, get_Incident_Count, localStoreArray, get_LocalStorage, deleteStoreData, storeData, setIncidentStatus, setOffenceStatus, setIncStatus, offenceFillterData, setOffenceFillterData, } = useContext(AgencyContext);
  const [clickedRow, setClickedRow] = useState(null);

  const navigate = useNavigate();
  const [chargeCodeDrp, setChargeCodeDrp] = useState([]);
  const [lawTitleIdDrp, setLawTitleIdDrp] = useState([]);
  const [nibrsCodeDrp, setNibrsCodeDrp] = useState([]);
  const [offenderLeftSceneDrp, setOffenderLeftSceneDrp] = useState([]);
  const [categoryIdDrp, setCategoryIdDrp] = useState([]);
  const [locationIdDrp, setLocationIdDrp] = useState([]);
  const [editval, setEditval] = useState();
  const [forceUsed, setForceUsed] = useState('');
  const [crimeId, setCrimeId] = useState('');
  const [modal, setModal] = useState(false)

  const [mainIncidentID, setMainIncidentID] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [loginPinID, setLoginPinID,] = useState('');
  const [effectiveScreenPermission, setEffectiveScreenPermission] = useState();


  const [value, setValue] = useState({
    'ChargeCodeID': '',
    'NIBRSCodeId': null,
    'OffenseCodeId': null,
    'LawTitleId': null,
    'OffenderLeftSceneId': null,
    'CategoryId': null,
    'PrimaryLocationId': null,
    'SecondaryLocationId': null,
    // text Box
    'Fine': '', 'CourtCost': '', 'FTAAmt': '', 'LitigationTax': '', 'DamageProperty': '', 'OfRoomsInvolved': '', 'PremisesEntered': '',
    // Checkbox
    'PropertyAbandoned': '',
    'IsForceused': '',
    'IsIncidentCode': false,
    //Radio Button
    'AttemptComplete': 'C',
    //Date picker
    'FTADate': '',
    // other
    'CrimeID': '',
    'IncidentID': '',
    'CreatedByUserFK': '',
    'ModifiedByUserFK': '',
  });

  const [errors, setErrors] = useState({
    'ChargeCodeIDError': '',
    'LawTitleIdError': '',
  });



  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage();
    }
  }, []);
  // console.log(crimeId,'sdfs')
  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray?.AgencyID && localStoreArray?.PINID && localStoreArray?.IncidentID) {
        setLoginAgencyID(localStoreArray?.AgencyID);
        setLoginPinID(localStoreArray?.PINID);
        setMainIncidentID(localStoreArray?.IncidentID);
        if (localStoreArray?.OffenceID) {
          setCrimeId(localStoreArray?.OffenceID);
          setOffenceID(localStoreArray?.OffenceID)
        }
        get_Offence_Count(localStoreArray?.OffenceID);
      }
    }
  }, [localStoreArray])
  // console.log(localStoreArray);
  useEffect(() => {
    if (mainIncidentID) {
      setValue(pre => { return { ...pre, 'IncidentID': mainIncidentID, 'CreatedByUserFK': loginPinID, } })
      get_Offence_Data(mainIncidentID);
    }
  }, [mainIncidentID]);

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.NIBRSCodeId)) {
      setErrors(prevValues => { return { ...prevValues, ['LawTitleIdError']: RequiredFieldIncident(value.NIBRSCodeId) } })
    }
    if (RequiredFieldIncident(value.ChargeCodeID)) {
      setErrors(prevValues => { return { ...prevValues, ['ChargeCodeIDError']: RequiredFieldIncident(value.ChargeCodeID) } })
    }
  }

  // Check All Field Format is True Then Submit 
  const { ChargeCodeIDError, LawTitleIdError } = errors

  useEffect(() => {
    if (ChargeCodeIDError === 'true' && LawTitleIdError === 'true') {
      if (crimeId && status) { Update_Offence() }
      else { Add_Offense() }
    }
  }, [ChargeCodeIDError, LawTitleIdError])

  useEffect(() => {
    if (crimeId) {
      GetSingleData(crimeId)
    }
  }, [crimeId,])

  const GetSingleData = (crimeId) => {
    const val = { 'CrimeID': crimeId }
    fetchPostData('Crime/GetSingleData_Offense', val)
      .then((res) => {
        if (res) {
          setEditval(res);
        } else { setEditval() }
      })
  }

  useEffect(() => {
    if (crimeId) {
      if (editval?.length > 0) {
        setValue({
          ...value,
          'CrimeID': editval[0]?.CrimeID,
          'NIBRSCodeId': editval[0]?.NIBRSCodeId,
          'ChargeCodeID': editval[0]?.ChargeCodeID,
          'LawTitleId': editval[0]?.LawTitleId,
          'OffenseCodeId': editval[0]?.OffenseCodeId,
          'PrimaryLocationId': editval[0]?.PrimaryLocationId,
          'SecondaryLocationId': editval[0]?.SecondaryLocationId,
          'OffenderLeftSceneId': editval[0]?.OffenderLeftSceneId,
          'CategoryId': editval[0]?.CategoryId,
          // text Box
          'Fine': editval[0]?.Fine ? editval[0]?.Fine : '', 'CourtCost': editval[0]?.CourtCost ? editval[0]?.CourtCost : '', 'FTAAmt': editval[0]?.FTAAmt ? editval[0]?.FTAAmt : "",
          'LitigationTax': editval[0]?.LitigationTax ? editval[0]?.LitigationTax : "", 'DamageProperty': editval[0]?.DamageProperty ? editval[0]?.DamageProperty : '', 'OfRoomsInvolved': editval[0]?.OfRoomsInvolved,
          'PremisesEntered': editval[0]?.PremisesEntered,
          // Checkbox
          'PropertyAbandoned': editval[0]?.PropertyAbandoned,
          'IsForceused': editval[0]?.IsForceused === 'N' || editval[0]?.IsForceused === null || editval[0]?.IsForceused === '' ? false : true,
          'IsIncidentCode': editval[0]?.IsIncidentCode,
          //Radio Button
          'AttemptComplete': editval[0]?.AttemptComplete,
          //Date picker
          'FTADate': editval[0]?.FTADate ? getShowingWithOutTime(editval[0]?.FTADate) : '',
          // other
          'ModifiedByUserFK': loginPinID,
        })
        setForceUsed(Get_Nibrs_Code(editval, nibrsCodeDrp));
        getChargeCodeIDDrp(editval[0]?.NIBRSCodeId);
        LawTitleIdDrpDwnVal(editval[0]?.ChargeCodeID, loginAgencyID);
      }
    } else {
      setValue({
        ...value,
        'ChargeCodeID': '',
        'NIBRSCodeId': null,
        'OffenseCodeId': null,
        'LawTitleId': null,
        'OffenderLeftSceneId': null,
        'CategoryId': null,
        'PrimaryLocationId': null,
        'SecondaryLocationId': null,
        // text Box
        'Fine': '', 'CourtCost': '', 'FTAAmt': '', 'LitigationTax': '', 'DamageProperty': '', 'OfRoomsInvolved': '', 'PremisesEntered': '',
        // Checkbox
        'PropertyAbandoned': '',
        'IsForceused': '',
        //Radio Button
        'AttemptComplete': '',
        //Date picker
        'FTADate': '',
        // other
        'CreatedByUserFK': '',
      });
    }
  }, [editval])

  const Reset = () => {
    setValue({
      ...value,
      'ChargeCodeID': '',
      'LawTitleId': '',
      'OffenseCodeId': '',
      'NIBRSCodeId': '',
      'OffenderLeftSceneId': '',
      'CategoryId': '',
      'PrimaryLocationId': '',
      'SecondaryLocationId': '',
      'Fine': '', 'CourtCost': '', 'FTAAmt': '', 'LitigationTax': '', 'DamageProperty': '', 'OfRoomsInvolved': '', 'PremisesEntered': '',
      'PropertyAbandoned': '',
      'IsForceused': '',
      'AttemptComplete': '',
      'FTADate': '',
      'CrimeID': '',

    })
    setErrors({
      ...errors,
      'ChargeCodeIDError': '', 'LawTitleIdError': '',
    })
  }

  const [nibrsCode, setNibrsCode] = useState('');
  const [primaryLocationCode, setPrimaryLocationCode] = useState('');

  const changeDropDown = (e, name) => {
    if (e) {
      if (name === 'NIBRSCodeId') {
        setChangesStatus(true);
        setValue({ ...value, ['NIBRSCodeId']: e.value, ['ChargeCodeID']: null, ['LawTitleId']: null });
        getChargeCodeIDDrp(e.value);
        setNibrsCode(e.id);
        if (e.id === '120') {
          setForceUsed(e.id);
          setValue(pre => ({ ...pre, [name]: e.value }));
        } else {
          setForceUsed('');
          setValue(pre => ({ ...pre, ['IsForceused']: false }));
        }
      } else if (name === 'ChargeCodeID') {
        setValue({ ...value, ['ChargeCodeID']: e.value, ['LawTitleId']: null });
        LawTitleIdDrpDwnVal(e.value, loginAgencyID);
      }
      else if (name === 'PrimaryLocationId') {
        setPrimaryLocationCode(e.id);
        setValue({ ...value, [name]: e.value });
        if (!e.value) {
          setValue(pre => ({ ...pre, ['SecondaryLocationId']: null }));
        }
      } else {
        setChangesStatus(true);
        setValue({ ...value, [name]: e.value });
      }
    } else if (e === null) {
      setChangesStatus(true);
      if (name === 'NIBRSCodeId') {
        setNibrsCode('');
        setChangesStatus(true);
        setValue({
          ...value, ['NIBRSCodeId']: "", ['ChargeCodeID']: "", ['LawTitleId']: null, ['IsForceused']: false,
        });
        setLawTitleIdDrp([]);
        setForceUsed('');
        setChargeCodeDrp([]);
      } else if (name === 'ChargeCodeID') {
        setValue({
          ...value, ['ChargeCodeID']: "", ['LawTitleId']: "",
        });
        setLawTitleIdDrp([]);
      } else if (name === 'PrimaryLocationId') {
        setPrimaryLocationCode('');
        setChangesStatus(true);
        setValue({ ...value, [name]: null });
        setValue(pre => ({ ...pre, ['SecondaryLocationId']: null }));
      } else {
        setChangesStatus(true);
        setValue({ ...value, [name]: null });
      }
    } else {
      setChangesStatus(true);
      setChangesStatus(true);
      setValue({ ...value, [name]: null });
      setForceUsed('');
      setChargeCodeDrp([]);
    }
  }

  const handleChange = (e) => {
    if (e.target.name === 'PropertyAbandoned' || e.target.name === 'IsForceused') {
      setChangesStatus(true)
      setValue({
        ...value,
        [e.target.name]: e.target.checked
      })
    } else if (e.target.name === 'OfRoomsInvolved' || e.target.name === 'PremisesEntered') {
      const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
      setChangesStatus(true)
      setValue({
        ...value,
        [e.target.name]: checkNumber
      });
    }
    else if (e.target.name === 'Fine' || e.target.name === 'LitigationTax' || e.target.name === 'FTAAmt' || e.target.name === 'CourtCost' || e.target.name === 'DamageProperty') {
      var ele = e.target.value.replace(/[^0-9\.]/g, "")
      if (ele.includes('.')) {
        if (ele.length === 16) {
          setChangesStatus(true)
          setValue({ ...value, [e.target.name]: ele });
        } else {

          if (ele.substr(ele.indexOf('.') + 1).slice(0, 2)) {
            const checkDot = ele.substr(ele.indexOf('.') + 1).slice(0, 2).match(/\./g)
            console.log(checkDot)
            if (!checkDot) {
              setChangesStatus(true)
              setValue({ ...value, [e.target.name]: ele.substring(0, ele.indexOf(".")) + '.' + ele.substr(ele.indexOf('.') + 1).slice(0, 2) });
            }
          } else {
            setChangesStatus(true)
            setValue({ ...value, [e.target.name]: ele })
          }
        }
      } else {
        if (ele.length === 16) {
          setChangesStatus(true)
          setValue({
            ...value,
            [e.target.name]: ele
          });
        } else {
          setChangesStatus(true)
          setValue({
            ...value,
            [e.target.name]: ele
          });
        }
      }
    } else {
      setChangesStatus(true)
      setValue({
        ...value,
        [e.target.name]: e.target.value
      })
    }
  }

  useEffect(() => {
    if (loginAgencyID) {
      NIBRSCodeDrpDwnVal(loginAgencyID); OffenderLeftSceneDrpDwnVal(loginAgencyID); CategoryDrpDwnVal(loginAgencyID); LocationIdDrpDwnVal(loginAgencyID);
    }
  }, [loginAgencyID])

  const LocationIdDrpDwnVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('LocationType/GetDataDropDown_LocationType', val).then((data) => {
      if (data) {
        setLocationIdDrp(threeColArray(data, 'LocationTypeID', 'Description', 'LocationTypeCode'))
      } else {
        setLocationIdDrp([]);
      }
    })
  }

  const CategoryDrpDwnVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('ChargeCategory/GetDataDropDown_ChargeCategory', val).then((data) => {
      if (data) {
        setCategoryIdDrp(Comman_changeArrayFormat(data, 'ChargeCategoryID', 'Description'))
      } else {
        setCategoryIdDrp([]);
      }
    })
  }

  const OffenderLeftSceneDrpDwnVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('CrimeLeftScene/GetDataDropDown_CrimeLeftScene', val).then((data) => {
      if (data) {
        setOffenderLeftSceneDrp(Comman_changeArrayFormat(data, 'LeftSceneId', 'Description'))
      } else {
        setOffenderLeftSceneDrp([]);
      }
    })
  }

  const NIBRSCodeDrpDwnVal = (loginAgencyID) => {
    const val = {
      AgencyID: loginAgencyID,
    }
    fetchPostData('FBICodes/GetDataDropDown_FBICodes', val).then((data) => {
      if (data) {
        setNibrsCodeDrp(threeColArrayWithCode(data, 'FBIID', 'Description', 'FederalSpecificFBICode'))
      } else {
        setNibrsCodeDrp([]);
      }
    })
  }

  const LawTitleIdDrpDwnVal = (ChargeID, loginAgencyID) => {
    const val = {
      ChargeCodeID: ChargeID,
      AgencyID: loginAgencyID,
    }
    fetchPostData('LawTitle/GetDataDropDown_LawTitle', val).then((data) => {
      if (data) {
        setLawTitleIdDrp(Comman_changeArrayFormat(data, 'LawTitleID', 'Description'))
      } else {
        setLawTitleIdDrp([]);
      }
    })
  }

  const getChargeCodeIDDrp = (FBIID) => {
    const val = {
      'FBIID': FBIID,
      'AgencyID': null,
    }
    fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
      if (data) {
        setChargeCodeDrp(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
      } else {
        setChargeCodeDrp([]);
      }
    })
  }

  useEffect(() => {
    if (status) {
      setValue({
        ...value,
        ['FTADate']: '',
      });
    }
  }, [])

  // Custom Style
  const colourStyles = {
    control: (styles) => ({
      ...styles, backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),

  };

  // custuom style withoutColor
  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  const Add_Offense = () => {
    AddDeleteUpadate('Crime/Insert_Offense', value).then((res) => {
      if (res.success) {
        // console.log(res);
        toastifySuccess(res.Message);
        if (res.CrimeID) {
          setCrimeId(res?.CrimeID);
          setOffenceID(res?.CrimeID);
          storeData({ 'OffenceID': res.CrimeID, 'OffenceStatus': true });
          setOffenceStatus(true);
          get_Incident_Count(mainIncidentID)
          get_Offence_Data(mainIncidentID);

        }
        setUpdateCount(updateCount + 1)
        // setChangesStatus(false)
        setStatus(false);
        setStatusFalse()
        setErrors({
          ...errors,
          ['ChargeCodeIDError']: '',
        })
      }
    })
  }
  const setStatusFalse = (e) => {
    setStatus(false);
    // setModal(true);
    Reset();
  }
  const Update_Offence = () => {
    AddDeleteUpadate('Crime/Update_Offense', value).then((res) => {
      toastifySuccess(res.Message)
      setChangesStatus(false)
      // setStatus(true)
      setStatusFalse();
      setErrors({
        ...errors,
        ['ChargeCodeIDError']: '',
      })
    })
  }

  const startRef = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const Cancel = () => {
    if (!changesStatus) {
      navigate('/Off-Home');
      deleteStoreData({ 'OffenceID': '', 'OffenceStatus': '' });
      setIncidentStatus(true);

    }
  }
  const columns = [
    // {
    //   width: '120px',
    //   name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
    //   cell: row =>
    //     <div style={{ position: 'absolute', top: 4, left: 20 }}>
    //       {
    //         effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok ?
    //           <Link to={'/Off-Home'} onClick={(e) => setEditVal(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
    //             <i className="fa fa-edit"></i>
    //           </Link>
    //           : <></>
    //           : <Link to={'/Off-Home'} onClick={(e) => setEditVal(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
    //             <i className="fa fa-edit"></i>
    //           </Link>
    //       }
    //     </div>

    // },
    {
      name: 'NIBRS Code',
      selector: (row) => row.FBIID_Description,
      sortable: true
    },
    {
      name: 'Offense',
      selector: (row) => row.OffenseName_Description,
      sortable: true
    },
    {
      name: 'Law Title',
      selector: (row) => row.LawTitle_Description,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 30 }}>Action</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, right: 30 }}>
          {/* {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.Changeok ?
              <Link to={'/Off-Home'} onClick={(e) => setEditVal(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                <i className="fa fa-edit"></i>
              </Link>
              : <></>
              : <Link to={'/Off-Home'} onClick={(e) => setEditVal(row)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1">
                <i className="fa fa-edit"></i>
              </Link>
          } */}
          {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
              <Link to={`#`} onClick={(e) => setCrimeId(row.CrimeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </Link>
              : <></>
              : <Link to={`#`} onClick={(e) => setCrimeId(row.CrimeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </Link>
          }
        </div>

    }
  ]
  const setEditVal = (row) => {
    if (row.CrimeID) {
      get_Offence_Count(row.CrimeID)
      setCrimeId(row.CrimeID);
      setOffenceID(row?.CrimeID);
      setOffenceStatus(true);
      setStatus(true);
      // storeData({ 'OffenceID': row.CrimeID, 'OffenceStatus': true })
    }
    setUpdateCount(updateCount + 1)
    setIncStatus(true)
  }
  const DeleteOffence = () => {
    const val = {
      'CrimeID': crimeId,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate('Crime/Delete_Offense', val).then((res) => {
      toastifySuccess(res.Message);
      get_Incident_Count(mainIncidentID)
      get_Offence_Data(mainIncidentID);
      setStatus(false);
      Reset();
    })
  }
  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];


  return (
    <>

      <div className="col-12 bb">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <Link to={'/ListManagement?page=Law%20Title&call=/Off-Home'} className='new-link'>
                  Law Title
                </Link>
              </div>
              <div className="col-7 col-md-7 col-lg-7  mt-2">
                <Select
                  name='LawTitleId'
                  styles={customStylesWithOutColor}
                  value={lawTitleIdDrp?.filter((obj) => obj.value === value?.LawTitleId)}
                  isClearable
                  options={lawTitleIdDrp}
                  onChange={(e) => changeDropDown(e, 'LawTitleId')}
                  placeholder="Select..."
                />
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <Link to={'/ListManagement?page=Charge%20Code&call=/Off-Home'} className='new-link'>
                  Offense Code/Name
                </Link>
              </div>
              <div className="col-7 col-md-7 col-lg-7  mt-2">
                <Select
                  name='ChargeCodeID'
                  styles={colourStyles}
                  value={chargeCodeDrp?.filter((obj) => obj.value === value?.ChargeCodeID)}
                  isClearable
                  options={chargeCodeDrp}
                  onChange={(e) => changeDropDown(e, 'ChargeCodeID')}
                  placeholder="Select..."
                />
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <label htmlFor="" className='new-label'>Primary Location</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <Select
                  name='PrimaryLocationId'
                  styles={ErrorStyleLocation(primaryLocationCode, nibrsCode, value?.PremisesEntered)}
                  value={locationIdDrp?.filter((obj) => obj.value === value?.PrimaryLocationId)}
                  isClearable
                  options={locationIdDrp}
                  onChange={(e) => changeDropDown(e, 'PrimaryLocationId')}
                  placeholder="Select..."
                />
              </div>

              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <label htmlFor="" className='new-label'># Of Rooms Involved</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 text-field">
                <input type="text" name='OfRoomsInvolved' value={value?.OfRoomsInvolved} onChange={handleChange} maxLength={4} required />
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <label htmlFor="" className='new-label'>Category</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7  mt-2">
                <Select
                  name='CategoryId'
                  styles={customStylesWithOutColor}
                  value={categoryIdDrp?.filter((obj) => obj.value === value?.CategoryId)}
                  isClearable
                  options={categoryIdDrp}
                  onChange={(e) => changeDropDown(e, 'CategoryId')}
                  placeholder="Select..."
                />
              </div>
              <div className="col-1 col-md-1 col-lg-4">
              </div>
              <div className="col-6 col-md-6 col-lg-5 mt-3">
                <div className="form-check ">
                  <input className="form-check-input" type="checkbox" name='PropertyAbandoned' checked={value?.PropertyAbandoned} value={value?.PropertyAbandoned} onChange={handleChange} id="flexCheckDefault" />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Property Abandoned
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-6 col-lg-3 mt-3 mb-1">
                <div className="form-check ">
                  <input className="form-check-input" type="checkbox" name='IsForceused' checked={value?.IsForceused} value={value?.IsForceused} disabled={forceUsed === '120' ? false : true} onChange={handleChange} id="flexCheckDefault3" />
                  <label className="form-check-label" htmlFor="flexCheckDefault3">
                    Force Used
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <Link to={'/ListManagement?page=FBI%20Code&call=/Off-Home'} className='new-link'>
                  NIBRS Code
                </Link>
              </div>
              <div className="col-7 col-md-7 col-lg-8  mt-2">
                <Select
                  name='NIBRSCodeId'
                  styles={colourStyles}
                  value={nibrsCodeDrp?.filter((obj) => obj.value === value?.NIBRSCodeId)}
                  isClearable
                  options={nibrsCodeDrp}
                  onChange={(e) => changeDropDown(e, 'NIBRSCodeId')}
                  placeholder="Select..."
                />
              </div>

              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <Link to={'/ListManagement?page=Crime%20Left%20%20Scene&call=/Off-Home'} className='new-link'>
                  Offender Left Scene
                </Link>
              </div>
              <div className="col-7 col-md-7 col-lg-8  mt-2">
                <Select
                  name='OffenderLeftSceneId'
                  styles={customStylesWithOutColor}
                  value={offenderLeftSceneDrp?.filter((obj) => obj.value === value?.OffenderLeftSceneId)}
                  isClearable
                  options={offenderLeftSceneDrp}
                  onChange={(e) => changeDropDown(e, 'OffenderLeftSceneId')}
                  placeholder="Select..."
                />
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1">
                <label htmlFor="" className='new-label'>Secondary Location</label>
              </div>
              <div className="col-7 col-md-7 col-lg-8  mt-2">
                <Select
                  name='SecondaryLocationId'
                  styles={customStylesWithOutColor}
                  value={locationIdDrp?.filter((obj) => obj.value === value?.SecondaryLocationId)}
                  isClearable
                  options={locationIdDrp}
                  onChange={(e) => changeDropDown(e, 'SecondaryLocationId')}
                  placeholder="Select..."
                  isDisabled={!value?.PrimaryLocationId}
                />
              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-1 ">
                <label htmlFor="" className='new-label '>Damage Property</label>
              </div>
              <div className="col-7 col-md-7 col-lg-8 mt-2 text-field">
                <input type="text" name='DamageProperty' value={'$' + value?.DamageProperty} onChange={handleChange} maxLength={16} required />
              </div>
              <div className="col-1 col-md-1 col-lg-4">
              </div>
              <div className="col-6 col-md-6 col-lg-4 mt-2 pt-1">
                <div className="form-check ">
                  {
                    value?.AttemptComplete === "A" ?

                      <input className="form-check-input mt-1" type="radio" onChange={handleChange} value="Attempted" checked={value.AttemptComplete} name="AttemptComplete" id="flexRadioDefault1" />

                      :
                      <input className="form-check-input mt-1" type="radio" onChange={handleChange} value="Attempted" name="AttemptComplete" id="flexRadioDefault1" />
                  }
                  <label className="form-check-label " htmlFor="flexRadioDefault1" >
                    Attempted
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-6 col-lg-4 mt-2 pt-1">
                <div className="form-check  ">
                  {
                    value?.AttemptComplete === "C" ?

                      <input className="form-check-input " type="radio" onChange={handleChange} value="Completed" checked={value.AttemptComplete} name="AttemptComplete" id="flexRadioDefault2" />

                      :
                      <input className="form-check-input" type="radio" onChange={handleChange} value="Completed" name="AttemptComplete" id="flexRadioDefault2" />
                  }
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Completed
                  </label>
                </div>
              </div>
            </div>
          </div>






          {/* <div className="col-6 col-md-6 col-lg-4 mt-2">
          <div className="text-field">
            <input type="text" name='Fine' value={'$' + value?.Fine} onChange={handleChange} maxLength={16} required />
            <label>Fine</label>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mt-2">
          <div className="text-field">
            <input type="text" required name='CourtCost' maxLength={19} value={'$' + value?.CourtCost} onChange={handleChange} />
            <label>Court Cost</label>
          </div>
        </div> */}
          {/* <div className="col-6 col-md-6 col-lg-4 mt-3 date__box">
          <DatePicker
            id='FTADate'
            name='FTADate'
            ref={startRef}
            onKeyDown={onKeyDown}
            onChange={(date) => { setValue({ ...value, ['FTADate']: date ? getShowingMonthDateYear(date) : null }) }}
            dateFormat="MM/dd/yyyy"
            isClearable={value?.FTADate ? true : false}
            selected={value?.FTADate && new Date(value?.FTADate)}
            maxDate={new Date()}
            placeholderText={'Select...'}
            autoComplete="Off"
            showDisabledMonthNavigation
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
          />
          <label htmlFor="">FTA Date</label>
        </div> */}
          {/* <div className="col-6 col-md-6 col-lg-4 mt-2">
          <div className="text-field">
            <input type="text" name='FTAAmt' value={'$' + value?.FTAAmt} onChange={handleChange} maxLength={16} required />
            <label>FTA Amt</label>
          </div>
        </div>
        <div className="col-6 col-md-6 col-lg-4 mt-2">
          <div className="text-field">
            <input type="text" name='LitigationTax' value={'$' + value?.LitigationTax} onChange={handleChange} maxLength={16} required />
            <label>Litigation Tax</label>
          </div>
        </div> */}



          {/* <div className="col-6 col-md-6 col-lg-4 pt-2 ">
          <div className="text-field">
            <input type="text" name='PremisesEntered' maxLength={2} value={value?.PremisesEntered} onChange={handleChange} required />
            <label>Premises Entered</label>
          </div>
        </div> */}

        </div>
        <div className="col-12 text-right mb-1 field-button" >
          <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => { setStatusFalse(); }}>New</button>
          {
            status && crimeId ?
              <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success mr-4">Update</button>
              :
              <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success mr-4">Save</button>
          }
          {/* <button type="button" className="btn btn-sm btn-success mr-4" onClick={() => { Cancel() }} data-toggle={changesStatus ? "modal" : ""} data-target={changesStatus ? "#SaveModal" : ''} data-dismiss="modal">Close</button> */}
        </div>
      </div>
      <div className="px-0 mt-2" style={{ height: '160px', overflowY: 'scroll' }}>
        <DataTable
          dense
          columns={columns}
          data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? offenceFillterData : '' : offenceFillterData}
          // selectableRowsHighlight
          highlightOnHover
          noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
          responsive
          // subHeaderComponent={<ThreeFilter Data={offenceData} setResult={setOffenceFillterData} Col1='FBIID_Description' Col2='OffenseName_Description' Col3='LawTitle_Description' searchName1='NIBRS Code' searchName2='Offense' searchName3='LawTitle' />}
          // pagination
          // paginationPerPage={'3'}
          // paginationRowsPerPageOptions={[3]}
          customStyles={tableCustomStyles}
          onRowClicked={(row) => {
            setClickedRow(row);
            setEditVal(row);
          }}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>

      <IdentifyFieldColor />
      <ChangesModal func={check_Validation_Error} />
      <DeletePopUpModal func={DeleteOffence} />
    </>


  )
}

export default Home

const Get_Nibrs_Code = (data, dropDownData) => {
  const result = data?.map((sponsor) =>
    (sponsor.NIBRSCodeId)
  )
  const result2 = dropDownData?.map((sponsor) => {
    if (sponsor.value === result[0]) {
      return { value: result[0], label: sponsor.label, id: sponsor.id }
    }
  }
  )
  const val = result2.filter(function (element) {
    return element !== undefined;
  });
  return val[0]?.id
}