// Axios Interceptop -> Send access_token all component

import axios from "axios";

const url = window.location.origin;

if (url === 'https://rms1.arustu.com') {
    axios.defaults.baseURL = 'https://rmsapi1.arustu.com/api/';
} else if (url === 'https://rms2.arustu.com' || url === 'https://rms.arustu.com') {
    axios.defaults.baseURL = 'https://rmsapi2.arustu.com/api/';
} else if (url === 'https://rmsgoldline.com') {
    axios.defaults.baseURL = 'https://apigoldline.com:5002/api/';
} else if (url === 'http://localhost:3000') {
    axios.defaults.baseURL = 'https://rmsapi2.arustu.com/api/';
} else if (url === 'https://nibrs.arustu.com') {
    axios.defaults.baseURL = 'https://nibrsapi.arustu.com/api/';
} else { axios.defaults.baseURL = 'https://rmsapidev.newinblue.com/api/'; }

let refresh = false;

const AxiosCom = () => {

    axios.interceptors.request.use(async (request) => {
        const access_token = sessionStorage.getItem('access_token');
        request.headers['Authorization'] = `Bearer ${access_token}`
        return request;
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(resp => { return resp }, async error => {
        if (error.response.status === 401 && !refresh) {
            refresh = true;
            try {
                const refresh_token = sessionStorage.getItem('refresh_token');
                const response = await axios.post('Account/GetToken', { refresh_token: refresh_token, grant_type: 'refresh_token' });
                console.log("axios refresh Token", response)
                if (response.status === 200) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data['access_token']}`;
                    return axios(error.config);
                }
            } catch (refreshError) {
                console.error('Error refreshing access token:', refreshError);
            } finally {
                refresh = false;
            }
        }
        return Promise.reject(error);
    });
    return <div></div>;
};

export default AxiosCom;

