import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { fetchPostData, AddDeleteUpadate } from '../../../../hooks/Api';
import { Decrypt_Id_Name } from '../../../../Common/Utility';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { NaSMTListDropDownArray } from '../../../../Utility/ListDropDownArray/ListDropArray';
import FindListDropDown from '../../../../Common/FindListDropDown';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import SmtAddUp from './SmtAddUp';

const Smt = () => {

    const { get_Name_Count, localStoreArray, get_LocalStorage, } = useContext(AgencyContext);
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');

    const [smtData, setSmtData] = useState();
    const [status, setStatus] = useState(false);
    const [modal, setModal] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(0)
    const [smtId, setSmtId] = useState();
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [masterNameID, setMasterNameID,] = useState('');
    const [nameID, setNameID] = useState();

    const localStore = {
        Value: "",
        UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
        Key: JSON.stringify({ AgencyID: "", PINID: "", MasterNameID: '', NameID: '', Agency_Name: "", }),
    }

    useEffect(() => {
        if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
            get_LocalStorage(localStore);
        }
    }, []);

    useEffect(() => {
        if (localStoreArray) {
            if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
                setLoginAgencyID(localStoreArray?.AgencyID);
                setLoginPinID(parseInt(localStoreArray?.PINID));
                setNameID(localStoreArray?.NameID); setMasterNameID(localStoreArray?.MasterNameID);
                get_Smt_Data(localStoreArray?.NameID, localStoreArray?.MasterNameID);
            }
        }
    }, [localStoreArray])


    const get_Smt_Data = (NameID, masterNameID) => {
        const val = {
            'NameID': NameID,
        }
        const req = {
            'MasterNameID': masterNameID,
        }
        fetchPostData(openPage === 'mastername' ? 'MainMasterNameSMT/GetData_MainMasterNameSMT' : 'NameSMT/GetData_NameSMT', openPage === 'mastername' ? req : val).then((res) => {
            if (res) {
                setSmtData(res)
            } else {
                setSmtData([]);
            }
        })
    }

    const columns = [
        {
            width: '120px',
            name: <p className='text-end' style={{ position: 'absolute', top: 8, }}>Action</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 4, left: 20 }}>
                    <Link to={openPage === 'mastername' ? '/nametab?page=mastername' : '/nametab'} onClick={(e) => { set_Edit_Value(e, row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#SMTModal" >
                        <i className="fa fa-edit"></i></Link>
                </div>
        },
        {
            name: 'SMT Type',
            selector: (row) => <>{row?.SMTType_Description} </>,
            sortable: true
        },
        {
            name: 'SMT Location',
            selector: (row) => <>{row?.SMTLocation_Description} </>,
            sortable: true
        },
        {
            name: 'Description',
            selector: (row) => <>{row?.SMT_Description ? row?.SMT_Description.substring(0, 50) : ''}{row?.SMT_Description?.length > 40 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 3 }}>Delete</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 4, right: 7 }}>

                    <Link to={openPage === 'mastername' ? '/nametab?page=mastername' : '/nametab'} onClick={() => { setSmtId(row.SMTID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                        <i className="fa fa-trash"></i>
                    </Link>

                </div>

        }
    ]

    const set_Edit_Value = (e, row) => {
        setStatus(true);
        setModal(true)
        setUpdateStatus(updateStatus + 1);
        setSmtId(row.SMTID);
    }

    const DeleteCourtDisposition = () => {
        const val = {
            'SMTID': smtId,
            'DeletedByUserFK': loginPinID,
        }
        AddDeleteUpadate('NameSMT/Delete_NameSMT', val).then((res) => {
            if (res) {
                toastifySuccess(res.Message);
                get_Name_Count(nameID);
                get_Smt_Data(nameID, masterNameID); setSmtId('');
            } else console.log("Somthing Wrong");
        })
    }

    const setStatusFalse = (e) => {
        setStatus(false); setModal(true); setSmtId();
        setUpdateStatus(updateStatus + 1);

    }

    return (
        <>
            <div className="col-md-12 mt-2">
                <div className="bg-line text-white py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                    <p className="p-0 m-0 d-flex align-items-center">
                        SMT
                    </p>
                    <div>
                        <Link to={openPage === 'mastername' ? '/nametab?page=mastername' : '/Name-Home?page=SMT'} className="btn btn-sm bg-green text-white px-2 py-0" onClick={setStatusFalse}
                            data-toggle="modal" data-target="#SMTModal" style={{ marginTop: '-7px' }}>
                            <i className="fa fa-plus"></i>
                        </Link>

                        <FindListDropDown
                            array={NaSMTListDropDownArray}
                        />
                    </div>
                </div>
                <DataTable
                    dense
                    columns={columns}
                    data={smtData}
                    pagination
                    highlightOnHover
                    noDataComponent={"There are no data to display"}
                />
            </div>
            <DeletePopUpModal func={DeleteCourtDisposition} />
            <SmtAddUp {...{ nameID, masterNameID, loginPinID, loginAgencyID, status, setStatus, setSmtId, smtId, modal, setModal, get_Smt_Data, updateStatus }} />
        </>
    )
}

export default Smt