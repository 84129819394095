import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AgencyContext } from "../../../Context/Agency/Index";

const DashboardSidebar = () => {

    const { incidentRecentData } = useContext(AgencyContext)
    const [expand, setExpand] = useState()
    const [expandList, setExpandList] = useState()
    const [plusMinus, setPlusMinus] = useState(false)
    const [plusMinus1, setPlusMinus1] = useState(false)
    const [plusMinus2, setPlusMinus2] = useState(false)
    const [plusMinus3, setPlusMinus3] = useState(false)

    const callUtilityModules = (type, val) => {
        if (type === 'List') {
            setPlusMinus(!plusMinus);
            setPlusMinus1(!plusMinus1)
            setPlusMinus2(!plusMinus2)
            setPlusMinus3(!plusMinus3)
            setExpand(expand ? expand[0].id === val ? { id: val } : '' : { id: val })
        } else {
            setPlusMinus(!plusMinus);
            setExpandList(expandList === val ? '' : val);
        }
    }

    return (
        <>
            <li className="">
                <Link to="/dashboard-page">
                    <i className="fa fa-home ml-3"></i>
                    <span>Dashboard</span>  
                </Link>
            </li>
            <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus} onClick={() => callUtilityModules('Table', 'Master Table')}><i className="fa fa-lock ml-3"></i><span>Utility</span></Link>
                <ul id="menu" role="menu" className={`${expandList === 'Master Table' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-23px' }}>
                    <li className="ml-2 p-0">
                        <Link to={`/ListManagement`}>
                            <i className="fa fa-home "></i>
                            <span className="m-0 p-0">List Manager </span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/security-manager`}>
                            <i className="fa fa-home"></i>
                            <span className="m-0 p-0">Security Manager </span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/Editable-Incident`}>
                            <i className="fa fa-home"></i>
                            <span className="m-0 p-0">Editable  Incident </span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/ListPermission`}>
                            <i className="fa fa-home"></i>
                            <span className="m-0 p-0">List-Module Manager </span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/CounterTable`}>
                            <i className="fa fa-home"></i>
                            <span className="m-0 p-0">Counter Table</span>
                        </Link>
                    </li>
                    <li className="ml-2 p-0">
                        <Link to={`/PreviousYearCounter`}>
                            <i className="fa fa-home"></i>
                            <span className="m-0 p-0">Pervious Year Counter</span>
                        </Link>
                    </li>
                </ul>
            </li>
            {/* reports */}
            <li>
                <Link to="/Reports" className="has-arrow arrow-c" aria-expanded={plusMinus1} onClick={() => callUtilityModules('Table1', 'Master Table1')}>
                    <i className="fa fa-file ml-3" aria-hidden="true" style={{ fontSize: '13px' }}></i>
                    <span>Report</span>
                </Link>

            </li>
            {/* Applications */}
            <li>
                <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus2} onClick={() => callUtilityModules('Table2', 'Master Table2')}>
                    <i className="fa fa-file ml-3" aria-hidden="true" style={{ fontSize: '13px' }}></i>
                    <span>Application</span>
                </Link>
                <ul id="menu" role="menu" aria-expanded={expandList === 'Master Table2'} className={`${expandList === 'Master Table2' ? 'collapse in' : 'collapse'}`} style={{ marginLeft: '-23px' }}>
                    <li className="ml-2 p-0">
                        <Link to={`/PawnInformation`}>
                            <i className="fa fa-home"></i>
                            <span className="m-0 p-0">Pawn Information</span>
                        </Link>
                    </li>
                </ul>
            </li>
            <li>
                <Link to="/Search" className="has-arrow arrow-c" aria-expanded={plusMinus3} onClick={() => callUtilityModules('Table3', 'Master Table3')}>
                    <i className="fa fa-search ml-3" aria-hidden="true"></i>
                    <span>Search</span>
                </Link>
            </li>
            <div className="dropdown-divider"></div>
            <p>
                <Link to="#" ><i className="fa fa-chevron-right ml-3"></i><span className="ml-3">Recent</span></Link>
                <ul className="recent">
                    {
                        incidentRecentData?.map((val) => (
                            <li key={val.IncidentID}>
                                <Link style={{ display: 'flex', flexDirection: 'column' }} to={`#`} onClick={() => {

                                }}>
                                    <span>Incident-{val.IncidentNumber}</span>
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </p>
        </>
    )
}

export default DashboardSidebar