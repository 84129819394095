import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react'
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor'
import Select from 'react-select';
import { DecryptedList, EncryptedList } from '../../../../Common/Utility';
import { FaxField, MunicipalityCodeValidator, ORIValidator, PhoneField, RequiredField } from '../../AgencyValidation/validators';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { AddDeleteUpadate, fetchData, fetchPostData, fieldPermision, ScreenPermision } from '../../../../hooks/Api';
import { Agency_Field_Permistion_Filter } from '../../../../Filter/AgencyFilter';
import { useNavigate } from 'react-router-dom';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import uploadImage from '../../../../../img/uploadImage.png'
import ChangesModal from '../../../../Common/ChangesModal';

const Home = (props) => {

    const { aId, pinID, allowMultipleLogin } = props

    // Hooks Initialization
    const { agencyData, getAgency, get_CountList, setStatus, status, inActiveStatus, setInActiveStatus, changesStatus, setChangesStatus, getInActiveAgency, setAgencyName } = useContext(AgencyContext);

    const navigate = useNavigate();
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [zipList, setZipList] = useState([]);
    const [agencyEditVal, setAgencyEditVal] = useState([]);
    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState();
    const [image, setImage] = useState('');

    const [errors, setErrors] = useState({
        'ORI': '',
        'MunicipalityCode': '',
        'Agency_Name': '',
        'ShortName': '',
        'Agency_Address1': '',
        'Agency_StateId': '',
        'Agency_CityId': '',
        'Agency_ZipId': '',
        'Agency_Phone': '',
        'Agency_Fax': '',
    })

    const [value, setValue] = useState({
        'ORI': '', 'MunicipalityCode': '', 'Agency_Name': '', 'ShortName': '', 'Agency_Address1': '', 'Agency_StateId': '', 'Agency_CityId': '', 'Agency_ZipId': '', 'Agency_Phone': '', 'Agency_Fax': '', 'ZipName': '', 'StateName': '', 'CityName': '', 'IsActive': true,
        'AgencyID': aId,
        'CreatedByUserFK': pinID,
        'ModifiedByUserFK': '',
    })

    const [fieldPermissionAgency, setFieldPermissionAgency] = useState({
        // Agency form
        'ORI': '', 'MunicipalityCode': '', 'Agency_Name': '', 'ShortName': '', 'Agency_Address1': '', 'Agency_StateId': '', 'Agency_CityId': '', 'Agency_ZipId': '', 'Agency_Phone': '', 'Agency_Fax': '',
    })

    // Onload Function
    useEffect(() => {
        getStateList();
        if (aId && pinID) {
            getScreenPermision(aId, pinID)
            get_Field_Permision_Agency(aId, pinID)
        }
    }, [aId, pinID])

    useEffect(() => {
        if (aId) {
            if (aId && localStorage.getItem('status')) {
                get_Edit_Agency_Data(aId);
                setValue({ ...value, ['ZipName']: '', ['StateName']: '', ['CityName']: '' }); setErrors({
                    'ORI': '', 'MunicipalityCode': '', 'Agency_Name': '', 'ShortName': '', 'Agency_Address1': '', 'Agency_StateId': '', 'Agency_CityId': '', 'Agency_ZipId': '', 'Agency_Phone': '', 'Agency_Fax': '',
                })
            }
        } else if (!status) {
            rest_Value();
        }
    }, [aId, status])

    useEffect(() => {
        if (agencyEditVal[0]?.AgencyID) {
            setValue({
                ...value,
                'ORI': agencyEditVal[0]?.ORI, 'IsActive': agencyEditVal[0]?.IsActive,
                'MunicipalityCode': agencyEditVal[0]?.MunicipalityCode,
                'Agency_Name': agencyEditVal[0]?.Agency_Name,
                'ShortName': agencyEditVal[0]?.ShortName,
                'Agency_Address1': agencyEditVal[0]?.Agency_Address1,
                'Agency_StateId': agencyEditVal[0]?.Agency_StateId,
                'Agency_CityId': agencyEditVal[0]?.Agency_CityId,
                'Agency_ZipId': agencyEditVal[0]?.Agency_ZipId,
                'Agency_Phone': agencyEditVal[0]?.Agency_Phone,
                'Agency_Fax': agencyEditVal[0]?.Agency_Fax,
                'AgencyID': agencyEditVal[0]?.AgencyID,
                'ZipName': changeArrayFormat_WithFilter(agencyEditVal, 'zip'), 'StateName': changeArrayFormat_WithFilter(agencyEditVal, 'state'), 'CityName': changeArrayFormat_WithFilter(agencyEditVal, 'city'),
                'ModifiedByUserFK': pinID,
            });
            setAgencyName(agencyEditVal[0]?.Agency_Name ? agencyEditVal[0]?.Agency_Name : '');
            if (agencyEditVal[0]?.IsActive) { setStatus(true); } else { setStatus(false); }
            getCity(agencyEditVal[0]?.Agency_StateId)
            getZipCode(agencyEditVal[0]?.Agency_CityId)
        } else if (!changesStatus) {
            setValue({
                ...value, 'ORI': '', 'MunicipalityCode': '', 'Agency_Name': '', 'ShortName': '', 'Agency_Address1': '', 'Agency_StateId': '', 'Agency_CityId': '', 'Agency_ZipId': '', 'Agency_Phone': '', 'Agency_Fax': '', 'AgencyID': ''
            }); setZipList([]); setCityList([]);
            setStatus(false)
        }
    }, [agencyEditVal])

    useEffect(() => {
        if (inActiveStatus) {
            rest_Value()
        } else if (!inActiveStatus) { rest_Value(); setStatus(false) }
    }, [inActiveStatus])

    // Get Effective Field Permission
    const get_Field_Permision_Agency = (aId, pinID) => {
        fieldPermision(aId, 'A001', pinID).then(res => {
            if (res) {
                const agencyOriFilter = Agency_Field_Permistion_Filter(res, "Agency-ORI");
                const agencyShortNameFilter = Agency_Field_Permistion_Filter(res, "Agency-ShortName");
                const agencyAgencyNameFilter = Agency_Field_Permistion_Filter(res, "Agency-AgencyName");
                const agencyAddressFilter = Agency_Field_Permistion_Filter(res, "Agency-Address");
                const agencyStateFilter = Agency_Field_Permistion_Filter(res, "Agency-State");
                const agencyCityFilter = Agency_Field_Permistion_Filter(res, "Agency-City");
                const agencyPhoneFilter = Agency_Field_Permistion_Filter(res, "Agency-Phone");
                const agencyFaxFilter = Agency_Field_Permistion_Filter(res, "Agency-Fax");
                const agencyMunicipalityCodeFilter = Agency_Field_Permistion_Filter(res, "Agency-MunicipalityCode");
                const agencyZipFilter = Agency_Field_Permistion_Filter(res, "Agency-Zip");

                setFieldPermissionAgency(prevValues => {
                    return {
                        ...prevValues,
                        ['ORI']: agencyOriFilter || prevValues['ORI'],
                        ['ShortName']: agencyShortNameFilter || prevValues['ShortName'],
                        ['Agency_Name']: agencyAgencyNameFilter || prevValues['Agency_Name'],
                        ['Agency_Address1']: agencyAddressFilter || prevValues['Agency_Address1'],
                        ['Agency_StateId']: agencyStateFilter || prevValues['Agency_StateId'],
                        ['Agency_CityId']: agencyCityFilter || prevValues['Agency_CityId'],
                        ['Agency_Phone']: agencyPhoneFilter || prevValues['Agency_Phone'],
                        ['Agency_Fax']: agencyFaxFilter || prevValues['Agency_Fax'],
                        ['MunicipalityCode']: agencyMunicipalityCodeFilter || prevValues['MunicipalityCode'],
                        ['Agency_ZipId']: agencyZipFilter || prevValues['Agency_ZipId'],

                    }
                });
            }
        });
    }
    // onChange Hooks Function
    function stateChanges(e) {
        if (e) {
            setValue({
                ...value,
                ['Agency_StateId']: e.value
            }); setChangesStatus(true)
            getCity(e.value)
        } else {
            setValue({
                ...value,
                ['Agency_StateId']: null, ['ZipName']: '', ['Agency_ZipId']: '', ['Agency_CityId']: '', ['CityName']: ''
            })
            setChangesStatus(true)
            setCityList()
            setZipList()
        }
    }

    function cityChanges(e) {
        if (e) {
            setValue({
                ...value,
                ['Agency_CityId']: e.value,
                ['ZipName']: '',
            }); setChangesStatus(true)
            getZipCode(e.value)
        } else {
            setValue({
                ...value,
                ['Agency_CityId']: null, ['ZipName']: '', ['Agency_ZipId']: ''
            }); setChangesStatus(true)
            setZipList()
        }
    }

    function zipChanges(e) {
        if (e) {
            setValue({
                ...value,
                ['Agency_ZipId']: e.value, ['ZipName']: { value: e.value, label: e.label }
            }); setChangesStatus(true)
        } else {
            setValue({
                ...value,
                ['Agency_ZipId']: null
            }); setChangesStatus(true)
        }
    }

    // Get Effective Screeen Permission
    const getScreenPermision = (aId, pinID) => {
        ScreenPermision("A001", aId, pinID).then(res => {
            if (res) { setEffectiveScreenPermission(res) }
            else setEffectiveScreenPermission()
        });
    }

    const handleChange = (e) => {
        if (e.target.name === 'Agency_Phone' || e.target.name === 'Agency_Fax') {
            let ele = e.target.value.replace(/[^0-9\s]/g, "")
            if (ele.length === 10) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                    setChangesStatus(true)
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    });
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/[^0-9\s]/g, "");
                setChangesStatus(true)
                setValue({
                    ...value,
                    [e.target.name]: ele
                });
            }
        } else if (e.target.name === 'MunicipalityCode') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            // /[^a-zA-Z\s]/g
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: checkNumber
            });
        } else {
            setChangesStatus(true)
            setValue({
                ...value,
                [e.target.name]: e.target.value
            });
        }
    }

    // Get List state, city and zip code 
    const get_Edit_Agency_Data = async (aId) => {
        const value = {
            AgencyID: aId
        }
        fetchPostData("Agency/GetData_SingleData", value).then((res) => {
            if (res) {
                setAgencyEditVal(res);
            } else setAgencyEditVal()
        })
    }

    const getStateList = async () => {
        fetchData("State_City_ZipCode/GetData_State")
            .then(res => {
                if (res) setStateList(changeArrayFormat(res, 'state'));
                else setStateList([])
            })
    }

    const getCity = async (stateID) => {
        fetchPostData('State_City_ZipCode/GetData_City', { StateID: stateID }).then((res) => {
            if (res) setCityList(changeArrayFormat(res, 'city'))
            else setCityList(changeArrayFormat(res, 'city'))
        }).catch((error) => {
            console.error('There was an error!', error);
        });
    }

    const getZipCode = async (cityID) => {
        fetchPostData('State_City_ZipCode/GetData_ZipCode', { CityId: cityID }).then((res) => {
            if (res) setZipList(changeArrayFormat(res, 'zip'))
            else setZipList(changeArrayFormat(res, 'zip'))
        }).catch((error) => {
            console.error('There was an error!', error);
        });
    }

    const check_Validation_Error = (e) => {
        e.preventDefault()
        const ORI = ORIValidator(value.ORI);
        const MunicipalityCode = MunicipalityCodeValidator(value.MunicipalityCode);
        const Agency_Name = RequiredField(value.Agency_Name);
        const ShortName = RequiredField(value.ShortName);
        const Agency_Address1 = RequiredField(value.Agency_Address1);
        const Agency_StateId = RequiredField(value.Agency_StateId);
        const Agency_CityId = RequiredField(value.Agency_CityId);
        const Agency_Phone = PhoneField(value.Agency_Phone);
        const Agency_Fax = FaxField(value.Agency_Fax);
        setErrors(prevValues => {
            return {
                ...prevValues,
                ['ORI']: ORI,
                ['MunicipalityCode']: MunicipalityCode,
                ['Agency_Name']: Agency_Name,
                ['ShortName']: ShortName,
                ['Agency_Address1']: Agency_Address1,
                ['Agency_StateId']: Agency_StateId,
                ['Agency_CityId']: Agency_CityId,
                ['Agency_Phone']: Agency_Phone,
                ['Agency_Fax']: Agency_Fax,
            }
        })
    }
    // Check All Field Format is True Then Submit 
    const { ORI, MunicipalityCode, Agency_Name, ShortName, Agency_Address1, Agency_StateId, Agency_CityId, Agency_Phone, Agency_Fax } = errors

    useEffect(() => {
        if (ORI === 'true' && MunicipalityCode === 'true' && Agency_Name === 'true' && ShortName === 'true' && Agency_Address1 === 'true' && Agency_StateId === 'true' && Agency_CityId === 'true' && Agency_Phone === 'true' && Agency_Fax === 'true') {
            if (aId != '000') { update_Agency() }
            else { agencySubmit() }
        }
    }, [ORI, MunicipalityCode, Agency_Name, ShortName, Agency_Address1, Agency_StateId, Agency_CityId, Agency_Phone, Agency_Fax])

    // Save Agency
    const agencySubmit = () => {
        const result = agencyData?.find(item => {
            if (item.ORI.toLowerCase() === value.ORI.toLowerCase()) {
                return item.ORI.toLowerCase() === value.ORI.toLowerCase()
            } else return item.ORI.toLowerCase() === value.ORI.toLowerCase()
        });
        if (result) {
            toastifyError('ORI Code Already Exists')
            setErrors({ ...errors, ['ORI']: '' })
        } else {
            // const values = JSON.stringify(value);
            // const data = {
            //     "Data": values
            // }
            axios.post('Agency/AgencyInsert', value).then(response => {
                if (response.data.success) {
                    console.log(response)
                    getAgency(aId, pinID);
                    upload_Image_File(response?.data?.Id);
                    setChangesStatus(false);
                    get_CountList(response.data.Id);
                    navigate(`/agencyTab?id=U2FsdGVkX1${response?.data?.Id.trim()}/rbn7XDh9W4GiUkZ4MTV1Vx8pMNVkdjyw=`); setStatus(true)
                    toastifySuccess(response?.data?.Message);
                    setErrors({ ...errors, ['Agency_Name']: '' });
                } else {
                    toastifyError(response.data.Message)
                }
            }).catch(error => {
                console.error('There was an error!', error);
            });
        }
    }

    // Update Agency 
    const update_Agency = () => {
        const result = agencyData?.find(item => {
            if (item?.AgencyID != aId) {
                if (item.ORI.toLowerCase() === value.ORI.toLowerCase()) {
                    return item.ORI.toLowerCase() === value.ORI.toLowerCase()
                } else return item.ORI.toLowerCase() === value.ORI.toLowerCase()
            }
        });
        if (result) {
            toastifyError('ORI Code Already Exists')
            setErrors({ ...errors, ['ORI']: '' })
        } else {
            // const values = JSON.stringify(value);
            // const data = {
            //     "Data": values
            // };
            axios.post('Agency/AgencyUpdate', value).then(response => {
                if (response.data.success) {
                    console.log(agencyEditVal[0]?.Agency_Photo);
                    console.log(image);
                    if (!agencyEditVal[0]?.Agency_Photo) {
                        if (image) {
                            upload_Image_File(aId)
                        }
                    }
                    toastifySuccess(response.data.Message); setChangesStatus(false)
                    setErrors({ ...errors, ['Agency_Name']: '' });
                    if (!inActiveStatus && !value.IsActive) {
                        getAgency(aId, pinID); setStatus(false)
                    } else if (!inActiveStatus && value.IsActive) {
                        getAgency(aId, pinID); setStatus(true)
                    } else if (inActiveStatus && value.IsActive) {
                        getInActiveAgency(); getAgency(aId, pinID); setInActiveStatus(false); setStatus(true); setAgencyName('')
                    } else {
                        console.log("CALL")
                    }
                } else {
                    toastifyError(response.data.Message)
                }
            }).catch(error => {
                console.error('There was an error!', error);
            });
        }
    }

    // Rest Value After Call Cencel Button
    const rest_Value = (e) => {
        setValue({
            ...value,
            'ORI': '', 'MunicipalityCode': '', 'Agency_Name': '', 'ShortName': '', 'Agency_Address1': '', 'Agency_StateId': '', 'Agency_CityId': '', 'Agency_ZipId': '', 'Agency_Phone': '', 'Agency_Fax': '', 'ZipName': '', 'CityName': '', 'StateName': '', 'IsActive': true,
        }); setCityList(); setZipList()
    }

    // Custom Style
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    useEffect(() => {
        const inputFile = document.querySelector("#picture__input");
        const pictureImage = document.querySelector(".picture__image");
        const pictureImageTxt = "Choose an image";
        pictureImage.innerHTML = pictureImageTxt;

        const img = document.createElement("img");
        img.src = agencyEditVal[0]?.Agency_Photo && status ? agencyEditVal[0]?.Agency_Photo : uploadImage;
        img.classList.add("picture__img");
        pictureImage.innerHTML = "";
        pictureImage.appendChild(img);
        inputFile.addEventListener("change", function (e) {
            const inputTarget = e.target;
            const file = inputTarget.files[0];
            if (file) {
                const reader = new FileReader();
                reader.addEventListener("load", function (e) {
                    const readerTarget = e.target;
                    const img = document.createElement("img");
                    img.src = readerTarget.result;
                    img.classList.add("picture__img");
                    pictureImage.innerHTML = "";
                    pictureImage.appendChild(img);
                });
                reader.readAsDataURL(file);
            } else {
                pictureImage.innerHTML = pictureImageTxt;
            }
        });
    }, [agencyEditVal, status])

    const get_Image_File = (e) => {
        try {
            let currentFileType = e.target.files[0].type;
            let checkPng = currentFileType.indexOf("png");
            let checkJpeg = currentFileType.indexOf("jpeg");
            let checkJpg = currentFileType.indexOf("jpg");
            if (checkPng !== -1 || checkJpeg !== -1 || checkJpg !== -1) {
                setImage(e.target.files[0]); setChangesStatus(true)
            } else {
                toastifyError("Error: Invalid image file!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Upload Agency Image 
    const upload_Image_File = (id) => {
        const val = { 'AgencyID': id, 'CreatedByUserFK': pinID, };
        const values = JSON.stringify(val);
        const formdata = new FormData();
        formdata.append("Agency_Path", image);
        formdata.append("Data", values);
        console.log(formdata)
        AddDeleteUpadate('Agency/AgencyPhoto', formdata).then((res) => {
            if (res.success) {
                get_Edit_Agency_Data(aId);
                setImage('');
            } else setImage('');
        }).catch(err => console.log(err))
    }

    const delete_Image_File = (e) => {
        e.preventDefault()
        const value = {
            AgencyID: aId,
            DeletedByUserFK: pinID
        }
        AddDeleteUpadate('Agency/Delete_AgencyPhoto', value).then((data) => {
            if (data.success) {
                toastifySuccess(data?.Message)
                get_Edit_Agency_Data(aId)
            } else {
                toastifyError(data?.Message)
            }
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-12 " id='display-not-form'>
                    <div className="col-12 col-md-12 pt-2 p-0" >
                        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0 d-flex align-items-center">
                                Agency
                            </p>
                        </div>
                    </div>
                    <div className="row px-1">
                        <div className="col-12 col-md-12 col-lg-10 pt-2 p-0" >
                            <div className="row">
                                <div className="col-6 col-md-3 col-lg-2 mt-1 pt-1">
                                    <div className="text-field">
                                        <input type="text" maxLength="9" name='ORI' style={{ textTransform: "uppercase" }}
                                            className={fieldPermissionAgency?.ORI ?
                                                fieldPermissionAgency?.ORI[0]?.Changeok === 0 && fieldPermissionAgency?.ORI[0]?.AddOK === 0 && status ? 'readonlyColor' : fieldPermissionAgency?.ORI[0]?.Changeok === 0 && fieldPermissionAgency?.ORI[0]?.AddOK === 1 && agencyEditVal?.ORI === '' && status ? 'requiredColor' : fieldPermissionAgency?.ORI[0]?.AddOK === 1 && !status ? 'requiredColor' : fieldPermissionAgency?.ORI[0]?.Changeok === 1 && status ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                            }
                                            onChange={fieldPermissionAgency?.ORI ?
                                                fieldPermissionAgency?.ORI[0]?.Changeok === 0 && fieldPermissionAgency?.ORI[0]?.AddOK === 0 && status ? '' : fieldPermissionAgency?.ORI[0]?.Changeok === 0 && fieldPermissionAgency?.ORI[0]?.AddOK === 1 && agencyEditVal?.ORI === '' && status ? handleChange : fieldPermissionAgency?.ORI[0]?.AddOK === 1 && !status ? handleChange : fieldPermissionAgency?.ORI[0]?.Changeok === 1 && status ? handleChange : '' : handleChange
                                            }
                                            value={value.ORI}
                                        />
                                        <label>ORI</label>
                                        <p><span className='hovertext' data-hover="ORI : Enter a 9 digit code starting with first two capital characters and ending with 00" ><i className='fa fa-exclamation-circle'></i></span></p>

                                        {errors.ORI !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ORI}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 col-lg-2 mt-1 pt-1">
                                    <div className="text-field">
                                        <input type="text" style={{ textTransform: "uppercase" }}
                                            className={fieldPermissionAgency?.ShortName[0] ?
                                                fieldPermissionAgency?.ShortName[0]?.Changeok === 0 && fieldPermissionAgency?.ShortName[0]?.AddOK === 0 && status ? 'readonlyColor' : fieldPermissionAgency?.ShortName[0]?.Changeok === 0 && fieldPermissionAgency?.ShortName[0]?.AddOK === 1 && agencyEditVal?.ShortName === '' && status ? 'requiredColor' : fieldPermissionAgency?.ShortName[0]?.AddOK === 1 && !status ? 'requiredColor' : fieldPermissionAgency?.ShortName[0]?.Changeok === 1 && status ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                            }
                                            name='ShortName' value={value.ShortName}
                                            onChange={fieldPermissionAgency?.ShortName[0] ?
                                                fieldPermissionAgency?.ShortName[0]?.Changeok === 0 && fieldPermissionAgency?.ShortName[0]?.AddOK === 0 && status ? '' : fieldPermissionAgency?.ShortName[0]?.Changeok === 0 && fieldPermissionAgency?.ShortName[0]?.AddOK === 1 && agencyEditVal?.ShortName === '' && status ? handleChange : fieldPermissionAgency?.ShortName[0]?.AddOK === 1 && !status ? handleChange : fieldPermissionAgency?.ShortName[0]?.Changeok === 1 && status ? handleChange : '' : handleChange
                                            }
                                            required />
                                        <label>Short Name</label>
                                        {errors.ShortName !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ShortName}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-5 mt-1 pt-1">
                                    <div className="text-field">
                                        <input type="text"
                                            name='Agency_Name' value={value.Agency_Name}
                                            className={fieldPermissionAgency?.Agency_Name[0] ?
                                                fieldPermissionAgency?.Agency_Name[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Name[0]?.AddOK === 0 && status ? 'readonlyColor' : fieldPermissionAgency?.Agency_Name[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Name[0]?.AddOK === 1 && agencyEditVal?.Agency_Name === '' && status ? 'requiredColor' : fieldPermissionAgency?.Agency_Name[0]?.AddOK === 1 && !status ? 'requiredColor' : fieldPermissionAgency?.Agency_Name[0]?.Changeok === 1 && status ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                            }
                                            onChange={fieldPermissionAgency?.Agency_Name[0] ?
                                                fieldPermissionAgency?.Agency_Name[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Name[0]?.AddOK === 0 && status ? '' : fieldPermissionAgency?.Agency_Name[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Name[0]?.AddOK === 1 && agencyEditVal?.Agency_Name === '' && status ? handleChange : fieldPermissionAgency?.Agency_Name[0]?.AddOK === 1 && !status ? handleChange : fieldPermissionAgency?.Agency_Name[0]?.Changeok === 1 && status ? handleChange : '' : handleChange
                                            }
                                            required
                                        />
                                        <label>Agency Name</label>
                                        {errors.Agency_Name !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Agency_Name}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-3 mt-2">
                                    <div className="text-field">
                                        <input type="text" maxLength="4"
                                            className={fieldPermissionAgency?.MunicipalityCode[0] ?
                                                fieldPermissionAgency?.MunicipalityCode[0]?.Changeok === 0 && fieldPermissionAgency?.MunicipalityCode[0]?.AddOK === 0 && status ? 'readonlyColor' : fieldPermissionAgency?.MunicipalityCode[0]?.Changeok === 0 && fieldPermissionAgency?.MunicipalityCode[0]?.AddOK === 1 && agencyEditVal?.MunicipalityCode === '' && status ? 'requiredColor' : fieldPermissionAgency?.MunicipalityCode[0]?.AddOK === 1 && !status ? 'requiredColor' : fieldPermissionAgency?.MunicipalityCode[0]?.Changeok === 1 && status ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                            } name='MunicipalityCode'
                                            value={value.MunicipalityCode}
                                            onChange={fieldPermissionAgency?.MunicipalityCode[0] ?
                                                fieldPermissionAgency?.MunicipalityCode[0]?.Changeok === 0 && fieldPermissionAgency?.MunicipalityCode[0]?.AddOK === 0 && status ? '' : fieldPermissionAgency?.MunicipalityCode[0]?.Changeok === 0 && fieldPermissionAgency?.MunicipalityCode[0]?.AddOK === 1 && agencyEditVal?.MunicipalityCode === '' && status ? handleChange : fieldPermissionAgency?.MunicipalityCode[0]?.AddOK === 1 && !status ? handleChange : fieldPermissionAgency?.MunicipalityCode[0]?.Changeok === 1 && status ? handleChange : '' : handleChange
                                            }
                                            required
                                        />
                                        <label>Municipality Code</label>
                                        <p ><span className='hovertext-small' data-hover="Enter a 4-digit Number" ><i className='fa fa-exclamation-circle'></i></span></p>
                                        {errors.MunicipalityCode !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.MunicipalityCode}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-9  mt-3">
                                            <div className="text-field">
                                                <textarea
                                                    className={fieldPermissionAgency?.Agency_Address1[0] ?
                                                        fieldPermissionAgency?.Agency_Address1[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Address1[0]?.AddOK === 0 && status ? 'readonlyColor' : fieldPermissionAgency?.Agency_Address1[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Address1[0]?.AddOK === 1 && agencyEditVal?.Agency_Address1 === '' && status ? 'requiredColor' : fieldPermissionAgency?.Agency_Address1[0]?.AddOK === 1 && !status ? 'requiredColor' : fieldPermissionAgency?.Agency_Address1[0]?.Changeok === 1 && status ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                                    }
                                                    name='Agency_Address1' value={value.Agency_Address1}
                                                    onChange={fieldPermissionAgency?.Agency_Address1[0] ?
                                                        fieldPermissionAgency?.Agency_Address1[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Address1[0]?.AddOK === 0 && status ? '' : fieldPermissionAgency?.Agency_Address1[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Address1[0]?.AddOK === 1 && agencyEditVal?.Agency_Address1 === '' && status ? handleChange : fieldPermissionAgency?.Agency_Address1[0]?.AddOK === 1 && !status ? handleChange : fieldPermissionAgency?.Agency_Address1[0]?.Changeok === 1 && status ? handleChange : '' : handleChange
                                                    }
                                                    required cols="30" rows="1"></textarea>
                                                <label>Address</label>
                                                {errors.Agency_Address1 !== 'true' ? (
                                                    <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Agency_Address1}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className="col-4 col-md-4 col-lg-4 mt-5 pt-1 dropdown__box_req">
                                                    <Select
                                                        styles={colourStyles}
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        value={stateList?.filter((obj) => obj.value === value?.Agency_StateId)}
                                                        name='Agency_StateId' menuPlacement="top"
                                                        options={stateList}
                                                        isClearable
                                                        onChange={fieldPermissionAgency?.Agency_StateId[0] ?
                                                            fieldPermissionAgency?.Agency_StateId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_StateId[0]?.AddOK === 0 ? '' : fieldPermissionAgency?.Agency_StateId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_StateId[0]?.AddOK === 1 && agencyEditVal?.Agency_StateId === '' ? stateChanges : fieldPermissionAgency?.Agency_StateId[0]?.AddOK === 1 && !status ? stateChanges : fieldPermissionAgency?.Agency_StateId[0]?.Changeok === 1 && status ? stateChanges : '' : stateChanges
                                                        }
                                                        isDisabled={fieldPermissionAgency?.Agency_StateId[0] ?
                                                            fieldPermissionAgency?.Agency_StateId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_StateId[0]?.AddOK === 0 ? true : fieldPermissionAgency?.Agency_StateId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_StateId[0]?.AddOK === 1 && agencyEditVal?.Agency_StateId === '' ? false : fieldPermissionAgency?.Agency_StateId[0]?.AddOK === 1 && !status ? false : fieldPermissionAgency?.Agency_StateId[0]?.Changeok === 1 ? false : true : false
                                                        }
                                                    />
                                                    <label htmlFor="" className='pt-1'>State</label>
                                                    {errors.Agency_StateId !== 'true' ? (
                                                        <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Agency_StateId}</span>
                                                    ) : null}
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-4 mt-5 pt-1 dropdown__box_req" >
                                                    <Select
                                                        styles={colourStyles}
                                                        value={cityList?.filter((obj) => obj.value === value?.Agency_CityId)}
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        name='Agency_CityId' menuPlacement="top"
                                                        options={cityList}
                                                        isClearable
                                                        onChange={fieldPermissionAgency?.Agency_CityId[0] ?
                                                            fieldPermissionAgency?.Agency_CityId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_CityId[0]?.AddOK === 0 ? '' : fieldPermissionAgency?.Agency_CityId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_CityId[0]?.AddOK === 1 && agencyEditVal?.Agency_CityId === '' ? cityChanges : fieldPermissionAgency?.Agency_CityId[0]?.AddOK === 1 && !status ? cityChanges : fieldPermissionAgency?.Agency_CityId[0]?.Changeok === 1 && status ? cityChanges : '' : cityChanges
                                                        }
                                                        isDisabled={fieldPermissionAgency?.Agency_CityId[0] ?
                                                            fieldPermissionAgency?.Agency_CityId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_CityId[0]?.AddOK === 0 ? true : fieldPermissionAgency?.Agency_CityId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_CityId[0]?.AddOK === 1 && agencyEditVal?.Agency_CityId === '' ? false : fieldPermissionAgency?.Agency_CityId[0]?.AddOK === 1 && !status ? false : fieldPermissionAgency?.Agency_CityId[0]?.Changeok === 1 ? false : true : false
                                                        }
                                                    />
                                                    <label htmlFor="" className='pt-1'>City</label>
                                                    {errors.Agency_CityId !== 'true' ? (
                                                        <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Agency_CityId}</span>
                                                    ) : null}
                                                </div>
                                                <div className="col-4 col-md-4 col-lg-4 mt-5 pt-1 dropdown__box">
                                                    <Select
                                                        className="basic-single"
                                                        value={zipList?.filter((obj) => obj.value === value?.Agency_ZipId)}
                                                        classNamePrefix="select"
                                                        styles={customStylesWithOutColor}
                                                        name='Agency_ZipId' menuPlacement="top"
                                                        options={zipList}
                                                        isClearable
                                                        onChange={fieldPermissionAgency?.Agency_ZipId[0] ?
                                                            fieldPermissionAgency?.Agency_ZipId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_ZipId[0]?.AddOK === 0 ? '' : fieldPermissionAgency?.Agency_ZipId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_ZipId[0]?.AddOK === 1 && agencyEditVal?.Agency_ZipId === '' ? zipChanges : fieldPermissionAgency?.Agency_ZipId[0]?.AddOK === 1 && !status ? zipChanges : fieldPermissionAgency?.Agency_ZipId[0]?.Changeok === 1 && status ? zipChanges : '' : zipChanges
                                                        }
                                                        isDisabled={fieldPermissionAgency?.Agency_ZipId[0] ?
                                                            fieldPermissionAgency?.Agency_ZipId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_ZipId[0]?.AddOK === 0 ? true : fieldPermissionAgency?.Agency_ZipId[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_ZipId[0]?.AddOK === 1 && agencyEditVal?.Agency_ZipId === '' ? false : fieldPermissionAgency?.Agency_ZipId[0]?.AddOK === 1 && !status ? false : fieldPermissionAgency?.Agency_ZipId[0]?.Changeok === 1 ? false : true : false
                                                        }
                                                    />
                                                    <label htmlFor="" className='pt-1'>Zip</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-2 col-md-2 col-lg-1 mt-4   pl-1 px-0 index-select" >
                                    <select name="" id="" className="form-control requiredColor" style={{ height: '32px' }}>
                                        <option value="">+1</option>
                                    </select>
                                    <label className=''>Phone</label>
                                </div>
                                <div className="col-4 col-md-4 col-lg-3 mt-3  ">
                                    <div className="text-field">
                                        <input type="text" maxLength={10}
                                            className={fieldPermissionAgency?.Agency_Phone[0] ?
                                                fieldPermissionAgency?.Agency_Phone[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Phone[0]?.AddOK === 0 && status ? 'readonlyColor' : fieldPermissionAgency?.Agency_Phone[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Phone[0]?.AddOK === 1 && agencyEditVal?.Agency_Phone === '' && status ? 'requiredColor' : fieldPermissionAgency?.Agency_Phone[0]?.AddOK === 1 && !status ? 'requiredColor' : fieldPermissionAgency?.Agency_Phone[0]?.Changeok === 1 && status ? 'requiredColor' : 'readonlyColor' : 'requiredColor'
                                            }
                                            name='Agency_Phone' value={value.Agency_Phone}
                                            onChange={fieldPermissionAgency?.Agency_Phone[0] ?
                                                fieldPermissionAgency?.Agency_Phone[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Phone[0]?.AddOK === 0 && status ? '' : fieldPermissionAgency?.Agency_Phone[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Phone[0]?.AddOK === 1 && agencyEditVal?.Agency_Phone === '' && status ? handleChange : fieldPermissionAgency?.Agency_Phone[0]?.AddOK === 1 && !status ? handleChange : fieldPermissionAgency?.Agency_Phone[0]?.Changeok === 1 && status ? handleChange : '' : handleChange
                                            }
                                            required
                                        />
                                        <p><span className='hovertext-small' data-hover="Enter your 10 digit phone number" ><i className='fa fa-exclamation-circle'></i></span></p>

                                        {errors.Agency_Phone !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Agency_Phone}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-5 col-md-4 col-lg-3 mt-3">
                                    <div className="text-field">
                                        <p><span className='hovertext-small' data-hover="Enter a 10 digit Number " ><i className='fa fa-exclamation-circle'></i></span></p>
                                        <input type="text" maxLength="10"
                                            name='Agency_Fax' value={value.Agency_Fax}
                                            className={fieldPermissionAgency?.Agency_Fax[0] ?
                                                fieldPermissionAgency?.Agency_Fax[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Fax[0]?.AddOK === 0 && status ? 'readonlyColor' : fieldPermissionAgency?.Agency_Fax[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Fax[0]?.AddOK === 1 && agencyEditVal?.Agency_Fax === '' && status ? '' : fieldPermissionAgency?.Agency_Fax[0]?.AddOK === 1 && !status ? '' : fieldPermissionAgency?.Agency_Fax[0]?.Changeok === 1 && status ? '' : 'readonlyColor' : ''
                                            }
                                            onChange={fieldPermissionAgency?.Agency_Fax[0] ?
                                                fieldPermissionAgency?.Agency_Fax[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Fax[0]?.AddOK === 0 && status ? '' : fieldPermissionAgency?.Agency_Fax[0]?.Changeok === 0 && fieldPermissionAgency?.Agency_Fax[0]?.AddOK === 1 && agencyEditVal?.Agency_Fax === '' && status ? handleChange : fieldPermissionAgency?.Agency_Fax[0]?.AddOK === 1 && !status ? handleChange : fieldPermissionAgency?.Agency_Fax[0]?.Changeok === 1 && status ? handleChange : '' : handleChange
                                            }
                                        />
                                        <label>Fax</label>
                                        {errors.Agency_Fax !== 'true' ? (
                                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.Agency_Fax}</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-2 mt-4 ml-5">
                                    <input type="checkbox" name="IsActive" checked={value?.IsActive} value={value?.IsActive} onChange={() => setValue({ ...value, ['IsActive']: !value?.IsActive })}
                                    />
                                    <label className='ml-2' >Active</label>
                                </div>
                                <div className="col-12 col-lg-12 mt-2 text-right">
                                    {
                                        status || aId != '000' ?
                                            effectiveScreenPermission ?
                                                effectiveScreenPermission[0]?.Changeok ?

                                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Update</button>
                                                    : <></> :
                                                <button type="button" className="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Update</button>
                                            :
                                            !inActiveStatus ?
                                                effectiveScreenPermission ?
                                                    effectiveScreenPermission[0]?.AddOK ?
                                                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Save</button>
                                                        : <></>
                                                    :
                                                    <button type="button" className="btn btn-sm btn-success mr-1" onClick={check_Validation_Error} >Save</button>
                                                : <></>
                                    }
                                </div>
                            </div>
                            <IdentifyFieldColor />
                        </div>
                        <div className="col-4 col-md-4 col-lg-2 pt-4">
                            <div className="img-box" >
                                <label className="picture" style={{ cursor: agencyEditVal[0]?.Agency_Photo && status ? 'not-allowed' : '' }} htmlFor={agencyEditVal[0]?.Agency_Photo && status ? "" : 'picture__input'} tabIndex="0">
                                    <span className="picture__image"></span>
                                </label>
                                <input type="file" name="picture__input" onChange={get_Image_File} id="picture__input" />
                            </div>
                            {
                                agencyEditVal[0]?.Agency_Photo && status ?
                                    <button type="button" data-toggle="modal" data-target="#DeleteModal" className="btn btn-sm btn-success ml-5">Clear</button>
                                    : <></>
                            }
                        </div>
                    </div>
                    {
                        allowMultipleLogin === '0' && !status ?
                            <div className="overlay-form">
                                <p>You don't have permision to Add Agency this data</p>
                            </div>
                            : <></>
                    }
                </div>
            </div>
            <DeletePopUpModal func={delete_Image_File} />
            <ChangesModal func={check_Validation_Error} />
        </>
    )
}

export default Home

export const changeArrayFormat = (data, type) => {
    if (type === 'zip') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.zipId, label: sponsor.Zipcode })
        )
        return result
    }
    if (type === 'state') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.StateID, label: sponsor.StateName })
        )
        return result
    }
    if (type === 'city') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.CityID, label: sponsor.CityName })
        );
        return result
    }
}

export const changeArrayFormat_WithFilter = (data, type) => {
    if (type === 'zip') {
        const result = data.map((sponsor) =>
            ({ value: sponsor.Agency_ZipId, label: sponsor.Zipcode })
        )
        return result[0]
    }
    if (type === 'state') {
        const result = data.map((sponsor) =>
            ({ value: sponsor.Agency_StateId, label: sponsor.StateName })
        )
        return result[0]
    }
    if (type === 'city') {
        const result = data.map((sponsor) =>
            ({ value: sponsor.Agency_CityId, label: sponsor.CityName })
        )
        return result[0]
    }
}