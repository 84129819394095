// Import Component
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import AgencyData from './Context/Agency/Index';
import AxiosCom from './interceptors/axios';
import { PrimeReactProvider } from 'primereact/api';

// Active user check
export const INACTIVE_USER_TIME_THRESHOLD = 600000;
export const USER_ACTIVITY_THROTTLER_TIME = 600000;

let userActivityTimeout = null;
let userActivityThrottlerTimeout = null;
let isInactive = true;

const Deactivate = () => {
  window.location.href = '/'
  localStorage.clear()
}

activateActivityTracker();

function activateActivityTracker() {
  window.addEventListener("load", userActivityThrottler);
  window.addEventListener("mousemove", userActivityThrottler);
  window.addEventListener("click", userActivityThrottler);
  window.addEventListener("scroll", userActivityThrottler);
  window.addEventListener("keydown", userActivityThrottler);
  window.addEventListener("resize", userActivityThrottler);
  window.addEventListener("beforeunload", deactivateActivityTracker);
}

function deactivateActivityTracker() {
  window.removeEventListener("mousemove", userActivityThrottler);
  window.removeEventListener("scroll", userActivityThrottler);
  window.removeEventListener("keydown", userActivityThrottler);
  window.removeEventListener("resize", userActivityThrottler);
  window.removeEventListener("beforeunload", deactivateActivityTracker);
}

function resetUserActivityTimeout() {
  clearTimeout(userActivityTimeout);

  userActivityTimeout = setTimeout(() => {
    userActivityThrottler();
    inactiveUserAction();
  }, INACTIVE_USER_TIME_THRESHOLD);
}

function userActivityThrottler() {
  if (isInactive) {
    ReactDOM.render(
      <PrimeReactProvider>
        <AgencyData>
          <App />
          <AxiosCom />
        </AgencyData>
      </PrimeReactProvider>
      ,
      document.getElementById('root')
    );
    resetUserActivityTimeout();
  }
  if (!userActivityThrottlerTimeout) {
    userActivityThrottlerTimeout = setTimeout(() => {
      resetUserActivityTimeout();
      clearTimeout(userActivityThrottlerTimeout);
    }, USER_ACTIVITY_THROTTLER_TIME);
  }
}

// Render Component
function inactiveUserAction() {
  console.log("callll  inactive user")
  ReactDOM.render(<Deactivate />, document.getElementById('root'));
}
