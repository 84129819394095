import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Link } from 'react-router-dom';
import { colourStyles, getShowingMonthDateYear } from '../../../Common/Utility';

const WarrantAdvanceSearch = () => {

    const [issuedDateFrom, setIssuedDateFrom] = useState();
    const [issuedToDate, setIssuedToDate] = useState();
    const [serviceAttemptedFrom, setServiceAttemptedFrom] = useState();
    const [serviceToAttempted, setServiceToAttempted] = useState();
    const [serviceDateFrom, setServiceDateFrom] = useState();
    const [serviceDateTo, setServiceDateTo] = useState();
    const [expiryDate, setExpiryDate] = useState();
    const [value, setValue] = useState();

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();
    const startRef5 = React.useRef();
    const startRef6 = React.useRef();
    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
            startRef5.current.setOpen(false);
            startRef6.current.setOpen(false);
        }
    };

    return (
    
            <div className="section-body pt-3 px-3">
                <div className="row">
                    <div className="col-12">
                        <fieldset className='fieldset'>
                            <legend>Warrant Information</legend>
                            <div className="row">
                                <div className="col-6 col-md-3 mt-1">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Warrant Number From</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 mt-1">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Warrant Number To</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mt-1">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Incident</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1 mt-1">
                                    <div className="dropdown__box">
                                        <Select
                                            styles={colourStyles}
                                            menuPlacement='top'
                                            isClearable
                                            placeholder="Select..."
                                        />
                                        <label htmlFor='' className='mt-0'>Status</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1 mt-1">
                                    <div className="dropdown__box">
                                        <Select
                                            styles={colourStyles}
                                            menuPlacement='top'
                                            isClearable
                                            placeholder="Select..."
                                        />
                                        <label htmlFor='' className='mt-0'>Type</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1 mt-1">
                                    <div className="dropdown__box">
                                        <Select
                                            styles={colourStyles}
                                            menuPlacement='top'
                                            isClearable
                                            placeholder="Select..."
                                        />
                                        <label htmlFor='' className='mt-0'>Classification</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1">
                                    <div className="dropdown__box">
                                        <DatePicker
                                            id='issuedDatefrom'
                                            name='issuedDatefrom'
                                            ref={startRef}
                                            onKeyDown={onKeyDown}
                                            onChange={(date) => { setIssuedDateFrom(date); setValue({ ...value, ['issuedDatefrom']: date ? getShowingMonthDateYear(date) : null }) }}
                                            className=''
                                            dateFormat="MM/dd/yyyy"
                                            timeInputLabel
                                            isClearable={value?.issuedDatefrom ? true : false}
                                            selected={issuedDateFrom}
                                            placeholderText={value?.issuedDatefrom ? value.issuedDatefrom : 'Select...'}
                                        />
                                        <label htmlFor="" className='pt-1'>Date Issue From</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1">
                                    <div className="dropdown__box">
                                        <DatePicker
                                            id='issuedToDate'
                                            name='issuedToDate'
                                            ref={startRef1}
                                            onKeyDown={onKeyDown}
                                            onChange={(date) => { setIssuedToDate(date); setValue({ ...value, ['issuedToDate']: date ? getShowingMonthDateYear(date) : null }) }}
                                            className=''
                                            dateFormat="MM/dd/yyyy"
                                            timeInputLabel
                                            isClearable={value?.issuedToDate ? true : false}
                                            selected={issuedToDate}
                                            placeholderText={value?.issuedToDate ? value.issuedToDate : 'Select...'}
                                        />
                                        <label htmlFor="" className='pt-1'>Date Issue To</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1">
                                    <div className="dropdown__box">
                                        <DatePicker
                                            id='serviceAttemptedFrom'
                                            name='serviceAttemptedFrom'
                                            ref={startRef2}
                                            onKeyDown={onKeyDown}
                                            onChange={(date) => { setServiceAttemptedFrom(date); setValue({ ...value, ['serviceAttemptedFrom']: date ? getShowingMonthDateYear(date) : null }) }}
                                            className=''
                                            dateFormat="MM/dd/yyyy"
                                            timeInputLabel
                                            isClearable={value?.serviceAttemptedFrom ? true : false}
                                            selected={serviceAttemptedFrom}
                                            placeholderText={value?.serviceAttemptedFrom ? value.serviceAttemptedFrom : 'Select...'}
                                        />
                                        <label htmlFor="" className='pt-1'>Service Attempted Date From</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1">
                                    <div className="dropdown__box">
                                        <DatePicker
                                            id='serviceToAttempted'
                                            name='serviceToAttempted'
                                            ref={startRef3}
                                            onKeyDown={onKeyDown}
                                            onChange={(date) => { setServiceToAttempted(date); setValue({ ...value, ['serviceToAttempted']: date ? getShowingMonthDateYear(date) : null }) }}
                                            className=''
                                            dateFormat="MM/dd/yyyy"
                                            timeInputLabel
                                            isClearable={value?.serviceToAttempted ? true : false}
                                            selected={serviceToAttempted}
                                            placeholderText={value?.serviceToAttempted ? value.serviceToAttempted : 'Select...'}
                                        />
                                        <label htmlFor="" className='pt-1'>Service Attempted Date To</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1">
                                    <div className="dropdown__box">
                                        <DatePicker
                                            id='serviceDateFrom'
                                            name='serviceDateFrom'
                                            ref={startRef4}
                                            onKeyDown={onKeyDown}
                                            onChange={(date) => { setServiceDateFrom(date); setValue({ ...value, ['serviceDateFrom']: date ? getShowingMonthDateYear(date) : null }) }}
                                            className=''
                                            dateFormat="MM/dd/yyyy"
                                            timeInputLabel
                                            isClearable={value?.serviceDateFrom ? true : false}
                                            selected={serviceDateFrom}
                                            placeholderText={value?.serviceDateFrom ? value.serviceDateFrom : 'Select...'}
                                        />
                                        <label htmlFor="" className='pt-1'>Service Date From</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1">
                                    <div className="dropdown__box">
                                        <DatePicker
                                            id='serviceDateTo'
                                            name='serviceDateTo'
                                            ref={startRef5}
                                            onKeyDown={onKeyDown}
                                            onChange={(date) => { setServiceDateTo(date); setValue({ ...value, ['serviceDateTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                            className=''
                                            dateFormat="MM/dd/yyyy"
                                            timeInputLabel
                                            isClearable={value?.serviceDateTo ? true : false}
                                            selected={serviceDateTo}
                                            placeholderText={value?.serviceDateTo ? value.serviceDateTo : 'Select...'}
                                        />
                                        <label htmlFor="" className='pt-1'>Service Date To</label>
                                    </div>
                                </div>



                                <div className="col-6 col-md-3 mb-1 mt-2">
                                    <div className="dropdown__box">
                                        <Select
                                            styles={colourStyles}
                                            menuPlacement='top'
                                            isClearable
                                            placeholder="Select..."
                                        />
                                        <label htmlFor='' className='mt-0'>Authority</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mb-1 mt-2">
                                    <div className="dropdown__box">
                                        <Select
                                            styles={colourStyles}
                                            menuPlacement='top'
                                            isClearable
                                            placeholder="Select..."
                                        />
                                        <label htmlFor='' className='mt-0'>Location</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='fieldset mt-2'>
                            <legend>Restraining Order Information</legend>
                            <div className="row">
                                <div className="col-12  mb-1">
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="Copy On Fire" />
                                                <label className="form-check-label" htmlFor="Copy On Fire">Copy On Fire</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="Child Custody" />
                                                <label className="form-check-label" htmlFor="Child Custody">Child Custody</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="Contact Prohibited" />
                                                <label className="form-check-label" htmlFor="Contact Prohibited">Contact Prohibited</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3
                    ">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="Forbidden To Return" />
                                                <label className="form-check-label" htmlFor="Forbidden To Return">Forbidden To Return</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="Granted Possession" />
                                                <label className="form-check-label" htmlFor="Granted Possession">Granted Possession</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-6 col-md-3 mt-1">
                                    <div className="dropdown__box">
                                        <Select
                                            styles={colourStyles}
                                            menuPlacement='top'
                                            isClearable
                                            placeholder="Select..."
                                        />
                                        <label htmlFor='' className='mt-0'>Warning Given</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 ">
                                    <div className="dropdown__box">
                                        <DatePicker
                                            id='expiryDate'
                                            name='expiryDate'
                                            ref={startRef6}
                                            onKeyDown={onKeyDown}
                                            onChange={(date) => { setExpiryDate(date); setValue({ ...value, ['expiryDate']: date ? getShowingMonthDateYear(date) : null }) }}
                                            className=''
                                            dateFormat="MM/dd/yyyy"
                                            timeInputLabel
                                            isClearable={value?.expiryDate ? true : false}
                                            selected={expiryDate}
                                            placeholderText={value?.expiryDate ? value.expiryDate : 'Select...'}
                                        />
                                        <label htmlFor="" className='pt-1'>Date Expire</label>
                                    </div>
                                </div>

                            </div>
                        </fieldset>

                        <fieldset className='fieldset mt-2'>
                            <legend>Name Information</legend>
                            <div className="row">
                                <div className="col-6 col-md-3">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Name ID</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Last Name</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>First Name</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Middle Name</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 mt-2">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>SSN</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset className='fieldset mt-2'>
                            <legend>Address Information</legend>
                            <div className="row">
                                <div className="col-6 col-md-3">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Common Place</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Premise Range From</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Premise Range To</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Apt Range From</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 mt-2">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Apt Range To</label>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3 mt-2">
                                    <div className="text-field">
                                        <input type="text" />
                                        <label className=''>Street Name</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mt-2">
                                    <div className="dropdown__box">
                                        <Select
                                            styles={colourStyles}
                                            menuPlacement='top'
                                            isClearable
                                            placeholder="Select..."
                                        />
                                        <label htmlFor='' className='mt-0'>State</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mt-2">
                                    <div className="dropdown__box">
                                        <Select
                                            styles={colourStyles}
                                            menuPlacement='top'
                                            isClearable
                                            placeholder="Select..."
                                        />
                                        <label htmlFor='' className='mt-0'>City</label>
                                    </div>
                                </div>

                                <div className="col-6 col-md-3 mt-2">
                                    <div className="dropdown__box">
                                        <Select
                                            styles={colourStyles}
                                            menuPlacement='top'
                                            isClearable
                                            placeholder="Select..."
                                        />
                                        <label htmlFor='' className='mt-0'>Zip</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                    </div>
                </div>
                <div className="row mt-3 px-2 text-right">
                    <div className="col-12">
                        <Link to={'/warrant-search'}>
                            <button type="button" className="btn btn-sm btn-success mr-1">Search</button>
                        </Link>
                        <Link to={'/warrant-search'}>
                            <button type="button" className="btn btn-sm btn-success">Close</button>
                        </Link>
                    </div>
                </div>
            </div>
     
    )
}

export default WarrantAdvanceSearch