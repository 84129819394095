import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Decrypt_Id_Name } from '../../../../Common/Utility'
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api'
import DataTable from 'react-data-table-component'
import { toastifySuccess } from '../../../../Common/AlertMsg'
import DeletePopUpModal from '../../../../Common/DeleteModal'
import { ProRecoveredListDropDownArray } from '../../../../Utility/ListDropDownArray/ListDropArray'
import FindListDropDown from '../../../../Common/FindListDropDown'
import Loader from '../../../../Common/Loader'
import { AgencyContext } from '../../../../../Context/Agency/Index'
import RecoveredPropertyAdd from './RecoveredPropertyAdd'

const RecoveredProperty = () => {

    const { get_vehicle_Count, localStoreArray, get_LocalStorage, } = useContext(AgencyContext);

    //screen permission 
    const [modal, setModal] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(0)
    const [vehicleData, setVehicleData] = useState([]);
    const [vehicleRecoveredID, setVehicleRecoveredID] = useState();
    const [loder, setLoder] = useState(false);
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [vehicleID, setVehicleID] = useState('');
    const [loginPinID, setLoginPinID] = useState('');
    const [mainIncidentID, setMainIncidentID] = useState('');

    const localStore = {
        Value: "",
        UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
        Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: '', VehicleID: '', }),
    }

    useEffect(() => {
        if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
            get_LocalStorage(localStore);
        }
    }, []);

    // Onload Function
    useEffect(() => {
        if (localStoreArray) {
            if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
                setLoginAgencyID(localStoreArray?.AgencyID);
                setLoginPinID(localStoreArray?.PINID);
                setMainIncidentID(localStoreArray?.IncidentID);
                if (localStoreArray?.VehicleID) {
                    setVehicleID(localStoreArray?.VehicleID);
                    get_property_Data(localStoreArray?.VehicleID);
                } else {
                    setVehicleID('');
                }
            }
        }
    }, [localStoreArray])

    const get_property_Data = (vehicleID) => {
        const val = {
            'VehicleID': vehicleID,
        }
        fetchPostData('VehicleRecovered/GetData_VehicleRecovered', val).then((res) => {
            if (res) {
                setVehicleData(res); setLoder(true)
            } else {
                setVehicleData(); setLoder(true)
            }
        })
    }

    const columns = [

        {
            name: 'RecoveredID Number',
            selector: (row) => row.RecoveredIDNumber,
            sortable: true
        },
        {
            name: 'Comments',
            selector: (row) => row.Comments,
            sortable: true
        },
        {
            name: 'RecoveredValue',
            selector: (row) => row.RecoveredValue,
            sortable: true
        },
        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 60 }}>Delete</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 4, right: 65 }}>
                    <Link to={`#`} onClick={() => { setVehicleRecoveredID(row.vehicleRecoveredID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                        <i className="fa fa-trash"></i>
                    </Link>

                </div>

        }
    ]

    const Delete_RecoveredProperty = () => {
        const val = {
            'VehicleRecoveredID': vehicleRecoveredID,
            'DeletedByUserFK': loginPinID,
        }
        AddDeleteUpadate('VehicleRecovered/Delete_VehicleRecovered', val).then((res) => {
            if (res) {
                toastifySuccess(res.Message);
                get_property_Data(vehicleID);
                get_vehicle_Count(vehicleID)
                setVehicleRecoveredID('');
            } else { console.log("Somthing Wrong"); }
        })
    }

    return (
        <>
            <div className="col-12 col-md-12 pt-1 p-0" >
                <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center ">
                    <p className="p-0 m-0">Vehicle Recovered</p>
                    <div style={{ marginLeft: 'auto' }}>
                        <Link to="" onClick={() => { setModal(true); setUpdateStatus(updateStatus + 1) }} className="btn btn-sm bg-green text-white px-2 py-0" data-toggle="modal" data-target="#RecoveredPropertyModal" style={{ marginTop: '-6px' }}>
                            <i className="fa fa-plus"></i>
                        </Link>
                        <FindListDropDown
                            array={ProRecoveredListDropDownArray}
                        />
                    </div>

                </div>
                <div className="col-12">
                    {
                        loder ?
                            <DataTable
                                dense
                                columns={columns}
                                data={vehicleData}
                                pagination
                                highlightOnHover
                                noDataComponent={"There are no data to display"}
                            />
                            :
                            <Loader />

                    }
                </div>
            </div>
            <DeletePopUpModal func={Delete_RecoveredProperty} />
            <RecoveredPropertyAdd {...{ vehicleRecoveredID, setVehicleRecoveredID, modal, setModal, updateStatus, get_property_Data, vehicleData, loginPinID, loginAgencyID, vehicleID, mainIncidentID }} />
        </>
    )
}

export default RecoveredProperty