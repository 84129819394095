import React from 'react'
import DataTable from 'react-data-table-component';

const NewCustom = () => {







    // Table Columns Array
    const columns = [
        {
            name: 'Code',
            selector: (row) => row.AgeUnitCode,
            sortable: true
        },
        {
            name: 'AgencyCode',
            selector: (row) => row.AgencyCode,
            sortable: true
        },
        {
            name: 'Description',
            selector: (row) => row.Description,
            sortable: true
        },


    ]



    return (

        <div className="row">
            <div className="col-12 col-md-12 col-lg-12 ">
                <div className="row mt-2">
                    <div className="col-12 ">
                        <div className="bg-green text-white py-1 px-2 d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0">list</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="table-responsive mt-2">
                <div className="col-12">
                    <div className="row ">
                        <div className="col-12">
                            <DataTable
                                columns={columns}
                                data={''}
                                dense
                                paginationPerPage={'10'}
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                highlightOnHover
                                noContextMenu
                                pagination
                                responsive
                                subHeaderAlign="right"
                                subHeaderWrap
                                noDataComponent={"There are no data to display"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default NewCustom