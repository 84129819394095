import React, { useState, useEffect, useContext } from "react";
import BasicInformation from "./BasicInformation/BasicInformation";
import CourtDisposition from "./CourtDisposition/CourtDisposition";
import Home from "./Home/Home";
import MethodOfEntry from "./MethodOfEntry/MethodOfEntry";
import MethodOfOperation from "./MethodOfOperation/MethodOfOperation";
import Offender from "./Offender/Offender";
import OtherCode from "./OtherCode/OtherCode";
import Property from "./Property/Property";
import Victim from "./Victim/Victim";
import Weapon from "./Weapon/Weapon";
import SubTab from "../../../Utility/Tab/SubTab";
import { OffenseTabs } from "../../../Utility/Tab/TabsArray";
import { AgencyContext } from "../../../../Context/Agency/Index";
import { Decrypt_Id_Name } from "../../../Common/Utility";
import { Link } from 'react-router-dom';
import Tab from "../../../Utility/Tab/Tab";

const OffenceHomeTabs = () => {

    const { offenceStatus,changesStatus, setOffenceStatus, updateCount, offenceShowPage, setOffenceShowPage, localStoreArray, get_LocalStorage,inActiveStatus } = useContext(AgencyContext);
    const [status, setStatus] = useState()

    const [mainIncidentID, setMainIncidentID] = useState('');
    const [offenceID, setOffenceID] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [showOffPage, setshowOffPage] = useState('home');
    const iconHome = <i className="fa fa-home" style={{ fontSize: '20px' }}></i>

    const localStore = {
        Value: "",
        UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
        Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: '',OffenceID:'', OffenceStatus: '', IncidentNumber: '', IncidentStatus: '' }),
    }

    useEffect(() => {
        if (!localStoreArray.AgencyID || !localStoreArray.PINID || !localStoreArray.IncidentStatus) {
            get_LocalStorage(localStore);
        }
    }, []);

    useEffect(() => {
        if (localStoreArray) {
            if (localStoreArray.AgencyID && localStoreArray.PINID ) {
                setLoginAgencyID(localStoreArray?.AgencyID);
                setLoginPinID(localStoreArray?.PINID);
                setMainIncidentID(localStoreArray?.IncidentID);
                setOffenceID(localStoreArray?.OffenceID);
                setOffenceStatus(localStoreArray?.OffenceStatus === true || localStoreArray?.OffenceStatus === "True" ? true : false)
            }
        }
        setshowOffPage('home');
    }, [localStoreArray])
    // console.log(localStoreArray?.OffenceID)

    useEffect(() => {
        if (offenceStatus) {
            setStatus(true);
        } else {
            setStatus(localStoreArray?.OffenceStatus === true || localStoreArray?.OffenceStatus === "True" ? true : false);
        }
        setshowOffPage('home');
    }, [updateCount, offenceStatus])

    return (
        <div className=" section-body pt-1 p-1 bt" >
            <div className="div">
                <div className="col-12  inc__tabs">
                    <Tab />
                </div>
                <div className="dark-row" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency incident-card">
                            <div className="card-body">
                                <div className="row " style={{ marginTop: '-18px', marginLeft: '-18px' }}>
                                    <div className="col-12  incident-tab">
                                        <ul className='nav nav-tabs'>
                                            <Link className={`nav-item ${showOffPage === 'home' ? 'active' : ''} ${!status || inActiveStatus ? 'disabled' : ''}`} to={'/Off-Home'}   style={{ color: showOffPage === 'home' ? 'Red' : '' }} data-toggle={changesStatus ? "modal" : "pill"} data-target={changesStatus ? "#SaveModal" : ''} aria-current="page"  onClick={() => {
                                                if (!changesStatus) setshowOffPage('home')
                                            }}>
                                                {iconHome}
                                            </Link>
                                
                                            <Link className={`nav-item ${showOffPage === 'CrimeInformation' ? 'active' : ''} ${!status || inActiveStatus ? 'disabled' : ''}`} data-toggle={changesStatus ? "modal" : "pill"} data-target={changesStatus ? "#SaveModal" : ''}  to={'/Off-Home?page=CrimeInformation'}   style={{ color: showOffPage === 'CrimeInformation' ? 'Red' : 'gray' }} aria-current="page" onClick={() => {
                                                if (!changesStatus) setshowOffPage('CrimeInformation')
                                            }}>
                                                Crime Information
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                                {
                                    showOffPage === 'home' ?
                                        <Home {...{ status, setStatus,offenceID, setOffenceID }} />
                                        :
                                        showOffPage === 'CrimeInformation' ?
                                            <BasicInformation {...{ loginPinID, loginAgencyID, offenceID, mainIncidentID }} />
                                            :
                                            // showOffPage === 'other code' ?
                                            //     <OtherCode {...{ loginPinID, loginAgencyID, offenceID, mainIncidentID }} />
                                            //     :
                                            //     showOffPage === 'court disposition' ?
                                            //         <CourtDisposition {...{ loginPinID, loginAgencyID, offenceID, mainIncidentID }} />
                                            //         :
                                            //         showOffPage === 'method of enrty' ?
                                            //             <MethodOfEntry {...{ loginPinID, loginAgencyID, offenceID, mainIncidentID }} />
                                            //             :
                                            //             showOffPage === 'method of operation' ?
                                            //                 <MethodOfOperation {...{ loginPinID, loginAgencyID, offenceID, mainIncidentID }} />
                                            //                 :
                                            //                 showOffPage === 'Offenders' ?
                                            //                     <Offender {...{ loginPinID, loginAgencyID, offenceID, mainIncidentID }} />
                                            //                     :
                                            //                     showOffPage === 'property' ?
                                            //                         <Property {...{ loginPinID, loginAgencyID, offenceID, mainIncidentID }} />
                                            //                         :
                                            //                         showOffPage === 'Victims' ?
                                            //                             <Victim {...{ loginPinID, loginAgencyID, offenceID, mainIncidentID }} />
                                            //                             :
                                            //                             showOffPage === 'weapon' ?
                                            //                                 <Weapon {...{ loginPinID, loginAgencyID, offenceID, mainIncidentID }} />
                                            //                                 :
                                                                            <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default OffenceHomeTabs