import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { Decrypt_Id_Name } from '../../../../Common/Utility';
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { ArrPoliceForceListDropDownArray } from '../../../../Utility/ListDropDownArray/ListDropArray';
import FindListDropDown from '../../../../Common/FindListDropDown';
import Loader from '../../../../Common/Loader';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import PoliceForceAddUp from './PoliceForceAddUp';

const PoliceForce = () => {

  const { localStoreArray, get_LocalStorage, get_Arrest_Count } = useContext(AgencyContext);

  //screen permission 
  const [policeForceData, setPoliceForceData] = useState();
  const [arrsetPoliceForceID, setArrsetPoliceForceID] = useState();
  const [status, setStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [loder, setLoder] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(0)

  const [loginAgencyID, setLoginAgencyID] = useState('')
  const [arrestID, setArrestID] = useState('')
  const [loginPinID, setLoginPinID] = useState('');

  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: "", IncidentID: '', ArrestID: '', }),
  }

  useEffect(() => {
    if (!localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage(localStore);
    }
  }, []);

  // Onload Function
  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
        setLoginAgencyID(localStoreArray?.AgencyID);
        setLoginPinID(parseInt(localStoreArray?.PINID));
        if (localStoreArray.ArrestID) { setArrestID(localStoreArray?.ArrestID); } else { setArrestID() }
      } get_Data_PoliceForce(localStoreArray?.ArrestID)
    }
  }, [localStoreArray])


  const get_Data_PoliceForce = (ArrestID) => {
    const val = {
      'ArrestID': ArrestID
    }
    fetchPostData('ArrsetPoliceForce/GetData_ArrsetPoliceForce', val).then((res) => {
      if (res) {
        setPoliceForceData(res); setLoder(true)
      } else {
        setPoliceForceData(); setLoder(true)
      }
    })
  }

  const columns = [
    {
      width: '120px',
      name: <p className='text-end' style={{ position: 'absolute', top: 8, }}>Action</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', top: 4, left: 20 }}>

          <Link to={''} onClick={(e) => { set_Edit_Value(row) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#PoliceForceModal" >
            <i className="fa fa-edit"></i></Link>
        </div>
    },
    {
      name: 'Officer_Name',
      selector: (row) => row.Officer_Name,
      sortable: true
    },
    {
      name: 'Description',
      selector: (row) => row.ArrPoliceForce_Description,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 0 }}>Delete</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', top: 4, right: 5 }}>

          <Link to={`#`} onClick={() => { setArrsetPoliceForceID(row.ArrsetPoliceForceID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
            <i className="fa fa-trash"></i>
          </Link>

        </div>
    }
  ]

  const set_Edit_Value = (row) => {
    get_Arrest_Count(arrestID)
    setStatus(true);
    setModal(true);
    setUpdateStatus(updateStatus + 1);
    setArrsetPoliceForceID(row.ArrsetPoliceForceID);
  }

  const DeletePin = () => {
    const val = {
      'ArrsetPoliceForceID': arrsetPoliceForceID,
      'DeletedByUserFK': loginPinID
    }
    AddDeleteUpadate('ArrsetPoliceForce/Delete_ArrsetPoliceForce', val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_Data_PoliceForce(arrestID);
        get_Arrest_Count(arrestID)
      } else { console.log("Somthing Wrong"); }
    })
  }

  const setStatusFalse = (e) => {
    setModal(true); setStatus(false);
    setUpdateStatus(updateStatus + 1);
  }

  return (
    <>
      <div className="col-12 col-md-12 pt-2 p-0" >
        <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center ">
          <p className="p-0 m-0">Police Force</p>
          <div style={{ marginLeft: 'auto' }}>
            <Link to={''} className="btn btn-sm bg-green text-white px-2 py-0" data-toggle="modal" onClick={setStatusFalse} data-target="#PoliceForceModal" style={{ marginTop: '-6px' }}>
              <i className="fa fa-plus"></i>
            </Link>
            <FindListDropDown
              array={ArrPoliceForceListDropDownArray}
            />
          </div>
        </div>
        {
          loder ?
            <DataTable
              dense
              columns={columns}
              data={policeForceData}
              pagination
              highlightOnHover
              noDataComponent={"There are no data to display"}
            />
            :
            <Loader />

        }
      </div>
      <PoliceForceAddUp  {...{ loginPinID, arrestID, loginAgencyID, arrsetPoliceForceID, status, setStatus, modal, setModal, get_Data_PoliceForce, updateStatus }} />
      <DeletePopUpModal func={DeletePin} />
    </>
  )
}

export default PoliceForce