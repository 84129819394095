import React, { useCallback, useState, useEffect, useContext } from 'react'
import Select from "react-select";
import { AddDeleteUpadate, fetchPostData } from '../../../../hooks/Api';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { toastifySuccess } from '../../../../Common/AlertMsg';
import { RequiredFieldIncident } from '../../../Utility/Personnel/Validation';
import { AgencyContext } from '../../../../../Context/Agency/Index';

const PoliceForceAddUp = (props) => {

    const { arrsetPoliceForceID, status, setStatus, modal, setModal, get_Data_PoliceForce, updateStatus, loginPinID, arrestID, loginAgencyID, } = props
    const { get_Arrest_Count } = useContext(AgencyContext)

    const [headOfAgency, setHeadOfAgency] = useState([]);
    const [policeForceDrp, setPoliceForceDrp] = useState([])
    const [editval, setEditval] = useState();

    const [value, setValue] = useState({
        'ArrestID': arrestID,
        'ArrPoliceForceID': '',
        'OfficerID': '',
        'CreatedByUserFK': loginPinID,
        'ModifiedByUserFK': '',
    })

    useEffect(() => {
        if (arrestID) setValue(pre => { return { ...pre, 'ArrestID': arrestID, 'CreatedByUserFK': loginPinID, } })
    }, [arrestID, updateStatus]);


    const [errors, setErrors] = useState({
        'ArrPoliceForceIDErrors': '',
        'OfficerIDErrors': '',
    })
    const reset = (e) => {
        setValue({
            ...value,
            'OfficerID': '', 'ArrPoliceForceID': '',

        });
        setErrors({
            'ArrPoliceForceIDErrors': '', 'OfficerIDErrors': '',
        });
    }

    const check_Validation_Error = (e) => {
        if (RequiredFieldIncident(value.ArrPoliceForceID)) {
            setErrors(prevValues => { return { ...prevValues, ['ArrPoliceForceIDErrors']: RequiredFieldIncident(value.ArrPoliceForceID) } })
        }
        if (RequiredFieldIncident(value.OfficerID)) {
            setErrors(prevValues => { return { ...prevValues, ['OfficerIDErrors']: RequiredFieldIncident(value.OfficerID) } })
        }
    }

    // Check All Field Format is True Then Submit 
    const { ArrPoliceForceIDErrors, OfficerIDErrors } = errors
    useEffect(() => {
        if (ArrPoliceForceIDErrors === 'true' && OfficerIDErrors === 'true') {
            if (status) Update_PoliceForce()
            else Add_PoliceForce()
        }
    }, [ArrPoliceForceIDErrors, OfficerIDErrors])

    useEffect(() => {
        if (arrsetPoliceForceID) {
            GetSingleData()
        }
    }, [arrsetPoliceForceID, updateStatus])

    const GetSingleData = () => {
        const val = { 'ArrsetPoliceForceID': arrsetPoliceForceID, }
        fetchPostData('ArrsetPoliceForce/GetSingleData_ArrsetPoliceForce', val)
            .then((res) => {
                if (res) {
                    setEditval(res);
                } else { setEditval([]) }
            })
    }

    useEffect(() => {
        if (status) {
            setValue({
                ...value,
                'ArrsetPoliceForceID': arrsetPoliceForceID,
                'ArrPoliceForceID': editval[0]?.ArrPoliceForceID,
                'OfficerID': editval[0]?.OfficerID,
                'ModifiedByUserFK': loginPinID,
            })
        } else {
            setValue({
                ...value,
                'ArrPoliceForceID': '',
                'OfficerID': '',
            })
        }
    }, [editval])

    useEffect(() => {
        if (loginAgencyID) {
            get_Head_Of_Agency(loginAgencyID); get_Polic_Force(loginAgencyID);
        }
    }, [loginAgencyID])

    const get_Head_Of_Agency = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
            if (data) {
                setHeadOfAgency(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency'));
            }
            else {
                setHeadOfAgency([])
            }
        })
    };

    const get_Polic_Force = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID
        }
        fetchPostData('PoliceForce/GetDataDropDown_PoliceForce', val).then((data) => {
            if (data) {
                setPoliceForceDrp(Comman_changeArrayFormat(data, 'PoliceForceID', 'Description'));
            }
            else {
                setPoliceForceDrp([])
            }
        })
    };

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
        } else {
            setValue({
                ...value,
                [name]: null
            })
        }
    }

    const Add_PoliceForce = () => {
        AddDeleteUpadate('ArrsetPoliceForce/Insert_ArrsetPoliceForce', value).then((res) => {
            get_Data_PoliceForce(arrestID);
            setModal(false);
            setStatus(false)
            get_Arrest_Count(arrestID)

            reset()
            toastifySuccess(res.Message)
        })
    }

    const Update_PoliceForce = () => {
        AddDeleteUpadate('ArrsetPoliceForce/Update_ArrsetPoliceForce', value).then((res) => {
            get_Data_PoliceForce(arrestID);
            setModal(false);
            setStatus(false)
            toastifySuccess(res.Message)
            get_Arrest_Count(arrestID)
            reset();
        })
    }
    const closeModal = () => {
        reset();
        setModal(false);
    }
    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            reset()
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 30,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }


    return (
        <>
            {
                modal ?

                    <dialog className="modal fade" style={{ background: "rgba(0,0,0, 0.5)" }} id="PoliceForceModal" tabIndex="-1" aria-hidden="true" data-backdrop="false">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="m-1 ">
                                        <fieldset style={{ border: '1px solid gray' }}>
                                            <legend style={{ fontWeight: 'bold' }}>Police Force</legend>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6  col-lg-6  dropdown__box">
                                                            <Select
                                                                name='OfficerID'
                                                                value={headOfAgency?.filter((obj) => obj.value === value?.OfficerID)}
                                                                options={headOfAgency}
                                                                styles={colourStyles}
                                                                isClearable
                                                                placeholder="Select..."
                                                                onChange={(e) => { ChangeDropDown(e, 'OfficerID') }}
                                                            />
                                                            <label htmlFor='' >Officer</label>
                                                            {errors.OfficerIDErrors !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.OfficerIDErrors}</span>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-12 col-md-6 mb-1 col-lg-6  dropdown__box">
                                                            <Select
                                                                name='ArrPoliceForceID'
                                                                value={policeForceDrp?.filter((obj) => obj.value === value?.ArrPoliceForceID)}
                                                                styles={colourStyles}
                                                                options={policeForceDrp}
                                                                isClearable
                                                                onChange={(e) => { ChangeDropDown(e, 'ArrPoliceForceID') }}
                                                                placeholder="Select..."
                                                            />
                                                            <label htmlFor='' >Description</label>
                                                            {errors.ArrPoliceForceIDErrors !== 'true' ? (
                                                                <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ArrPoliceForceIDErrors}</span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="btn-box text-right  mr-1 mb-2">
                                    {
                                        status ?

                                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => check_Validation_Error()}>Update</button>

                                            :

                                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => check_Validation_Error()}>Save</button>

                                    }
                                    <button type="button" data-dismiss="modal" onClick={() => { closeModal(); }} className="btn btn-sm btn-success mr-1">Close</button>
                                </div>
                            </div>
                        </div>
                    </dialog>

                    :
                    <></>
            }
        </>
    )
}

export default PoliceForceAddUp