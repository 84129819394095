import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Sidebar from './Components/Inc/Sidebar';
import Header from './Components/Inc/Header';
import { AgencyContext } from './Context/Agency/Index';
const CryptoJS = require("crypto-js");

const Auth = (props) => {

  const { cmp, listManagementSideBar, agencySideBar, personnelSideBar, path, incidentSideBar, dashboardSidebar, nameSearchSideBar, arrestSearchSideBar, progressData, progressStatus, reportSidebar, searchSidebar } = props
  const Com = cmp;


  const navigate = useNavigate()

  const { setAuthSession, setLogByOtp, setIsLogout } = useContext(AgencyContext);

  const IsLoginSession = sessionStorage.getItem('is-login') ? sessionStorage.getItem('is-login') : false;

  const currentLocation = window.location.pathname + window.location.search + window.location.hash;


  useEffect(() => {
    if (IsLoginSession && window.location.pathname === "/dashboard-page") {
      preventBack();
    }
  }, [window.location.pathname]);

  function preventBack() {
    window.onpopstate = function (event) {
      if (event.state && event.state.url === currentLocation || currentLocation === window.location.pathname) {
        window.history.go(1);
      }
    };
    window.history.pushState({ url: currentLocation }, '');
  }


  useEffect(() => {
    if (IsLoginSession === 'true' || IsLoginSession === true) {
      console.log("Login Sucess")
    } else {
      setIsLogout(false);
      setLogByOtp(false)
      setAuthSession('');
      navigate('/');
    }
  }, [])

  useEffect(() => {
    if (IsLoginSession === 'true' || IsLoginSession === true) {
      console.log("Login Sucess")
    } else {
      setIsLogout(false);
      setLogByOtp(false)
      setAuthSession('');
      navigate('/');
    }
  }, [IsLoginSession])

  return (
    <>
      {/* Sidebar */}
      {
        !IsLoginSession ? navigate('/')
          : <>
            <Sidebar {...{ listManagementSideBar, agencySideBar, personnelSideBar, path, incidentSideBar, dashboardSidebar, nameSearchSideBar, arrestSearchSideBar, reportSidebar, searchSidebar }} />
            <div className="page">
              <Header {...{ listManagementSideBar, agencySideBar, personnelSideBar }} />
              {/* Component */}
              <Com {...{ progressData, progressStatus }} />
            </div>
          </>
      }
    </>
  )
}

export default Auth


export function encrypt(plain) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const encryptedData = CryptoJS.AES.encrypt(plain, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedData.ciphertext.toString()));
}


export function decrypt(secret) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const decryptedData = CryptoJS.AES.decrypt(secret, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decryptedData.toString(CryptoJS.enc.Utf8);
}