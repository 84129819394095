import React, { useState, useEffect, useContext } from "react";
import { Decrypt_Id_Name, } from "../Common/Utility";
import { Link } from "react-router-dom";
import { AgencyContext } from "../../Context/Agency/Index";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Knob } from "primereact/knob";
import incident from '../../dashboardimg/incident.png';
import name from '../../dashboardimg/name.png';
import property from '../../dashboardimg/property.png';
import vehicle from '../../dashboardimg/vehicle.png';
import arrest from '../../dashboardimg/arrest.png';
import warrant from '../../dashboardimg/warrant.png';

const Dashboard = () => {

  const { localStoreArray, get_LocalStorage } = useContext(AgencyContext);
  const [greet, setGreet] = useState('');
  const [userName, setUserName] = useState('');
  const [startDate, setStartDate] = useState(new Date());

  const localStore = {
    Value: "",
    UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    Key: JSON.stringify({ AgencyID: '', PINID: '', IncidentStatus: '', UserName: '', IncidentID: '', IncidentNumber: '', MasterNameID: '', NameID: '', IsSuperadmin: '', LocalAgencyID: '', ArrestID: '', ChargeID: '', arrestStatus: '', Agency_Name: '', NameStatus: '', OffenceID: '', propertyStatus: '', VehicleID: '', VehicleStatus: '', MasterPropertyID: '', WarrantID: '', WarrantStatus: '', }),
  }

  useEffect(() => {
    if (!localStoreArray.UserName || !localStoreArray.AgencyID || !localStoreArray.PINID) {
      get_LocalStorage(localStore);
    }
  }, []);

  // Onload Function
  useEffect(() => {
    if (localStoreArray) {
      if (localStoreArray?.UserName) {
        setUserName(localStoreArray?.UserName);
      }
    }
  }, [localStoreArray])

  const myDate = new Date();
  const hrs = myDate.getHours();

  useEffect(() => {
    if (hrs < 12) {
      setGreet('Good Morning')
    } else if (hrs >= 12 && hrs <= 17) {
      setGreet('Good Afternoon')
    } else if (hrs >= 17 && hrs <= 24) {
      setGreet('Good Evening')
    } else {
      setGreet('')
    }
  }, []);

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>
      <div className="section-body mt-4" style={{ margin: '10px 10px 10px 15px' }}>
        <div className="row clearfix">
          <div className="main-dashboard col-12 mb-2 mt-2">
            <div className="d-flex mt-2">
              <div className="md col-9">
                <h6 className="d-inline-block form-set p-2" >
                  Welcome ,{userName ? userName : ''}
                </h6>
                <h4 className="d-inline-block ml-2">{greet} </h4>
                <small className="d-block">
                </small>
              </div>
              <div className="col-3">
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
              </div>
            </div>
            <div className=" col-12 mb-3 mt-2">
              <div className="box-container ">
                <div className="box">
                  <Link to={'/incident'}>
                    <img src={incident} alt="" className="dashimg" />
                    <p className="dashname">Incident</p>
                  </Link>
                </div>
                <div className="box">
                  <Link to='/name'>
                    <img src={name} alt="" className="dashimg" />
                    <p className="dashname">Name</p>
                  </Link>
                </div>
                <div className="box">
                  <Link to='/property'>
                    <img src={property} alt="" className="dashimg" />
                    <p className="dashname">Property</p>
                  </Link>
                </div>
                <div className="box">
                  <Link to='/vehicle'>
                    <img src={vehicle} alt="" className="dashimg" />
                    <p className="dashname">Vehicle</p>
                  </Link>
                </div>

                <div className="box">
                  <Link to='/newreports'>
                    <img src={arrest} alt="" className="dashimg" />
                    <p className="dashname">Arrest</p>
                  </Link>
                </div>
                <div className="box">
                  <Link to='/warrant'>
                    <img src={warrant} alt="" className="dashimg" />
                    <p className="dashname">Warrant</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4" style={{ margin: '10px 10px 10px 15px', padding: '0px 0px' }}>
        <div className="row clearfix mt-2">
          <div className="main-dashboard col-12 mb-1">
            <div className="d-flex dash-theme">
              <div className="md col-9">
                <div className="row">
                  <div className="col-2" style={{ borderRight: '1px solid gray' }}>
                    <h6>Total Incident</h6>
                    <h5 className="text-bold pl-2 mt-0 mb-0 text-white">110</h5>
                  </div>
                  <div className="col-2" style={{ borderRight: '1px solid gray' }}>
                    <h6>Total Incident</h6>
                    <h5 className="text-bold pl-2 mt-0 mb-0 text-white">90</h5>
                  </div>
                  <div className="col-2" style={{ borderRight: '1px solid gray' }}>
                    <h6>Total Incident</h6>
                    <h5 className="text-bold pl-2 mt-0 mb-0 text-white">10</h5>
                  </div>
                </div>
              </div>
              <div className="col-3 mt-2">
                <Select
                  name='FBIID'
                  styles={customStylesWithOutColor}
                  isClearable
                  placeholder="Select Incident..."
                />
              </div>
            </div>
            <div className=" col-12 mb-5 mt-3">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="card-body text-center">
                    <Knob value={50} readOnly valueColor="#708090" rangeColor="#081c3c" size={100} step={10} strokeWidth={12} valueTemplate={'20%'} min={-50} max={50} />
                    <h6>Planned</h6>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card-body text-center">
                    <Knob value={50} readOnly valueColor="#708090" rangeColor="#081c3c" size={100} step={10} strokeWidth={12} valueTemplate={'40%'} min={-50} max={50} />
                    <h6>In progress</h6>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card-body text-center">
                    <Knob value={50} readOnly valueColor="#708090" rangeColor="#081c3c" size={100} step={10} strokeWidth={12} valueTemplate={'60%'} min={-50} max={50} />
                    <h6>Completed</h6>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="card-body text-center">
                    <Knob value={50} readOnly valueColor="#708090" rangeColor="#081c3c" size={100} step={10} strokeWidth={12} valueTemplate={'70%'} min={-50} max={50} />
                    <h6>In Completed</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
